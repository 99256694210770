import matrix3x3 from './matrix3x3';
import matrix8x8 from './matrix8x8';
import matrix8x16 from './matrix8x16';
import matrixOled128x64 from './matrixOled128x64';
import matrix8x16JR from './matrix8x16JR';
import matrix8x16BhootJr from './matrix8x16BhootJr';
import piezoBuzzerPiano from './piezoBuzzerPiano';
import piezoBuzzerPianoNoDuration from './piezoBuzzerPianoNoDuration';
import createVariableModal from './createVariableModal';
import createFuncionModal from './createFuncionModal';
import display7Segments from './display7Segments';

window.popups = {
  openCreateVariableModal: (event) => {
    createVariableModal.js.bind(createVariableModal)(event);
  },
  openCreateFuncionModal: (event) => {
    createFuncionModal.js.bind(createFuncionModal)(event);
  },
  openModal: (block) => {
    window.Blockly.WidgetDiv.createDom();
    if (block.openModal === 'matrix3x3') matrix3x3.js.bind(matrix3x3)(block);
    if (block.openModal === 'matrix8x8') matrix8x8.js.bind(matrix8x8)(block);
    if (block.openModal === 'matrix8x16') matrix8x16.js.bind(matrix8x16)(block);
    if (block.openModal === 'matrixOled128x64') matrixOled128x64.js.bind(matrixOled128x64)(block);
    if (block.openModal === 'matrix8x16JR') matrix8x16JR.js.bind(matrix8x16JR)(block);
    if (block.openModal === 'matrix8x16BhootJr') matrix8x16BhootJr.js.bind(matrix8x16BhootJr)(block);
    if (block.openModal === 'piezoBuzzerPiano') piezoBuzzerPiano.js.bind(piezoBuzzerPiano)(block);
    if (block.openModal === 'piezoBuzzerPianoNoDuration') piezoBuzzerPianoNoDuration.js.bind(piezoBuzzerPianoNoDuration)(block);
    if (block.openModal === 'display7Segments') display7Segments.js.bind(display7Segments)(block);
  },
  firstInit: (block) => {
    if (block.openModal === 'matrix3x3') matrix3x3.buildMiniBlock.bind(matrix3x3)(block, block.cols, true);
    if (block.openModal === 'matrix8x8') matrix8x8.buildMiniBlock.bind(matrix8x8)(block, block.cols, true);
    if (block.openModal === 'matrix8x16') matrix8x16.buildMiniBlock.bind(matrix8x16)(block, block.cols, true);
    if (block.openModal === 'matrixOled128x64') matrixOled128x64.buildMiniBlock.bind(matrixOled128x64)(block, true);
    if (block.openModal === 'matrix8x16JR') matrix8x16JR.buildMiniBlock.bind(matrix8x16JR)(block, block.cols, true);
    if (block.openModal === 'matrix8x16BhootJr') matrix8x16BhootJr.buildMiniBlock.bind(matrix8x16BhootJr)(block, block.cols, true);
    if (block.openModal === 'piezoBuzzerPiano') piezoBuzzerPiano.buildMiniBlock.bind(piezoBuzzerPiano)(block, true);
    if (block.openModal === 'piezoBuzzerPianoNoDuration') piezoBuzzerPianoNoDuration.buildMiniBlock.bind(piezoBuzzerPianoNoDuration)(block, true);
    if (block.openModal === 'display7Segments') display7Segments.buildMiniBlock.bind(display7Segments)(block, true);
  },
};

export default window.popups;
