/* eslint no-underscore-dangle: 0 */
/* eslint camelcase: 0 */
/* eslint dot-notation: 0 */
/* eslint no-param-reassign: 0 */
const LOGIC_OPERATORS = {
  EQ: '==',
  NEQ: '!=',
  LT: '<',
  LTE: '<=',
  GT: '>',
  GTE: '>=',
}

const LOGIC_OPERATIONS = {
  AND: '&&',
  OR: '||',
}

export const logic_compare = (block, generator) => {
  const mode = block.getFieldValue('OP');
  const operator = LOGIC_OPERATORS[mode];
  const order = (operator === '==' || operator === '!=')
    ? generator.ORDER_EQUALITY : generator.ORDER_RELATIONAL;
  const arg0 = generator.valueToCode(block, 'A', order) || '0';
  const arg1 = generator.valueToCode(block, 'B', order) || '0';
  const code = `${arg0} ${operator} ${arg1}`
  return [code, order];
};

export const logic_operation = (block, generator) => {
  const mode = block.getFieldValue('OP');
  const operation = LOGIC_OPERATIONS[mode];
  const order = (operation === '&&')
    ? generator.ORDER_LOGICAL_AND : generator.ORDER_LOGICAL_OR;
  const arg0 = generator.valueToCode(block, 'A', order) || '0';
  const arg1 = generator.valueToCode(block, 'B', order) || '0';
  const code = `${arg0} ${operation} ${arg1}`;
  return [code, order];
}

export const logic_negate = (block, generator) => {
  const order = generator.ORDER_UNARY_PREFIX;
  const code = generator.valueToCode(block, 'BOOL', order) || 'false';
  return [code, generator.ORDER_ATOMIC];
}

export const logic_boolean = (block, generator) => {
  let code = block.getFieldValue('BOOL');
  code = (code === 'TRUE')
    ? 'true' : 'false';
  return [code, generator.ORDER_ATOMIC];
}

export const logic_null = (block, generator) => {
  return ['NULL', generator.ORDER_ATOMIC];
};