/* eslint import/no-extraneous-dependencies: 0 */
/* eslint no-underscore-dangle: 0 */
/* eslint object-shorthand: 0 */
/* eslint dot-notation: 0 */
import * as Blockly from 'blockly';
import { FieldSlider } from '@blockly/field-slider';
import colors from '../utils/colors';


const mathNumberValidator = function (value) {
  const isPwm = this.getSourceBlock().parentBlock_
  let number = 0;
  if (isPwm !== null && isPwm.type === 'math_number_pwm') {
    number = parseFloat(value || 0);
    if (number > 255) {
      number = 255;
    }
    return (Number.isNaN(number) || parseInt(number, 10) < 0) ? null : String(number);
  }
  number = parseFloat(value || 0);
  return Number.isNaN(number) ? null : String(number);
};

Blockly.Blocks.math_number = {
  init: function () {
    this.setOutputShape(Blockly.OUTPUT_SHAPE_ROUND);
    this.setColour('#ffffff');
    this.appendDummyInput()
      .appendField(new Blockly.FieldTextInput('0', mathNumberValidator), 'NUM');
    this.setOutput(true, Number);
    this.setTooltip(Blockly.Msg['LANG_MATH_NUMBER_TOOLTIP']);
  },
};

Blockly.Blocks.math_arithmetic = {
  OPERATORS: [
    ['%{BKY_MATH_ADDITION_SYMBOL}', 'ADD'],
    ['%{BKY_MATH_SUBTRACTION_SYMBOL}', 'MINUS'],
    ['%{BKY_MATH_MULTIPLICATION_SYMBOL}', 'MULTIPLY'],
    ['%{BKY_MATH_DIVISION_SYMBOL}', 'DIVIDE'],
    ['%{BKY_MATH_POWER_SYMBOL}', 'POWER'],
  ],
  TOOLTIPS: {
    ADD: Blockly.Msg.MATH_ARITHMETIC_TOOLTIP_ADD,
    MINUS: Blockly.Msg.MATH_ARITHMETIC_TOOLTIP_MINUS,
    MULTIPLY: Blockly.Msg.MATH_ARITHMETIC_TOOLTIP_MULTIPLY,
    DIVIDE: Blockly.Msg.MATH_ARITHMETIC_TOOLTIP_DIVIDE,
    POWER: Blockly.Msg.MATH_ARITHMETIC_TOOLTIP_POWER,
  },
  init: function () {
    this.setOutputShape(Blockly.OUTPUT_SHAPE_ROUND);
    this.setColour(colors.COLOUR_MATH);
    this.setOutput(true, Number);
    this.appendValueInput('A')
      .setCheck(Number);
    this.appendValueInput('B')
      .setCheck(Number)
      .appendField(new Blockly.FieldDropdown(this.OPERATORS), 'OP');
    this.setInputsInline(true);
    this.setTooltip(() => {
      const mode = this.getFieldValue('OP');
      return this.TOOLTIPS[mode];
    });
  },
};

Blockly.Blocks.math_single = {
  init: function () {
    this.setColour(colors.COLOUR_MATH);
    this.setOutput(true, Number);
    this.setOutputShape(Blockly.OUTPUT_SHAPE_ROUND);
    this.appendValueInput('NUM')
      .setCheck(Number)
      .appendField(new Blockly.FieldDropdown([
        [Blockly.Msg.MATH_SINGLE_OP_ROOT || 'SQR ROOT', 'ROOT'],
        [Blockly.Msg.MATH_SIGNLE_OP_ABS || 'ABS', 'ABS'],
        ['-', 'NEG'],
        ['ln', 'LN'],
        ['log10', 'LOG10'],
        ['e^', 'EXP'],
        ['10^', 'POW10'],
      ]), 'OP');
    this.setTooltip(() => {
      const op = this.getFieldValue('OP');
      return Blockly.Blocks.math_single.TOOLTIPS[op];
    })
  },
};

Blockly.Blocks.math_single.TOOLTIPS = {
  ROOT: Blockly.Msg.MATH_SINGLE_TOOLTIP_ROOT,
  ABS: Blockly.Msg.MATH_SINGLE_TOOLTIP_ABS,
  NEG: Blockly.Msg.MATH_SINGLE_TOOLTIP_NEG,
  LN: Blockly.Msg.MATH_SINGLE_TOOLTIP_LN,
  LOG10: Blockly.Msg.MATH_SINGLE_TOOLTIP_LOG10,
  EXP: Blockly.Msg.MATH_SINGLE_TOOLTIP_EXP,
  POW10: Blockly.Msg.MATH_SINGLE_TOOLTIP_POW10,
};

Blockly.Blocks.math_modulo = {
  init: function () {
    this.setColour(colors.COLOUR_MATH);
    this.setOutput(true, Number);
    this.setOutputShape(Blockly.OUTPUT_SHAPE_ROUND);
    this.appendValueInput('DIVIDEND')
      .setCheck(Number)
    this.appendDummyInput()
      .appendField('%');
    this.appendValueInput('DIVISOR')
      .setCheck(Number)
      .setAlign(Blockly.ALIGN_RIGHT);
    this.setInputsInline(true);
    this.setTooltip(Blockly.Msg.MATH_MODULO_TOOLTIP);
  },
}

Blockly.Blocks.advanced_map = {
  init: function () {
    this.setColour(colors.COLOUR_MATH);
    this.setOutput(true);
    this.setOutputShape(Blockly.OUTPUT_SHAPE_ROUND);
    this.appendValueInput('NUM', Number)
      .appendField(Blockly.Msg.LANG_MATH_ADVANCED_MAP_MAP)
      .setCheck(Number);
    this.appendValueInput('FROM_MIN', Number)
      .appendField(Blockly.Msg.LANG_MATH_ADVANCED_MAP_FROM)
      .setCheck(Number);
    this.appendValueInput('FROM_MAX', Number)
      .appendField(Blockly.Msg.LANG_MATH_ADVANCED_MAP_HYPHEN)
      .setCheck(Number);
    this.appendDummyInput()
      .appendField(Blockly.Msg.LANG_MATH_ADVANCED_MAP_BRACKET);
    this.appendValueInput('TO_MIN', Number)
      .appendField(Blockly.Msg.LANG_MATH_ADVANCED_MAP_TO)
      .setCheck(Number);
    this.appendValueInput('TO_MAX', Number)
      .appendField(Blockly.Msg.LANG_MATH_ADVANCED_MAP_HYPHEN)
      .setCheck(Number);
    this.appendDummyInput()
      .appendField(Blockly.Msg.LANG_MATH_ADVANCED_MAP_HYPHEN);
    this.setInputsInline(true);
    this.setTooltip(Blockly.Msg.LANG_MATH_ADVANCED_MAP_TOOLTIP);
  },
}

Blockly.Blocks.math_array = {
  init: function () {
    this.setColour(colors.COLOUR_MATH);
    this.setOutput(true, Number);
    this.setOutputShape(Blockly.OUTPUT_SHAPE_ROUND);
    this.appendDummyInput()
      .appendField(Blockly.Msg.LANG_MATH_ARRAY_ARRAY3)
      .appendField(Blockly.Msg.LANG_MATH_ARRAY_BRACKET3)
      .appendField(new Blockly.FieldTextInput('0', mathNumberValidator), 'NUM0')
      .appendField(Blockly.Msg.LANG_MATH_ARRAY_COMMA);
    this.appendDummyInput()
      .appendField(new Blockly.FieldTextInput('0', mathNumberValidator), 'NUM1')
      .appendField(Blockly.Msg.LANG_MATH_ARRAY_COMMA);
    this.appendDummyInput()
      .appendField(new Blockly.FieldTextInput('0', mathNumberValidator), 'NUM2')
      .appendField(Blockly.Msg.LANG_MATH_ARRAY_BRACKET4);
    this.setInputsInline(true);
    this.setTooltip(Blockly.Msg.LANG_MATH_ARRAY_TOOLTIP);
  },
}

Blockly.Blocks.math_random = {
  init: function () {
    this.setColour(colors.COLOUR_MATH);
    this.setOutput(true, Number);
    this.setOutputShape(Blockly.OUTPUT_SHAPE_ROUND);
    this.appendValueInput('NUM', Number)
      .appendField(Blockly.Msg.LANG_ADVANCED_MATH_RANDOM)
      .setCheck(Number);
    this.appendValueInput('DMAX', Number)
      .appendField(Blockly.Msg.LANG_ADVANCED_MATH_RANDOM_AND)
      .setCheck(Number);
    this.setInputsInline(true);
    this.setTooltip(Blockly.Msg.LANG_ADVANCED_MATH_RANDOM_TOOLTIP);
  },
}

Blockly.Blocks.math_number_pwm = {
  init: function () {
    this.setColour(colors.COLOUR_MATH);
    this.setOutput(true, Number);
    this.setOutputShape(Blockly.OUTPUT_SHAPE_ROUND);
    this.appendValueInput('NUM', Number)
      .appendField(Blockly.Msg.LANG_MATH_PWM)
      .setCheck(Number);
    this.setInputsInline(true);
    this.setTooltip(Blockly.Msg.LANG_MATH_NUMBER_TOOLTIP);
  },
}

Blockly.Blocks.math_number_minmax = {
  init: function () {
    this.setColour(colors.COLOUR_MATH);
    this.setOutput(true, Number);
    this.setOutputShape(Blockly.OUTPUT_SHAPE_ROUND);
    this.appendDummyInput()
      .appendField(new FieldSlider(0, 0, 255, 1), 'SLIDER');
    this.setInputsInline(true);
    this.setTooltip(Blockly.Msg.LANG_MATH_NUMBER_TOOLTIP);
  },
}