/* eslint prefer-promise-reject-errors: 0 */
import axios from 'axios';
import * as routes from '@educabot/educablocks-cosmos';
import { createIntl, createIntlCache } from 'react-intl';
import { Subject } from 'rxjs';
import { getNewMessage, getNewState } from '../actions/bloquesAction';
import { getLocale } from '../config/locale';
import SocketAgent from './SocketAgent';
import WebSerialAgent from './webSerialAgent';
import WebUSBAgent from './webUSBAgent';

class Agent {
  constructor(store, board) {
    this.store = store;
    this.board = board;
    this.platform = 'Windows';
    this.socketElectronConnection = 'ws://localhost:3000';
    this.httpElectronConnection = 'http://localhost:3001';
    this.serialMonitorMessages = new Subject();

    const { locale, intlMessages } = getLocale();
    this.intl = createIntl({ locale, messages: intlMessages }, createIntlCache());

    this.agent = (this.board.compilerBoard === 'microbit')
      ? new WebUSBAgent(this.store, this.board, this.socketElectronConnection, this.httpElectronConnection, this.serialMonitorMessages, this.intl)
      : new SocketAgent(this.store, this.socketElectronConnection, this.httpElectronConnection, this.serialMonitorMessages, this.intl);
    this.agentType = (this.board.compilerBoard === 'microbit') ? 'usb' : 'socket';
    this.intervalConnectAgent = null;
  }

  checkSocket = () => {
    if (!process.env.IS_ELECTRON && !this.agent?.state?.socket && !this.agent.MICROBIT_PRODUCT_ID && 'serial' in navigator) {
      // if (process.env.IS_ELECTRON || this.platform === 'ChromeOS' || process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'staging') {
      this.agent.stopCheckingAgent();
      this.agent = new WebSerialAgent(this.store, this.board, this.socketElectronConnection, this.httpElectronConnection, this.serialMonitorMessages, this.intl);
      this.agentType = 'serial';
    }
    // console.log('===============agent', this.agentType, this.platform, process.env.NODE_ENV);
  }

  connectNewBoard = () => {
    if (this.agentType === 'serial' || this.agentType === 'usb') {
      this.agent.connectNewBoard();
    }
  }

  forceAgent = (type = 'socket') => {
    this.agent.stopCheckingAgent();
    switch (type) {
      case 'serial':
        this.agent = new WebSerialAgent(this.store, this.board, this.socketElectronConnection, this.httpElectronConnection, this.serialMonitorMessages, this.intl);
        break;
      case 'usb':
        this.agent = new WebUSBAgent(this.store, this.board, this.socketElectronConnection, this.httpElectronConnection, this.serialMonitorMessages, this.intl);
        break;
      case 'socket':
      default:
        this.agent = new SocketAgent(this.store, this.socketElectronConnection, this.httpElectronConnection, this.serialMonitorMessages, this.intl);
        this.agent.disconnectSocket();
        break;
    }
    this.agentType = type;
  }

  setPlatform = (platform, timeout = 4000) => {
    this.platform = platform;
    if (!this.intervalConnectAgent) {
      this.intervalConnectAgent = setTimeout(() => {
        this.checkSocket();
        clearTimeout(this.intervalConnectAgent);
        this.intervalConnectAgent = null;
      }, timeout);
    }
  };

  disconnectSocket = () => this.agent.disconnectSocket();

  stopCheckingAgent = () => this.agent.stopCheckingAgent();

  findPorts = () => {
    if (this.agent) {
      this.agent.findPorts();
    }
  }

  closeMonitor = (port = '') => this.agent.closeMonitor(port);

  openMonitor = (port = '', baudios) => this.agent.openMonitor(port, baudios);

  uploadToPort = (code = '', board = '', port = '') => this.agent.uploadToPort(code, board, port);

  changeBaudios = (port = '', baudios = 0) => {
    this.closeMonitor(port);
    this.openMonitor(port, baudios);
  };

  sendMonitor = (port = '', command = '') => this.agent.sendMonitor(port, command);

  uploadRemoteSession = (hex = '', board = '', port = '') => this.agent.uploadRemoteSession(hex, board, port);

  compileRemoteSession = (code = '', board = '') => new Promise((resolve, reject) => {
    this.store.dispatch(getNewState('#### UPLOAD TO PORT'));
    /**
     * Response object
     * status: Possible values are SUCCESS, ERROR.
     * message: Error message, if there is any.
     */
    const selectedBoard = board.compilerBoard || 'arduino';
    if (!board) {
      this.store.dispatch(getNewState('#### ERROR EB_BUILD'));
      this.store.dispatch(getNewMessage(this.intl.formatMessage({ id: 'agent.boardMustBeSelected' })));
      reject('Debes seleccionar una placa');
    }

    this.store.dispatch(getNewMessage(this.intl.formatMessage({ id: 'agent.codeIsCompiling' })));


    let buildURI = (process.env.IS_ELECTRON) ? `${this.httpElectronConnection}/eb_build` : routes.ebBuildUri;
    let buildParams = `board=${board.compilerBoard}&text=${encodeURIComponent(code)}`;
    if (selectedBoard === 'microbit') {
      buildURI = (process.env.IS_ELECTRON) ? `${this.httpElectronConnection}/microbit_build` : routes.microbitBuildUri;
      buildParams = `text=${encodeURIComponent(code)}`;
    }

    this.store.dispatch(getNewState('#### UPLOAD TO EB_BUILD'));

    axios.post(
      buildURI,
      buildParams,
      {
        withCredentials: false,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': '*/*', //eslint-disable-line
        },
      },
    ).then((response) => {
      const hex = (selectedBoard === 'microbit') ? response.data.payload.hexMbcodal : response.data.payload.hex;
      if (response.data.status === 'SUCCESS' && hex) {
        // store.dispatch(getNewState('#### FINISH EB_BUILD'));
        this.store.dispatch(getNewState('#### FINISH AGENT'));
        resolve(hex);
      } else {
        this.store.dispatch(getNewState('#### ERROR EB_BUILD'));
        this.store.dispatch(getNewMessage(response.data.message));
        reject(response.data.message);
      }
    }).catch((responseError) => {
      this.store.dispatch(getNewState('#### ERROR EB_BUILD'));
      // store.dispatch(getNewMessage(responseError));
      reject(responseError);
    });
  });
}

export default Agent;
