/* eslint no-underscore-dangle: 0 */
/* eslint camelcase: 0 */
/* eslint dot-notation: 0 */

/* eslint  no-param-reassign: 0 */
export const math_number = (block, generator) => {
  const code = parseFloat(block.getField('NUM').getText());

  return [code, code < 0 ? generator.ORDER_UNARY_PREFIX : generator.ORDER_ATOMIC];
};

export const math_arithmetic = (block, generator) => {
  const OPERATORS = {
    ADD: [' + ', generator.ORDER_ADDITIVE],
    MINUS: [' - ', generator.ORDER_ADDITIVE],
    MULTIPLY: [' * ', generator.ORDER_MULTIPLICATIVE],
    DIVIDE: [' / ', generator.ORDER_MULTIPLICATIVE],
    POWER: [null, generator.ORDER_NONE],
  };

  const mode = block.getFieldValue('OP');
  const tuple = OPERATORS[mode];
  const op = tuple[0];
  const order = tuple[1];

  const argument0 = generator.valueToCode(block, 'A', order) || '0';
  const argument1 = generator.valueToCode(block, 'B', order) || '0';
  let code;
  if (!op) {
    code = `pow(${argument0}, ${argument1})`;
    return [code, generator.ORDER_UNARY_POSTFIX];
  }
  code = argument0 + op + argument1;

  return [code, order];
};

const math_single_operations = {
  ABS: 'abs(',
  ROOT: 'sqrt(',
  LN: 'log(',
  LOG10: 'log(',
  EXP: 'exp(',
  POW10: 'pow(10, ',
  SIN: 'sin(',
  ASIN: 'asin(',
  COS: 'cos(',
  ACOS: 'acos(',
  TAN: 'tan(',
  ATAN: 'atan(',
};

export const math_single = (block, generator) => {
  const op = block.getFieldValue('OP');
  let arg = generator.valueToCode(block, 'NUM', generator.ORDER_UNARY_PREFIX) || '';
  let code = ''
  if (op === 'NEG') {
    if (arg[0] === '-') {
      // --3 is not legal in Dart.
      arg = ` ${arg}`;
    }
    code += `-${arg}`;
    return [code, generator.ORDER_UNARY_PREFIX];
  }

  const PI = 3.14159;

  if (op === 'SIN' || op === 'COS' || op === 'TAN') {
    code = `${math_single_operations[op]}${arg} / 180 * ${PI})`;
    return [code, generator.ORDER_UNARY_POSTFIX];
  }
  if (op === 'ASIN' || op === 'ACOS' || op === 'ATAN') {
    code = `${math_single_operations[op]}${arg}) / ${PI} * 180`;
    return [code, generator.ORDER_MULTIPLICATIVE];
  }
  if (op === 'LOG10') {
    code = `${math_single_operations[op]}${arg}) / log(10)`;
    return [code, generator.ORDER_MULTIPLICATIVE];
  }
  code = `${math_single_operations[op]}${arg})`;
  return [code, generator.ORDER_UNARY_POSTFIX];
};

export const math_modulo = (block, generator) => {
  const dividend = generator.valueToCode(block, 'DIVIDEND', generator.ORDER_MULTIPLICATIVE) || '';
  const divisor = generator.valueToCode(block, 'DIVISOR', generator.ORDER_MULTIPLICATIVE) || '';
  const code = `${dividend} % ${divisor}`;
  return [code, generator.ORDER_MULTIPLICATIVE];
}

export const advanced_map = (block, generator) => {
  const num = generator.valueToCode(block, 'NUM', generator.ORDER_NONE);
  const from_min = generator.valueToCode(block, 'FROM_MIN', generator.ORDER_NONE);
  const from_max = generator.valueToCode(block, 'FROM_MAX', generator.ORDER_NONE);
  const to_min = generator.valueToCode(block, 'TO_MIN', generator.ORDER_NONE);
  const to_max = generator.valueToCode(block, 'TO_MAX', generator.ORDER_NONE);

  const code = `map(${num},${from_min},${from_max},${to_min},${to_max})`;
  return [code, generator.ORDER_ATOMIC];
}

export const math_array = (block, generator) => {
  let num0 = block.getFieldValue('NUM0');
  num0 = window.parseFloat(num0);
  let num1 = block.getFieldValue('NUM1');
  num1 = window.parseFloat(num1);
  let num2 = block.getFieldValue('NUM2');
  num2 = window.parseFloat(num2);

  const code = `{${num0},${num1},${num2}}`;
  return [code, generator.ORDER_ATOMIC];
}

export const math_random = (block, generator) => {
  const num = generator.valueToCode(block, 'NUM', generator.ORDER_NONE);
  let dmax = generator.valueToCode(block, 'DMAX', generator.ORDER_ATOMIC);
  dmax = parseInt(dmax, 10) + 1;

  generator.setups_['math_random'] = 'randomSeed(analogRead(0));';

  const code = `random(${num},${dmax})`;
  return [code, generator.ORDER_ATOMIC];
}

export const math_number_pwm = (block, generator) => {
  const code = generator.valueToCode(block, 'NUM', generator.ORDER_ATOMIC);
  const order = code < 0
    ? generator.ORDER_UNARY_PREFIX : generator.ORDER_ATOMIC;
  return [code, order];
}

export const math_number_minmax = (block, generator) => {
  const code = block.getFieldValue('SLIDER');

  return [code, generator.ORDER_ATOMIC];
}