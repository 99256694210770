/* eslint no-underscore-dangle: 0 */
/* eslint camelcase: 0 */
/* eslint dot-notation: 0 */
/* eslint no-param-reassign: 0 */
export const colour_educabot = function (block, generator) {
  const color = block.getFieldValue('COLOUR');
  const red = parseInt(color.substring(1, 3), 16);
  const green = parseInt(color.substring(3, 5), 16);
  const blue = parseInt(color.substring(5), 16);
  const code = `${red}, ${green}, ${blue}`;
  return [code, generator.ORDER_ATOMIC];
};

export const salidas_led2 = function (block, generator) {
  const dropdown_state = block.getFieldValue('STAT');
  const dropdown_npin = block.getFieldValue('NPIN');

  generator.setups_[`setup_green_led_${dropdown_npin}`] = `pinMode(${dropdown_npin}, OUTPUT);`;

  const code = `digitalWrite(${dropdown_npin}, ${dropdown_state});
`;

  return code;
};

export const salidas_led_brillo = (block, generator) => {
  const pin = block.getFieldValue('NPIN');
  const intensity = generator.valueToCode(block, 'DATOIN', generator.ORDER_ATOMIC) || 0;

  generator.setups_[`setup_led_brillo_${pin}`] = `pinMode(${pin}, OUTPUT)`;

  const code = `analogWrite(${pin}, ${intensity});
  `;

  return code;
};

export const led_neopixel_pin = function (block, generator) {
  const pin = block.getFieldValue('NPIN');
  const color = generator.valueToCode(block, 'COLOUR', generator.ORDER_ATOMIC) || 0;

  generator.definitions_['def_led_neopixel_pin'] = '#include <Adafruit_NeoPixel.h>';
  generator.definitions_[`def_led_neopixel_${pin}`] = `Adafruit_NeoPixel LEDRGB_${pin}(1, ${pin}, NEO_GRB + NEO_KHZ800);`;
  generator.setups_[`setup_led_neopixel_${pin}`] = `LEDRGB_${pin}.begin();`;

  const code = `LEDRGB_${pin}.clear();
LEDRGB_${pin}.setPixelColor(0, LEDRGB_${pin}.Color(${color}));
LEDRGB_${pin}.show();
delayMicroseconds(300);
`;

  return code;
};

export const ENTRADAS_piezo_buzzer_piano = (block, generator) => {
  const value = block.getFieldValue('PIANO');
  const pin = block.getFieldValue('NPIN');
  return `tone(${pin || 2}, ${(value.basePitch * (value.octave === 3 ? 4 : value.octave)) || 165}, ${value.duration || 1000});
`;
};

export const ENTRADAS_piezo_buzzerno = (block, generator) => {
  const pin = block.getFieldValue('NPIN');
  return `noTone(${pin});
`
};