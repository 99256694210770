import React, { Component } from 'react';
import clsx from 'clsx';
import { locales, setLocale, getLocale } from '../../config/locale';
import styles from './TranslationsDropdown.mod.scss';

class TranslationsDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      state: props.state,
      loading: false,
      openDropdown: false,
      selectedLocale: '',
    };
    this.openDropdown = this.openDropdown.bind(this);
    this.onSelect = this.onSelect.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  componentWillReceiveProps(newProps) {
    this.setState({ selectedLocale: getLocale().locale });
  }

  handleClickOutside = (event) => {
    const { openDropdown } = this.state;
    if (openDropdown) {
      if (this.translationButtonRef && !this.translationButtonRef.contains(event.target)) {
        this.setState({
          openDropdown: false,
        });
      }
    }
  }

  openDropdown = () => {
    const { openDropdown } = this.state;
    this.setState({ openDropdown: !openDropdown });
  }

  onSelect = (newLocale) => {
    console.log('=================Selected:', newLocale);
    setLocale(newLocale);
    window.location.reload(); // Reload the page to apply the new locale
  }

  render() {
    const { openDropdown, selectedLocale } = this.state;

    return (
      <div
        ref={(translationButtonRef) => { this.translationButtonRef = translationButtonRef; return false; }}
      >
        <div className={openDropdown ? styles.modalCardBase : styles.modalCardHidden}>
          {
            Object.keys(locales).map((opt) => (
              <button
                type="button"
                key={`button-${locales[opt].name}`}
                className={clsx(styles.modalCardButton, (selectedLocale === locales[opt].locale) ? styles.selected : '')}
                onClick={(selectedLocale !== locales[opt].locale) ? () => { this.onSelect(opt); } : () => {}}
              >
                {locales[opt].name}
              </button>
            ))
          }
        </div>
        <div className="avatarContainer" onClick={this.openDropdown} style={{ cursor: 'pointer' }}>
          <img alt="Language" className="" src={`${process.env.IS_ELECTRON ? '.' : ''}/images/language_multilingual.svg`} />
        </div>
      </div>
    );
  }
}

export default TranslationsDropdown;
