/* eslint import/no-extraneous-dependencies: 0 */
/* eslint dot-notation: 0 */
/* eslint quotes: 0 */
/* eslint no-multi-spaces: 0 */
/* eslint spaced-comment: 0 */
import * as localeEn from 'blockly/msg/en';

export const en = {
  ...localeEn,
};

en["ADD_COMMENT"] = "Add Comment";
en["CANNOT_DELETE_VARIABLE_PROCEDURE"] = "Can't delete the variable '%1' because it's part of the definition of the function '%2'";
en["CHANGE_VALUE_TITLE"] = "Change value:";
en["CLEAN_UP"] = "Clean up Blocks";
en["COLLAPSE_ALL"] = "Collapse Blocks";
en["COLLAPSE_BLOCK"] = "Collapse Block";
en["COLOUR_BLEND_COLOUR1"] = "colour 1";
en["COLOUR_BLEND_COLOUR2"] = "colour 2";
en["COLOUR_BLEND_HELPURL"] = "http://meyerweb.com/eric/tools/color-blend/";
en["COLOUR_BLEND_RATIO"] = "ratio";
en["COLOUR_BLEND_TITLE"] = "blend";
en["COLOUR_BLEND_TOOLTIP"] = "Blends two colours together with a given ratio (0.0 - 1.0).";
en["COLOUR_PICKER_HELPURL"] = "https://en.wikipedia.org/wiki/Color";
en["COLOUR_PICKER_TOOLTIP"] = "Choose a colour from the palette.";
en["COLOUR_RANDOM_HELPURL"] = "http://randomcolour.com";
en["COLOUR_RANDOM_TITLE"] = "random colour";
en["COLOUR_RANDOM_TOOLTIP"] = "Choose a colour at random.";
en["COLOUR_RGB_BLUE"] = "blue";
en["COLOUR_RGB_GREEN"] = "green";
en["COLOUR_RGB_HELPURL"] = "http://www.december.com/html/spec/colorper.html";
en["COLOUR_RGB_RED"] = "red";
en["COLOUR_RGB_TITLE"] = "colour with";
en["COLOUR_RGB_TOOLTIP"] = "Create a colour with the specified amount of red, green, and blue. All values must be between 0 and 100.";
en["CONTROLS_FLOW_STATEMENTS_HELPURL"] = "https://github.com/google/blockly/wiki/Loops#loop-termination-blocks";
en["CONTROLS_FLOW_STATEMENTS_OPERATOR_BREAK"] = "break out of loop";
en["CONTROLS_FLOW_STATEMENTS_OPERATOR_CONTINUE"] = "continue with next iteration of loop";
en["CONTROLS_FLOW_STATEMENTS_TOOLTIP_BREAK"] = "Break out of the containing loop.";
en["CONTROLS_FLOW_STATEMENTS_TOOLTIP_CONTINUE"] = "Skip the rest of this loop, and continue with the next iteration.";
en["CONTROLS_FLOW_STATEMENTS_WARNING"] = "Warning: This block may only be used within a loop.";
en["CONTROLS_FOREACH_HELPURL"] = "https://github.com/google/blockly/wiki/Loops#for-each";
en["CONTROLS_FOREACH_TITLE"] = "for each item %1 in list %2";
en["CONTROLS_FOREACH_TOOLTIP"] = "For each item in a list, set the variable '%1' to the item, and then do some statements.";
en["CONTROLS_FOR_HELPURL"] = "https://github.com/google/blockly/wiki/Loops#count-with";
en["CONTROLS_FOR_TITLE"] = "count with %1 from %2 to %3 by %4";
en["CONTROLS_FOR_TOOLTIP"] = "Have the variable '%1' take on the values from the start number to the end number, counting by the specified interval, and do the specified blocks.";
en["CONTROLS_IF_ELSEIF_TOOLTIP"] = "Add a condition to the if block.";
en["CONTROLS_IF_ELSE_TOOLTIP"] = "Add a final, catch-all condition to the if block.";
en["CONTROLS_IF_HELPURL"] = "https://github.com/google/blockly/wiki/IfElse";
en["CONTROLS_IF_IF_TOOLTIP"] = "Add, remove, or reorder sections to reconfigure this if block.";
en["CONTROLS_IF_MSG_ELSE"] = "else";
en["CONTROLS_IF_MSG_ELSEIF"] = "else if";
en["CONTROLS_IF_MSG_IF"] = "if";
en["CONTROLS_IF_TOOLTIP_1"] = "If a value is true, then do some statements.";
en["CONTROLS_IF_TOOLTIP_2"] = "If a value is true, then do the first block of statements. Otherwise, do the second block of statements.";
en["CONTROLS_IF_TOOLTIP_3"] = "If the first value is true, then do the first block of statements. Otherwise, if the second value is true, do the second block of statements.";
en["CONTROLS_IF_TOOLTIP_4"] = "If the first value is true, then do the first block of statements. Otherwise, if the second value is true, do the second block of statements. If none of the values are true, do the last block of statements.";
en["CONTROLS_REPEAT_HELPURL"] = "https://en.wikipedia.org/wiki/For_loop";
en["CONTROLS_REPEAT_INPUT_DO"] = ", then";
en["CONTROLS_REPEAT_TITLE"] = "repeat %1 times";
en["CONTROLS_REPEAT_TOOLTIP"] = "Do some statements several times.";
en["CONTROLS_WHILEUNTIL_HELPURL"] = "https://github.com/google/blockly/wiki/Loops#repeat";
en["CONTROLS_WHILEUNTIL_OPERATOR_UNTIL"] = "repeat until";
en["CONTROLS_WHILEUNTIL_OPERATOR_WHILE"] = "repeat while";
en["CONTROLS_WHILEUNTIL_TOOLTIP_UNTIL"] = "While a value is false, then do some statements.";
en["CONTROLS_WHILEUNTIL_TOOLTIP_WHILE"] = "While a value is true, then do some statements.";
en["DELETE_ALL_BLOCKS"] = "Delete all %1 blocks?";
en["DELETE_BLOCK"] = "Delete Block";
en["DELETE_VARIABLE"] = "Delete the '%1' variable";
en["DELETE_VARIABLE_CONFIRMATION"] = "Delete %1 uses of the '%2' variable?";
en["DELETE_X_BLOCKS"] = "Delete %1 Blocks";
en["DISABLE_BLOCK"] = "Disable Block";
en["DUPLICATE_BLOCK"] = "Duplicate";
en["DUPLICATE_COMMENT"] = "Duplicate Comment";
en["ENABLE_BLOCK"] = "Enable Block";
en["EXPAND_ALL"] = "Expand Blocks";
en["EXPAND_BLOCK"] = "Expand Block";
en["EXTERNAL_INPUTS"] = "External Inputs";
en["FUNCTIONS_CALL_TITLE"] = "call";
en["FUNCTIONS_DEFAULT_BOOLEAN_ARG_NAME"] = "bool";
en["FUNCTIONS_DEFAULT_CUSTOM_ARG_NAME"] = "arg";
en["FUNCTIONS_DEFAULT_FUNCTION_NAME"] = "do_something";
en["FUNCTIONS_DEFAULT_NUMBER_ARG_NAME"] = "num";
en["FUNCTIONS_DEFAULT_STRING_ARG_NAME"] = "text";
en["FUNCTIONS_EDIT_OPTION"] = "Edit";
en["FUNCTION_CALL_TOOLTIP"] = "Call a function that was previously defined.";
en["FUNCTION_CREATE_NEW"] = "Create a Function";
en["FUNCTION_WARNING_ARG_NAME_IS_FUNCTION_NAME"] = "Argument names must not be the same as the function name.";
en["FUNCTION_WARNING_DUPLICATE_ARG"] = "Functions cannot use the same argument name more than once.";
en["FUNCTION_WARNING_EMPTY_NAME"] = "Function and argument names cannot be empty.";
en["HELP"] = "Help";
en["INLINE_INPUTS"] = "Inline Inputs";
en["IOS_CANCEL"] = "Cancel";
en["IOS_ERROR"] = "Error";
en["IOS_OK"] = "OK";
en["IOS_EDIT"] = "Change";
en["IOS_PROCEDURES_ADD_INPUT"] = "+ Add Input";
en["IOS_PROCEDURES_ALLOW_STATEMENTS"] = "Allow statements";
en["IOS_PROCEDURES_DUPLICATE_INPUTS_ERROR"] = "This function has duplicate inputs.";
en["IOS_PROCEDURES_INPUTS"] = "INPUTS";
en["IOS_VARIABLES_ADD_BUTTON"] = "Add";
en["IOS_VARIABLES_ADD_VARIABLE"] = "+ Add Variable";
en["IOS_VARIABLES_DELETE_BUTTON"] = "Delete";
en["IOS_VARIABLES_EMPTY_NAME_ERROR"] = "You can't use an empty variable name.";
en["IOS_VARIABLES_RENAME_BUTTON"] = "Rename";
en["IOS_VARIABLES_VARIABLE_NAME"] = "Variable name";
en["LISTS_CREATE_EMPTY_HELPURL"] = "https://github.com/google/blockly/wiki/Lists#create-empty-list";
en["LISTS_CREATE_EMPTY_TITLE"] = "create empty list";
en["LISTS_CREATE_EMPTY_TOOLTIP"] = "Returns a list, of length 0, containing no data records";
en["LISTS_CREATE_WITH_CONTAINER_TITLE_ADD"] = "list";
en["LISTS_CREATE_WITH_CONTAINER_TOOLTIP"] = "Add, remove, or reorder sections to reconfigure this list block.";
en["LISTS_CREATE_WITH_HELPURL"] = "https://github.com/google/blockly/wiki/Lists#create-list-with";
en["LISTS_CREATE_WITH_INPUT_WITH"] = "create list with";
en["LISTS_CREATE_WITH_ITEM_TOOLTIP"] = "Add an item to the list.";
en["LISTS_CREATE_WITH_TOOLTIP"] = "Create a list with any number of items.";
en["LISTS_GET_INDEX_FIRST"] = "first";
en["LISTS_GET_INDEX_FROM_END"] = "# from end";
en["LISTS_GET_INDEX_FROM_START"] = "#";
en["LISTS_GET_INDEX_GET"] = "get";
en["LISTS_GET_INDEX_GET_REMOVE"] = "get and remove";
en["LISTS_GET_INDEX_LAST"] = "last";
en["LISTS_GET_INDEX_RANDOM"] = "random";
en["LISTS_GET_INDEX_REMOVE"] = "remove";
en["LISTS_GET_INDEX_TAIL"] = "";
en["LISTS_GET_INDEX_TOOLTIP_GET_FIRST"] = "Returns the first item in a list.";
en["LISTS_GET_INDEX_TOOLTIP_GET_FROM"] = "Returns the item at the specified position in a list.";
en["LISTS_GET_INDEX_TOOLTIP_GET_LAST"] = "Returns the last item in a list.";
en["LISTS_GET_INDEX_TOOLTIP_GET_RANDOM"] = "Returns a random item in a list.";
en["LISTS_GET_INDEX_TOOLTIP_GET_REMOVE_FIRST"] = "Removes and returns the first item in a list.";
en["LISTS_GET_INDEX_TOOLTIP_GET_REMOVE_FROM"] = "Removes and returns the item at the specified position in a list.";
en["LISTS_GET_INDEX_TOOLTIP_GET_REMOVE_LAST"] = "Removes and returns the last item in a list.";
en["LISTS_GET_INDEX_TOOLTIP_GET_REMOVE_RANDOM"] = "Removes and returns a random item in a list.";
en["LISTS_GET_INDEX_TOOLTIP_REMOVE_FIRST"] = "Removes the first item in a list.";
en["LISTS_GET_INDEX_TOOLTIP_REMOVE_FROM"] = "Removes the item at the specified position in a list.";
en["LISTS_GET_INDEX_TOOLTIP_REMOVE_LAST"] = "Removes the last item in a list.";
en["LISTS_GET_INDEX_TOOLTIP_REMOVE_RANDOM"] = "Removes a random item in a list.";
en["LISTS_GET_SUBLIST_END_FROM_END"] = "to # from end";
en["LISTS_GET_SUBLIST_END_FROM_START"] = "to #";
en["LISTS_GET_SUBLIST_END_LAST"] = "to last";
en["LISTS_GET_SUBLIST_HELPURL"] = "https://github.com/google/blockly/wiki/Lists#getting-a-sublist";
en["LISTS_GET_SUBLIST_START_FIRST"] = "get sub-list from first";
en["LISTS_GET_SUBLIST_START_FROM_END"] = "get sub-list from # from end";
en["LISTS_GET_SUBLIST_START_FROM_START"] = "get sub-list from #";
en["LISTS_GET_SUBLIST_TAIL"] = "";
en["LISTS_GET_SUBLIST_TOOLTIP"] = "Creates a copy of the specified portion of a list.";
en["LISTS_INDEX_FROM_END_TOOLTIP"] = "%1 is the last item.";
en["LISTS_INDEX_FROM_START_TOOLTIP"] = "%1 is the first item.";
en["LISTS_INDEX_OF_FIRST"] = "find first occurrence of item";
en["LISTS_INDEX_OF_HELPURL"] = "https://github.com/google/blockly/wiki/Lists#getting-items-from-a-list";
en["LISTS_INDEX_OF_LAST"] = "find last occurrence of item";
en["LISTS_INDEX_OF_TOOLTIP"] = "Returns the index of the first/last occurrence of the item in the list. Returns %1 if item is not found.";
en["LISTS_INLIST"] = "in list";
en["LISTS_ISEMPTY_HELPURL"] = "https://github.com/google/blockly/wiki/Lists#is-empty";
en["LISTS_ISEMPTY_TITLE"] = "%1 is empty";
en["LISTS_ISEMPTY_TOOLTIP"] = "Returns true if the list is empty.";
en["LISTS_LENGTH_HELPURL"] = "https://github.com/google/blockly/wiki/Lists#length-of";
en["LISTS_LENGTH_TITLE"] = "length of %1";
en["LISTS_LENGTH_TOOLTIP"] = "Returns the length of a list.";
en["LISTS_REPEAT_HELPURL"] = "https://github.com/google/blockly/wiki/Lists#create-list-with";
en["LISTS_REPEAT_TITLE"] = "create list with item %1 repeated %2 times";
en["LISTS_REPEAT_TOOLTIP"] = "Creates a list consisting of the given value repeated the specified number of times.";
en["LISTS_REVERSE_HELPURL"] = "https://github.com/google/blockly/wiki/Lists#reversing-a-list";
en["LISTS_REVERSE_MESSAGE0"] = "reverse %1";
en["LISTS_REVERSE_TOOLTIP"] = "Reverse a copy of a list.";
en["LISTS_SET_INDEX_HELPURL"] = "https://github.com/google/blockly/wiki/Lists#in-list--set";
en["LISTS_SET_INDEX_INPUT_TO"] = "as";
en["LISTS_SET_INDEX_INSERT"] = "insert at";
en["LISTS_SET_INDEX_SET"] = "set";
en["LISTS_SET_INDEX_TOOLTIP_INSERT_FIRST"] = "Inserts the item at the start of a list.";
en["LISTS_SET_INDEX_TOOLTIP_INSERT_FROM"] = "Inserts the item at the specified position in a list.";
en["LISTS_SET_INDEX_TOOLTIP_INSERT_LAST"] = "Append the item to the end of a list.";
en["LISTS_SET_INDEX_TOOLTIP_INSERT_RANDOM"] = "Inserts the item randomly in a list.";
en["LISTS_SET_INDEX_TOOLTIP_SET_FIRST"] = "Sets the first item in a list.";
en["LISTS_SET_INDEX_TOOLTIP_SET_FROM"] = "Sets the item at the specified position in a list.";
en["LISTS_SET_INDEX_TOOLTIP_SET_LAST"] = "Sets the last item in a list.";
en["LISTS_SET_INDEX_TOOLTIP_SET_RANDOM"] = "Sets a random item in a list.";
en["LISTS_SORT_HELPURL"] = "https://github.com/google/blockly/wiki/Lists#sorting-a-list";
en["LISTS_SORT_ORDER_ASCENDING"] = "ascending";
en["LISTS_SORT_ORDER_DESCENDING"] = "descending";
en["LISTS_SORT_TITLE"] = "sort %1 %2 %3";
en["LISTS_SORT_TOOLTIP"] = "Sort a copy of a list.";
en["LISTS_SORT_TYPE_IGNORECASE"] = "alphabetic, ignore case";
en["LISTS_SORT_TYPE_NUMERIC"] = "numeric";
en["LISTS_SORT_TYPE_TEXT"] = "alphabetic";
en["LISTS_SPLIT_HELPURL"] = "https://github.com/google/blockly/wiki/Lists#splitting-strings-and-joining-lists";
en["LISTS_SPLIT_LIST_FROM_TEXT"] = "make list from text";
en["LISTS_SPLIT_TEXT_FROM_LIST"] = "make text from list";
en["LISTS_SPLIT_TOOLTIP_JOIN"] = "Join a list of texts into one text, separated by a delimiter.";
en["LISTS_SPLIT_TOOLTIP_SPLIT"] = "Split text into a list of texts, breaking at each delimiter.";
en["LISTS_SPLIT_WITH_DELIMITER"] = "with delimiter";
en["LOGIC_BOOLEAN_FALSE"] = "false";
en["LOGIC_BOOLEAN_HELPURL"] = "https://github.com/google/blockly/wiki/Logic#values";
en["LOGIC_BOOLEAN_TOOLTIP"] = "Returns either true or false.";
en["LOGIC_BOOLEAN_TRUE"] = "true";
en["LOGIC_COMPARE_HELPURL"] = "https://en.wikipedia.org/wiki/Inequality_(mathematics)";
en["LOGIC_COMPARE_TOOLTIP_EQ"] = "Return true if both inputs equal each other.";
en["LOGIC_COMPARE_TOOLTIP_GT"] = "Return true if the first input is greater than the second input.";
en["LOGIC_COMPARE_TOOLTIP_GTE"] = "Return true if the first input is greater than or equal to the second input.";
en["LOGIC_COMPARE_TOOLTIP_LT"] = "Return true if the first input is smaller than the second input.";
en["LOGIC_COMPARE_TOOLTIP_LTE"] = "Return true if the first input is smaller than or equal to the second input.";
en["LOGIC_COMPARE_TOOLTIP_NEQ"] = "Return true if both inputs are not equal to each other.";
en["LOGIC_NEGATE_HELPURL"] = "https://github.com/google/blockly/wiki/Logic#not";
en["LOGIC_NEGATE_TITLE"] = "not %1";
en["LOGIC_NEGATE_TOOLTIP"] = "Returns true if the input is false. Returns false if the input is true.";
en["LOGIC_NULL"] = "null";
en["LOGIC_NULL_HELPURL"] = "https://en.wikipedia.org/wiki/Nullable_type";
en["LOGIC_NULL_TOOLTIP"] = "Returns null.";
en["LOGIC_OPERATION_AND"] = "and";
en["LOGIC_OPERATION_HELPURL"] = "https://github.com/google/blockly/wiki/Logic#logical-operations";
en["LOGIC_OPERATION_OR"] = "or";
en["LOGIC_OPERATION_TOOLTIP_AND"] = "Return true if both inputs are true.";
en["LOGIC_OPERATION_TOOLTIP_OR"] = "Return true if at least one of the inputs is true.";
en["LOGIC_TERNARY_CONDITION"] = "test";
en["LOGIC_TERNARY_HELPURL"] = "https://en.wikipedia.org/wiki/%3F:";
en["LOGIC_TERNARY_IF_FALSE"] = "if false";
en["LOGIC_TERNARY_IF_TRUE"] = "if true";
en["LOGIC_TERNARY_TOOLTIP"] = "Check the condition in 'test'. If the condition is true, returns the 'if true' value; otherwise returns the 'if false' value.";
en["MATH_ADDITION_SYMBOL"] = "+";
en["MATH_ARITHMETIC_HELPURL"] = "https://en.wikipedia.org/wiki/Arithmetic";
en["MATH_ARITHMETIC_TOOLTIP_ADD"] = "Return the sum of the two numbers.";
en["MATH_ARITHMETIC_TOOLTIP_DIVIDE"] = "Return the quotient of the two numbers.";
en["MATH_ARITHMETIC_TOOLTIP_MINUS"] = "Return the difference of the two numbers.";
en["MATH_ARITHMETIC_TOOLTIP_MULTIPLY"] = "Return the product of the two numbers.";
en["MATH_ARITHMETIC_TOOLTIP_POWER"] = "Return the first number raised to the power of the second number.";
en["MATH_CHANGE_HELPURL"] = "https://en.wikipedia.org/wiki/Programming_idiom#Incrementing_a_counter";
en["MATH_CHANGE_TITLE"] = "change %1 by %2";
en["MATH_CHANGE_TOOLTIP"] = "Add a number to variable '%1'.";
en["MATH_CONSTANT_HELPURL"] = "https://en.wikipedia.org/wiki/Mathematical_constant";
en["MATH_CONSTANT_TOOLTIP"] = "Return one of the common constants: π (3.141…), e (2.718…), φ (1.618…), sqrt(2) (1.414…), sqrt(½) (0.707…), or ∞ (infinity).";
en["MATH_CONSTRAIN_HELPURL"] = "https://en.wikipedia.org/wiki/Clamping_(graphics)";
en["MATH_CONSTRAIN_TITLE"] = "constrain %1 low %2 high %3";
en["MATH_CONSTRAIN_TOOLTIP"] = "Constrain a number to be between the specified limits (inclusive).";
en["MATH_DIVISION_SYMBOL"] = "÷";
en["MATH_IS_DIVISIBLE_BY"] = "is divisible by";
en["MATH_IS_EVEN"] = "is even";
en["MATH_IS_NEGATIVE"] = "is negative";
en["MATH_IS_ODD"] = "is odd";
en["MATH_IS_POSITIVE"] = "is positive";
en["MATH_IS_PRIME"] = "is prime";
en["MATH_IS_TOOLTIP"] = "Check if a number is an even, odd, prime, whole, positive, negative, or if it is divisible by certain number. Returns true or false.";
en["MATH_IS_WHOLE"] = "is whole";
en["MATH_MODULO_HELPURL"] = "https://en.wikipedia.org/wiki/Modulo_operation";
en["MATH_MODULO_TITLE"] = "remainder of %1 ÷ %2";
en["MATH_MODULO_TOOLTIP"] = "Return the remainder from dividing the two numbers.";
en["MATH_MULTIPLICATION_SYMBOL"] = "×";
en["MATH_NUMBER_HELPURL"] = "https://en.wikipedia.org/wiki/Number";
en["MATH_NUMBER_TOOLTIP"] = "A number.";
en["MATH_ONLIST_HELPURL"] = "";
en["MATH_ONLIST_OPERATOR_AVERAGE"] = "average of list";
en["MATH_ONLIST_OPERATOR_MAX"] = "max of list";
en["MATH_ONLIST_OPERATOR_MEDIAN"] = "median of list";
en["MATH_ONLIST_OPERATOR_MIN"] = "min of list";
en["MATH_ONLIST_OPERATOR_MODE"] = "modes of list";
en["MATH_ONLIST_OPERATOR_RANDOM"] = "random item of list";
en["MATH_ONLIST_OPERATOR_STD_DEV"] = "standard deviation of list";
en["MATH_ONLIST_OPERATOR_SUM"] = "sum of list";
en["MATH_ONLIST_TOOLTIP_AVERAGE"] = "Return the average (arithmetic mean) of the numeric values in the list.";
en["MATH_ONLIST_TOOLTIP_MAX"] = "Return the largest number in the list.";
en["MATH_ONLIST_TOOLTIP_MEDIAN"] = "Return the median number in the list.";
en["MATH_ONLIST_TOOLTIP_MIN"] = "Return the smallest number in the list.";
en["MATH_ONLIST_TOOLTIP_MODE"] = "Return a list of the most common item(s) in the list.";
en["MATH_ONLIST_TOOLTIP_RANDOM"] = "Return a random element from the list.";
en["MATH_ONLIST_TOOLTIP_STD_DEV"] = "Return the standard deviation of the list.";
en["MATH_ONLIST_TOOLTIP_SUM"] = "Return the sum of all the numbers in the list.";
en["MATH_POWER_SYMBOL"] = "^";
en["MATH_RANDOM_FLOAT_HELPURL"] = "https://en.wikipedia.org/wiki/Random_number_generation";
en["MATH_RANDOM_FLOAT_TITLE_RANDOM"] = "random fraction";
en["MATH_RANDOM_FLOAT_TOOLTIP"] = "Return a random fraction between 0.0 (inclusive) and 1.0 (exclusive).";
en["MATH_RANDOM_INT_HELPURL"] = "https://en.wikipedia.org/wiki/Random_number_generation";
en["MATH_RANDOM_INT_TITLE"] = "random integer from %1 to %2";
en["MATH_RANDOM_INT_TOOLTIP"] = "Return a random integer between the two specified limits, inclusive.";
en["MATH_ROUND_HELPURL"] = "https://en.wikipedia.org/wiki/Rounding";
en["MATH_ROUND_OPERATOR_ROUND"] = "round";
en["MATH_ROUND_OPERATOR_ROUNDDOWN"] = "round down";
en["MATH_ROUND_OPERATOR_ROUNDUP"] = "round up";
en["MATH_ROUND_TOOLTIP"] = "Round a number up or down.";
en["MATH_SINGLE_HELPURL"] = "https://en.wikipedia.org/wiki/Square_root";
en["MATH_SINGLE_OP_ABSOLUTE"] = "absolute";
en["MATH_SINGLE_OP_ROOT"] = "square root";
en["MATH_SINGLE_TOOLTIP_ABS"] = "Return the absolute value of a number.";
en["MATH_SINGLE_TOOLTIP_EXP"] = "Return e to the power of a number.";
en["MATH_SINGLE_TOOLTIP_LN"] = "Return the natural logarithm of a number.";
en["MATH_SINGLE_TOOLTIP_LOG10"] = "Return the base 10 logarithm of a number.";
en["MATH_SINGLE_TOOLTIP_NEG"] = "Return the negation of a number.";
en["MATH_SINGLE_TOOLTIP_POW10"] = "Return 10 to the power of a number.";
en["MATH_SINGLE_TOOLTIP_ROOT"] = "Return the square root of a number.";
en["MATH_SUBTRACTION_SYMBOL"] = "-";
en["MATH_TRIG_ACOS"] = "acos";
en["MATH_TRIG_ASIN"] = "asin";
en["MATH_TRIG_ATAN"] = "atan";
en["MATH_TRIG_COS"] = "cos";
en["MATH_TRIG_HELPURL"] = "https://en.wikipedia.org/wiki/Trigonometric_functions";
en["MATH_TRIG_SIN"] = "sin";
en["MATH_TRIG_TAN"] = "tan";
en["MATH_TRIG_TOOLTIP_ACOS"] = "Return the arccosine of a number.";
en["MATH_TRIG_TOOLTIP_ASIN"] = "Return the arcsine of a number.";
en["MATH_TRIG_TOOLTIP_ATAN"] = "Return the arctangent of a number.";
en["MATH_TRIG_TOOLTIP_COS"] = "Return the cosine of a degree (not radian).";
en["MATH_TRIG_TOOLTIP_SIN"] = "Return the sine of a degree (not radian).";
en["MATH_TRIG_TOOLTIP_TAN"] = "Return the tangent of a degree (not radian).";
en["NEW_COLOUR_VARIABLE"] = "Create colour variable...";
en["NEW_NUMBER_VARIABLE"] = "Create number variable...";
en["NEW_STRING_VARIABLE"] = "Create string variable...";
en["NEW_VARIABLE"] = "Create variable...";
en["NEW_VARIABLE_DROPDOWN"] = "New variable...";
en["NEW_VARIABLE_TITLE"] = "New variable name:";
en["NEW_VARIABLE_TYPE_DROPDOWN"] = "New %1 variable...";
en["NEW_VARIABLE_TYPE_TITLE"] = "New variable type:";
en["ORDINAL_NUMBER_SUFFIX"] = "";
en["PROCEDURES_ALLOW_STATEMENTS"] = "allow statements";
en["PROCEDURES_BEFORE_PARAMS"] = "with: ";
en["PROCEDURES_CALLNORETURN_HELPURL"] = "https://en.wikipedia.org/wiki/Subroutine";
en["PROCEDURES_CALLNORETURN_TOOLTIP"] = "Run the user-defined function '%1'.";
en["PROCEDURES_CALLRETURN_HELPURL"] = "https://en.wikipedia.org/wiki/Subroutine";
en["PROCEDURES_CALLRETURN_TOOLTIP"] = "Run the user-defined function '%1' and use its output.";
en["PROCEDURES_CALL_BEFORE_PARAMS"] = "with: ";
en["PROCEDURES_CREATE_DO"] = "Create '%1'";
en["PROCEDURES_DEFNORETURN_COMMENT"] = "Describe this function...";
en["PROCEDURES_DEFNORETURN_DO"] = "";
en["PROCEDURES_DEFNORETURN_HELPURL"] = "https://en.wikipedia.org/wiki/Subroutine";
en["PROCEDURES_DEFNORETURN_PROCEDURE"] = "do something";
en["PROCEDURES_DEFNORETURN_TITLE"] = "do";
en["PROCEDURES_DEFNORETURN_TOOLTIP"] = "Creates a function with no output.";
en["PROCEDURES_DEFRETURN_HELPURL"] = "https://en.wikipedia.org/wiki/Subroutine";
en["PROCEDURES_DEFRETURN_RETURN"] = "return";
en["PROCEDURES_DEFRETURN_TOOLTIP"] = "Creates a function with an output.";
en["PROCEDURES_DEF_DUPLICATE_WARNING"] = "Warning: This function has duplicate parameters.";
en["PROCEDURES_HIGHLIGHT_DEF"] = "Highlight function definition";
en["PROCEDURES_IFRETURN_HELPURL"] = "http://c2.com/cgi/wiki?GuardClause";
en["PROCEDURES_IFRETURN_TOOLTIP"] = "If a value is true, then return a second value.";
en["PROCEDURES_IFRETURN_WARNING"] = "Warning: This block may be used only within a function definition.";
en["PROCEDURES_MUTATORARG_TITLE"] = "input name:";
en["PROCEDURES_MUTATORARG_TOOLTIP"] = "Add an input to the function.";
en["PROCEDURES_MUTATORCONTAINER_TITLE"] = "inputs";
en["PROCEDURES_MUTATORCONTAINER_TOOLTIP"] = "Add, remove, or reorder inputs to this function.";
en["REDO"] = "Redo";
en["REMOVE_COMMENT"] = "Remove Comment";
en["RENAME_VARIABLE"] = "Rename variable...";
en["RENAME_VARIABLE_TITLE"] = "Rename all '%1' variables to:";
en["TEXT_APPEND_HELPURL"] = "https://github.com/google/blockly/wiki/Text#text-modification";
en["TEXT_APPEND_TITLE"] = "to %1 append text %2";
en["TEXT_APPEND_TOOLTIP"] = "Append some text to variable '%1'.";
en["TEXT_CHANGECASE_HELPURL"] = "https://github.com/google/blockly/wiki/Text#adjusting-text-case";
en["TEXT_CHANGECASE_OPERATOR_LOWERCASE"] = "to lower case";
en["TEXT_CHANGECASE_OPERATOR_TITLECASE"] = "to Title Case";
en["TEXT_CHANGECASE_OPERATOR_UPPERCASE"] = "to UPPER CASE";
en["TEXT_CHANGECASE_TOOLTIP"] = "Return a copy of the text in a different case.";
en["TEXT_CHARAT_FIRST"] = "get first letter";
en["TEXT_CHARAT_FROM_END"] = "get letter # from end";
en["TEXT_CHARAT_FROM_START"] = "get letter #";
en["TEXT_CHARAT_HELPURL"] = "https://github.com/google/blockly/wiki/Text#extracting-text";
en["TEXT_CHARAT_LAST"] = "get last letter";
en["TEXT_CHARAT_RANDOM"] = "get random letter";
en["TEXT_CHARAT_TAIL"] = "";
en["TEXT_CHARAT_TITLE"] = "in text %1 %2";
en["TEXT_CHARAT_TOOLTIP"] = "Returns the letter at the specified position.";
en["TEXT_COUNT_HELPURL"] = "https://github.com/google/blockly/wiki/Text#counting-substrings";
en["TEXT_COUNT_MESSAGE0"] = "count %1 in %2";
en["TEXT_COUNT_TOOLTIP"] = "Count how many times some text occurs within some other text.";
en["TEXT_CREATE_JOIN_ITEM_TOOLTIP"] = "Add an item to the text.";
en["TEXT_CREATE_JOIN_TITLE_JOIN"] = "join";
en["TEXT_CREATE_JOIN_TOOLTIP"] = "Add, remove, or reorder sections to reconfigure this text block.";
en["TEXT_GET_SUBSTRING_END_FROM_END"] = "to letter # from end";
en["TEXT_GET_SUBSTRING_END_FROM_START"] = "to letter #";
en["TEXT_GET_SUBSTRING_END_LAST"] = "to last letter";
en["TEXT_GET_SUBSTRING_HELPURL"] = "https://github.com/google/blockly/wiki/Text#extracting-a-region-of-text";
en["TEXT_GET_SUBSTRING_INPUT_IN_TEXT"] = "in text";
en["TEXT_GET_SUBSTRING_START_FIRST"] = "get substring from first letter";
en["TEXT_GET_SUBSTRING_START_FROM_END"] = "get substring from letter # from end";
en["TEXT_GET_SUBSTRING_START_FROM_START"] = "get substring from letter #";
en["TEXT_GET_SUBSTRING_TAIL"] = "";
en["TEXT_GET_SUBSTRING_TOOLTIP"] = "Returns a specified portion of the text.";
en["TEXT_INDEXOF_HELPURL"] = "https://github.com/google/blockly/wiki/Text#finding-text";
en["TEXT_INDEXOF_OPERATOR_FIRST"] = "find first occurrence of text";
en["TEXT_INDEXOF_OPERATOR_LAST"] = "find last occurrence of text";
en["TEXT_INDEXOF_TITLE"] = "in text %1 %2 %3";
en["TEXT_INDEXOF_TOOLTIP"] = "Returns the index of the first/last occurrence of the first text in the second text. Returns %1 if text is not found.";
en["TEXT_ISEMPTY_HELPURL"] = "https://github.com/google/blockly/wiki/Text#checking-for-empty-text";
en["TEXT_ISEMPTY_TITLE"] = "%1 is empty";
en["TEXT_ISEMPTY_TOOLTIP"] = "Returns true if the provided text is empty.";
en["TEXT_JOIN_HELPURL"] = "https://github.com/google/blockly/wiki/Text#text-creation";
en["TEXT_JOIN_TITLE_CREATEWITH"] = "Join text";
en["TEXT_JOIN_TOOLTIP"] = "Create a piece of text by joining together any number of items.";
en["TEXT_LENGTH_HELPURL"] = "https://github.com/google/blockly/wiki/Text#text-modification";
en["TEXT_LENGTH_TITLE"] = "length of %1";
en["TEXT_LENGTH_TOOLTIP"] = "Returns the number of letters (including spaces) in the provided text.";
en["TEXT_PRINT_HELPURL"] = "https://github.com/google/blockly/wiki/Text#printing-text";
en["TEXT_PRINT_TITLE"] = "print %1";
en["TEXT_PRINT_TOOLTIP"] = "Print the specified text, number or other value.";
en["TEXT_PROMPT_HELPURL"] = "https://github.com/google/blockly/wiki/Text#getting-input-from-the-user";
en["TEXT_PROMPT_TOOLTIP_NUMBER"] = "Prompt for user for a number.";
en["TEXT_PROMPT_TOOLTIP_TEXT"] = "Prompt for user for some text.";
en["TEXT_PROMPT_TYPE_NUMBER"] = "prompt for number with message";
en["TEXT_PROMPT_TYPE_TEXT"] = "prompt for text with message";
en["TEXT_REPLACE_HELPURL"] = "https://github.com/google/blockly/wiki/Text#replacing-substrings";
en["TEXT_REPLACE_MESSAGE0"] = "replace %1 with %2 in %3";
en["TEXT_REPLACE_TOOLTIP"] = "Replace all occurances of some text within some other text.";
en["TEXT_REVERSE_HELPURL"] = "https://github.com/google/blockly/wiki/Text#reversing-text";
en["TEXT_REVERSE_MESSAGE0"] = "reverse %1";
en["TEXT_REVERSE_TOOLTIP"] = "Reverses the order of the characters in the text.";
en["TEXT_TEXT_HELPURL"] = "https://en.wikipedia.org/wiki/String_(computer_science)";
en["TEXT_TEXT_TOOLTIP"] = "A letter, word, or line of text.";
en["TEXT_TRIM_HELPURL"] = "https://github.com/google/blockly/wiki/Text#trimming-removing-spaces";
en["TEXT_TRIM_OPERATOR_BOTH"] = "trim spaces from both sides of";
en["TEXT_TRIM_OPERATOR_LEFT"] = "trim spaces from left side of";
en["TEXT_TRIM_OPERATOR_RIGHT"] = "trim spaces from right side of";
en["TEXT_TRIM_TOOLTIP"] = "Return a copy of the text with spaces removed from one or both ends.";
en["TODAY"] = "Today";
en["UNDO"] = "Undo";
en["VARIABLES_DEFAULT_NAME"] = "item";
en["VARIABLES_GET_CREATE_SET"] = "Create 'set %1'";
en["VARIABLES_GET_HELPURL"] = "https://github.com/google/blockly/wiki/Variables#get";
en["VARIABLES_GET_TOOLTIP"] = "Returns the value of this variable.";
en["VARIABLES_SET"] = "set %1 to %2";
en["VARIABLES_SET_CREATE_GET"] = "Create 'get %1'";
en["VARIABLES_SET_HELPURL"] = "https://github.com/google/blockly/wiki/Variables#set";
en["VARIABLES_SET_TOOLTIP"] = "Sets this variable to be equal to the input.";
en["VARIABLE_ALREADY_EXISTS_NONAME"] = "Variable already exists";
en["VARIABLE_ALREADY_EXISTS"] = "A variable or function named '%1' already exists.";
en["VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE"] = "A variable named '%1' already exists for another type: '%2'.";
en["WORKSPACE_COMMENT_DEFAULT_TEXT"] = "Say something...";
en["PROCEDURES_DEFRETURN_TITLE"] = en["PROCEDURES_DEFNORETURN_TITLE"];
en["CONTROLS_IF_IF_TITLE_IF"] = en["CONTROLS_IF_MSG_IF"];
en["CONTROLS_WHILEUNTIL_INPUT_DO"] = en["CONTROLS_REPEAT_INPUT_DO"];
en["CONTROLS_IF_MSG_THEN"] = en["CONTROLS_REPEAT_INPUT_DO"];
en["CONTROLS_IF_ELSE_TITLE_ELSE"] = en["CONTROLS_IF_MSG_ELSE"];
en["PROCEDURES_DEFRETURN_PROCEDURE"] = en["PROCEDURES_DEFNORETURN_PROCEDURE"];
en["LISTS_GET_SUBLIST_INPUT_IN_LIST"] = en["LISTS_INLIST"];
en["LISTS_GET_INDEX_INPUT_IN_LIST"] = en["LISTS_INLIST"];
en["MATH_CHANGE_TITLE_ITEM"] = en["VARIABLES_DEFAULT_NAME"];
en["PROCEDURES_DEFRETURN_DO"] = en["PROCEDURES_DEFNORETURN_DO"];
en["CONTROLS_IF_ELSEIF_TITLE_ELSEIF"] = en["CONTROLS_IF_MSG_ELSEIF"];
en["LISTS_GET_INDEX_HELPURL"] = en["LISTS_INDEX_OF_HELPURL"];
en["CONTROLS_FOREACH_INPUT_DO"] = en["CONTROLS_REPEAT_INPUT_DO"];
en["LISTS_SET_INDEX_INPUT_IN_LIST"] = en["LISTS_INLIST"];
en["CONTROLS_FOR_INPUT_DO"] = en["CONTROLS_REPEAT_INPUT_DO"];
en["LISTS_CREATE_WITH_ITEM_TITLE"] = en["VARIABLES_DEFAULT_NAME"];
en["TEXT_APPEND_VARIABLE"] = en["VARIABLES_DEFAULT_NAME"];
en["TEXT_CREATE_JOIN_ITEM_TITLE_ITEM"] = en["VARIABLES_DEFAULT_NAME"];
en["LISTS_INDEX_OF_INPUT_IN_LIST"] = en["LISTS_INLIST"];
en["PROCEDURES_DEFRETURN_COMMENT"] = en["PROCEDURES_DEFNORETURN_COMMENT"];

en["MATH_HUE"] = "230";
en["LOOPS_HUE"] = "120";
en["LISTS_HUE"] = "260";
en["LOGIC_HUE"] = "210";
en["VARIABLES_HUE"] = "330";
en["TEXTS_HUE"] = "160";
en["REPORTERS_HUE"] = "290";
en["PROCEDURES_HUE"] = "290";
en["COLOUR_HUE"] = "20";
en["VARIABLES_DYNAMIC_HUE"] = "310";



///////////////////////////////////////////////////////
///////////////////////////////////////////////////////
///////////////////////////////////////////////////////
en["TEXT_PRINTCODE_TITLE"] = 'Line of code';
en["PROCEDURES_PARAMETERS"] = 'Parameters Qty.';
en["PROCEDURES_ALREADY_EXISTS"] = 'Procedure or one of its variables already exists';
en["PROCEDURES_REPEATED_VAR_NAMES"] = "Procedure's params must have different names";
en["PROCEDURES_NAME_EMPTY"] = "Procedure's name cannot be empty";
en["PROCEDURES_NAME"] = "Procedure's name";
en["PROCEDURES_CREATE_NORETURN_PROCEDURE"] = 'Creates a function with no output';
en["PROCEDURES_CREATE_RETURN_PROCEDURE"] = 'Create a procedure with output';
en["PROCEDURES_CREATE_PROCEDURE"] = 'Create a procedure';
en["PROCEDURES_MODIFY_DO"] = "Modify '%1'";
en["NEW_GLOBAL_VARIABLE"] = "Declare GLOBAL variable";
en["NEW_GLOBAL_VARIABLE_TYPE"] = "of type";
en["NEW_LOCAL_VARIABLE"] = "Declare LOCAL variable";
en["NEW_LOCAL_VARIABLE_TYPE"] = "of type";
en["VARIABLE_STATEMENT"] = "Var";
en["VARIABLE_NAME_EMPTY"] = "Variable's name cannot be empty";
en["VARIABLE_STATEMENT_DEF"] = "Set";
en["VARIABLE_STATEMENT_DEF_TO"] = "to";
en["VARIABLE_STATEMENT_CHANGE"] = "Change";
en["VARIABLE_STATEMENT_CHANGE_BY"] = "by";
en["VARIABLE_TYPE_STRING"] = "String";
en["VARIABLE_TYPE_INT"] = "Int";
en["VARIABLE_TYPE_LONG"] = "Long";
en["VARIABLE_TYPE_BYTE"] = "Byte";
en["VARIABLE_TYPE_FLOAT"] = "Float";
en["VARIABLE_TYPE_CHAR"] = "Char";
en["NEW_ARRAY_VARIABLE"] = "Declare vector";
en["NEW_ARRAY_VARIABLE_TYPE"] = "of type";
en["ARRAY_STATEMENT"] = "Vector";
en["CONTROLS_IF_MSG_ELSEIF"] = "else if";


// BLOCKS
en["BLOCKLY_MSG_ADD_COMMENT"] = "Add Comment";
en["BLOCKLY_MSG_COLLAPSE_ALL"] = "Collapse Blocks";
en["BLOCKLY_MSG_COLLAPSE_BLOCK"] = "Collapse Block";
en["BLOCKLY_MSG_DELETE_BLOCK"] = "Delete Block";
en["BLOCKLY_MSG_DELETE_X_BLOCKS"] = "Delete %1 Block";
en["BLOCKLY_MSG_DISABLE_BLOCK"] = "Disable Blocks";
en["BLOCKLY_MSG_DUPLICATE_BLOCK"] = "Duplicate";
en["BLOCKLY_MSG_ENABLE_BLOCK"] = "Enable Blocks";
en["BLOCKLY_MSG_EXPAND_ALL"] = "Expand Blocks";
en["BLOCKLY_MSG_EXPAND_BLOCK"] = "Expand Block";
en["BLOCKLY_MSG_EXTERNAL_INPUTS"] = "External Inputs";
en["BLOCKLY_MSG_HELP"] = "Help";
en["BLOCKLY_MSG_INLINE_INPUTS"] = "Inline Inputs";
en["BLOCKLY_MSG_REMOVE_COMMENT"] = "Remove Comment";
en["LANG_ADVANCED_BLUE_READ"] = "Read from Bluetooth";
en["LANG_ADVANCED_BT_SERIAL_AVAILABLE"] = "Bluetooth: Serial Port Available";
en["LANG_ADVANCED_BT_SERIAL_AVAILABLE_TOOLTIP"] = "Check if the Bluetooth module is available or not";
en["LANG_BHOOT_BT_SERIAL_AVAILABLE"] = "If receiving a message via Bluetooth, read";
en["LANG_BHOOT_BT_SERIAL_AVAILABLE_TOOLTIP"] = "Check if a message is received from the Bluetooth module";
en["LANG_ADVANCED_BUILTIN_LED"] = "Onboard LED";
en["LANG_ADVANCED_BUILTIN_LED_OFF"] = "Off";
en["LANG_ADVANCED_BUILTIN_LED_ON"] = "On";
en["LANG_ADVANCED_BUILTIN_LED_TOOLTIP"] = "Onboard LED connected internally to pin 13.";
en["LANG_ADVANCED_CONVERSION_BINARY"] = "Binary";
en["LANG_ADVANCED_CONVERSION_CONVERT"] = "Convert";
en["LANG_ADVANCED_CONVERSION_DECIMAL"] = "Decimal";
en["LANG_ADVANCED_CONVERSION_HEXADECIMAL"] = "Hexadecimal";
en["LANG_ADVANCED_CONVERSION_OCTAL"] = "Octal";
en["LANG_ADVANCED_CONVERSION_TOOLTIP"] = "Convert the base of a number.";
en["LANG_ADVANCED_CONVERSION_VALUE"] = "value";
en["LANG_ADVANCED_HIGHLOW_HIGH"] = "High";
en["LANG_ADVANCED_HIGHLOW_LOW"] = "Low";
en["LANG_ADVANCED_HIGHLOW_TOOLTIP"] = "Write 'High' or 'Low' based on the selection.";
en["LANG_ADVANCED_INOUT_ANALOG_READ"] = "Read analog pin";
en["LANG_ADVANCED_INOUT_ANALOG_READ_TOOLTIP"] = "Read the value from a specific analog pin.";
en["LANG_ADVANCED_INOUT_ANALOG_WRITE"] = "Write to analog pin";
en["LANG_ADVANCED_INOUT_ANALOG_WRITE_TOOLTIP"] = "Write a value between 0 and 255 to a specific analog pin.";
en["LANG_ADVANCED_INOUT_ANALOG_WRITE_VALUE"] = "analog value";
en["LANG_ADVANCED_INOUT_DIGITAL_READ"] = "Read digital pin";
en["LANG_ADVANCED_INOUT_DIGITAL_READ_TOOLTIP"] = "Read the value from a specific digital pin.";
en["LANG_ADVANCED_INOUT_DIGITAL_WRITE"] = "Write";
en["LANG_ADVANCED_INOUT_DIGITAL_WRITE_HIGH"] = "High";
en["LANG_ADVANCED_INOUT_DIGITAL_WRITE_LOW"] = "Low";
en["LANG_ADVANCED_INOUT_DIGITAL_WRITE_STATE"] = "state";
en["LANG_ADVANCED_INOUT_DIGITAL_WRITE_TOOLTIP"] = "Write a value to a specific digital pin.";
en["LANG_ADVANCED_MATH_RANDOM"] = "Random between";
en["LANG_ADVANCED_MATH_RANDOM_AND"] = " and ";

en["LANG_ADVANCED_MATH_RANDOM_TOOLTIP"] = "Create a random number between the two given limits.";
en["LANG_ADVANCED_SERIAL_AVAILABLE"] = "Serial Port Available";
en["LANG_ADVANCED_SERIAL_AVAILABLE_TOOLTIP"] = "Check if the serial port is available or not.";
en["LANG_ADVANCED_SERIAL_BLUE_TAG"] = "Send message";
en["LANG_ADVANCED_SERIAL_BLUE_TAG_WITHTAG"] = "with tag";
en["LANG_ADVANCED_SERIAL_BLUE_TAG_BLUETOOTH"] = "via Bluetooth";
en["LANG_ADVANCED_SERIAL_BLUE"] = "Write via Bluetooth";
en["LANG_ADVANCED_SERIAL_PARSEINT"] = "Read integer from serial port";
en["LANG_ADVANCED_SERIAL_PARSEINT_TOOLTIP"] = "Returns the first integer (long) received from the serial port.";
en["LANG_ADVANCED_SERIAL_PRINT"] = "Serial write";
en["LANG_ADVANCED_SERIAL_PRINTLN"] = "Serial write with newline";
en["LANG_ADVANCED_SERIAL_PRINTLN_TOOLTIP"] = "Prints data as ASCII text with a carriage return (CR).";
en["LANG_ADVANCED_SERIAL_PRINT_TOOLTIP"] = "Prints data as ASCII text.";
en["LANG_ADVANCED_SERIAL_READ"] = "Read from serial port";
en["LANG_ADVANCED_SERIAL_READSTRING"] = "Read string from serial port";
en["LANG_ADVANCED_SERIAL_READSTRING_TOOLTIP"] = "Reads data received from the serial port as ASCII text.";
en["LANG_ADVANCED_SERIAL_READ_TOOLTIP"] = "Reads data received from the serial port as byte data.";
en["LANG_ADVANCED_SERIAL_SPECIAL"] = "Special characters";
en["LANG_ADVANCED_SERIAL_SPECIAL_CARRIAGE_RETURN"] = "Carriage return";
en["LANG_ADVANCED_SERIAL_SPECIAL_LINE_FEED"] = "Line feed";
en["LANG_ADVANCED_SERIAL_SPECIAL_TAB"] = "Tab";
en["LANG_ADVANCED_SERIAL_SPECIAL_TOOLTIP"] = "Writes special characters.";
en["LANG_ARRAY_GET_BRACKET1"] = "[";
en["LANG_ARRAY_GET_BRACKET2"] = "]";
en["LANG_ARRAY_GET_TOOLTIP"] = "Returns the value of a specific element in the array.";

en["LANG_CATEGORY_PROCEDURES"] = "Functions";
en["LANG_CATEGORY_CONTROLS"] = "Control";
en["LANG_CATEGORY_LOGIC"] = "Logic";
en["LANG_CATEGORY_MATH"] = "Math";
en["LANG_CATEGORY_VARIABLES"] = "Variables";
en["LANG_CATEGORY_TEXT"] = "Text";
en["LANG_CATEGORY_COMMUNICATION"] = "Communication";
en["LANG_CATEGORY_ENTRADAS"] = "Inputs";
en["LANG_SUBCATEGORY_DIGITALES"] = "Digital";
en["LANG_SUBCATEGORY_ANALOGICAS"] = "Analog";
en["LANG_CATEGORY_SALIDAS"] = "Outputs";
en["LANG_CATEGORY_LCD"] = "Displays";
en["LANG_CATEGORY_SERVO"] = "Servos";
en["LANG_CATEGORY_MOTORS"] = "Motors";

en["LANG_CATEGORY_SPECIALS"] = "Specials";
en["LANG_CATEGORY_EXTERNALS"] = "Externals";
en["LANG_CATEGORY_MICROBIT"] = "Microbit";
en["LANG_CATEGORY_BASIC"] = "Básic";
en["LANG_CATEGORY_PIROUETTES"] = "Pirouettes";
en["LANG_CATEGORY_LIGHTNING"] = "Lightning";
en["LANG_CATEGORY_COMPARISON"] = "Comparison";
en["LANG_CATEGORY_ACTION"] = "Action";
en["LANG_CATEGORY_ACTIONS"] = "Actions";
en["LANG_CATEGORY_MOTION"] = "Motion";
en["LANG_SUBCATEGORY_MOTORDOBLEPWM"] = "Motor Double PWM";

en["LANG_CATEGORY_ADVANCED"] = "Pin Procedures";
en["LANG_CONTROLS_BASE_DELAY_TOOLTIP"] = "Wait for the specified time in milliseconds (ms).";
en["LANG_CONTROLS_BASE_DELAY_WAIT"] = "Wait [ms]";
en["LANG_CONTROLS_BASE_MILLIS"] = "Time since startup (ms)";
en["LANG_CONTROLS_BASE_MILLIS_TOOLTIP"] = "Number of milliseconds since the program started (long integer).";
en["LANG_CONTROLS_DOWHILE_OPERATOR_DO"] = "Do";
en["LANG_CONTROLS_DOWHILE_OPERATOR_WHILE"] = "while";
en["LANG_CONTROLS_DOWHILE_TOOLTIP"] = "While the condition is true, continue executing the actions within the block.";
en["LANG_CONTROLS_EXECUTE"] = "Execute";
en["LANG_CONTROLS_EXECUTE_TOOLTIP"] = "Execute C/C++ code. Use with caution, as it can easily break the program and prevent proper compilation.";
en["LANG_CONTROLS_FLOW_STATEMENTS_HELPURL"] = "";
en["LANG_CONTROLS_FLOW_STATEMENTS_INPUT_OFLOOP"] = "the loop";
en["LANG_CONTROLS_FLOW_STATEMENTS_OPERATOR_BREAK"] = "break";
en["LANG_CONTROLS_FLOW_STATEMENTS_OPERATOR_CONTINUE"] = "continue with the next iteration";
en["LANG_CONTROLS_FLOW_STATEMENTS_TOOLTIP_BREAK"] = "Break the loop containing this statement.";
en["LANG_CONTROLS_FLOW_STATEMENTS_TOOLTIP_CONTINUE"] = "Skip the remaining actions of this iteration and continue with the next iteration.";
en["LANG_CONTROLS_FLOW_STATEMENTS_WARNING"] = "Attention: This block can only be used inside a loop.";
en["LANG_CONTROLS_FOR"] = "Repeat";
en["LANG_CONTROLS_FOR_FROM_WARNING"] = "You cannot assign a variable to the initial value of the 'for' loop.";
en["LANG_CONTROLS_FOR_INPUT"] = "x";
en["LANG_CONTROLS_FOR_INPUT_DO"] = "do";
en["LANG_CONTROLS_FOR_INPUT_FROM"] = "from";
en["LANG_CONTROLS_FOR_INPUT_TO"] = "to";
en["LANG_CONTROLS_FOR_INPUT_VAR"] = "x";
en["LANG_CONTROLS_FOR_INPUT_WITH"] = "Create loop with";
en["LANG_CONTROLS_FOR_TOOLTIP"] = "Count from a starting number to an ending number. Each time the count increments by one, the variable takes that value and the actions are executed.";
en["LANG_CONTROLS_FOR_TO_WARNING"] = "You cannot assign a variable to the final value of the 'for' loop.";
en["LANG_CONTROLS_IF_ELSEIF_Field_ELSEIF"] = "else if";
en["LANG_CONTROLS_IF_ELSEIF_TOOLTIP"] = "Add a condition to the 'if' block.";
en["LANG_CONTROLS_IF_ELSE_Field_ELSE"] = "else";
en["LANG_CONTROLS_IF_ELSE_TOOLTIP"] = "Add a final condition to the 'if' block to capture the remaining options.";
en["LANG_CONTROLS_IF_IF_Field_IF"] = "if";
en["LANG_CONTROLS_IF_IF_TOOLTIP"] = "Add, delete, or reorder sections to reconfigure this 'if' block.";
en["LANG_CONTROLS_IF_MSG_ELSE"] = "else";
en["LANG_CONTROLS_IF_MSG_ELSEIF"] = "else if";
en["LANG_CONTROLS_IF_MSG_IF"] = "if";
en["LANG_CONTROLS_IF_MSG_THEN"] = "execute";
en["LANG_CONTROLS_IF_TOOLTIP_1"] = "If the condition is true, execute the actions within the block.";
en["LANG_CONTROLS_IF_TOOLTIP_2"] = "If the condition is true, execute the first set of commands. Otherwise, execute the second set of commands.";
en["LANG_CONTROLS_IF_TOOLTIP_3"] = "If the first value is true, execute the first set of commands. Otherwise, if the second value is true, execute the second set of commands.";
en["LANG_CONTROLS_IF_TOOLTIP_4"] = "If the first value is true, execute the first set of commands. Otherwise, if the second value is true, execute the second set of commands. If neither value is true, execute the last set of commands.";
en["LANG_CONTROLS_REPEAT_INPUT_DO"] = "execute";
en["LANG_CONTROLS_REPEAT_TITLE_REPEAT"] = "Repeat";
en["LANG_CONTROLS_REPEAT_TITLE_TIMES"] = "times";
en["LANG_CONTROLS_REPEAT_TOOLTIP"] = "Execute the instructions a specific number of times.";
en["LANG_CONTROLS_CICLO_TITLE_REPEAT"] = "Execute sequence";
en["LANG_CONTROLS_CICLO_TITLE_TIMES"] = "during";
en["LANG_CONTROLS_CICLO_MS"] = "ms";
en["LANG_CONTROLS_CICLO_TOOLTIP"] = "Execute the instructions in sequence.";
en["LANG_CONTROLS_SETUP_LOOP_LOOP_TITLE"] = "Repeat";
en["LANG_CONTROLS_SETUP_LOOP_SETUP_TITLE"] = "Start";
en["LANG_CONTROLS_SETUP_LOOP_TOOLTIP"] = "The blocks in Start will execute only once at startup, while the blocks in Repeat will execute repeatedly.";
en["LANG_CONTROLS_SWITCH"] = "Switch";
en["LANG_CONTROLS_SWITCH_CASE"] = "case ";
en["LANG_CONTROLS_SWITCH_CASE_TOOLTIP"] = "case ";
en["LANG_CONTROLS_SWITCH_COLON"] = ": ";
en["LANG_CONTROLS_SWITCH_DEFAULT"] = "otherwise";
en["LANG_CONTROLS_SWITCH_DEFAULT_TOOLTIP"] = "The 'default' block specifies the action to be executed if none of the previous cases are met.";
en["LANG_CONTROLS_SWITCH_DO"] = "execute";
en["LANG_CONTROLS_SWITCH_IS"] = "is: ";
en["LANG_CONTROLS_SWITCH_SWITCH_TOOLTIP"] = "This block compares one by one if the different cases are met.";
en["LANG_CONTROLS_SWITCH_TOOLTIP_1"] = "Execute the actions of the case that is met.";
en["LANG_CONTROLS_SWITCH_TOOLTIP_2"] = "Execute the actions of the case that is met.";
en["LANG_CONTROLS_SWITCH_TOOLTIP_3"] = "Execute the actions of the case that is met.";
en["LANG_CONTROLS_SWITCH_TOOLTIP_4"] = "Execute the actions of the case that is met.";
en["LANG_CONTROLS_WHILEUNTIL_OPERATOR_UNTIL"] = "until";
en["LANG_CONTROLS_WHILEUNTIL_OPERATOR_WHILE"] = "while";
en["LANG_CONTROLS_WHILEUNTIL_TOOLTIP_UNTIL"] = "While the condition is false, execute the instructions.";
en["LANG_CONTROLS_WHILEUNTIL_TOOLTIP_WHILE"] = "While the condition is true, execute the instructions.";
en["LANG_CONTROL_CHRONOMETER"] = "Start chronometer";
en["LANG_CONTROL_CHRONOMETER_TOOLTIP"] = "Start chronometer";
en["LANG_CONTROL_CHRONOMETER_MILLIS"] = "Chronometer";
en["LANG_CONTROL_CHRONOMETER_MILLIS_TOOLTIP"] = "Chronometer";
en["LANG_Color"] = "Color";
en["LANG_ENTRADAS_BUTTON"] = "Button";
en["LANG_ENTRADAS_BUTTON_PIN"] = "BUTTON on pin";
en["LANG_ENTRADAS_BUTTON_TOOLTIP"] = "Button";
en["LANG_ENTRADAS_CHOQUE"] = "SHOCK on pin";
en["LANG_ENTRADAS_CHOQUE_PIN"] = "Pin";
en["LANG_ENTRADAS_CHOQUE_TOOLTIP"] = "Returns the digital value measured by the shock sensor";
en["LANG_ENTRADAS_GOLPE"] = "IMPACT on pin";
en["LANG_ENTRADAS_GOLPE_PIN"] = "Pin";
en["LANG_ENTRADAS_GOLPE_TOOLTIP"] = "Returns the digital value measured by the impact sensor";
en["LANG_ENTRADAS_INTERRUPTOR_OPTICO"] = "OPTICAL SWITCH on pin";
en["LANG_ENTRADAS_INTERRUPTOR_OPTICO_PIN"] = "Pin";
en["LANG_ENTRADAS_INTERRUPTOR_OPTICO_TOOLTIP"] = "Returns the digital value measured by the optical switch";
en["LANG_ENTRADAS_FUEGO"] = "FIRE on pin";
en["LANG_ENTRADAS_FUEGO_TOOLTIP"] = "Returns the analog value measured by the fire sensor";
en["LANG_ENTRADAS_PULSO"] = "PULSE on pin";
en["LANG_ENTRADAS_PULSO_TOOLTIP"] = "Returns the analog value measured by the pulse sensor";
en["LANG_ENTRADAS_MAGNETICO"] = "MAGNETIC on pin";
en["LANG_ENTRADAS_MAGNETICO_TOOLTIP"] = "Returns the analog value measured by the magnetic sensor";
en["LANG_ENTRADAS_TEMPERATURA_ANALOGICO"] = "TEMPERATURE on pin";
en["LANG_ENTRADAS_TEMPERATURA_ANALOGICO_TOOLTIP"] = "Returns the analog value measured by the temperature sensor";
en["LANG_ENTRADAS_JOYSTICK"] = "of JOYSTICK on pin";
en["LANG_ENTRADAS_JOYSTICK_X"] = "X Axis";
en["LANG_ENTRADAS_JOYSTICK_Y"] = "Y Axis";
en["LANG_ENTRADAS_JOYSTICK_A"] = "Button";
en["LANG_ENTRADAS_JOYSTICK_TOOLTIP"] = "Returns the analog value measured by the joystick sensor";
en["LANG_ENTRADAS_ENCODER"] = "ROTATION on dt pin";
en["LANG_ENTRADAS_ENCODER_CLK"] = "and clk pin";
en["LANG_ENTRADAS_ENCODER_PIN"] = "Pin";
en["LANG_ENTRADAS_ENCODER_TOOLTIP"] = "Returns the digital value of the rotation encoder";
en["LANG_ENTRADAS_DHT11"] = "DHT11";
en["LANG_ENTRADAS_DHT11_PIN"] = "on pin";
en["LANG_ENTRADAS_DHT11_TYPE"] = "Type";
en["LANG_ENTRADAS_DHT11_TOOLTIP"] = "Initialize the DHT11 library";
en["LANG_ENTRADAS_READ_DHT11_TOOLTIP"] = "Returns the digital value measured by the DHT11 temperature and humidity sensor";
en["LANG_ENTRADAS_DS18B20"] = "DS18B20";
en["LANG_ENTRADAS_DS18B20_PIN"] = "TEMPERATURE on pin";
en["LANG_ENTRADAS_DS18B20_TOOLTIP"] = "Initialize the DS18B20 library";
en["LANG_ENTRADAS_FOLLOWER"] = "Line Follower";
en["LANG_ENTRADAS_FOLLOWER_LEFT"] = "Left";
en["LANG_ENTRADAS_FOLLOWER_NINGUNO"] = "None";
en["LANG_ENTRADAS_FOLLOWER_PIN_LEFT"] = "Left pin";
en["LANG_ENTRADAS_FOLLOWER_PIN_RIGHT"] = "Right pin";
en["LANG_ENTRADAS_FOLLOWER_RIGHT"] = "Right";
en["LANG_ENTRADAS_FOLLOWER_TOOLTIP"] = "Returns the digital value of the optical sensors and creates a conditional for each one";
en["LANG_ENTRADAS_Frecuencia"] = "Frequency";
en["LANG_ENTRADAS_HALL"] = "MAGNETIC on pin";
en["LANG_ENTRADAS_HALL_PIN"] = "Pin";
en["LANG_ENTRADAS_HALL_TOOLTIP"] = "Returns the digital value measured by the magnetic sensor";
en["LANG_ENTRADAS_HUMEDAD"] = "HUMIDITY";
en["LANG_ENTRADAS_HUMEDAD_TOOLTIP"] = "Returns the humidity percentage";
en["LANG_ENTRADAS_INFRARED"] = "OPTICAL on pin";
en["LANG_ENTRADAS_INFRARED_PIN"] = "Pin";
en["LANG_ENTRADAS_INFRARED_DETECT"] = "detects";
en["LANG_ENTRADAS_INFRARED_TOOLTIP"] = "Returns the digital value of the optical sensor";
en["LANG_ENTRADAS_IR"] = "IR on pin";
en["LANG_ENTRADAS_IRCONTROL"] = "IR available";
en["LANG_ENTRADAS_IRCONTROL2"] = "receives";
en["LANG_ENTRADAS_IRCONTROL2_PIN"] = "button";
en["LANG_ENTRADAS_IRCONTROL2_TOOLTIP"] = "";
en["LANG_ENTRADAS_IRCONTROL_TOOLTIP"] = "";
en["LANG_ENTRADAS_IR_PIN"] = "Pin";
en["LANG_ENTRADAS_IR_TOOLTIP"] = "Initialize the IR control library";
en["LANG_ENTRADAS_LLUVIA"] = "RAIN on pin";
en["LANG_ENTRADAS_LLUVIA_PIN"] = "Pin";
en["LANG_ENTRADAS_LLUVIA_TOOLTIP"] = "Returns the analog value measured by the rain sensor";
en["LANG_ENTRADAS_OBSTACULO"] = "OBSTACLE on pin";
en["LANG_ENTRADAS_OBSTACULO_PIN"] = "Pin";
en["LANG_ENTRADAS_OBSTACULO_TOOLTIP"] = "Returns the digital value measured by the obstacle sensor";
en["LANG_ENTRADAS_PHOTORESISTOR"] = "LIGHT on pin";
en["LANG_ENTRADAS_PHOTORESISTOR_PIN"] = "Pin";
en["LANG_ENTRADAS_PHOTORESISTOR_TOOLTIP"] = "Returns the analog value measured by the light sensor (photocell).";
en["LANG_ENTRADAS_POTENTIOMETER"] = "POTENTIOMETER on pin";
en["LANG_ENTRADAS_POTENTIOMETER_PIN"] = "Pin";
en["LANG_ENTRADAS_POTENTIOMETER_TOOLTIP"] = "Returns the analog value measured by the potentiometer";
en["LANG_ENTRADAS_SONIDO"] = "SOUND on pin";
en["LANG_ENTRADAS_SONIDO_PIN"] = "Pin";
en["LANG_ENTRADAS_SONIDO_TOOLTIP"] = "Returns the analog value measured by the sound sensor.";
en["LANG_ENTRADAS_SUELO"] = "SOIL on pin";
en["LANG_ENTRADAS_SUELO_PIN"] = "Pin";
en["LANG_ENTRADAS_SUELO_TOOLTIP"] = "Returns the analog value measured by the soil sensor";
en["LANG_ENTRADAS_SensorColor_TOOLTIP"] = "Returns true if the specified color is detected, otherwise returns false";
en["LANG_ENTRADAS_TEMPERATURA"] = "TEMPERATURE";
en["LANG_ENTRADAS_TEMPERATURA_DS18B20"] = "TEMPERATURE";
en["LANG_ENTRADAS_TEMPERATURA_DS18B20_PIN"] = "on pin";
en["LANG_ENTRADAS_TEMPERATURA_DS18B20_TOOLTIP"] = "Returns the temperature in degrees Celsius or humidity percentage";
en["LANG_ENTRADAS_TEMPERATURA_TOOLTIP"] = "Returns the temperature in degrees Celsius";
en["LANG_ENTRADAS_TILT"] = "TILT on pin";
en["LANG_ENTRADAS_TILT_PIN"] = "Pin";
en["LANG_ENTRADAS_TILT_TOOLTIP"] = "Returns the digital value measured by the tilt sensor";
en["LANG_ENTRADAS_TOUCH"] = "TOUCH";
en["LANG_ENTRADAS_TOUCH_Boton"] = "Button";
en["LANG_ENTRADAS_TOUCH_PIN"] = "on pin";
en["LANG_ENTRADAS_TOUCH_TOOLTIP"] = "Returns the digital value measured by the touch sensor";
en["LANG_ENTRADAS_TecladoTactil"] = "Touch keyboard";
en["LANG_ENTRADAS_ULTRASONIDO"] = "ULTRASONIC";
en["LANG_ENTRADAS_ULTRASONIDO_RED_PIN"] = "on pin";
en["LANG_ENTRADAS_ULTRASONIDO_PIN"] = "on pin";
en["LANG_ENTRADAS_ULTRASONIDO_TRIGGER_PIN"] = "on trigger pin";
en["LANG_ENTRADAS_ULTRASONIDO_ECHO_PIN"] = "and echo pin";
en["LANG_ENTRADAS_ULTRASONIDO_TOOLTIP"] = "Returns the distance measured by the ultrasonic sensor";
en["LANG_ENTRADAS_ULTRASONIDO_CLOSE"] = "close";
en["LANG_ENTRADAS_ULTRASONIDO_FAR"] = "far";
en["LANG_ENTRADAS_VIBRATION"] = "VIBRATION on pin";
en["LANG_ENTRADAS_VIBRATION_PIN"] = "Pin";
en["LANG_ENTRADAS_VIBRATION_TOOLTIP"] = "Returns the digital value measured by the vibration sensor";
en["LANG_Gesto"] = "Gesture";
en["LANG_OLED_CLEAR"] = "Clear OLED screen";
en["LANG_OLED_CLEAR_TOOLTIP"] = "Clears the OLED screen";
en["LANG_OLED_TEXT"] = "Escribir";
en["LANG_OLED_TEXT_SIZE"] = "with size";
en["LANG_OLED_TEXT_X"] = "in x";
en["LANG_OLED_TEXT_Y"] = "y";
en["LANG_OLED_TEXT_OLED"] = "of the OLED screen";
en["LANG_OLED_TEXT_TOOLTIP"] = "Write text to the OLED screen";
en["LANG_OLED_DRAW"] = "Draw on OLED";
en["LANG_OLED_DRAW_TOOLTIP"] = "Draw on OLED screen";
en["LANG_LCD_ADVANCED_COLUMNS"] = "and column";
en["LANG_LCD_ADVANCED_DEF"] = "Advanced LCD";
en["LANG_LCD_ADVANCED_ROWS"] = "in row";
en["LANG_LCD_CLEAR"] = "Clear LCD text";
en["LANG_LCD_CLEAR_TOOLTIP"] = "LCD: Clear";
en["LANG_LCD_DEF"] = "LCD (2x16)";
en["LANG_LCD_DEF_ADVANCED_TOOLTIP"] = "Block that defines the advanced LCD";
en["LANG_LCD_DEF_TOOLTIP"] = "Write text to the LCD screen";
en["LANG_LCD_DIRECCION"] = "I2C Address";
en["LANG_LCD_ADVANCED_COLUMNS_I2C"] = "and column";
en["LANG_LCD_DIRECCION_I2C"] = "of the LCD at I2C address";
en["LANG_LCD_DIRECCION_I2C_2"] = "at I2C address";
en["LANG_LCD_DIRECCION_I2C_3"] = "on pin";
en["LANG_LCD_I2C"] = "Write";
en["LANG_LCD_I2C_POSITIONX1"] = "line from x";
en["LANG_LCD_I2C_POSITIONY1"] = ", y";
en["LANG_LCD_I2C_POSITIONX2"] = "to x";
en["LANG_LCD_I2C_POSITIONY2"] = ", y";
en["LANG_LCD_PINS"] = "of the LCD on pin";
en["LANG_LCD_PRINT"] = "LCD: Write ";
en["LANG_LCD_PRINT_POSITION"] = "Fix text position?";
en["LANG_LCD_PRINT_TOOLTIP"] = "Prints a string on the LCD screen at the specified position or the next available one."
en["LANG_LCD_PRINT_columna"] = "column"
en["LANG_LCD_PRINT_fila"] = "row"
en["LANG_LCD_SETBACKLIGHT"] = "the LCD backlight"
en["LANG_LCD_SETBACKLIGHT_CLOSE"] = ""
en["LANG_LCD_SETBACKLIGHT_TOOLTIP"] = "Adjusts the backlight of the LCD screen"
en["LANG_LCD_scroll"] = "Scrolling text"
en["LANG_LIBRERIA"] = "Library"
en["LANG_LOGIC_BOOLEAN_FALSE"] = "false"
en["LANG_LOGIC_BOOLEAN_TOOLTIP"] = "Returns true or false based on the selection."
en["LANG_LOGIC_BOOLEAN_TRUE"] = "true"
en["LANG_COMPARE_ULTRASONIC"] = "detects object"
en["LANG_COMPARE_OBSTACLE"] = "detects obstacle"
en["LANG_COMPARE_HUMIDITY"] = "is"
en["LANG_COMPARE_HUMIDITY_wet"] = "wet";
en["LANG_COMPARE_HUMIDITY_dry"] = "dry";
en["LANG_COMPARE_NOISE"] = "detects"
en["LANG_COMPARE"] = "is"
en["LANG_COMPARE_TO"] = "to"
en["LANG_LOGIC_COMPARE_TOOLTIP_EQ"] = "Compares if the two inputs are equal."
en["LANG_LOGIC_COMPARE_TOOLTIP_GT"] = "Compares if the first input is greater than the second input."
en["LANG_LOGIC_COMPARE_TOOLTIP_GTE"] = "Compares if the first input is greater than or equal to the second input."
en["LANG_LOGIC_COMPARE_TOOLTIP_LT"] = "Compares if the first input is less than the second input."
en["LANG_LOGIC_COMPARE_TOOLTIP_LTE"] = "Compares if the first input is less than or equal to the second input."
en["LANG_LOGIC_COMPARE_TOOLTIP_NEQ"] = "Compares if the two inputs are not equal."
en["LANG_LOGIC_NEGATE_HELPURL"] = ""
en["LANG_LOGIC_NEGATE_INPUT_NOT"] = "not"
en["LANG_LOGIC_NEGATE_TOOLTIP"] = "Returns the opposite of the input."
en["LANG_LOGIC_OPERATION_AND"] = "and"
en["LANG_LOGIC_OPERATION_OR"] = "or"
en["LANG_LOGIC_OPERATION_TOOLTIP_AND"] = "Compares if both inputs are true."
en["LANG_LOGIC_OPERATION_TOOLTIP_OR"] = "Compares if either of the inputs is true."
en["LANG_MATH_ADVANCED_MAP_BRACKET"] = "]"
en["LANG_MATH_ADVANCED_MAP_FROM"] = "From ["
en["LANG_MATH_ADVANCED_MAP_HYPHEN"] = "-"
en["LANG_MATH_ADVANCED_MAP_MAP"] = "Map "
en["LANG_MATH_ADVANCED_MAP_TO"] = "to ["
en["LANG_MATH_ADVANCED_MAP_TOOLTIP"] = "Maps the input from one range of initial values to another distinct range."
en["LANG_MATH_ARRAY_ARRAY3"] = "[3]"
en["LANG_MATH_ARRAY_BRACKET3"] = "{"
en["LANG_MATH_ARRAY_BRACKET4"] = "}"
en["LANG_MATH_ARRAY_COMMA"] = ","
en["LANG_MATH_ARRAY_TOOLTIP"] = "Vector of three integers"
en["LANG_MATH_BASE_MAP"] = "Map "
en["LANG_MATH_BASE_MAP_BRACKET"] = "]"
en["LANG_MATH_BASE_MAP_TOOLTIP"] = "Maps the input from the range [0-1023] to another range of values."
en["LANG_MATH_BASE_MAP_VALUE_TO"] = "Value between [0-"
en["LANG_MATH_MODULO_TOOLTIP"] = "Returns the remainder of the division of the two inputs."
en["LANG_MATH_NUMBER_TOOLTIP"] = "Integer number"
en["LANG_MATH_PWM"] = "PWM"
en["LANG_MATH_COUNTER"] = "Countdown of"
en["LANG_MATH_COUNTER_SECONDS"] = "seconds"
en["LANG_MATH_COUNTER_TOOLTIP"] = "Generates a countdown timer"
en["LANG_MATH_SINGLE_OP_ABSOLUTE"] = "absolute value"
en["LANG_MATH_SINGLE_OP_ROOT"] = "square root"
en["LANG_MATH_SINGLE_TOOLTIP_ABS"] = "Returns the absolute value of a number."
en["LANG_MATH_SINGLE_TOOLTIP_EXP"] = "Returns the exponential of a number."
en["LANG_MATH_SINGLE_TOOLTIP_LN"] = "Returns the natural logarithm of a number."
en["LANG_MATH_SINGLE_TOOLTIP_LOG10"] = "Returns the base 10 logarithm of a number."
en["LANG_MATH_SINGLE_TOOLTIP_NEG"] = "Returns the number with its sign changed."
en["LANG_MATH_SINGLE_TOOLTIP_POW10"] = "Returns 10 raised to a power."
en["LANG_MATH_SINGLE_TOOLTIP_ROOT"] = "Returns the square root of a number."
en["LANG_PROCEDURES_CALLNORETURN_CALL"] = "execute"
en["LANG_PROCEDURES_CALLNORETURN_HELPURL"] = ""
en["LANG_PROCEDURES_CALLNORETURN_PROCEDURE"] = "function_without_return"
en["LANG_PROCEDURES_CALLNORETURN_TOOLTIP"] = "Executes this function."
en["LANG_PROCEDURES_CALLRETURN_CALL"] = "execute"
en["LANG_PROCEDURES_CALLRETURN_HELPURL"] = ""
en["LANG_PROCEDURES_CALLRETURN_PROCEDURE"] = "func_with_return"
en["LANG_PROCEDURES_CALLRETURN_TOOLTIP"] = "Execute this function that has a return value."
en["LANG_PROCEDURES_CALL_WITHOUT_DEFINITION"] = "Calling a function without prior definition."
en["LANG_PROCEDURES_DEFNORETURN_DO"] = "execute"
en["LANG_PROCEDURES_DEFNORETURN_HELPURL"] = ""
en["LANG_PROCEDURES_DEFNORETURN_PROCEDURE"] = "func_without_return"
en["LANG_PROCEDURES_DEFNORETURN_TOOLTIP"] = "Function that will be executed without returning anything."
en["LANG_PROCEDURES_DEFRETURN_DO"] = "execute"
en["LANG_PROCEDURES_DEFRETURN_HELPURL"] = ""
en["LANG_PROCEDURES_DEFRETURN_PROCEDURE"] = "func_with_return"
en["LANG_PROCEDURES_DEFRETURN_RETURN"] = "returns"
en["LANG_PROCEDURES_DEFRETURN_TOOLTIP"] = "Function with a return value."
en["LANG_PROCEDURES_DEF_DUPLICATE_WARNING"] = "Attention: This function has duplicate parameters."
en["LANG_PROCEDURES_HIGHLIGHT_DEF"] = "Highlight the function"
en["LANG_PROCEDURES_IFRETURN_TOOLTIP"] = "If the condition is true, the function will return this value."
en["LANG_PROCEDURES_IFRETURN_WARNING"] = "Attention: This block can only be used within a function that has a return value."
en["LANG_PROCEDURES_MUTATORARG_Field"] = "variable:"
en["LANG_PROCEDURES_MUTATORCONTAINER_Field"] = "parameters"
en["LANG_PROCEDURES_RETURN"] = "returns"
en["LANG_PROCEDURES_RETURN_TOOLTIP"] = "Returns a value"
en["LANG_RESERVED_WORDS"] = "Reserved word: this name is not allowed."
en["LANG_SALIDAS_BLUETOOTH_DEF"] = "Bluetooth"
en["LANG_SALIDAS_BLUETOOTH_DEF_BAUD_RATE"] = "Baud rate"
en["LANG_SALIDAS_BLUETOOTH_DEF_NAME"] = "Name"
en["LANG_SALIDAS_BLUETOOTH_DEF_PIN1"] = "RX"
en["LANG_SALIDAS_BLUETOOTH_DEF_PIN2"] = "TX"
en["LANG_SALIDAS_BLUETOOTH_DEF_PINCODE"] = "PIN code"
en["LANG_SALIDAS_BLUETOOTH_DEF_RECEIVE"] = "Receive"
en["LANG_SALIDAS_BLUETOOTH_DEF_SEND"] = "Send"
en["LANG_SALIDAS_BLUETOOTH_DEF_TOOLTIP"] = "Definition of the Bluetooth module"
en["LANG_SALIDAS_BLUETOOTH_NAME"] = "Rename Bluetooth module"
en["LANG_SALIDAS_BLUETOOTH_NAME_TOOLTIP"] = "Change the name of the Bluetooth module"
en["LANG_SALIDAS_BLUETOOTH_RECEIVE"] = "Bluetooth: receive "
en["LANG_SALIDAS_BLUETOOTH_RECEIVE_BLUETOOTH"] = "Bluetooth"
en["LANG_SALIDAS_BLUETOOTH_RECEIVE_TOOLTIP"] = "Returns the data received by the Bluetooth module"
en["LANG_SALIDAS_BLUETOOTH_SEND"] = "Bluetooth: Send"
en["LANG_SALIDAS_BLUETOOTH_SEND_SEND"] = "Send data"
en["LANG_SALIDAS_BLUETOOTH_SEND_TOOLTIP"] = "Takes input data and sends it using the Bluetooth module"
en["LANG_SALIDAS_BUTTONS"] = "Robot Control"
en["LANG_SALIDAS_BUTTONS_SPACE"] = "         ";
en["LANG_SALIDAS_BUTTONS_BUTTON_A"] = "Button     A"
en["LANG_SALIDAS_BUTTONS_BUTTON_B"] = "Button     B"
en["LANG_SALIDAS_BUTTONS_BUTTON_D"] = "Button     ↓"
en["LANG_SALIDAS_BUTTONS_BUTTON_L"] = "Button  "
en["LANG_SALIDAS_BUTTONS_BUTTON_R"] = "Button  "
en["LANG_SALIDAS_BUTTONS_BUTTON_S"] = "Button  "
en["LANG_SALIDAS_BUTTONS_BUTTON_U"] = "Button  "
en["LANG_SALIDAS_BUTTONS_TOOLTIP"] = "Control your board via Bluetooth"
en["LANG_SALIDAS_INOUT_TONE"] = "Tone on pin #"
en["LANG_SALIDAS_INOUT_TONE_TOOLTIP"] = "Generates audio tones on a pin"
en["LANG_SALIDAS_INOUT_NOTONE"] = "No tone on pin #"
en["LANG_SALIDAS_INOUT_NOTONE_TOOLTIP"] = "Stops audio tone generation on a pin"
en["LANG_SALIDAS_RAYITA"] = "|"
en["LANG_SALIDAS_ZUMBADOR_ACTIVO"] = "the ACTIVE BUZZER on pin"
en["LANG_SALIDAS_ZUMBADOR_ACTIVO_TOOLTIP"] = "Controls the active buzzer"
en["LANG_SALIDAS_LASER"] = "the LASER on pin"
en["LANG_SALIDAS_LASER_TOOLTIP"] = "Controls the laser"
en["LANG_SALIDAS_RELE_UNOMEGA"] = "the RELAY on pin"
en["LANG_SALIDAS_RELE_UNOMEGA_TOOLTIP"] = "Controls the relay"
en["LANG_SALIDAS_LED_BICOLOR_ELEGIR"] = "Choose the color"
en["LANG_SALIDAS_LED_BICOLOR_APAGAR"] = "Turn off the BICOLOR LED on pin R"
en["LANG_SALIDAS_LED_BICOLOR_PIN_R"] = "of the BICOLOR LED on pin R"
en["LANG_SALIDAS_LED_BICOLOR_PIN_Y"] = "and pin Y"
en["LANG_SALIDAS_LED_BICOLOR_TOOLTIP"] = "Controls the bicolor LED"
en["LANG_SALIDAS_LEDRGB_UNOMEGA"] = "Choose the color"
en["LANG_SALIDAS_LEDRGB_UNOMEGA_PIN_R"] = "of the RGB LED on pin R"
en["LANG_SALIDAS_LEDRGB_UNOMEGA_PIN_G"] = "G"
en["LANG_SALIDAS_LEDRGB_UNOMEGA_PIN_B"] = "B"
en["LANG_SALIDAS_LEDRGB_UNOMEGA_TOOLTIP"] = "Controls the RGB LED"
en["LANG_SALIDAS_LED_NEW"] = "the LED on pin"
en["LANG_SALIDAS_LED"] = "LED"
en["LANG_SALIDAS_LED_PWM"] = "of the LED on pin"
en["LANG_SALIDAS_LED_RGB_PWM"] = "of the LED MODULE on pin"
en["LANG_SALIDAS_LEDRGB"] = "RGB LED"
en["LANG_SALIDAS_LEDRGB_AMARILLO"] = "yellow"
en["LANG_SALIDAS_LEDRGB_APAGADO"] = "Off"
en["LANG_SALIDAS_LEDRGB_AZUL"] = "blue"
en["LANG_SALIDAS_LEDRGB_COLOR"] = "Color"
en["LANG_SALIDAS_LEDRGB_DURATION"] = "Duration [ms]"
en["LANG_SALIDAS_LEDRGB_NARANJA"] = "orange"
en["LANG_SALIDAS_LEDRGB_PINAZUL"] = "blue"
en["LANG_SALIDAS_LEDRGB_PINROJO"] = "red"
en["LANG_SALIDAS_LEDRGB_PINVERDE"] = "green"
en["LANG_SALIDAS_LEDRGB_ROJO"] = "red"
en["LANG_SALIDAS_LEDRGB_TOOLTIP"] = "RGB LED"
en["LANG_SALIDAS_LEDRGB_VERDE"] = "green"
en["LANG_SALIDAS_LEDRGB_VIOLETA"] = "violet"
en["LANG_SALIDAS_LED_RGB_COLOR"] = "Set color to R"
en["LANG_SALIDAS_LED_RGB_COLOR_G"] = "G"
en["LANG_SALIDAS_LED_RGB_COLOR_B"] = "B"
en["LANG_SALIDAS_LED_INTENSIDAD"] = "Set brightness to"
en["LANG_SALIDAS_LED_OFF"] = "Turn off"
en["LANG_SALIDAS_LED_ON"] = "Turn on"
en["LANG_SALIDAS_LED_PIN"] = "Pin"
en["LANG_SALIDAS_LED_STATE"] = "State"
en["LANG_SALIDAS_LED_TOOLTIP"] = "Controls the LED"
en["LANG_SALIDAS_LED_RGB_TOOLTIP"] = "Controls the RGB LED"
en["LANG_SEMAPHORE_LGO20_PIN"] = "the SEMAPHORE on pin";
en["LANG_BHOOT_LED_RGB"] = "Turn on color"
en["LANG_BHOOT_LED_RGB_PIN"] = "the LED MODULE on pin"
en["LANG_BHOOT_LED_RGB_PIN_SINGLE"] = "the RGB LED on pin"
en["LANG_BHOOT_LED_RGB_TOOLTIP"] = "Change the color of RGB LEDs";
en["LANG_BHOOT_LED_NEOPIXEL"] = "Choose the color";
en["LANG_BHOOT_LED_NEOPIXEL_LED"] = "on the LED";
en["LANG_BHOOT_LED_NEOPIXEL_LED_BOTH"] = "both";
en["LANG_BHOOT_LED_NEOPIXEL_LED_RIGHT"] = "right";
en["LANG_BHOOT_LED_NEOPIXEL_LED_LEFT"] = "left";
en["LANG_BHOOT_LIGHT_INTENSITY"] = "COLOR INTENSITY";
en["LANG_BHOOT_LIGHT_INTENSITY_PIN"] = "on pin";
en["LANG_BHOOT_LIGHT_INTENSITY_TOOLTIP"] = "Get the intensity of a color";
en["LANG_BHOOT_LIGHT_COLOR"] = "COLOR on pin";
en["LANG_BHOOT_LIGHT_COLOR_IS"] = "is";
en["LANG_BHOOT_LIGHT_COLOR_TOOLTIP"] = "Get the intensity of a color";
en["LANG_PEATON_NEOPIXEL_LED"] = "on the pedestrian light";
en["LANG_PEATON_NEOPIXEL_LED_TOOLTIP"] = "Turn on the pedestrian light";
en["LANG_PEATON_OFF_NEOPIXEL_LED_TOOLTIP"] = "Turn off the pedestrian light";
en["LANG_PEATON_OFF_NEOPIXEL"] = "Turn off the pedestrian light";
en["LANG_BHOOT_LED_NEOPIXEL_CLEAR"] = "Turn off the LED";
en["LANG_BHOOT_LED_NEOPIXEL_SHOW"] = "Send colors to the LED";
en["LANG_BHOOT_JOYSTICK"] = "from JOYSTICK on pin";
en["LANG_BHOOT_JOYSTICK_X"] = "X-axis";
en["LANG_BHOOT_JOYSTICK_Y"] = "Y-axis";
en["LANG_BHOOT_JOYSTICK_BUTTON"] = "Button";
en["LANG_BHOOT_JOYSTICK_TOOLTIP"] = "Get the position of the JOYSTICK";
en["LANG_SALIDAS_SEMAFORO"] = "from the traffic light";
en["LANG_SALIDAS_SEMAFORO_LED"] = "the LED";
en["LANG_SALIDAS_SEMAFORO_TOOLTIP"] = "Turn on a color in the traffic light";
en["LANG_SALIDAS_SEMAFORO_GIRO"] = "from the turn signal";
en["LANG_SALIDAS_SEMAFORO_GIRO_TOOLTIP"] = "Turn on the turn signal light";
en["LANG_SALIDAS_LUCES_CIUDAD"] = "the lights of";
en["LANG_SALIDAS_LUCES_CIUDAD_TOOLTIP"] = "Turn on the lights in the selected area";
en["MATRIZ_CIUDAD_TOOLTIP"] = "Write text on the city matrix";
en["MATRIZ_CIUDAD_ESCRIBIR"] = "Write";
en["MATRIZ_CIUDAD_VELOCIDAD"] = "on the matrix with speed";
en["LANG_ENTRADAS_CIUDAD_LDR"] = "Light level";
en["LANG_ENTRADAS_CIUDAD_LDR_TOOLTIP"] = "";
en["LANG_SALIDAS_MOTOR"] = "DC Motor";
en["LANG_SALIDAS_MOTOR2_DIRECCION"] = "Direction";
en["LANG_SALIDAS_MOTOR_DIRECCION"] = "Direction";
en["LANG_SALIDAS_MOTOR_SINGLE_TOOLTIP"] = "Control individual DC motors";
en["LANG_SALIDAS_MOTOR_SINGLE_PIN"] = "on MOTOR pin";
en["LANG_SALIDAS_MOTOR_DOBLE"] = "DC motors on pins";
en["LANG_SALIDAS_MOTOR_DOBLE_PIND"] = "MD";
en["LANG_SALIDAS_MOTOR_DOBLE_PINI"] = "and MI";
en["LANG_SALIDAS_MOTOR_DOBLE_TOOLTIP"] = "Control DC motors";
en["LANG_SALIDAS_MOTOR_DOBLE_PWM"] = "DC Motors";
en["LANG_SALIDAS_MOTOR_DOBLE_PWM_PIND"] = "MD";
en["LANG_SALIDAS_MOTOR_DOBLE_PWM_PINI"] = "and MI";
en["LANG_SALIDAS_MOTOR_DOBLE_PWM_SPEED"] = "with speed";
en["LANG_SALIDAS_MOTOR_DOBLE_PWM_TOOLTIP"] = "Control DC motors with speed";
en["LANG_SALIDAS_MOTOR_OFF"] = "OFF";
en["LANG_SALIDAS_MOTOR_ON"] = "ON";
en["LANG_SALIDAS_MOTOR_PIN"] = "the FAN MOTOR on pin";
en["LANG_SALIDAS_MOTOR_PIN_PWM"] = "Rotate DC MOTOR on pin";
en["LANG_SALIDAS_MOTOR_DC_PIN"] = "Move DC MOTOR on pin";
en["LANG_SALIDAS_MOTOR_STATE"] = "";
en["LANG_SALIDAS_MOTOR_TOOLTIP"] = "Control the DC motor";
en["LANG_SERVO_360"] = "360° SERVO on pin";
en["LANG_SERVO_360_TOOLTIP"] = "Control the 360° SERVO";
en["LANG_SERVO_270"] = "the 270° SERVO on pin";
en["LANG_SERVO_270_MILLIS_MOVE_PIN"] = "[ms] the 270° SERVO on pin";
en["LANG_SERVO_270_TOOLTIP"] = "Control the 270° SERVO";
en["LANG_CIUDAD_REESTABLECER"] = "Reset service";
en["LANG_CIUDAD_REESTABLECER_TOOLTIP"] = "";
en["LANG_SALIDAS_PIEZO_BUZZER_PIANO"] = "| Play";
en["LANG_SALIDAS_PIEZO_BUZZER_PIANO_NOTE"] = "";
en["LANG_SALIDAS_PIEZO_BUZZER_PIANO_DURATION"] = "note for";
en["LANG_SALIDAS_PIEZO_BUZZER_PIANO_PIN"] = "[ms] on pin";
en["LANG_SALIDAS_PIEZO_BUZZER_PIANO_OCTAVE"] = "octave";
en["LANG_SALIDAS_PIEZO_BUZZER_PIANO_SPACE"] = "                                                                  ";
en["LANG_SALIDAS_PIEZO_BUZZER_PIANO_SPACE_CSS"] = "pseudoBlocklyEmptyText-EN";
en["LANG_BHOOT_PIEZO_BUZZER_PIANO_SPACE"] = "                                        ";
en["LANG_BHOOT_PIEZO_BUZZER_PIANO"] = "Play";
en["LANG_SALIDAS_PIEZO_BUZZER"] = "Buzzer";
en["LANG_SALIDAS_PIEZO_BUZZERNO"] = "Turn off BUZZER";
en["LANG_BHOOT_PIEZO_BUZZERNO"] = "| Turn off built-in BUZZER";
en["LANG_BHOOT_PIEZO_BUZZER_PIN"] = "[ms] on built-in BUZZER";
en["LANG_LGO20_PIEZO_BUZZER_PIN"] = "on the BUZZER on pin";
en["LANG_SALIDAS_PIEZO_BUZZERNO_PIN"] = "Pin";
en["LANG_SALIDAS_PIEZO_BUZZER_DO"] = "C";
en["LANG_SALIDAS_PIEZO_BUZZER_DURATION"] = "Duration [ms]";
en["LANG_BHOOT_PIEZO_BUZZER_DURATION"] = "with duration [ms]";
en["LANG_SALIDAS_PIEZO_BUZZER_FA"] = "F";
en["LANG_SALIDAS_PIEZO_BUZZER_LA"] = "A";
en["LANG_SALIDAS_PIEZO_BUZZER_MI"] = "E";
en["LANG_SALIDAS_PIEZO_BUZZER_PIN"] = "on the pin";
en["LANG_SALIDAS_PIEZO_BUZZER_RE"] = "D";
en["LANG_SALIDAS_PIEZO_BUZZER_SI"] = "B";
en["LANG_SALIDAS_PIEZO_BUZZER_SOL"] = "G";
en["LANG_SALIDAS_PIEZO_BUZZER_TONE"] = "Tone";
en["LANG_SALIDAS_PIEZO_BUZZER_TONE_LC"] = "tone";
en["LANG_SALIDAS_PIEZO_BUZZER_NOTE"] = "note";
en["LANG_SALIDAS_PIEZO_BUZZER_TOOLTIP"] = "Piezoelectric buzzer";
en["LANG_SALIDAS_RELE"] = "Relay (inverted)";
en["LANG_SALIDAS_RELE_OFF"] = "Turn on";
en["LANG_SALIDAS_RELE_ON"] = "Turn off";
en["LANG_SALIDAS_RELE_PIN"] = "Pin";
en["LANG_SALIDAS_RELE_TOOLTIP"] = "Control the relay";
en["LANG_SALIDAS_SCAN_DEF"] = "I2C Scanner";
en["LANG_SALIDAS_DISPLAY_7_SEGMENTS"] = "on the 7-segment display";
en["LANG_SALIDAS_DISPLAY_7_SEGMENTS_ESCRIBIR"] = "Write";
en["LANG_SALIDAS_DISPLAY_7_SEGMENTS_TOOLTIP"] = "Display a number on the 7-segment display";
en["LANG_SALIDAS_DISPLAY_7_SEGMENTS_OFF"] = "Turn off the 7-segment display";
en["LANG_SALIDAS_DISPLAY_7_SEGMENTS_OFF_TOOLTIP"] = "Turn off the 7-segment display";
en["LANG_SERVO"] = "Servo";
en["LANG_SERVO_CONT"] = "Continuous Rotation Servo";
en["LANG_SERVO_CONT_DELAY"] = "Delay [ms]";
en["LANG_SERVO_CONT_PIN"] = "Pin";
en["LANG_SERVO_CONT_ROT"] = "Rotation";
en["LANG_SERVO_CONT_STOPPED"] = "Stop";
en["LANG_SERVO_CONT_TOOLTIP"] = "Continuous rotation servo.";
en["LANG_SERVO_CONT_TURN_CLOCKWISE"] = "Clockwise";
en["LANG_SERVO_CONT_TURN_COUNTERCLOCKWISE"] = "Counterclockwise";
en["LANG_SERVO_MOVE"] = "the SERVO on pin";
en["LANG_SERVO_MOVE_DEGREES"] = "Position at degree";
en["LANG_SERVO_MILLIS_MOVE_MILLIS"] = "degrees every";
en["LANG_SERVO_MILLIS_MOVE_PIN"] = "[ms] the SERVO on pin";
en["LANG_SERVO_MILLIS_MOVE_DEGREES"] = "Gradually move to";
en["LANG_SERVO_MOVE_DELAY"] = "Delay [ms]";
en["LANG_SERVO_MOVE_PIN"] = "Pin";
en["LANG_SERVO_MOVE_TOOLTIP"] = "Move the servo between 0 and 180 degrees.";
en["LANG_SERVO_READ"] = "SERVO on pin";
en["LANG_SERVO_WARNING"] = "You cannot assign a variable to the servo pin";
en["LANG_COPA_NANOBOT_DIRECTION"] = "degrees the NANOROBOT in direction";
en["LANG_COPA_NANOBOT_TURN"] = "Turn";
en["LANG_COPA_NANOBOT_TURN_TOOLTIP"] = "Turn the nanorobot";
en["LANG_COPA_SHOOT_ANTIGEN"] = "Shoot ANTIGEN at VIRAL AGENT";
en["LANG_COPA_SHOOT_ANTIGEN_TOOLTIP"] = "Shoot ANTIGEN";
en["LANG_COPA_IDENTIFY_AGENT"] = "Identify VIRAL AGENT";
en["LANG_COPA_IDENTIFY_AGENT_TOOLTIP"] = "Identify VIRAL AGENT";
en["LANG_COPA_EVALUATE_POSITION"] = "Evaluate position with respect to VIRAL AGENT";
en["LANG_COPA_EVALUATE_POSITION_TOOLTIP"] = "Evaluate position with respect to VIRAL AGENT";
en["LANG_COPA_SHOOT_SPECIAL_ANTIGEN"] = "Shoot antigen at SPECIAL VIRAL AGENT";
en["LANG_COPA_SHOOT_SPECIAL_ANTIGEN_POSITION"] = "at position";
en["LANG_COPA_SHOOT_SPECIAL_ANTIGEN_TOOLTIP"] = "Shoot antigen at SPECIAL VIRAL AGENT";
en["LANG_SensorFrecuencia"] = "Frequency";
en["LANG_SensorFrecuenciaInit_TOOLTIP"] = "Initialize the frequency sensor library";
en["LANG_SensorFrecuenciaSalida_TOOLTIP"] = "Return the measured frequency value in Hz";
en["LANG_SensorGesto"] = "Gestures";
en["LANG_SensorGesto_BUTTON_A"] = "Near";
en["LANG_SensorGesto_BUTTON_B"] = "Far";
en["LANG_SensorGesto_BUTTON_D"] = "Down";
en["LANG_SensorGesto_BUTTON_L"] = "Left";
en["LANG_SensorGesto_BUTTON_R"] = "Right";
en["LANG_SensorGesto_BUTTON_U"] = "Up";
en["LANG_SensorGesto_TOOLTIP"] = "Execute an action depending on the read gesture";
en["LANG_TEXT_APPEND_APPENDTEXT"] = "append text";
en["LANG_TEXT_APPEND_HELPURL"] = "";
en["LANG_TEXT_APPEND_TO"] = "to";
en["LANG_TEXT_APPEND_TOOLTIP"] = "Append text to variable %1.";
en["LANG_TEXT_APPEND_VARIABLE"] = "element";
en["LANG_TEXT_CODE"] = "Code line:"
en["LANG_TEXT_CODE_TOOLTIP"] = "Write code line"
en["LANG_TEXT_CREATE_JOIN_Field_JOIN"] = "join"
en["LANG_TEXT_CREATE_JOIN_ITEM_Field_ITEM"] = "item"
en["LANG_TEXT_CREATE_JOIN_ITEM_TITLE_ITEM"] = "item"
en["LANG_TEXT_CREATE_JOIN_ITEM_TOOLTIP"] = "Add an item to the text."
en["LANG_TEXT_CREATE_JOIN_TITLE_JOIN"] = "join"
en["LANG_TEXT_CREATE_JOIN_TOOLTIP"] = "Add, delete, or rearrange sections to reconfigure this text block."
en["LANG_TEXT_EQUALSIGNORECASE_EQUAL"] = " ="
en["LANG_TEXT_EQUALSIGNORECASE_IS"] = ""
en["LANG_TEXT_EQUALSIGNORECASE_QUESTION"] = ""
en["LANG_TEXT_EQUALSIGNORECASE_TOOLTIP"] = "Compare whether the two input texts are equal, regardless of case."
en["LANG_TEXT_JOIN_Field_CREATEWITH"] = "create text with"
en["LANG_TEXT_JOIN_HELPURL"] = ""
en["LANG_TEXT_JOIN_TOOLTIP"] = "Create text by joining any number of elements."
en["LANG_TEXT_LENGTH_HELPURL"] = ""
en["LANG_TEXT_LENGTH_INPUT_LENGTH"] = "length"
en["LANG_TEXT_LENGTH_TOOLTIP"] = "Returns the number of letters (including spaces) in the input text."
en["LANG_TEXT_SUBSTRING"] = "Substring "
en["LANG_TEXT_SUBSTRING_FROM"] = "from"
en["LANG_TEXT_SUBSTRING_TO"] = "to"
en["LANG_TEXT_SUBSTRING_TOOLTIP"] = "Cuts out the characters from the input text that fall between the two indices and creates a new text with them."
en["LANG_TEXT_TEXT_HELPURL"] = ""
en["LANG_TEXT_TEXT_TOOLTIP"] = "A letter, a word, or a line of text."
en["LANG_VARIABLES_CALL_WITHOUT_DEFINITION"] = "This variable is not declared."
en["LANG_VARIABLES_DEFINE"] = "Define variable "
en["LANG_VARIABLES_DEFINE_AS"] = "as"
en["LANG_VARIABLES_DEFINE_TOOLTIP"] = "Define the value of a variable."
en["LANG_VARIABLES_GET"] = "Var"
en["LANG_VARIABLES_GET_TOOLTIP"] = "Returns the value of a variable."
en["LANG_VARIABLES_GLOBAL"] = "Declare GLOBAL variable"
en["LANG_VARIABLES_GLOBAL_EQUALS"] = "="
en["LANG_VARIABLES_GLOBAL_TOOLTIP"] = "Declares and defines a GLOBAL variable of type integer (int) or text (String)."
en["LANG_VARIABLES_GLOBAL_TYPE"] = "of type "
en["LANG_VARIABLES_LOCAL"] = "Declare variable"
en["LANG_VARIABLES_LOCAL_EQUALS"] = "=";
en["LANG_VARIABLES_LOCAL_TOOLTIP"] = "Declare and define a LOCAL variable of type integer (int) or text (String).";
en["LANG_VARIABLES_LOCAL_TYPE"] = "of type ";
en["LANG_VARIABLES_PIN_ANALOG"] = "Analog pin";
en["LANG_VARIABLES_PIN_DIGITAL"] = "Digital pin";
en["LANG_VARIABLES_PIN_DIGITAL0"] = "CAUTION: Digital pin 0 (Rx pin) is used for uploading programs to the board from the computer. Using it to connect components may cause issues during programming.";
en["LANG_VARIABLES_PIN_TOOLTIP"] = "Select the desired pin.";
en["LANG_VARIABLES_SET"] = "Var";
en["LANG_VARIABLES_SET_AS"] = "=";
en["LANG_VARIABLES_SET_ITEM"] = "item";
en["LANG_VARIABLES_SET_TOOLTIP"] = "Change the value of a variable.";
en["LANG_VARIABLES_TYPE_CHAR"] = "Character";
en["LANG_VARIABLES_TYPE_BYTE"] = "Byte";
en["LANG_VARIABLES_TYPE_FLOAT"] = "Decimal";
en["LANG_VARIABLES_TYPE_INTEGER"] = "Integer";
en["LANG_VARIABLES_TYPE_INTEGER_LONG"] = "Long integer";
en["LANG_VARIABLES_TYPE_STRING"] = "Text";
en["LANG_VARIABLES_SET_VALUE"] = "Set Amount to";
en["LANG_VARIABLES_SET_VALUE_TOOLTIP"] = "Initialize the Amount variable with a value.";
en["LANG_VARIABLES_GET_VALUE"] = "Amount";
en["LANG_VARIABLES_GET_VALUE_TOOLTIP"] = "Get the value of the Amount variable.";
en["LANG_VARIABLES_ADD_ONE"] = "Add 1";
en["LANG_VARIABLES_ADD_ONE_TOOLTIP"] = "Add 1 to the Amount variable.";
en["LANG_VARIABLES_SUBSTRACT_ONE"] = "Subtract 1";
en["LANG_VARIABLES_SUBSTRACT_ONE_TOOLTIP"] = "Subtract 1 from the Amount variable.";
en["LANG_MORSE_SAVE_MESSAGE"] = "Save message";
en["LANG_MORSE_SAVE_MESSAGE_TOOLTIP"] = "Save message.";
en["LANG_MORSE_DECODE_MESSAGE"] = "Decode message";
en["LANG_MORSE_DECODE_MESSAGE_TOOLTIP"] = "Decode message.";
en["LANG_MORSE_GET_MESSAGE"] = "Decoded message";
en["LANG_MORSE_GET_MESSAGE_TOOLTIP"] = "Get the decoded message.";
en["LANG_gestos_DEF"] = "Gestures and color";
en["LANG_gestos_DEF_TOOLTIP"] = "Initialize the gestures and color sensor library.";
en["LANG_matriz_DEF"] = "Matrix";
en["LANG_matriz_DEF_TOOLTIP"] = "Define the Matrix";
en["LANG_matriz_PRINT"] = "Matrix: Write ";
en["LANG_matriz_PRINT_TOOLTIP"] = "Print text on the matrix";
en["LANG_matriz_RGB"] = "RGB Matrix";
en["LANG_matriz_caras_enojado"] = "Angry";
en["LANG_matriz_caras_estado"] = "Matrix: Face";
en["LANG_matriz_caras_feliz"] = "Happy";
en["LANG_matriz_caras_sorprendido"] = "Surprised";
en["LANG_matriz_dados_estado"] = "Matrix: Dice";
en["LANG_MATRIZ_TEXT_ON"] = "in 8x8 matrix on IIC pin";
en["LANG_MATRIZ_TEXT_PIN"] = "";
en["LANG_MATRIZ_TEXT_PIN_TOOLTIP"] = "Write text on the 8x8 matrix";
en["LANG_MATRIZ_8x16_TEXT"] = "on the 8x16 matrix with speed";
en["LANG_MATRIZ_8x16_TEXT_SLOW"] = "slow";
en["LANG_MATRIZ_8x16_TEXT_FAST"] = "fast";
en["LANG_MATRIZ_8x16_TEXT_DRAW"] = "Draw";
en["LANG_MATRIZ_8x16_TEXT_CLEAR"] = "Clear";
en["LANG_MATRIZ_8x16_TEXT_PIN"] = "on pin";
en["LANG_MATRIZ_8x16_TEXT_PIN_TOOLTIP"] = "Write text on the 8x16 matrix";
en["LANG_ENTRADAS_LINE_FOLLOWER"] = "Line Follower";
en["LANG_ENTRADAS_LINE_FOLLOWER_PIN"] = "on pin";
en["LANG_ENTRADAS_LINE_FOLLOWER_TOOLTIP"] = "Get line sensor values for programming a line-following robot";
en["LANG_ENTRADAS_LINE_FOLLOWER_DERECHA"] = "Right";
en["LANG_ENTRADAS_LINE_FOLLOWER_CENTRO"] = "Center";
en["LANG_ENTRADAS_LINE_FOLLOWER_IZQUIERDA"] = "Left";
en["LANG_ENTRADAS_HUMEDAD_TREN"] = "HUMIDITY";
en["LANG_ENTRADAS_HUMEDAD_TREN_TOOLTIP"] = "Returns the humidity percentage";
en["LANG_COMPARE_TRAIN"] = "detects that the train";
en["LANG_COMPARE_TRAIN_COMMING"] = "is coming";
en["LANG_COMPARE_TRAIN_PASSING"] = "has passed";
en["LANG_COMPARE_LUMINOSITY"] = "is";
en["LANG_COMPARE_LUMINOSITY_HIGH"] = "high";
en["LANG_COMPARE_LUMINOSITY_LOW"] = "low";
en["LANG_COMPARE_SHIP"] = "is";
en["LANG_COMPARE_SHIP_WAITING"] = "waiting";
en["LANG_COMPARE_SHIP_PASSED"] = "already crossed";
en["LANG_COMPARE_SHIP_PASSING"] = "moving";
en["LANG_SHIP"] = "Ship";
en["LANG_SHIP_TOOLTIP"] = "State of the ship";
en["LANG_BRIDGE"] = "Bridge"
en["LANG_BRIDGE_TOOLTIP"] = "Bridge";
en["LANG_COMPARE_BRIDGE"] = "is";
en["LANG_COMPARE_BRIDGE_CLOSED"] = "closed";
en["LANG_COMPARE_BRIDGE_OPEN"] = "open";
en["LANG_COMPARE_BRIDGE_MOVING"] = "moving";
en["LANG_COMPARE_BRIDGE_TOOLTIP"] = "Bridge state";
en["LANG_RESTORE_ELECTRICITY"] = "Restore electricity service";
en["LANG_RESTORE_ELECTRICITY_TOOLTIP"] = "Restore electricity service";
en["LANG_LUMINARIA_ZONA3"] = "zone 3 lights";
en["LANG_LUMINARIA_ZONA3_TOOLTIP"] = "Turn on lights in zone 3";
en["LANG_LUMINARIA_ZONA1"] = "zone 1 lights";
en["LANG_LUMINARIA_ZONA1_TOOLTIP"] = "Turn on lights in zone 1";
en["LANG_LUMINARIA_ZONA5"] = "zone 5 lights";
en["LANG_LUMINARIA_ZONA5_TOOLTIP"] = "Turn on lights in zone 5";
en["LANG_LUCES_DIQUE3"] = "dike 3 lights";
en["LANG_LUCES_DIQUE3_TOOLTIP"] = "Turn on lights in dike 3";
en["LANG_LUCES_DIQUE3_OFF"] = "Turn off dike 3 lights";
en["LANG_LUCES_DIQUE3_OFF_TOOLTIP"] = "Turn off lights in dike 3";
en["LANG_PUENTE_MUJER"] = "Puente de la Mujer light";
en["LANG_PUENTE_MUJER_TOOLTIP"] = "Turn on lights on the Puente de la Mujer";
en["LANG_PUENTE_MUJER_OFF"] = "Turn off Puente de la Mujer light";
en["LANG_PUENTE_MUJER_OFF_TOOLTIP"] = "Turn off lights on the Puente de la Mujer";
en["LANG_SENDA_PEATONAL"] = "pedestrian path lights";
en["LANG_SENDA_PEATONAL_TOOLTIP"] = "Turn on lights on the pedestrian path";
en["LANG_SENDA_PEATONAL_OFF"] = "Turn off pedestrian path lights";
en["LANG_SENDA_PEATONAL_OFF_TOOLTIP"] = "Turn off lights on the pedestrian path";
en["LANG_OPEN_PUENTE_MUJER"] = "Puente de la Mujer";
en["LANG_OPEN_PUENTE_MUJER_ON"] = "Open";
en["LANG_OPEN_PUENTE_MUJER_OFF"] = "Close";
en["LANG_OPEN_PUENTE_MUJER_TOOLTIP"] = "Move the Puente de la Mujer";
en["LANG_SHIP_OK_SIGNAL"] = "Give OK signal to the ship to pass";
en["LANG_SHIP_OK_SIGNAL_TOOLTIP"] = "Give OK signal to the ship to pass";
en["LANG_PLANETARIO_LIGHTS_EFFECT"] = "Choose effect";
en["LANG_PLANETARIO_LIGHTS_DOME"] = "on the dome lights";
en["LANG_PLANETARIO_LIGHTS_TOOLTIP"] = "Turn on planetarium lights with the selected effect";
en["LANG_PLANETARIO_LIGHTS_EFFECT_RAINBOW"] = "rainbow";
en["LANG_PLANETARIO_LIGHTS_EFFECT_CASCADE"] = "cascade";
en["LANG_PLANETARIO_LIGHTS_EFFECT_TRANSITION"] = "transition";
en["LANG_PLANETARIO_LIGHTS_EFFECT_OFF"] = "Turn off dome lights";
en["LANG_PLANETARIO_LIGHTS_OFF_TOOLTIP"] = "Turn off the dome lights";
en["LANG_PLANETARIO_REFLECTOR"] = "on the reflectors";
en["LANG_PLANETARIO_REFLECTOR_TOOLTIP"] = "Turn on the planetarium reflectors";
en["LANG_PLANETARIO_REFLECTOR_OFF"] = "Turn off the reflectors";
en["LANG_PLANETARIO_REFLECTOR_OFF_TOOLTIP"] = "Turn off the planetarium reflectors";
en["LANG_PLANETARIO_SCREEN_MESSAGE_SHOW"] = "Show the message";
en["LANG_PLANETARIO_SCREEN_MESSAGE_0"] = "JOURNEY THROUGH THE SOLAR SYSTEM";
en["LANG_PLANETARIO_SCREEN_MESSAGE_1"] = "LIVE ASTRONOMY";
en["LANG_PLANETARIO_SCREEN_MESSAGE_2"] = "PLANETARIUM OF BUENOS AIRES CITY";
en["LANG_PLANETARIO_SCREEN_MESSAGE_3"] = "NATIONAL DAY OF ASTRONOMY";
en["LANG_PLANETARIO_SCREEN"] = "on the screen";
en["LANG_PLANETARIO_SCREEN_TOOLTIP"] = "Display the selected message on the screen";
en["LANG_PLANETARIO_TELESCOPE_AIM"] = "Aim in the direction of";
en["LANG_PLANETARIO_TELESCOPE_BASE"] = "the telescope base";
en["LANG_PLANETARIO_TELESCOPE_NORTE"] = "north";
en["LANG_PLANETARIO_TELESCOPE_NORESTE"] = "northeast";
en["LANG_PLANETARIO_TELESCOPE_ESTE"] = "east";
en["LANG_PLANETARIO_TELESCOPE_SURESTE"] = "southeast";
en["LANG_PLANETARIO_TELESCOPE_SUR"] = "south";
en["LANG_PLANETARIO_TELESCOPE_SUROESTE"] = "southwest";
en["LANG_PLANETARIO_TELESCOPE_OESTE"] = "west";
en["LANG_PLANETARIO_TELESCOPE_NOROESTE"] = "northwest";
en["LANG_PLANETARIO_TELESCOPE"] = "the telescope lens";
en["LANG_PLANETARIO_TELESCOPE_TOOLTIP"] = "Move the telescope";
en["LANG_COLON_LIGHTS_EFFECT"] = "Turn on with effect";
en["LANG_COLON_LIGHTS_EFFECT_TRANSITION"] = "transition";
en["LANG_COLON_LIGHTS_EFFECT_FIXED"] = "fixed";
en["LANG_COLON_LIGHTS_EFFECT_ATTENUATION"] = "attenuation";
en["LANG_COLON_LIGHTS"] = "the lights of the Teatro Colón";
en["LANG_COLON_LIGHTS_TOOLTIP"] = "Turn on the Teatro Colón lights with the selected effect";
en["LANG_COLON_LIGHTS_OFF"] = "Turn off the Teatro Colón lights";
en["LANG_COLON_LIGHTS_OFF_TOOLTIP"] = "Turn off the Teatro Colón lights";
en["LANG_OBELISCO_LIGHTS"] = "the lights of the Obelisco";
en["LANG_OBELISCO_LIGHTS_TOOLTIP"] = "Turn on the lights of the Obelisk with the chosen color";
en["LANG_OBELISCO_LIGHTS_OFF"] = "Turn off the lights of the Obelisk";
en["LANG_OBELISCO_LIGHTS_OFF_TOOLTIP"] = "Turn off the lights of the Obelisk";
en["LANG_OBELISCO_SCREEN_MESSAGE_SHOW"] = "Show the message";
en["LANG_OBELISCO_SCREEN_MESSAGE_0"] = "RESPECT THE MAXIMUM SPEED";
en["LANG_OBELISCO_SCREEN_MESSAGE_1"] = "DRIVE WITH CAUTION";
en["LANG_OBELISCO_SCREEN_MESSAGE_2"] = "DO NOT USE YOUR PHONE WHILE DRIVING";
en["LANG_OBELISCO_SCREEN_MESSAGE_3"] = "USE YOUR SEATBELT";
en["LANG_OBELISCO_SCREEN"] = "on the screen";
en["LANG_OBELISCO_SCREEN_TOOLTIP"] = "Display the selected message on the screen";
en["LANG_LUMINARIA_ZONA2"] = "zone 2 luminaire";
en["LANG_LUMINARIA_ZONA2_TOOLTIP"] = "Turn on the lights in zone 2";
en["LANG_LUMINARIA_ZONA4"] = "zone 4 luminaire";
en["LANG_LUMINARIA_ZONA4_TOOLTIP"] = "Turn on the lights in zone 4";
en["LANG_FLORALIS_LIGHTS"] = "the lights of the Floralis Genérica";
en["LANG_FLORALIS_LIGHTS_TOOLTIP"] = "Turn on the lights of the Floralis Genérica with the chosen color";
en["LANG_FLORALIS_LIGHTS_OFF"] = "Turn off the lights of the Floralis Genérica";
en["LANG_FLORALIS_LIGHTS_OFF_TOOLTIP"] = "Turn off the lights of the Floralis Genérica";
en["LANG_FLORALIS_REFLECTOR"] = "the reflectors";
en["LANG_FLORALIS_REFLECTOR_TOOLTIP"] = "Turn on the reflectors of the Floralis Genérica";
en["LANG_FLORALIS_REFLECTOR_OFF"] = "Turn off the reflectors";
en["LANG_FLORALIS_REFLECTOR_OFF_TOOLTIP"] = "Turn off the reflectors of the Floralis Genérica";
en["LANG_RESTORE_PETAL"] = "Repair petal of the Floralis Genérica";
en["LANG_RESTORE_PETAL_TOOLTIP"] = "Repair the petals of the Floralis Genérica";
en["LANG_FLORALIS_OPEN_PETAL"] = "the Floralis Genérica";
en["LANG_FLORALIS_ACTION_OPEN"] = "Open";
en["LANG_FLORALIS_ACTION_CLOSE"] = "Close";
en["LANG_FLORALIS_OPEN_PETAL_TOOLTIP"] = "Open the petals of the Floralis Genérica";
en["LANG_BHOOT_LED_NEOPIXEL2"] = "Turn on in color";
en["LANG_ENTRADAS_TRAIN_PASSING"] = "Train passing sensor";
en["LANG_ENTRADAS_TRAIN_PASSING_TOOLTIP"] = "";
en["LANG_RETIRO_BLINKING_LIGHTS"] = "blinking lights";
en["LANG_RETIRO_BLINKING_LIGHTS_SECONDS"] = "seconds";
en["LANG_RETIRO_BLINKING_LIGHTS_TOOLTIP"] = "Turn on the blinking lights";
en["LANG_RETIRO_TRAIN_SERVICE"] = "train service";
en["LANG_RETIRO_TRAIN_SERVICE_SANMARTIN"] = "San Martín railway service";
en["LANG_RETIRO_TRAIN_SERVICE_MITRE"] = "Mitre railway service";
en["LANG_RETIRO_TRAIN_SERVICE_ON"] = "Start";
en["LANG_RETIRO_TRAIN_SERVICE_OFF"] = "Stop";
en["LANG_RETIRO_TRAIN_SERVICE_TOOLTIP"] = "Starts the railway service";
en["LANG_RETIRO_TRAIN_FREQUENCY"] = "Set railway service";
en["LANG_RETIRO_TRAIN_FREQUENCY_NAME_SANMARTIN"] = "San Martín";
en["LANG_RETIRO_TRAIN_FREQUENCY_NAME_MITRE"] = "Mitre";
en["LANG_RETIRO_TRAIN_FREQUENCY_EVERY"] = "every";
en["LANG_RETIRO_TRAIN_FREQUENCY_SANMARTIN"] = "Set San Martín railway service every";
en["LANG_RETIRO_TRAIN_FREQUENCY_MITRE"] = "Set Mitre railway service every";
en["LANG_RETIRO_TRAIN_FREQUENCY_SECONDS"] = "seconds";
en["LANG_RETIRO_TRAIN_FREQUENCY_TOOLTIP"] = "Sets the frequency of the railway service";
en["LANG_RETIRO_BARRIERS"] = "level crossing barrier";
en["LANG_RETIRO_BARRIERS_UP"] = "Raise";
en["LANG_RETIRO_BARRIERS_DOWN"] = "Lower";
en["LANG_RETIRO_BARRIERS_TOOLTIP"] = "Raises or lowers the level crossing barrier";


// MICROBIT
en["LANG_RAYITA"] = '|';
en["LANG_START"] = 'On start';
en["LANG_START_2"] = 'Start';
en["LANG_FOREVER"] = 'Forever';
en["LANG_REPEAT"] = 'Repeat';
en["LANG_DELAY"] = 'Wait';
en["LANG_DELAY_MS"] = '[ms]';
en["LANG_DELAY_TOOLTIP"] = 'Pause execution for the specified time in milliseconds';
en["LANG_SHOW_NUMBER"] = 'Show number';
en["LANG_SHOW_NUMBER_TOOLTIP"] = 'Displays a number on the LED matrix. If it doesn\'t fit, the number will scroll';
en["LANG_SHOW_STRING"] = 'Show text';
en["LANG_SHOW_STRING_TOOLTIP"] = 'Displays text on the LED matrix. If it doesn\'t fit, the text will scroll';
en["LANG_SHOW_ICON"] = 'Show icon';
en["LANG_SHOW_ICON_TOOLTIP"] = 'Draws the selected icon on the LED screen';
en["LANG_SHOW_ICON_HEART_TOOLTIP"] = "Heart";
en["LANG_SHOW_ICON_HEART_SMALL_TOOLTIP"] = "Small heart";
en["LANG_SHOW_ICON_YES_TOOLTIP"] = "Yes";
en["LANG_SHOW_ICON_NO_TOOLTIP"] = "No";
en["LANG_SHOW_ICON_HAPPY_FACE_TOOLTIP"] = "Happy";
en["LANG_SHOW_ICON_SAD_FACE_TOOLTIP"] = "Sad";
en["LANG_SHOW_ICON_CONFUSED_TOOLTIP"] = "Confused";
en["LANG_SHOW_ICON_ANGRY_TOOLTIP"] = "Angry";
en["LANG_SHOW_ICON_ASLEEP_TOOLTIP"] = "Asleep";
en["LANG_SHOW_ICON_SURPRISED_TOOLTIP"] = "Surprised";
en["LANG_SHOW_ICON_SILLY_TOOLTIP"] = "Silly";
en["LANG_SHOW_ICON_FABULOUS_TOOLTIP"] = "Fabulous";
en["LANG_SHOW_ICON_MEH_TOOLTIP"] = "Meh";
en["LANG_SHOW_ICON_TSHIRT_TOOLTIP"] = "T-shirt";
en["LANG_SHOW_ICON_ROLLERSKATE_TOOLTIP"] = "Rollerskate";
en["LANG_CLEAR_SCREEN"] = "Clear the screen";
en["LANG_CLEAR_SCREEN_TOOLTIP"] = "Turn off all LEDs";
en["LANG_SHOW_ARROW"] = "Show arrow";
en["LANG_SHOW_ARROW_TOOLTIP"] = "Draws an arrow on the LEDs";
en["LANG_SHOW_ARROW_NORTH"] = "North";
en["LANG_SHOW_ARROW_NORTHEAST"] = "Northeast";
en["LANG_SHOW_ARROW_EAST"] = "East";
en["LANG_SHOW_ARROW_SOUTHEAST"] = "Southeast";
en["LANG_SHOW_ARROW_SOUTH"] = "South";
en["LANG_SHOW_ARROW_SOUTHWEST"] = "Southwest";
en["LANG_SHOW_ARROW_WEST"] = "West";
en["LANG_SHOW_ARROW_NORTHWEST"] = "Northwest";
en["LANG_BUTTON_PRESSED"] = "On button pressed";
en["LANG_BUTTON_PRESSED_TOOLTIP"] = "Do something when a button (A, B, or both A+B) is pressed and released again";
en["LANG_IF"] = "If";
en["LANG_IF_2"] = "On";
en["LANG_GESTURE"] = "Gesture";
en["LANG_GESTURE_TOOLTIP"] = "Do something when a gesture is made (such as shaking the micro:bit)";
en["LANG_GESTURE_EVENT_TOOLTIP"] = "Get the state of a gesture";
en["LANG_GESTURE_SHAKE_TOOLTIP"] = "shaken";
en["LANG_GESTURE_SHAKE_TOOLTIP_2"] = "shake";
en["LANG_GESTURE_LOGOUP_TOOLTIP"] = "logo up";
en["LANG_GESTURE_LOGODOWN_TOOLTIP"] = "logo down";
en["LANG_GESTURE_SCREENUP_TOOLTIP"] = "screen up";
en["LANG_GESTURE_SCREENDOWN_TOOLTIP"] = "screen down";
en["LANG_GESTURE_TILTLEFT_TOOLTIP"] = "tilt left";
en["LANG_GESTURE_TILTLEFT_TOOLTIP_2"] = "tilt to the left";
en["LANG_GESTURE_TILTRIGHT_TOOLTIP"] = "tilt right";
en["LANG_GESTURE_TILTRIGHT_TOOLTIP_2"] = "tilt to the right";
en["LANG_GESTURE_FREEFALL_TOOLTIP"] = "freefall";
en["LANG_GESTURE_THREEG_TOOLTIP"] = "3g";
en["LANG_GESTURE_SIXG_TOOLTIP"] = "6g";
en["LANG_GESTURE_EIGHTG_TOOLTIP"] = "8g";
en["LANG_PIN_PRESSED"] = "When pin pressed";
en["LANG_PIN_PRESSED_TOOLTIP"] = "Do something when a pin is touched and released again (while also touching the GND pin)";
en["LANG_PIN_RELEASED"] = "When pin released";
en["LANG_PIN_RELEASED_TOOLTIP"] = "Do something when a pin is released";
en["LANG_BUTTON"] = "button";
en["LANG_BUTTON_2"] = "Button";
en["LANG_PIN"] = "on pin";
en["LANG_IN"] = "in";
en["LANG_PRESSED"] = "pressed";
en["LANG_IS_PRESSED"] = "is pressed";
en["LANG_BUTTON_BOOLEAN_TOOLTIP"] = "Get the state of the button (pressed or not) for 'A' and 'B'";
en["LANG_PIN_BOOLEAN"] = "pin";
en["LANG_PIN_BOOLEAN_TOOLTIP"] = "Get the state of the button (pressed or not) for 'A' and 'B'";
en["LANG_ACCELERATION"] = "acceleration (mg)";
en["LANG_ACCELERATION_TOOLTIP"] = "Get the acceleration value in milligravities (when the board is horizontally positioned with the screen facing up: x = 0, y = 0, z = -1024)";
en["LANG_LIGHT_LEVEL"] = "light level";
en["LANG_LIGHT_LEVEL_TOOLTIP"] = "Reads the light level applied to the LED screen in a range from '0' (dark) to '255' (bright)";
en["LANG_COMPASS"] = "compass direction (°)";
en["LANG_COMPASS_TOOLTIP"] = "Get the compass heading in degrees";
en["LANG_TEMPERATURE"] = "temperature (°C)";
en["LANG_TEMPERATURE_2"] = "TEMPERATURE";
en["LANG_HUMIDITY"] = "HUMIDITY";
en["LANG_TEMPERATURE_TOOLTIP"] = "Get the temperature in degrees Celsius (°C)";
en["LANG_ROTATION"] = "rotation (°)";
en["LANG_ROTATION_PITCH"] = "pitch";
en["LANG_ROTATION_ROLL"] = "roll";
en["LANG_ROTATION_TOOLTIP"] = "The pitch or roll of the device, rotation around 'X-axis' or 'Y-axis' in degrees";
en["LANG_FORCE"] = "force";
en["LANG_MAGNETIC_FORCE"] = "magnetic force (uT)";
en["LANG_MAGNETIC_FORCE_TOOLTIP"] = "Get the value of the | 'microteslas' (uT)";
en["LANG_RUNNING_TIME"] = "time since startup (ms)";
en["LANG_RUNNING_TIME_TOOLTIP"] = "Get the number of milliseconds (ms) elapsed since startup";
en["LANG_RUNNING_TIME_MICROS"] = "time since startup (us)";
en["LANG_RUNNING_TIME_MICROS_TOOLTIP"] = "Get the number of microseconds (us) elapsed since startup";
en["LANG_ACCELEROMETER_RANGE"] = "set accelerometer range";
en["LANG_ACCELEROMETER_RANGE_TOOLTIP"] = "Set the accelerometer sampling range in gravities";
en["LANG_PLAY_TONE"] = "play tone";
en["LANG_PLAY_TONE_TOOLTIP"] = "Plays a tone through pin 'P0' for the specified duration";
en["LANG_RINGTONE_BY"] = "with duration of";
en["LANG_RINGTONE"] = "Play note";
en["LANG_RINGTONE_TOOLTIP"] = "Plays a tone through pin 'P0'";
en["LANG_PLAY_REST"] = "Rest (ms)";
en["LANG_PLAY_REST_TOOLTIP"] = "Silence (does nothing) for a specified time through pin 'P0'";
en["LANG_PLAY_MELODY"] = "Start melody";
en["LANG_PLAY_MELODY_TOOLTIP"] = "Starts playing a melody. Notes are expressed as a string with this format: NOTE[octave][:duration]";
en["LANG_REPEATING"] = "repeating";
en["LANG_PLAY_MELODY_DADADADUM"] = "dadadadum";
en["LANG_PLAY_MELODY_ENTERTAINER"] = "entertainer";
en["LANG_PLAY_MELODY_PRELUDE"] = "prelude";
en["LANG_PLAY_MELODY_ODE"] = "ode";
en["LANG_PLAY_MELODY_NYAN"] = "nyan";
en["LANG_PLAY_MELODY_RINGTONE"] = "ringtone";
en["LANG_PLAY_MELODY_FUNK"] = "funk";
en["LANG_PLAY_MELODY_BLUES"] = "blues";
en["LANG_PLAY_MELODY_BIRTHDAY"] = "birthday";
en["LANG_PLAY_MELODY_WEDDING"] = "wedding";
en["LANG_PLAY_MELODY_FUNERAL"] = "funeral";
en["LANG_PLAY_MELODY_PUNCHLINE"] = "punchline";
en["LANG_PLAY_MELODY_BADDY"] = "baddy";
en["LANG_PLAY_MELODY_CHASE"] = "chase";
en["LANG_PLAY_MELODY_BADING"] = "ba ding";
en["LANG_PLAY_MELODY_WAWAWAWAA"] = "wawawawaa";
en["LANG_PLAY_MELODY_JUMPUP"] = "jump up";
en["LANG_PLAY_MELODY_JUMPDOWN"] = "jump down";
en["LANG_PLAY_MELODY_POWERUP"] = "power up";
en["LANG_PLAY_MELODY_POWERDOWN"] = "power down";
en["LANG_PLAY_MELODY_ONCE"] = "once";
en["LANG_PLAY_MELODY_FOREVER"] = "forever";
en["LANG_PLAY_MELODY_ONCEBACKGROUND"] = "once in background";
en["LANG_PLAY_MELODY_FOREVERBACKGROUND"] = "forever in background";
en["LANG_PLAY_MELODY_UNTILDONE"] = "until done";
en["LANG_PLAY_MELODY_INBACKGROUND"] = "in background";
en["LANG_MUSIC_EVENT"] = "Music on";
en["LANG_MUSIC_EVENT_TOOLTIP"] = "Registers code to run on various melody events";
en["LANG_MUSIC_EVENT_MELODYNOTEPLAYED"] = "melody note played";
en["LANG_MUSIC_EVENT_MELODYSTARTED"] = "melody started";
en["LANG_MUSIC_EVENT_MELODYENDED"] = "melody ended";
en["LANG_MUSIC_EVENT_MELODYREPEATED"] = "melody repeated";
en["LANG_MUSIC_EVENT_BACKGROUNDMELODYNOTEPLAYED"] = "background melody note played";
en["LANG_MUSIC_EVENT_BACKGROUNDMELODYSTARTED"] = "background melody started";
en["LANG_MUSIC_EVENT_BACKGROUNDMELODYENDED"] = "background melody ended";
en["LANG_MUSIC_EVENT_BACKGROUNDMELODYREPEATED"] = "background melody repeated";
en["LANG_MUSIC_EVENT_BACKGROUNDMELODYPAUSED"] = "background melody paused";
en["LANG_MUSIC_EVENT_BACKGROUNDMELODYRESUMED"] = "background melody resumed";
en["LANG_STOP_MELODY"] = "Stop melody";
en["LANG_STOP_MELODY_TOOLTIP"] = "Stop melodies";
en["LANG_STOP_MELODY_ALL"] = "all";
en["LANG_STOP_MELODY_FOREGROUND"] = "foreground";
en["LANG_STOP_MELODY_BACKGROUND"] = "background";
en["LANG_TONES_TOOLTIP"] = "Get the value of a tone";
en["LANG_TONES_C_LOW"] = "C low";
en["LANG_TONES_CSHARP_LOW"] = "C# low";
en["LANG_TONES_D_LOW"] = "D low";
en["LANG_TONES_DSHARP_LOW"] = "D# low";
en["LANG_BEATS_TOOLTIP"] = "Get the duration of a tone";
en["LANG_BEATS_BEAT_WHOLE"] = "1 beat";
en["LANG_BEATS_BEAT_HALF"] = "1/2 beat";
en["LANG_BEATS_BEAT_QUARTER"] = "1/4 beat";
en["LANG_BEATS_BEAT_EIGHTH"] = "1/8 beat";
en["LANG_BEATS_BEAT_SEXTEENTH"] = "1/16 beat";
en["LANG_BEATS_BEAT_DOUBLE"] = "2 beats";
en["LANG_BEATS_BEAT_BREVE"] = "4 beats";
en["LANG_TEMPO"] = "tempo (bpm)";
en["LANG_TEMPO_TOOLTIP"] = "Returns the tempo in beats per minute (bpm). The tempo determines how fast notes are played. A higher tempo value results in faster note playback.";
en["LANG_CHANGE_TEMPO"] = "Change tempo by (bpm)";
en["LANG_CHANGE_TEMPO_TOOLTIP"] = "Change the tempo by the specified amount";
en["LANG_SET_TEMPO"] = "Set tempo to (bpm)";
en["LANG_SET_TEMPO_TOOLTIP"] = "Set the tempo to the specified value";
en["LANG_PLOT"] = "Plot";
en["LANG_PLOT_TOOLTIP"] = "Turn on the specified LED using x, y coordinates (where x is horizontal and y is vertical). (0,0) is the top-left corner.";
en["LANG_UNPLOT"] = "Hide";
en["LANG_UNPLOT_TOOLTIP"] = "Turn off the LED at the specified x, y coordinates (where x is horizontal and y is vertical). (0,0) is the top-left corner.";
en["LANG_TOGGLE"] = "Toggle";
en["LANG_TOGGLE_TOOLTIP"] = "Toggle a specific pixel on or off";
en["LANG_POINT"] = "point";
en["LANG_POINT_TOOLTIP"] = "Get the on/off state of a specific LED using x, y coordinates. (0,0) is the top-left corner.";
en["LANG_MATRIX8X8_PIN"] = "on pin";
en["LANG_MATRIX8X8_ROTATE"] = "Rotate to position";
en["LANG_MATRIX8X8_ROTATE_MATRIX"] = "the 8x8 MATRIX";
en["LANG_MATRIX8X8_ROTATE_TOOLTIP"] = "Change the orientation of the display";
en["LANG_MATRIX8X8_CLEAR"] = "Clear the 8x8 MATRIX";
en["LANG_MATRIX8X8_CLEAR_TOOLTIP"] = "Clear everything drawn on the display";
en["LANG_MATRIX8X8_INVERT"] = "Invert colors of the 8x8 MATRIX";
en["LANG_MATRIX8X8_INVERT_TOOLTIP"] = "Swap foreground and background colors";
en["LANG_MATRIX8X8_DRAW"] = "in the 8x8 MATRIX on pin";
en["LANG_MATRIX8X8_DRAW_DRAW"] = "Draw";
en["LANG_MATRIX8X8_DRAW_ERASE"] = "Erase";
en["LANG_MATRIX8X8_DRAW_TOOLTIP"] = "Draw a line on the 8x8 matrix";
en["LANG_PLOT_BARGRAPH"] = "Bar graph";
en["LANG_PLOT_BARGRAPH_TOOLTIP"] = "Display a vertical bar graph based on 'value' and 'height'. If 'height' is 0, the graph adjusts automatically.";
en["LANG_PLOT_BARGRAPH_OF"] = "of";
en["LANG_PLOT_BARGRAPH_UPTO"] = "up to";
en["LANG_PLOT_BRIGHTNESS"] = "Plot";
en["LANG_PLOT_BRIGHTNESS_TOOLTIP"] = "Turn on the specified LED using x, y coordinates (where x is horizontal and y is vertical) with the specified brightness. (0,0) is the top-left corner.";
en["LANG_PLOT_BRIGHTNESS_WARNING"] = "You must enter a number between 0 and 255.";
en["LANG_BRIGHTNESS"] = "brightness";
en["LANG_DIGITAL_READ"] = "Read digital pin";
en["LANG_DIGITAL_READ_TOOLTIP"] = "Read the specified pin or connector as 0 or 1.";
en["LANG_ANALOG_READ"] = "Read analog pin";
en["LANG_ANALOG_READ_TOOLTIP"] = "Read the value of the connector as analog, i.e., a value between 0 and 1023.";
en["LANG_BUTTON_READ"] = "BUTTON";
en["LANG_BUTTON_READ_TOOLTIP"] = "Read the state of the button as 0 or 1.";
en["LANG_TOUCH_READ"] = "TOUCH";
en["LANG_TOUCH_READ_TOOLTIP"] = "Read the state of the touch button as 0 or 1.";
en["LANG_LIMIT_SWITCH_READ"] = "SHOCK";
en["LANG_LIMIT_SWITCH_READ_TOOLTIP"] = "Read the state of the shock sensor as 0 or 1.";
en["LANG_POTENTIOMETER_READ"] = "POTENTIOMETER";
en["LANG_POTENTIOMETER_READ_TOOLTIP"] = "Read the state of the potentiometer as a value between 0 and 1023.";
en["LANG_TEMPERATURE_READ"] = "TEMPERATURE";
en["LANG_TEMPERATURE_READ_TOOLTIP"] = "Read the state of the temperature sensor as a value between 0 and 1023.";
en["LANG_LCD"] = "LCD";
en["LANG_LCD_INIT"] = "Initialize LCD";
en["LANG_LCD_ADDRESS"] = "of the LCD";
en["LANG_LCD_ADDRESS_2"] = "at address";
en["LANG_LCD_INIT_TOOLTIP"] = "Specify the I2C address of the LCD display. The value '0' means 'automatic'.";
en["LANG_LCD_SHOW"] = "LCD: show text";
en["LANG_LCD_SHOW_TOOLTIP"] = "Display the entered text at the specified position on the LCD screen.";
en["LANG_LCD_I2C"] = "Write";
en["LANG_LCD_COLUMN"] = "and column";
en["LANG_LCD_ROW"] = "in row";
en["LANG_LCD_SETBACKLIGHT"] = "LCD backlight";
en["LANG_LCD_SETBACKLIGHT_TOOLTIP"] = "Turn the LCD backlight on or off.";
en["LANG_MOTOR_TURN_RIGHT"] = "Turn right";
en["LANG_MOTOR_TURN_LEFT"] = "Turn left";
en["LANG_MOTOR_TURN_BRAKE"] = "Stop";
en["LANG_MOTOR_SINGLE_TOOLTIP"] = "Control DC motors individually.";
en["LANG_MOTOR_SINGLE_SPEED"] = "at speed";
en["LANG_MOTOR_SINGLE_PIN"] = "the MOTOR on pin";
en["LANG_MOTOR_DOUBLE_FORWARD"] = "Move forward";
en["LANG_MOTOR_DOUBLE_BACKWARD"] = "Move backward";
en["LANG_MOTOR_DOUBLE_TURN_LEFT"] = "Turn left";
en["LANG_MOTOR_DOUBLE_TURN_RIGHT"] = "Turn right";
en["LANG_MOTOR_DOUBLE_BRAKE"] = "Stop";
en["LANG_MOTOR_DOUBLE_FORWARD_SHORT"] = "Go forward";
en["LANG_MOTOR_DOUBLE_BACKWARD_SHORT"] = "Go backward";
en["LANG_MOTOR_DOUBLE_TURN_LEFT_SHORT"] = "Turn left";
en["LANG_MOTOR_DOUBLE_TURN_RIGHT_SHORT"] = "Turn right";
en["LANG_MOTOR_DOUBLE_BRAKE_SHORT"] = "Stop";
en["LANG_MOTOR_DOUBLE_FORWARD_MICROBIT"] = "Go forward";
en["LANG_MOTOR_DOUBLE_BACKWARD_MICROBIT"] = "Go backward";
en["LANG_MOTOR_DOUBLE_TURN_LEFT_MICROBIT"] = "Turn left";
en["LANG_MOTOR_DOUBLE_TURN_RIGHT_MICROBIT"] = "Turn right";
en["LANG_MOTOR_DOUBLE_BRAKE_MICROBIT"] = "Stop";
en["LANG_MOTOR_DOUBLE_TOOLTIP"] = "Control DC motors";
en["LANG_SERVO_180"] = "the SERVO";
en["LANG_SERVO_180_TOOLTIP"] = "Rotate the servo by the specified number of degrees";
en["LANG_SERVO_180_DEGREES"] = "Position on angle";
en["LANG_TILT"] = "TILT";
en["LANG_TILT_TOOLTIP"] = "Returns the digital value measured by the tilt sensor";
en["LANG_VIBRATION"] = "VIBRATION";
en["LANG_VIBRATION_TOOLTIP"] = "Returns the digital value measured by the vibration sensor";
en["LANG_HALL_EFFECT"] = "HALL EFFECT";
en["LANG_HALL_EFFECT_2"] = "Magnetic force";
en["LANG_HALL_EFFECT_TOOLTIP"] = "Returns the digital value measured by the Hall effect sensor";
en["LANG_PIR"] = "PIR";
en["LANG_PIR_TOOLTIP"] = "Returns the digital value measured by the PIR sensor";
en["LANG_IR_SWITCH"] = "PHOTO INTERRUPTER";
en["LANG_IR_SWITCH_TOOLTIP"] = "Returns the digital value measured by the photo interrupter sensor";
en["LANG_LDR_READ"] = "LIGHT";
en["LANG_LDR_READ_TOOLTIP"] = "Returns the analog value measured by the light sensor (photoresistor)";
en["LANG_SOIL_READ"] = "SOIL";
en["LANG_SOIL_READ_TOOLTIP"] = "Returns the analog value measured by the soil moisture sensor";
en["LANG_SOUND_READ"] = "SOUND";
en["LANG_SOUND_READ_TOOLTIP"] = "Returns the analog value measured by the sound sensor";
en["LANG_FIRE_READ"] = "FIRE";
en["LANG_FIRE_READ_TOOLTIP"] = "Returns the analog value measured by the fire sensor";
en["LANG_ALCOHOL_READ"] = "ALCOHOL";
en["LANG_ALCOHOL_READ_TOOLTIP"] = "Returns the analog value measured by the alcohol sensor";
en["LANG_GAS_READ"] = "GAS";
en["LANG_GAS_READ_TOOLTIP"] = "Returns the analog value measured by the gas sensor";
en["LANG_SOIL_READ"] = "SOIL";
en["LANG_SOIL_READ_TOOLTIP"] = "Returns the analog value measured by the soil moisture sensor";
en["LANG_STEAM_READ"] = "STEAM";
en["LANG_STEAM_READ_TOOLTIP"] = "Returns the analog value measured by the steam sensor";
en["LANG_PRESSURE_READ"] = "PRESSURE";
en["LANG_PRESSURE_READ_TOOLTIP"] = "Returns the analog value measured by the pressure sensor";
en["LANG_RAIN_READ"] = "RAIN";
en["LANG_RAIN_READ_TOOLTIP"] = "Returns the analog value measured by the rain sensor";
en["LANG_WRITE_DIGITAL"] = "Write to digital pin";
en["LANG_WRITE_DIGITAL_STATE"] = "state";
en["LANG_WRITE_DIGITAL_TOOLTIP"] = "Change the state (on/off) of the specified digital pin";
en["LANG_COLOUR"] = "Color";
en["LANG_COLOUR_I2C"] = "";
en["LANG_COLOUR_I2C_PIN"] = "COLOR VALUE on pin";
en["LANG_COLOUR_I2C_TOOLTIP"] = "Gets the color value on the rgb LED";
en["LANG_LEDRGB"] = "RGB LED";
en["LANG_LEDRGB_RED"] = "Red";
en["LANG_LEDRGB_GREEN"] = "Green";
en["LANG_LEDRGB_BLUE"] = "Blue";
en["LANG_LEDRGB_TOOLTIP"] = "Turn on the RGB LED with the specified color.";
en["LANG_ENTRADAS_ULTRASONIDO_TRIGGER"] = "trigger";
en["LANG_ENTRADAS_ULTRASONIDO_ECHO"] = "echo";
en["LANG_ENTRADAS_ULTRASONIDO_UNIT"] = ", in";
en["LANG_ENTRADAS_ULTRASONIDO_UNIT_CM"] = "centimeters";
en["LANG_ENTRADAS_ULTRASONIDO_UNIT_IN"] = "inches";
en["LANG_LED"] = "the LED";
en["LANG_LED_TOOLTIP"] = "Control the state of the LED.";
en["LANG_LED_BRILLO"] = "of the LED";
en["LANG_LED_BRILLO_INTENSIDAD"] = "Adjust the brightness to";
en["LANG_LED_BRILLO_TOOLTIP"] = "Control the brightness of the LED.";
en["LANG_RELAY"] = "the RELAY";
en["LANG_RELAY_TOOLTIP"] = "Control the state of the relay.";
en["LANG_CHONOMETER"] = "Start chronometer";
en["LANG_CHONOMETER_TOOLTIP"] = "Sets a chronometer";
en["LANG_COMPARE_STRINGS_TOOLTIP"] = "Compares two strings";
en["LANG_BUTTON_BOTH"] = "Both";
en["LANG_LOGO_PRESSED"] = "LOGO is pressed";
en["LANG_ENTRADAS_LINE_FOLLOWER_3"] = "LINE FOLLOWER";
en["LANG_MICROBIT_LIGHT_LEVEL"] = "LIGHT LEVEL on microbit sensor";
en["LANG_MICROBIT_LIGHT_LEVEL_TOOLTIP"] = "Gets the light level of microbit sensor";
en["LANG_HALL_EFFECT_3"] = "MAGNETIC FORCE in";
en["LANG_MICROBIT_TEMPERATURE"] = "TEMPERATURE on microbit sensor";
en["LANG_MICROBIT_TEMPERATURE_TOOLTIP"] = "Gets the temperature of microbit sensor";
en["LANG_MICROBIT_ACCELERATION"] = "ACCELERATION at";
en["LANG_MICROBIT_ACCELERATION_TOOLTIP"] = "Returns the digital value measured by the acceleration sensor";
en["LANG_MICROBIT_SOUND"] = "SOUND level on microbit sensor";
en["LANG_MICROBIT_SOUND_TOOLTIP"] = "Gets the sound level on microbit sensor";
en["LANG_PLAY_MELODY_2"] = "Play melody";
en["LANG_MATRIX_8X8"] = "8x8 MATRIX";
en["LANG_BLUETOOTH_READ_TOOLTIP"] = "";
en["LANG_WITH_LABEL"] = "with label";
en["LANG_NUMBER"] = "Number";
en["LANG_STRING"] = "String";
en["LANG_BLUETOOTH_SEND_MESSAGE"] = "Send bluetooth message";
en["LANG_BLUETOOTH_SEND_MESSAGE_TOOLTIP"] = "";
en["LANG_BLUETOOTH_IF"] = "If a bluetooth message is received, read";
en["LANG_BLUETOOTH_IF_TOOLTIP"] = "";
en["LANG_BLUETOOTH_CONTROL"] = "Robot Control         ";
en["LANG_BLUETOOTH_CONTROL_JOYSTICK"] = "Joystick    ";
en["LANG_BLUETOOTH_CONTROL_BUTTON"] = "Button       ";
en["LANG_BLUETOOTH_CONTROL_TOOLTIP"] = "";

///////////////////////////////////////////////////////
////////////////  JUNIOR  /////////////////////////////
///////////////////////////////////////////////////////
en['TOOLTIP_NEW_EVENT_REPEAT'] = "Repeat the code indefinitely";
en['TOOLTIP_NEW_EVENT_START'] = "Execute the code when the program starts";
en['TOOLTIP_PAUSE_MILLIS'] = "Pause execution for the specified time in milliseconds";
en['TOOLTIP_PAUSE_SECONDS'] = "Pause execution for the specified time in seconds";
en['TOOLTIP_SHOW_STRING'] = "Display text on the screen. If the text doesn't fit, it will automatically scroll";
en['TOOLTIP_SHOW_NUMBER'] = "Display numbers on the screen. If the number doesn't fit, it will automatically scroll";
en['TOOLTIP_ICON'] = "Draw the selected icon on the LED screen";
en['TOOLTIP_ICON_HEART'] = "Heart";
en['TOOLTIP_ICON_HEART_SMALL'] = "Small heart";
en['TOOLTIP_ICON_YES'] = "Yes";
en['TOOLTIP_ICON_NO'] = "No";
en['TOOLTIP_ICON_HAPPY_FACE'] = "Happy";
en['TOOLTIP_ICON_SAD_FACE'] = "Sad";
en['TOOLTIP_ICON_CONFUSED'] = "Confused";
en['TOOLTIP_ICON_ANGRY'] = "Angry";
en['TOOLTIP_ICON_ASLEEP'] = "Asleep";
en['TOOLTIP_ICON_SURPRISED'] = "Surprised";
en['TOOLTIP_ICON_SILLY'] = "Silly";
en['TOOLTIP_ICON_FABULOUS'] = "Fabulous";
en['TOOLTIP_ICON_MEH'] = "Meh";
en['TOOLTIP_ICON_TSHIRT'] = "T-shirt";
en['TOOLTIP_ICON_ROLLERSKATE'] = "Rollerskate";
en['TOOLTIP_BUTTON_A_PRESSED'] = "Do something when a button (A, B, or both A+B) is pressed and released again";
en['TOOLTIP_BUTTON_A'] = "Button A";
en['TOOLTIP_BUTTON_B'] = "Button B";
en['TOOLTIP_BUTTON_AB'] = "Button A+B";
en['TOOLTIP_PAUSE_MILLIS_1'] = "1 second";
en['TOOLTIP_PAUSE_MILLIS_2'] = "2 seconds";
en['TOOLTIP_PAUSE_MILLIS_3'] = "3 seconds";
en['TOOLTIP_PAUSE_MILLIS_4'] = "4 seconds";
en['TOOLTIP_PAUSE_MILLIS_5'] = "5 seconds";
en['TOOLTIP_PAUSE_MILLIS_6'] = "6 seconds";
en['TOOLTIP_PAUSE_MILLIS_7'] = "7 seconds";
en['TOOLTIP_PAUSE_MILLIS_8'] = "8 seconds";
en['TOOLTIP_PAUSE_MILLIS_9'] = "9 seconds";
en['TOOLTIP_CONTROL_LOOP_FOREVER'] = "Repeat the sequence indefinitely";
en['TOOLTIP_CONTROL_LOOP_NUMBER'] = "Repeat a sequence the indicated number of times";
en['TOOLTIP_CONTROL_LOOP_NUMBER_1'] = "1 time";
en['TOOLTIP_CONTROL_LOOP_NUMBER_3'] = "2 times";
en['TOOLTIP_CONTROL_LOOP_NUMBER_4'] = "3 times";
en['TOOLTIP_CONTROL_LOOP_NUMBER_2'] = "4 times";
en['TOOLTIP_CONTROL_LOOP_NUMBER_5'] = "5 times";
en['TOOLTIP_CONTROL_LOOP_NUMBER_6'] = "6 times";
en['TOOLTIP_CONTROL_LOOP_NUMBER_7'] = "7 times";
en['TOOLTIP_CONTROL_LOOP_NUMBER_8'] = "8 times";
en['TOOLTIP_CONTROL_LOOP_NUMBER_9'] = "9 times";
en['TOOLTIP_BHOOT_MOTOR_FORWARD'] = "Move the robot forward for the specified number of seconds";
en['TOOLTIP_BHOOT_MOTOR_BACKWARD'] = "Move the robot backward for the specified number of seconds";
en['TOOLTIP_BHOOT_MOTOR_RIGHT'] = "Turn the robot right for the specified number of seconds";
en['TOOLTIP_BHOOT_MOTOR_LEFT'] = "Turn the robot left for the specified number of seconds";
en['TOOLTIP_BHOOT_LINE_FOLLOWER'] = "Move the robot following a line drawn on the floor";
en['TOOLTIP_BHOOT_REPEAT_FOREVER'] = "Repeat the code indefinitely";
en['TOOLTIP_OBSTACLE_AVOIDANCE'] = "Move the robot while avoiding obstacles encountered on the path";
en['TOOLTIP_OBSTACLE_AVOIDANCE_NUMBER_5'] = "5 centimeters";
en['TOOLTIP_OBSTACLE_AVOIDANCE_NUMBER_10'] = "10 centimeters";
en['TOOLTIP_OBSTACLE_AVOIDANCE_NUMBER_20'] = "20 centimeters";
en['TOOLTIP_PLAY_TONE'] = "Play a note on the buzzer";
en['TOOLTIP_PLAY_TONE_DO'] = "C";
en['TOOLTIP_PLAY_TONE_RE'] = "D";
en['TOOLTIP_PLAY_TONE_MI'] = "E";
en['TOOLTIP_PLAY_TONE_FA'] = "F";
en['TOOLTIP_PLAY_TONE_SOL'] = "G";
en['TOOLTIP_PLAY_TONE_LA'] = "A";
en['TOOLTIP_PLAY_TONE_SI'] = "B";
en['TOOLTIP_LED'] = "Turn on the robot's lights with the selected color";
en['TOOLTIP_LED_OFF'] = "Turn off the robot's lights";
en['TOOLTIP_LED_COLOUR_1'] = "Yellow";
en['TOOLTIP_LED_COLOUR_2'] = "Orange";
en['TOOLTIP_LED_COLOUR_3'] = "Pink";
en['TOOLTIP_LED_COLOUR_4'] = "Red";
en['TOOLTIP_LED_COLOUR_5'] = "White";
en['TOOLTIP_LED_COLOUR_6'] = "Green";
en['TOOLTIP_LED_COLOUR_7'] = "Cyan";
en['TOOLTIP_LED_COLOUR_8'] = "Blue";
en['TOOLTIP_LED_COLOUR_9'] = "Violet";
en['TOOLTIP_MATRIX_8X8'] = "Draw a face on the LED matrix";
en['TOOLTIP_MATRIX_8X8_OFF'] = "Turn off the LED matrix";
en['TOOLTIP_MATRIX_8X8_FACE_1'] = "";
en['TOOLTIP_MATRIX_8X8_FACE_2'] = "";
en['TOOLTIP_MATRIX_8X8_FACE_3'] = "";
en['TOOLTIP_MATRIX_8X8_FACE_4'] = "";
en['TOOLTIP_MATRIX_8X8_FACE_5'] = "";
en['TOOLTIP_MATRIX_8X8_FACE_6'] = "";
en['TOOLTIP_MATRIX_8X8_FACE_7'] = "";
en['TOOLTIP_MATRIX_8X8_FACE_8'] = "";
en["LANG_PIN_NOT_AVAILABLE"] = "Not available";