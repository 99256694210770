/* eslint import/no-extraneous-dependencies: 0 */
/* eslint object-shorthand: 0 */
/* eslint no-underscore-dangle: 0 */
/* eslint no-useless-constructor: 0 */
/* eslint camelcase: 0 */
/* eslint spaced-comment: 0 */
/* eslint no-param-reassign: 0 */
/* eslint new-cap: 0 */
import * as Blockly from 'blockly';
import './pianoModal.scss';

export class PianoModal extends Blockly.Field {
  constructor(value, validator, config) {
    super(value, validator, config);
  }

  name_ = 'Piano';

  blockWidth_ = 410;

  blockHeight_ = 43;

  boundEvents_ = [];

  ////////////////////////////
  ////// CUSTOM METHODS //////
  ////////////////////////////
  audioCtx_ = (window.AudioContext) ? new window.AudioContext() : new window.webkitAudioContext();

  oscillator_ = null;

  renderBlockContent_() {
    let note = '';
    switch (this.value_.basePitch) {
      case 131:
        note = Blockly.Msg.LANG_SALIDAS_PIEZO_BUZZER_DO || 'DO';
        break;
      case 139:
        note = `${Blockly.Msg.LANG_SALIDAS_PIEZO_BUZZER_DO}#` || 'DO#';
        break;
      case 147:
        note = Blockly.Msg.LANG_SALIDAS_PIEZO_BUZZER_RE || 'RE';
        break;
      case 156:
        note = `${Blockly.Msg.LANG_SALIDAS_PIEZO_BUZZER_RE}#` || 'RE#';
        break;
      case 165:
        note = Blockly.Msg.LANG_SALIDAS_PIEZO_BUZZER_MI || 'MI';
        break;
      case 175:
        note = Blockly.Msg.LANG_SALIDAS_PIEZO_BUZZER_FA || 'FA';
        break;
      case 185:
        note = `${Blockly.Msg.LANG_SALIDAS_PIEZO_BUZZER_FA}#` || 'FA#';
        break;
      case 196:
        note = Blockly.Msg.LANG_SALIDAS_PIEZO_BUZZER_SOL || 'SOL';
        break;
      case 208:
        note = `${Blockly.Msg.LANG_SALIDAS_PIEZO_BUZZER_SOL}#` || 'SOL#';
        break;
      case 220:
        note = Blockly.Msg.LANG_SALIDAS_PIEZO_BUZZER_LA || 'LA';
        break;
      case 233:
        note = `${Blockly.Msg.LANG_SALIDAS_PIEZO_BUZZER_LA}#` || 'LA#';
        break;
      case 247:
        note = Blockly.Msg.LANG_SALIDAS_PIEZO_BUZZER_SI || 'SI';
        break;
      default:
        note = '--';
    }

    return `
      <div class="piano-mini-modal piano-mini-modal-${this.normalizeBlocklyIds_()}">
        <div class="pseudoBlocklyText">${Blockly.Msg.LANG_SALIDAS_PIEZO_BUZZER_PIANO_NOTE}</div>
        <div class="mini-piano-keys">
          <div class="key white-key ${this.value_.basePitch === 131 ? 'selected' : ''} c-natural"></div>
          <div class="key black-key ${this.value_.basePitch === 139 ? 'selected' : ''} c-sharp"></div>
          <div class="key white-key ${this.value_.basePitch === 147 ? 'selected' : ''} d-natural"></div>
          <div class="key black-key ${this.value_.basePitch === 156 ? 'selected' : ''} d-sharp"></div>
          <div class="key white-key ${this.value_.basePitch === 165 ? 'selected' : ''} e-natural"></div>
          <div class="key white-key ${this.value_.basePitch === 175 ? 'selected' : ''} f-natural"></div>
          <div class="key black-key ${this.value_.basePitch === 185 ? 'selected' : ''} f-sharp"></div>
          <div class="key white-key ${this.value_.basePitch === 196 ? 'selected' : ''} g-natural"></div>
          <div class="key black-key ${this.value_.basePitch === 208 ? 'selected' : ''} g-sharp"></div>
          <div class="key white-key ${this.value_.basePitch === 220 ? 'selected' : ''} a-natural"></div>
          <div class="key black-key ${this.value_.basePitch === 233 ? 'selected' : ''} a-sharp"></div>
          <div class="key white-key ${this.value_.basePitch === 247 ? 'selected' : ''} b-natural"></div>
        </div>
        <div class="note">${note}<div class="octave">${this.value_.octave}</div></div>
        <div class="pseudoBlocklyText">${Blockly.Msg.LANG_SALIDAS_PIEZO_BUZZER_PIANO_DURATION || 'con duración [ms]'}</div>
        <div class="duration">${this.value_.duration}</div>
      </div>
    `;
  }

  getDigit(digit, segment) {
    let duration;
    if (typeof this.value_.duration === 'undefined') {
      this.value_.duration = 1000;
    }
    switch (this.value_.duration.toString().length) {
      case 4:
        duration = this.value_.duration.toString();
        break;
      case 3:
        duration = `0${this.value_.duration.toString()}`;
        break;
      case 2:
        duration = `00${this.value_.duration.toString()}`;
        break;
      case 1:
        duration = `000${this.value_.duration.toString()}`;
        break;
      default:
        duration = this.value_.duration.toString();
    }

    let segmentsToTurnOff = [];

    if (duration[digit] === '9') segmentsToTurnOff = ['E'];
    else if (duration[digit] === '7') segmentsToTurnOff = ['D', 'E', 'F', 'G'];
    else if (duration[digit] === '6') segmentsToTurnOff = ['B'];
    else if (duration[digit] === '5') segmentsToTurnOff = ['B', 'E'];
    else if (duration[digit] === '4') segmentsToTurnOff = ['A', 'E', 'G'];
    else if (duration[digit] === '3') segmentsToTurnOff = ['E', 'F'];
    else if (duration[digit] === '2') segmentsToTurnOff = ['C', 'F'];
    else if (duration[digit] === '1') segmentsToTurnOff = ['A', 'D', 'E', 'F', 'G'];
    else if (duration[digit] === '0') segmentsToTurnOff = ['D'];

    return segmentsToTurnOff.indexOf(segment) !== -1;
  }

  createDynamicPinsHtml() {
    const availablePins = [];
    const options = this.getSourceBlock().getField(this.value_.pinName).getOptions();
    for (let i = 0; i < options.length; i += 1) {
      availablePins.push(options[i][1]);
    }

    let html = '';
    const maxCols = 6;
    for (let j = 0; j < (options.length / maxCols); j += 1) {
      html += `<div class="${(j > 0) ? 'lower' : 'upper'}">`;
      for (let i = j * maxCols; i < (j * maxCols) + maxCols; i += 1) {
        if (typeof options[i] !== 'undefined') {
          html += `<div class="pin pin-${options[i][1]} ${options[i][1] === this.getSourceBlock().getFieldValue(this.value_.pinName) ? 'selected' : ''}" data-pin="${options[i][1]}">${options[i][0]}</div>`;
        }
      }
      html += '</div>';
    }
    return html;
  }

  getSegmentDigit(digit) {
    return `
      <div class="segment segment-A ${this.getDigit(digit, 'A') ? 'turned-off' : ''}"></div>
      <div class="segment segment-B ${this.getDigit(digit, 'B') ? 'turned-off' : ''}"></div>
      <div class="segment segment-C ${this.getDigit(digit, 'C') ? 'turned-off' : ''}"></div>
      <div class="segment segment-D ${this.getDigit(digit, 'D') ? 'turned-off' : ''}"></div>
      <div class="segment segment-E ${this.getDigit(digit, 'E') ? 'turned-off' : ''}"></div>
      <div class="segment segment-F ${this.getDigit(digit, 'F') ? 'turned-off' : ''}"></div>
      <div class="segment segment-G ${this.getDigit(digit, 'G') ? 'turned-off' : ''}"></div>
    `;
  }

  updateSegments() {
    document.querySelectorAll('.digit').forEach((digit, index) => {
      const segments = digit.querySelectorAll('.segment');

      segments.forEach((segment) => {
        let duration;
        if (typeof this.value_.duration === 'undefined') {
          this.value_.duration = 1000;
        }
        switch (this.value_.duration.toString().length) {
          case 4:
            duration = this.value_.duration.toString();
            break;
          case 3:
            duration = `0${this.value_.duration.toString()}`;
            break;
          case 2:
            duration = `00${this.value_.duration.toString()}`;
            break;
          case 1:
            duration = `000${this.value_.duration.toString()}`;
            break;
          default:
            duration = this.value_.duration.toString();
        }
        segment.classList.remove('turned-off');

        /* eslint no-multi-spaces: 0 */
        if (duration[index] === '9' && (segment.getAttribute('class')).match(/segment-(E)/g)) segment.classList.add('turned-off');
        else if (duration[index] === '7' && (segment.getAttribute('class')).match(/segment-(D|E|F|G)/g)) segment.classList.add('turned-off');
        else if (duration[index] === '6' && (segment.getAttribute('class')).match(/segment-(B)/g)) segment.classList.add('turned-off');
        else if (duration[index] === '5' && (segment.getAttribute('class')).match(/segment-(B|E)/g)) segment.classList.add('turned-off');
        else if (duration[index] === '4' && (segment.getAttribute('class')).match(/segment-(A|E|G)/g)) segment.classList.add('turned-off');
        else if (duration[index] === '3' && (segment.getAttribute('class')).match(/segment-(E|F)/g)) segment.classList.add('turned-off');
        else if (duration[index] === '2' && (segment.getAttribute('class')).match(/segment-(C|F)/g)) segment.classList.add('turned-off');
        else if (duration[index] === '1' && (segment.getAttribute('class')).match(/segment-(A|D|E|F|G)/g)) segment.classList.add('turned-off');
        else if (duration[index] === '0' && (segment.getAttribute('class')).match(/segment-(D)/g)) segment.classList.add('turned-off');
        /* eslint no-multi-spaces: 1 */
      });
    });
  }

  updateOctave() {
    const octave = document.querySelector('.octave-screen');
    octave.querySelectorAll('.segment').forEach((segment, index) => {
      segment.classList.remove('turned-off');
      if (this.value_.octave === 3 && (segment.getAttribute('class')).match(/segment-(E|F)/g)) segment.classList.add('turned-off');
      else if (this.value_.octave === 2 && (segment.getAttribute('class')).match(/segment-(C|F)/g)) segment.classList.add('turned-off');
      else if (this.value_.octave === 1 && (segment.getAttribute('class')).match(/segment-(A|D|E|F|G)/g)) segment.classList.add('turned-off');
    });
  }

  renderModalContent_() {
    return `
      <div class="piano-panel">
        <div class="octave-screen">
          <div class="segment-octave">${Blockly.Msg.LANG_SALIDAS_PIEZO_BUZZER_PIANO_OCTAVE || 'OCTAVA'}</div>
          <div class="segment segment-A ${this.value_.octave === 2 || this.value_.octave === 3 ? '' : 'turned-off'}"></div>
          <div class="segment segment-B"></div>
          <div class="segment segment-C ${this.value_.octave === 1 || this.value_.octave === 3 ? '' : 'turned-off'}"></div>
          <div class="segment segment-D ${this.value_.octave === 2 || this.value_.octave === 3 ? '' : 'turned-off'}"></div>
          <div class="segment segment-E ${this.value_.octave === 2 ? '' : 'turned-off'}"></div>
          <div class="segment segment-F turned-off"></div>
          <div class="segment segment-G ${this.value_.octave === 2 || this.value_.octave === 3 ? '' : 'turned-off'}"></div>
          <div class="octave-button octave-up"></div>
          <div class="octave-button octave-down"></div>
        </div>
        <div class="duration-screen">
          <div class="segment-duration">${Blockly.Msg.LANG_SALIDAS_PIEZO_BUZZER_DURATION || 'DURACIÓN [MS]'}</div>
          <input class="direct-input" id="${this.normalizeBlocklyIds_()}" type="text" value="${this.value_.duration}" pattern="([0-9])" maxlength="4" />
          <div class="digit first-digit">
            ${this.getSegmentDigit(0)}
          </div>
          <div class="digit second-digit">
            ${this.getSegmentDigit(1)}
          </div>
          <div class="digit third-digit">
            ${this.getSegmentDigit(2)}
          </div>
          <div class="digit fourth-digit">
            ${this.getSegmentDigit(3)}
          </div>
          <div class="duration-button duration-up"></div>
          <div class="duration-button duration-down"></div>
        </div>
        <div class="pin-selector">
          ${this.createDynamicPinsHtml()}
        </div>
      </div>
      <div class="piano-keys">
        <div class="key white-key ${this.value_.basePitch === 131 ? 'selected' : ''} c-natural"><span>${Blockly.Msg.LANG_SALIDAS_PIEZO_BUZZER_DO || 'DO'}</span></div>
        <div class="key black-key ${this.value_.basePitch === 139 ? 'selected' : ''} c-sharp"><span>${`${Blockly.Msg.LANG_SALIDAS_PIEZO_BUZZER_DO}#` || 'DO'}</span></div>
        <div class="key white-key ${this.value_.basePitch === 147 ? 'selected' : ''} d-natural"><span>${Blockly.Msg.LANG_SALIDAS_PIEZO_BUZZER_RE || 'RE'}</span></div>
        <div class="key black-key ${this.value_.basePitch === 156 ? 'selected' : ''} d-sharp"><span>${`${Blockly.Msg.LANG_SALIDAS_PIEZO_BUZZER_RE}#` || 'RE#'}</span></div>
        <div class="key white-key ${this.value_.basePitch === 165 ? 'selected' : ''} e-natural"><span>${Blockly.Msg.LANG_SALIDAS_PIEZO_BUZZER_MI || 'MI'}</span></div>
        <div class="key white-key ${this.value_.basePitch === 175 ? 'selected' : ''} f-natural"><span>${Blockly.Msg.LANG_SALIDAS_PIEZO_BUZZER_FA || 'FA'}</span></div>
        <div class="key black-key ${this.value_.basePitch === 185 ? 'selected' : ''} f-sharp"><span>${`${Blockly.Msg.LANG_SALIDAS_PIEZO_BUZZER_FA}#` || 'FA#'}</span></div>
        <div class="key white-key ${this.value_.basePitch === 196 ? 'selected' : ''} g-natural"><span>${Blockly.Msg.LANG_SALIDAS_PIEZO_BUZZER_SOL || 'SOL'}</span></div>
        <div class="key black-key ${this.value_.basePitch === 208 ? 'selected' : ''} g-sharp"><span>${`${Blockly.Msg.LANG_SALIDAS_PIEZO_BUZZER_SOL}#` || 'SOL#'}</span></div>
        <div class="key white-key ${this.value_.basePitch === 220 ? 'selected' : ''} a-natural"><span>${Blockly.Msg.LANG_SALIDAS_PIEZO_BUZZER_LA || 'LA'}</span></div>
        <div class="key black-key ${this.value_.basePitch === 233 ? 'selected' : ''} a-sharp"><span>${`${Blockly.Msg.LANG_SALIDAS_PIEZO_BUZZER_LA}#` || 'LA#'}</span></div>
        <div class="key white-key ${this.value_.basePitch === 247 ? 'selected' : ''} b-natural"><span>${Blockly.Msg.LANG_SALIDAS_PIEZO_BUZZER_SI || 'SI'}</span></div>
      </div>
    `;
  }

  clearSegmentInterval() {
    clearInterval(this.segmentsInterval_);
    this.segmentsInterval_ = null;
  }

  playNote(frequency) {
    if (!this.oscillator_) {
      this.oscillator_ = this.audioCtx_.createOscillator();
      this.oscillator_.type = 'square';
      this.oscillator_.frequency.value = frequency;
      this.oscillator_.connect(this.audioCtx_.destination);
      this.oscillator_.start();
      this.noteInterval_ = setTimeout(() => {
        if (this.oscillator_) {
          this.oscillator_.stop();
          this.oscillator_ = null;
        }
        clearInterval(this.noteInterval_);
        this.noteInterval_ = null;
      }, 300);
    }
  }

  stopNote() {
    if (this.oscillator_) {
      this.oscillator_.stop();
    }
  }

  addCustomModalEvents() {
    // KEYS
    document.querySelectorAll('.piano-keys .key').forEach((key) => {
      this.addEvent_(key, 'click', this, (e) => {
        const target = e.target;
        if (e.target.classList.contains('key')) {
          document.querySelectorAll('.key').forEach((currentKey) => {
            currentKey.classList.remove('selected');
          });

          target.classList.add('selected');

          if (target.classList.contains('c-natural')) {
            this.value_.basePitch = 131;
          } else if (target.classList.contains('c-sharp')) {
            this.value_.basePitch = 139;
          } else if (target.classList.contains('d-natural')) {
            this.value_.basePitch = 147;
          } else if (target.classList.contains('d-sharp')) {
            this.value_.basePitch = 156;
          } else if (target.classList.contains('e-natural')) {
            this.value_.basePitch = 165;
          } else if (target.classList.contains('f-natural')) {
            this.value_.basePitch = 175;
          } else if (target.classList.contains('f-sharp')) {
            this.value_.basePitch = 185;
          } else if (target.classList.contains('g-natural')) {
            this.value_.basePitch = 196;
          } else if (target.classList.contains('g-sharp')) {
            this.value_.basePitch = 208;
          } else if (target.classList.contains('a-natural')) {
            this.value_.basePitch = 220;
          } else if (target.classList.contains('a-sharp')) {
            this.value_.basePitch = 233;
          } else if (target.classList.contains('b-natural')) {
            this.value_.basePitch = 247;
          }
          this.playNote(this.value_.basePitch * (this.value_.octave === 3 ? 4 : this.value_.octave));
          this.refreshBlockContent();
        }
      });
    });


    // PINS
    document.querySelectorAll('.pin').forEach((pin) => {
      this.addEvent_(pin, 'click', this, (e) => {
        const target = e.target;
        if (!target.classList.contains('disabled')) {
          document.querySelectorAll('.pin').forEach((p) => p.classList.remove('selected'));
          target.classList.add('selected');

          this.getSourceBlock().setFieldValue(target.getAttribute('data-pin'), this.value_.pinName);
          this.refreshBlockContent();
        }
      });
    });


    // OCTAVE
    const octaveUp = document.querySelector('.octave-up');
    if (octaveUp) {
      this.addEvent_(octaveUp, 'mousedown', this, () => {
        this.value_.octave = (this.value_.octave < 3) ? this.value_.octave + 1 : this.value_.octave;
        this.refreshBlockContent();
        this.updateOctave();
      });
    }

    const octaveDown = document.querySelector('.octave-down');
    if (octaveDown) {
      this.addEvent_(octaveDown, 'mousedown', this, () => {
        this.value_.octave = (this.value_.octave > 1) ? this.value_.octave - 1 : this.value_.octave;
        this.refreshBlockContent();
        this.updateOctave();
      });
    }


    // DURATION
    const durationUp = document.querySelector('.duration-up');
    if (durationUp) {
      this.addEvent_(durationUp, 'mousedown', this, () => {
        this.segmentsInterval_ = setInterval(() => {
          this.value_.duration = (this.value_.duration < 10000) ? this.value_.duration + 1 : this.value_.duration;
          this.refreshBlockContent();
          this.updateSegments();
        }, 50);
      });
      this.addEvent_(durationUp, 'mouseup', this, () => {
        this.clearSegmentInterval();
      });
      this.addEvent_(durationUp, 'mouseout', this, () => {
        this.clearSegmentInterval();
      });
    }

    const durationDown = document.querySelector('.duration-down');
    if (durationDown) {
      this.addEvent_(durationDown, 'mousedown', this, () => {
        this.segmentsInterval_ = setInterval(() => {
          this.value_.duration = (this.value_.duration > 1) ? this.value_.duration - 1 : this.value_.duration;
          this.refreshBlockContent();
          this.updateSegments();
        }, 50);
      });
      this.addEvent_(durationDown, 'mouseup', this, () => {
        this.clearSegmentInterval();
      });
      this.addEvent_(durationDown, 'mouseout', this, () => {
        this.clearSegmentInterval();
      });
    }


    // DURATION INPUT
    const durationInput = document.querySelector('.direct-input');
    if (durationInput) {
      this.addEvent_(durationInput, 'blur', this, () => {
        durationInput.style.opacity = 0;
        document.querySelectorAll('.duration-button').forEach((button) => {
          button.style.pointerEvents = 'auto';
          button.style.opacity = 1;
        });
      });

      this.addEvent_(durationInput, 'click', this, () => {
        durationInput.value = this.value_.duration;
        durationInput.style.opacity = 1;
        document.querySelectorAll('.duration-button').forEach((button) => {
          button.style.pointerEvents = 'onne';
          button.style.opacity = 0;
        });

        this.addEvent_(document, 'keyup', this, (event) => {
          if (event.which === 13) {
            if (durationInput.value && /^[0-9]*$/.test(durationInput.value)) {
              this.value_.duration = (durationInput.value) ? parseInt(durationInput.value, 10) : this.value_.duration;
              this.refreshBlockContent();
              this.updateSegments();
              durationInput.blur();
            } else {
              durationInput.style.border = '2px solid red';
            }
          } else if (event.which === 27) {
            durationInput.blur();
            durationInput.value = this.value_.duration;
          }
        });
      });
    }
  }


  ////////////////////////////
  ////// COMMON METHODS //////
  ////////////////////////////
  normalizeBlocklyIds_() {
    return this.getSourceBlock().id.replace(/[^a-zA-Z0-9]+/g, '');
  }

  refreshBlockContent() {
    const divContent = document.getElementById(`${this.name_}-${this.normalizeBlocklyIds_()}`);
    divContent.innerHTML = this.renderBlockContent_();
    this.updateWorkspace();
  }

  addModalEvents() {
    if (this.addCustomModalEvents) {
      this.addCustomModalEvents();
    }
  }

  initView() {
    const fieldGroup = this.getParentInput().fieldRow[0].fieldGroup_;
    if (fieldGroup) {
      const divContent = document.createElement('div');
      divContent.id = `${this.name_}-${this.normalizeBlocklyIds_()}`;
      divContent.className = `${this.name_}BlockContent`;
      divContent.innerHTML = this.renderBlockContent_();

      const foreignObject = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject');
      foreignObject.setAttribute('id', `foreign-${this.name_}-${this.normalizeBlocklyIds_()}`);
      foreignObject.setAttribute('width', this.blockWidth_);
      foreignObject.setAttribute('height', this.blockHeight_);
      foreignObject.appendChild(divContent);

      fieldGroup.appendChild(foreignObject);
      Blockly.browserEvents.conditionalBind(
        foreignObject,
        'click',
        this,
        (_) => Blockly.WidgetDiv.show(this, false, this.widgetDispose_),
      );

      this.updateSize_();
    }
  }

  updateSize_() {
    this.size_.width = this.blockWidth_;
    this.size_.height = this.blockHeight_;
  }

  updateWorkspace() {
    Blockly.Events.fire(
      new (Blockly.Events.get(Blockly.Events.BLOCK_CHANGE))(
        this.getSourceBlock(),
        'field',
        this.name || null,
        this.initialValue,
        this.getValue(),
      ),
    );
  }

  showEditor_() {
    const backDrop = document.createElement('div');
    backDrop.className = `${this.name_}BackDrop`;

    const div = document.createElement('div');
    div.innerHTML = this.renderModalContent_();
    div.className = `${this.name_}Modal`;
    div.classList.add('initial-animation');

    Blockly.WidgetDiv.createDom();
    const widgetDiv = Blockly.WidgetDiv.getDiv();
    if (widgetDiv) {
      widgetDiv.style.top = 0;
      backDrop.appendChild(div);
      widgetDiv.appendChild(backDrop);
    }
    this.addModalEvents();

    this.addEvent_(backDrop, 'click', this, (event) => {
      if (event.target === backDrop) {
        this.updateWorkspace();
        const modalElement = document.querySelector(`.${this.name_}Modal`);
        modalElement.classList.remove('initial-animation');
        modalElement.classList.add('final-animation');
        setTimeout(() => {
          this.disposeEvents();
          Blockly.WidgetDiv.hide();
        }, 200);
      }
    });
  }

  disposeEvents() {
    this.boundEvents_.map((event) => {
      Blockly.browserEvents.unbind(event);
      return false;
    });
    this.boundEvents_ = [];
  }

  widgetDispose_() {
    const widgetDiv = Blockly.WidgetDiv.getDiv();
    if (widgetDiv) {
      while (widgetDiv.firstChild) {
        widgetDiv.removeChild(widgetDiv.firstChild);
      }
    }
  }

  addEvent_(node, name, thisObject, func) {
    const event = Blockly.browserEvents.conditionalBind(
      node,
      name,
      thisObject,
      func,
    );
    this.boundEvents_.push(event);
  }
}
