/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
/* eslint-disable no-continue */
/* eslint-disable no-nested-ternary */
const $ = window.$;

const BrushTypes = { BRUSH: 'brush', ERASE: 'erase' };

export default {

  currentFrame: 0,
  brushWidth: 5,
  brushType: BrushTypes.BRUSH,


  buildMaximizedBlockLeds(block, frame = 0) {
    const ele = document.querySelector(`.oled-modal-frame-${frame}`);
    if (ele) {
      const rows = block.rows;
      const cols = block.cols;
      let ledsHtml = '';
      for (let i = 0; i < rows; i += 1) {
        ledsHtml += '<div class="led-row">';
        for (let j = 0; j < cols; j += 1) {
          ledsHtml += `<div class="led ${(block.frames[frame].leds[(i * cols) + j]) ? 'led-on' : ''}"></div>`;
        }
        ledsHtml += '</div>';
      }

      ele.innerHTML = ledsHtml;
    }
  },

  buildMaximizedBlock(block, frame = 0) {
    return `
    <div class="popup-modal-parent oled-modal-parent oled-modal-parent-16">
      <div class="popup-modal-under popup-modal-under-maximized"></div>
      <div class="oled-modal-under oled-modal-under-maximized">
        <div class="oled-frames">
          <div class="oled-modal oled-modal-frame-${frame} oled-modal-16 initial-popup">
          </div>
        </div>
        <div class="popup-modal-close oled-modal-close oled-modal-close-maximized">
            <span class="icon-cross11"></span>
        </div>
        <div class="modal-sidebar icon-modal-16">
          <div class="icon-modal-16 modal-sidebar-main-panel"></div>
          <div class="modal-sidebar-buttons">
            <span class="emotions-picker picker"></span>
            <span class="icon-picker picker"></span>
            <span class="ruler-button button"><span class="icon-grid"></span></span>
            <span class="erase-button button"><span class="icon-delete"></span></span>
          </div>
        </div>
        <div class="icon-brush">
          <span class="erase-icon"></span>
          <span class="brush-icon selected"></span>
        </div>
        <div class="icon-slider">
          <div class="slider-labels">Grosor</div>
          <input type="range" min="1" max="10" value="7" class="slider" id="strokeWidthSlider">
          <div class="slider-labels">
            <span>1</span>
            <span>10</span>
          </div>
        </div>
      </div>
    </div>
    `;
  },

  buildBigBlockLeds(block) {
    const ele = document.querySelector('.oled-carousel-modals');
    if (ele) {
      const rows = block.rows;
      const cols = block.cols;
      const frames = [];

      for (let frame = 0; frame < block.frames.length; frame += 1) {
        let ledDivs = '';
        for (let rowStart = 0; rowStart < block.rows; rowStart += 4) {
          const divStart = '<div class="led-row">';
          let divLed = '';
          for (let colStart = 0; colStart < block.cols; colStart += 4) {
            let sum = 0;
            for (let i = 0; i < 4; i += 1) {
              for (let j = 0; j < 4; j += 1) {
                const rowIndex = rowStart + i;
                const colIndex = colStart + j;
                if (rowIndex < block.rows && colIndex < block.cols) {
                  sum += block.frames[frame].leds[(rowIndex * block.cols) + colIndex] ? 1 : 0;
                }
              }
            }

            const normalizedSum = sum / 16;
            let opacityClass = '';
            if (normalizedSum > 0.75) {
              opacityClass = 'opacity100';
            } else if (normalizedSum > 0.50) {
              opacityClass = 'opacity75';
            } else if (normalizedSum > 0.25) {
              opacityClass = 'opacity50';
            } else if (normalizedSum > 0) {
              opacityClass = 'opacity25';
            }
            divLed += `<div class='led ${(sum > 0) ? `led-on ${opacityClass}` : ''}'></div>`;
          }
          const divEnd = '</div>';
          ledDivs += divStart + divLed + divEnd;
        }
        frames.push({
          leds: ledDivs,
          duration: block.frames[frame].duration,
        });
      }

      let ledsHtml = frames.map((ledRow, i) => (`
        <div class="oled-modal oled-modal-frame-${i} oled-modal-16 oled-modal-minimized initial-popup">
          <div class="oled-modal-frame-maximize-${i} oled-modal-frame-maximize"></div>
          <div class="oled-modal-frame-leds">
            ${ledRow.leds}
          </div>
          <div class="oled-modal-frame-title">Frame ${i}${(block.frames.length > 1) ? ` <div class="oled-modal-frame-delete-${i} oled-modal-frame-delete"></div>` : ''}</div>
        </div>
        <div class="oled-frames-duration initial-popup">
          Tiempo [ms]
          <input type="text" class="input-duration-${i}" value="${ledRow.duration}" pattern="([0-9])" maxlength="4" />
        </div>
      `)).join('');

      ledsHtml += '<div class="oled-modal oled-modal-16 initial-popup oled-frame-add"></div>';

      ele.innerHTML = ledsHtml;

      $('.oled-frames-duration input').on('input', (e) => {
        e.target.value = e.target.value.replace(/[^0-9]/g, '');
        const className = $(e.target).attr('class');
        const match = className.match(/input-duration-(\d+)/);
        if (match) {
          const frameNo = parseInt(match[1], 10);
          block.frames[frameNo].duration = e.target.value;
        }
      });

      $('.oled-frame-add').on('click', () => {
        block.frames.push({ leds: {}, duration: 1000 });
        this.currentFrame += 1;
        this.renderWidget(block, this.currentFrame);
        this.markFrameSelected();
        return false;
      });

      $('.oled-modal-frame-delete').on('click', (e) => {
        const className = e.target.className;
        const match = className.match(/oled-modal-frame-delete-(\d+)/);
        if (match) {
          const index = parseInt(match[1], 10);
          block.frames.splice(index, 1);
          this.currentFrame = (this.currentFrame - 1 > 0) ? this.currentFrame - 1 : 0;
          this.renderWidget(block, this.currentFrame);
          this.buildMiniBlock(block);
          this.markFrameSelected();
        }
        return false;
      });
    }
  },

  buildBigBlock(block) {
    return `
    <div class="popup-modal-parent oled-modal-parent oled-modal-parent-16">
      <div class="popup-modal-under"></div>
      <div class="oled-modal-under">
        <div class="oled-frames">
          <div class="oled-frames-prev"></div>
          <div class="oled-carousel">
            <div class="oled-carousel-modals">
            </div>
          </div>
          <div class="oled-frames-next"></div>
        </div>
        <div class="popup-modal-close oled-modal-close">
            <span class="icon-cross11"></span>
        </div>
        <div class="modal-sidebar icon-modal-16">
          <div class="icon-modal-16 modal-sidebar-main-panel"></div>
          <div class="modal-sidebar-buttons">
            <span class="emotions-picker picker"></span>
            <span class="icon-picker picker"></span>
            <span class="erase-button button"><span class="icon-delete"></span></span>
          </div>
        </div>
      </div>
    </div>`;
  },

  buildMiniBlock(block, startUp = false) {
    const ele = document.querySelector(`[data-id='${block.id}']`);
    const eleText = document.querySelector(`[data-id='${block.id}'] text:nth-child(2)`);

    let ledDivs = '';
    for (let rowStart = 0; rowStart < block.rows; rowStart += 8) {
      const divStart = '<div class="led-row">';
      let divLed = '';
      for (let colStart = 0; colStart < block.cols; colStart += 8) {
        let sum = 0;
        for (let i = 0; i < 8; i += 1) {
          for (let j = 0; j < 8; j += 1) {
            const rowIndex = rowStart + i;
            const colIndex = colStart + j;
            if (rowIndex < block.rows && colIndex < block.cols) {
              sum += block.frames[0].leds[(rowIndex * block.cols) + colIndex] ? 1 : 0;
            }
          }
        }

        const normalizedSum = sum / 64;
        let opacityClass = '';
        if (normalizedSum > 0.75) {
          opacityClass = 'opacity100';
        } else if (normalizedSum > 0.50) {
          opacityClass = 'opacity75';
        } else if (normalizedSum > 0.25) {
          opacityClass = 'opacity50';
        } else if (normalizedSum > 0) {
          opacityClass = 'opacity25';
        }
        divLed += `<div class='led ${(sum > 0) ? `led-on ${opacityClass}` : ''}'></div>`;
      }
      const divEnd = '</div>';
      ledDivs += divStart + divLed + divEnd;
    }

    if (document.getElementById(`oled-${block.id}`)) {
      const foreignObject = document.getElementById(`oled-${block.id}`);
      foreignObject.innerHTML = `
        <div class="oled-mini-modal oled-mini-modal-16 oled-mini-modal-${block.id}">
          <div class="mini-modal-leds-container">
            ${ledDivs}
          </div>
        </div>
      `;
    } else {
      const ns = 'http://www.w3.org/2000/svg';
      // const svg = document.querySelector('svg');
      const foreignObject = document.createElementNS(ns, 'foreignObject');
      foreignObject.setAttribute('class', 'oled');
      foreignObject.setAttribute('id', `oled-${block.id}`);
      foreignObject.innerHTML = `
        <div class="oled-mini-modal oled-mini-modal-16 oled-mini-modal-${block.id}">
          <div class="mini-modal-leds-container">
            ${ledDivs}
          </div>
        </div>
      `;
      ele.insertBefore(foreignObject, eleText);
    }
    // block.workspace.fireChangeListener('resize');
    if (!startUp) {
      const event = new window.Blockly.Events.Change(block, 'mutation', 'data-custom', '', window.Blockly.Xml.domToText(block.mutationToDom()));
      window.Blockly.Events.fire(event);
    }
  },

  markFrameSelected() {
    $('.oled-modal').removeClass('selected');
    $(`.oled-modal-frame-${this.currentFrame}`).addClass('selected');
  },

  showItem(index) {
    const $carousel = $('.oled-carousel-modals');
    const $items = $carousel.children('.oled-modal');
    const itemCount = $items.length;

    const translateX = ((itemCount - index - 2) * (262 + 60)) + (itemCount <= 2 ? 5 : 2);
    $carousel.css('transform', `translateX(${translateX}px)`);

    $('.oled-frames-prev').addClass('disabled');
    $('.oled-frames-next').addClass('disabled');
    if (index > 0) {
      $('.oled-frames-prev').removeClass('disabled');
    }
    if (index < itemCount - 2) {
      $('.oled-frames-next').removeClass('disabled');
    }
  },

  renderMaximized(block, frame = 0) {
    window.Blockly.WidgetDiv.DIV.innerHTML = this.buildMaximizedBlock(block, frame);
    this.buildMaximizedBlockLeds(block, frame);

    const slider = document.getElementById('strokeWidthSlider');
    this.brushWidth = this.mapBrushValue(slider.value);

    slider.addEventListener('input', (e) => {
      const brush = this.mapBrushValue(e.target.value);
      this.brushWidth = brush;
    });

    setTimeout(() => {
      $('.oled-modal-parent .modal-sidebar').toggleClass('start');
      $('.oled-modal-parent .oled-modal').removeClass('initial-popup');
      $('.oled-modal-parent .oled-frames-duration').removeClass('initial-popup');
    }, 300);

    this.brushType = BrushTypes.BRUSH;
    $('.icon-brush').on('click', '.erase-icon', () => {
      $('.erase-icon').addClass('selected');
      $('.brush-icon').removeClass('selected');
      this.brushType = BrushTypes.ERASE;
    });
    $('.icon-brush').on('click', '.brush-icon', () => {
      $('.brush-icon').addClass('selected');
      $('.erase-icon').removeClass('selected');
      this.brushType = BrushTypes.BRUSH;
    });

    // hide modal functionality
    $('.oled-modal-parent').on('click', '.oled-modal-close-maximized, .popup-modal-under-maximized', () => {
      this.renderWidget(block, frame);
    });
  },

  renderWidget(block, frame = 0) {
    window.Blockly.WidgetDiv.DIV.innerHTML = this.buildBigBlock(block);
    this.buildBigBlockLeds(block);

    setTimeout(() => {
      $('.oled-modal-parent .modal-sidebar').toggleClass('start');
      $('.oled-modal-parent .oled-modal').removeClass('initial-popup');
      $('.oled-modal-parent .oled-frames-duration').removeClass('initial-popup');
    }, 300);

    this.currentFrame = frame;
    const $carousel = $('.oled-carousel-modals');
    const $items = $carousel.children('.oled-modal');
    const itemCount = $items.length;

    $('.oled-frames-prev').on('click', () => {
      if (this.currentFrame > 0) {
        this.currentFrame -= 1;
        this.showItem(this.currentFrame);
        this.markFrameSelected();
      }
    });

    $('.oled-frames-next').on('click', () => {
      if (this.currentFrame < itemCount - 2) {
        this.currentFrame += 1;
        this.showItem(this.currentFrame);
        this.markFrameSelected();
      }
    });

    $('.oled-modal-frame-maximize').on('click', (e) => {
      const className = e.target.className;
      const match = className.match(/oled-modal-frame-maximize-(\d+)/);
      if (match) {
        const index = parseInt(match[1], 10);
        this.currentFrame = index;
        this.renderMaximized(block, this.currentFrame);
      }
      return false;
    });

    $('.oled-modal-parent').on('click', '.oled-modal-close, .popup-modal-under', () => {
      $('.oled-modal').addClass('popup-hide');
      $('.oled-frames-duration').addClass('popup-hide');
      $('.modal-sidebar').addClass('popup-hide');
      $('.oled-modal-close').addClass('popup-hide');
      setTimeout(() => {
        $('.blocklyWidgetDiv').unbind('click');
        window.Blockly.WidgetDiv.hide();
      }, 200);
    });

    this.showItem(this.currentFrame);
    this.markFrameSelected();
  },

  mapBrushValue(value) {
    const brushMapping = [1, 2, 3, 5, 7, 9, 11, 13, 15, 17];
    const brush = 11 - parseInt(value, 10);
    return brushMapping[brush - 1];
  },

  js(block) {
    const self = this;
    const rows = block.rows;
    const ledsPerRow = block.cols;

    this.renderWidget(block);
    window.Blockly.WidgetDiv.show(block, true);

    // UNBIND AND BIND
    $('.blocklyWidgetDiv').unbind('click');
    $('.blocklyWidgetDiv').unbind('mousedown mouseover');
    $('.blocklyWidgetDiv').on('mousedown mouseover', '.oled-modal-minimized', (e) => {
      if ((e.buttons === 1 || e.buttons === 3) && !$(e.target).hasClass('oled-modal-frame-delete')) {
        const oledModal = $(e.target).closest('.oled-modal');
        const className = $(oledModal).attr('class');
        const match = className.match(/oled-modal-frame-(\d+)/);
        if (match) {
          const frame = parseInt(match[1], 10);
          if (frame === this.currentFrame) {
            this.renderMaximized(block, this.currentFrame);
          } else {
            this.currentFrame = frame;
            this.showItem(this.currentFrame);
            this.markFrameSelected();
          }
        }
      }
    });
    $('.blocklyWidgetDiv').on('mousedown mouseover', '.oled-modal-under-maximized div.led', (e) => {
      if (e.buttons === 1 || e.buttons === 3) {
        const oledModal = $(e.target).closest('.oled-modal');
        const className = $(oledModal).attr('class');
        const match = className.match(/oled-modal-frame-(\d+)/);
        if (match) {
          const frame = parseInt(match[1], 10);
          if (frame === this.currentFrame) {
            const colIndex = $(e.target).index();
            const rowIndex = $(e.target).closest('.led-row').index();

            const strokeWidthStart = Math.floor(this.brushWidth / 2);
            const strokeWidthEnd = Math.ceil(this.brushWidth / 2);
            for (let i = -strokeWidthStart; i < strokeWidthEnd; i += 1) {
              for (let j = -strokeWidthStart; j < strokeWidthEnd; j += 1) {
                if (this.brushWidth <= 2 || (i * i + j * j <= strokeWidthStart * strokeWidthEnd)) {
                  const neighborRowIndex = rowIndex + i;
                  const neighborColIndex = colIndex + j;
                  if (neighborRowIndex >= 0 && neighborRowIndex < block.rows && neighborColIndex >= 0 && neighborColIndex < block.cols) {
                    const neighborIndex = (neighborRowIndex * ledsPerRow) + neighborColIndex;
                    const neighborLed = $(`.oled-modal-frame-${frame} .led-row:nth-child(${neighborRowIndex + 1}) .led:nth-child(${neighborColIndex + 1})`);

                    if (this.brushType === BrushTypes.BRUSH) {
                      neighborLed.addClass('led-on');
                      block.frames[frame].leds[neighborIndex] = true;
                    }
                    if (this.brushType === BrushTypes.ERASE) {
                      neighborLed.removeClass('led-on');
                      block.frames[frame].leds[neighborIndex] = false;
                    }
                  }
                }
              }
            }

            self.buildMiniBlock(block);
          }
        }
      }
    });

    let currentDrawer = '';

    $('.blocklyWidgetDiv').on('click', '.oled-modal-parent .modal-sidebar .modal-sidebar-buttons span.picker', function (e) { // eslint-disable-line

      function fillSidebar() {
        if ($(e.target).hasClass('emotions-picker')) {
          currentDrawer = 'emotions-picker';
          $('.modal-sidebar .modal-sidebar-main-panel')[0].innerHTML = `
          <div class="sidebar-scroller emotions-scroller">
            <div><span class="emotions-rectangle emotion1"></span></div>
            <div><span class="emotions-rectangle emotion2"></span></div>
          </div>
          `;
        } else if ($(e.target).hasClass('icon-picker')) {
          currentDrawer = 'icon-picker';
          $('.modal-sidebar .modal-sidebar-main-panel')[0].innerHTML = `
          <div class="sidebar-scroller icon-scroller">
            <div><span class="icon-rectangle icon1"></span></div>
            <div><span class="icon-rectangle icon2"></span></div>
            <div><span class="icon-rectangle icon3"></span></div>
            <div><span class="icon-rectangle icon4"></span></div>
            <div><span class="icon-rectangle icon5"></span></div>
            <div><span class="icon-rectangle icon6"></span></div>
          </div>
          `;
        }

        $('.sidebar-scroller').slick({
          infinite: true,
          slidesToShow: 3,
          slidesToScroll: 3,
        });
      }

      // is the drawer open?
      if ($('.blocklyWidgetDiv .modal-sidebar').hasClass('open')) {
        $('.blocklyWidgetDiv .modal-sidebar').removeClass('open');
        // was it not I who opened it?
        if ($(e.target).attr('class').indexOf(currentDrawer) === -1) {
          $('.blocklyWidgetDiv .modal-sidebar .modal-sidebar-buttons span').removeClass('selected');
          $(e.target).addClass('selected');
          setTimeout(() => {
            fillSidebar();
            $('.oled-modal-parent .modal-sidebar').addClass('open');
          }, 300);
        } else { $(e.target).removeClass('selected'); }
      } else {
        $('.blocklyWidgetDiv .modal-sidebar .modal-sidebar-buttons span').removeClass('selected');
        $(e.target).addClass('selected');
        fillSidebar();
        $('.oled-modal-parent .modal-sidebar').addClass('open');
      }
    });

    // erase functionality
    $('.blocklyWidgetDiv').on('click', '.oled-modal-parent .erase-button', () => {
      $(`.oled-modal-frame-${this.currentFrame} .led-row div.led`).removeClass('led-on');
      // eslint-disable-next-line no-param-reassign
      block.frames[this.currentFrame] = {
        leds: {},
        duration: 1000,
      };
      self.buildMiniBlock(block);
    });

    // grid numbers functionality
    $('.blocklyWidgetDiv').on('click', '.oled-modal-parent .ruler-button', (ele) => {
      if ($(ele.target).hasClass('ruler-on')) {
        $(ele.target).removeClass('ruler-on');
        $('.oled-modal .led-row').each((index, e) => {
          $(e).attr('data-content', '');
        });
        $('.oled-modal .led-row:first-child div.led').each((index, e) => {
          $(e).attr('data-content', '');
        });
      } else {
        $(ele.target).addClass('ruler-on');
        $('.oled-modal .led-row').each((index, e) => {
          if (index % 8 === 0) {
            $(e).attr('data-content', index);
          }
        });
        $('.oled-modal .led-row:first-child div.led').each((index, e) => {
          if (index % 8 === 0) {
            $(e).attr('data-content', index);
          }
        });
      }
    });

    function turnValuesIntoLeds(ledArray, frame = 0) {
      for (let i = 0; i < (rows * ledsPerRow); i += 1) {
        if (ledArray.indexOf(i) !== -1) {
          // eslint-disable-next-line no-param-reassign
          block.frames[frame].leds[i] = true;

          const row = Math.ceil(i / ledsPerRow);
          const led = Math.ceil(i % ledsPerRow) + 1;
        } else {
          // eslint-disable-next-line no-param-reassign
          block.frames[frame].leds[i] = false;
        }
      }

      if ($('.oled-modal-under-maximized').length) {
        self.buildMaximizedBlockLeds(block, self.currentFrame);
      } else {
        self.buildBigBlockLeds(block);
        self.markFrameSelected();
      }
      self.buildMiniBlock(block);
    }


    $('.blocklyWidgetDiv').on('click', '.oled-modal-parent-16 .emotions-rectangle', (e) => {
      $(`.oled-modal-frame-${self.currentFrame} .led-row div.led`).removeClass('led-on');
      $(`.oled-modal-frame-${self.currentFrame} .led-row div.led`).removeClass('opacity*');
      const n = $(e.target).attr('class').replace('emotions-rectangle emotion', '');

      let ledArray = [];

      switch (n) {
        case '1': // cara 1
          ledArray = [
            2645, 2646, 2647, 2725, 2726, 2727, 2728, 2772, 2773, 2774, 2775, 2776, 2852, 2853, 2854, 2855, 2856, 2857, 2899, 2900, 2901, 2902, 2903, 2904, 2905, 2980, 2981, 2982, 2983, 2984, 2985, 2986, 3026, 3027, 3028, 3029, 3030, 3031, 3032, 3033, 3108, 3109, 3110, 3111, 3112, 3113, 3114, 3115, 3153, 3154, 3155, 3156, 3157, 3158, 3159, 3160, 3161, 3236, 3237, 3238, 3239, 3240, 3241, 3242,
            3243, 3244, 3280, 3281, 3282, 3283, 3284, 3285, 3286, 3287, 3288, 3365, 3366, 3367, 3368, 3369, 3370, 3371, 3372, 3373, 3407, 3408, 3409, 3410, 3411, 3412, 3413, 3414, 3415, 3494, 3495, 3496, 3497, 3498, 3499, 3500, 3501, 3502, 3534, 3535, 3536, 3537, 3538, 3539, 3540, 3541, 3542, 3623, 3624, 3625, 3626, 3627, 3628, 3629, 3630, 3631, 3661, 3662, 3663, 3664, 3665, 3666, 3667, 3668,
            3669, 3752, 3753, 3754, 3755, 3756, 3757, 3758, 3759, 3760, 3788, 3789, 3790, 3791, 3792, 3793, 3794, 3795, 3796, 3881, 3882, 3883, 3884, 3885, 3886, 3887, 3888, 3916, 3917, 3918, 3919, 3920, 3921, 3922, 3923, 3924, 4009, 4010, 4011, 4012, 4013, 4014, 4015, 4016, 4044, 4045, 4046, 4047, 4048, 4049, 4050, 4051, 4052, 4136, 4137, 4138, 4139, 4140, 4141, 4142, 4143, 4144, 4173, 4174,
            4175, 4176, 4177, 4178, 4179, 4180, 4181, 4263, 4264, 4265, 4266, 4267, 4268, 4269, 4270, 4271, 4302, 4303, 4304, 4305, 4306, 4307, 4308, 4309, 4310, 4390, 4391, 4392, 4393, 4394, 4395, 4396, 4397, 4398, 4431, 4432, 4433, 4434, 4435, 4436, 4437, 4438, 4439, 4517, 4518, 4519, 4520, 4521, 4522, 4523, 4524, 4525, 4560, 4561, 4562, 4563, 4564, 4565, 4566, 4567, 4568, 4644, 4645, 4646,
            4647, 4648, 4649, 4650, 4651, 4652, 4689, 4690, 4691, 4692, 4693, 4694, 4695, 4696, 4697, 4772, 4773, 4774, 4775, 4776, 4777, 4778, 4779, 4818, 4819, 4820, 4821, 4822, 4823, 4824, 4825, 4900, 4901, 4902, 4903, 4904, 4905, 4906, 4947, 4948, 4949, 4950, 4951, 4952, 4953, 5028, 5029, 5030, 5031, 5032, 5033, 5076, 5077, 5078, 5079, 5080, 5157, 5158, 5159, 5160, 5205, 5206, 5207,
          ];
          break;
        case '2': // cara 2
          ledArray = [
            1835, 1836, 1837, 1876, 1877, 1878, 1962, 1963, 1964, 1965, 1966, 2003, 2004, 2005, 2006, 2007, 2089, 2090, 2091, 2092, 2093, 2094, 2095, 2130, 2131, 2132, 2133, 2134, 2135, 2136, 2217, 2218, 2219, 2220, 2221, 2222, 2223, 2258, 2259, 2260, 2261, 2262, 2263, 2264, 2345, 2346, 2347, 2348, 2349, 2350, 2351, 2386, 2387, 2388, 2389, 2390, 2391, 2392, 2473, 2474, 2475, 2476, 2477, 2478,
            2479, 2514, 2515, 2516, 2517, 2518, 2519, 2520, 2601, 2602, 2603, 2604, 2605, 2606, 2607, 2642, 2643, 2644, 2645, 2646, 2647, 2648, 2729, 2730, 2731, 2732, 2733, 2734, 2735, 2770, 2771, 2772, 2773, 2774, 2775, 2776, 2857, 2858, 2859, 2860, 2861, 2862, 2863, 2898, 2899, 2900, 2901, 2902, 2903, 2904, 2985, 2986, 2987, 2988, 2989, 2990, 2991, 3026, 3027, 3028, 3029, 3030, 3031, 3032,
            3113, 3114, 3115, 3116, 3117, 3118, 3119, 3154, 3155, 3156, 3157, 3158, 3159, 3160, 3241, 3242, 3243, 3244, 3245, 3246, 3247, 3282, 3283, 3284, 3285, 3286, 3287, 3288, 3369, 3370, 3371, 3372, 3373, 3374, 3375, 3410, 3411, 3412, 3413, 3414, 3415, 3416, 3497, 3498, 3499, 3500, 3501, 3502, 3503, 3538, 3539, 3540, 3541, 3542, 3543, 3544, 3625, 3626, 3627, 3628, 3629, 3630, 3631, 3666,
            3667, 3668, 3669, 3670, 3671, 3672, 3753, 3754, 3755, 3756, 3757, 3758, 3759, 3794, 3795, 3796, 3797, 3798, 3799, 3800, 3881, 3882, 3883, 3884, 3885, 3886, 3887, 3922, 3923, 3924, 3925, 3926, 3927, 3928, 4009, 4010, 4011, 4012, 4013, 4014, 4015, 4050, 4051, 4052, 4053, 4054, 4055, 4056, 4137, 4138, 4139, 4140, 4141, 4142, 4143, 4178, 4179, 4180, 4181, 4182, 4183, 4184, 4265, 4266,
            4267, 4268, 4269, 4270, 4271, 4306, 4307, 4308, 4309, 4310, 4311, 4312, 4393, 4394, 4395, 4396, 4397, 4398, 4399, 4434, 4435, 4436, 4437, 4438, 4439, 4440, 4521, 4522, 4523, 4524, 4525, 4526, 4527, 4562, 4563, 4564, 4565, 4566, 4567, 4568, 4649, 4650, 4651, 4652, 4653, 4654, 4655, 4690, 4691, 4692, 4693, 4694, 4695, 4696, 4777, 4778, 4779, 4780, 4781, 4782, 4783, 4818, 4819, 4820,
            4821, 4822, 4823, 4824, 4905, 4906, 4907, 4908, 4909, 4910, 4911, 4946, 4947, 4948, 4949, 4950, 4951, 4952, 5033, 5034, 5035, 5036, 5037, 5038, 5039, 5074, 5075, 5076, 5077, 5078, 5079, 5080, 5161, 5162, 5163, 5164, 5165, 5166, 5167, 5202, 5203, 5204, 5205, 5206, 5207, 5208, 5289, 5290, 5291, 5292, 5293, 5294, 5295, 5330, 5331, 5332, 5333, 5334, 5335, 5336, 5417, 5418, 5419, 5420,
            5421, 5422, 5423, 5458, 5459, 5460, 5461, 5462, 5463, 5464, 5545, 5546, 5547, 5548, 5549, 5550, 5551, 5586, 5587, 5588, 5589, 5590, 5591, 5592, 5673, 5674, 5675, 5676, 5677, 5678, 5679, 5714, 5715, 5716, 5717, 5718, 5719, 5720, 5801, 5802, 5803, 5804, 5805, 5806, 5807, 5842, 5843, 5844, 5845, 5846, 5847, 5848, 5930, 5931, 5932, 5933, 5934, 5971, 5972, 5973, 5974, 5975, 6059, 6060,
            6061, 6100, 6101, 6102,
          ];
          break;
        default:
          ledArray = [];
          break;
      }

      turnValuesIntoLeds(ledArray, self.currentFrame);
    });

    $('.blocklyWidgetDiv').on('click', '.oled-modal-parent-16 .icon-rectangle', (e) => {
      $(`.oled-modal-frame-${self.currentFrame} .led-row div.led`).removeClass('led-on');
      $(`.oled-modal-frame-${self.currentFrame} .led-row div.led`).removeClass('opacity*');
      const n = $(e.target).attr('class').replace('icon-rectangle icon', '');

      let ledArray = [];

      switch (n) {
        case '1': // flecha arriba
          ledArray = [
            1215, 1216, 1341, 1342, 1343, 1344, 1345, 1346, 1466, 1467, 1468, 1469, 1470, 1471, 1472, 1473, 1474, 1475, 1476, 1592, 1593, 1594, 1595, 1596, 1597, 1598, 1599, 1600, 1601, 1602, 1603, 1604, 1605, 1606, 1607, 1718, 1719, 1720, 1721, 1722, 1723, 1724, 1725, 1726, 1727, 1728, 1729, 1730, 1731, 1732, 1733, 1734, 1735, 1736, 1737, 1844, 1845, 1846, 1847, 1848, 1849, 1850, 1851, 1852,
            1853, 1854, 1855, 1856, 1857, 1858, 1859, 1860, 1861, 1862, 1863, 1864, 1865, 1866, 1867, 1969, 1970, 1971, 1972, 1973, 1974, 1975, 1976, 1977, 1978, 1979, 1980, 1981, 1982, 1983, 1984, 1985, 1986, 1987, 1988, 1989, 1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 2095, 2096, 2097, 2098, 2099, 2100, 2101, 2102, 2103, 2104, 2105, 2106, 2107, 2108, 2109, 2110, 2111, 2112, 2113, 2114,
            2115, 2116, 2117, 2118, 2119, 2120, 2121, 2122, 2123, 2124, 2125, 2126, 2127, 2128, 2221, 2222, 2223, 2224, 2225, 2226, 2227, 2228, 2229, 2230, 2231, 2232, 2233, 2234, 2235, 2236, 2237, 2238, 2239, 2240, 2241, 2242, 2243, 2244, 2245, 2246, 2247, 2248, 2249, 2250, 2251, 2252, 2253, 2254, 2255, 2256, 2257, 2258, 2347, 2348, 2349, 2350, 2351, 2352, 2353, 2354, 2355, 2356, 2357, 2358,
            2359, 2360, 2361, 2362, 2363, 2364, 2365, 2366, 2367, 2368, 2369, 2370, 2371, 2372, 2373, 2374, 2375, 2376, 2377, 2378, 2379, 2380, 2381, 2382, 2383, 2384, 2385, 2386, 2387, 2388, 2472, 2473, 2474, 2475, 2476, 2477, 2478, 2479, 2480, 2481, 2482, 2483, 2484, 2485, 2486, 2487, 2488, 2489, 2490, 2491, 2492, 2493, 2494, 2495, 2496, 2497, 2498, 2499, 2500, 2501, 2502, 2503, 2504, 2505,
            2506, 2507, 2508, 2509, 2510, 2511, 2512, 2513, 2514, 2515, 2516, 2517, 2518, 2598, 2599, 2600, 2601, 2602, 2603, 2604, 2605, 2606, 2607, 2608, 2609, 2610, 2611, 2612, 2613, 2614, 2615, 2616, 2617, 2618, 2619, 2620, 2621, 2622, 2623, 2624, 2625, 2626, 2627, 2628, 2629, 2630, 2631, 2632, 2633, 2634, 2635, 2636, 2637, 2638, 2639, 2640, 2641, 2642, 2643, 2644, 2645, 2646, 2647, 2648,
            2649, 2724, 2725, 2726, 2727, 2728, 2729, 2730, 2731, 2732, 2733, 2734, 2735, 2736, 2737, 2738, 2739, 2740, 2741, 2742, 2743, 2744, 2745, 2746, 2747, 2748, 2749, 2750, 2751, 2752, 2753, 2754, 2755, 2756, 2757, 2758, 2759, 2760, 2761, 2762, 2763, 2764, 2765, 2766, 2767, 2768, 2769, 2770, 2771, 2772, 2773, 2774, 2775, 2776, 2777, 2778, 2779, 2850, 2851, 2852, 2853, 2854, 2855, 2856,
            2857, 2858, 2859, 2860, 2861, 2862, 2863, 2864, 2865, 2866, 2867, 2868, 2869, 2870, 2871, 2872, 2873, 2874, 2875, 2876, 2877, 2878, 2879, 2880, 2881, 2882, 2883, 2884, 2885, 2886, 2887, 2888, 2889, 2890, 2891, 2892, 2893, 2894, 2895, 2896, 2897, 2898, 2899, 2900, 2901, 2902, 2903, 2904, 2905, 2906, 2907, 2908, 2909, 2975, 2976, 2977, 2978, 2979, 2980, 2981, 2982, 2983, 2984, 2985,
            2986, 2987, 2988, 2989, 2990, 2991, 2992, 2993, 2994, 2995, 2996, 2997, 2998, 2999, 3000, 3001, 3002, 3003, 3004, 3005, 3006, 3007, 3008, 3009, 3010, 3011, 3012, 3013, 3014, 3015, 3016, 3017, 3018, 3019, 3020, 3021, 3022, 3023, 3024, 3025, 3026, 3027, 3028, 3029, 3030, 3031, 3032, 3033, 3034, 3035, 3036, 3037, 3038, 3039, 3101, 3102, 3103, 3104, 3105, 3106, 3107, 3108, 3109, 3110,
            3111, 3112, 3113, 3114, 3115, 3116, 3117, 3118, 3119, 3120, 3121, 3122, 3123, 3124, 3125, 3126, 3127, 3128, 3129, 3130, 3131, 3132, 3133, 3134, 3135, 3136, 3137, 3138, 3139, 3140, 3141, 3142, 3143, 3144, 3145, 3146, 3147, 3148, 3149, 3150, 3151, 3152, 3153, 3154, 3155, 3156, 3157, 3158, 3159, 3160, 3161, 3162, 3163, 3164, 3165, 3166, 3167, 3168, 3169, 3170, 3227, 3228, 3229, 3230,
            3231, 3232, 3233, 3234, 3235, 3236, 3237, 3238, 3239, 3240, 3241, 3242, 3243, 3244, 3245, 3246, 3247, 3248, 3249, 3250, 3251, 3252, 3253, 3254, 3255, 3256, 3257, 3258, 3259, 3260, 3261, 3262, 3263, 3264, 3265, 3266, 3267, 3268, 3269, 3270, 3271, 3272, 3273, 3274, 3275, 3276, 3277, 3278, 3279, 3280, 3281, 3282, 3283, 3284, 3285, 3286, 3287, 3288, 3289, 3290, 3291, 3292, 3293, 3294,
            3295, 3296, 3297, 3298, 3299, 3300, 3353, 3354, 3355, 3356, 3357, 3358, 3359, 3360, 3361, 3362, 3363, 3364, 3365, 3366, 3367, 3368, 3369, 3370, 3371, 3372, 3373, 3374, 3375, 3376, 3377, 3378, 3379, 3380, 3381, 3382, 3383, 3384, 3385, 3386, 3387, 3388, 3389, 3390, 3391, 3392, 3393, 3394, 3395, 3396, 3397, 3398, 3399, 3400, 3401, 3402, 3403, 3404, 3405, 3406, 3407, 3408, 3409, 3410,
            3411, 3412, 3413, 3414, 3415, 3416, 3417, 3418, 3419, 3420, 3421, 3422, 3423, 3424, 3425, 3426, 3427, 3428, 3429, 3430, 3497, 3498, 3499, 3500, 3501, 3502, 3503, 3504, 3505, 3506, 3507, 3508, 3509, 3510, 3511, 3512, 3513, 3514, 3515, 3516, 3517, 3518, 3519, 3520, 3521, 3522, 3523, 3524, 3525, 3526, 3527, 3528, 3529, 3530, 3531, 3532, 3533, 3534, 3535, 3536, 3537, 3538, 3539, 3540,
            3541, 3625, 3626, 3627, 3628, 3629, 3630, 3631, 3632, 3633, 3634, 3635, 3636, 3637, 3638, 3639, 3640, 3641, 3642, 3643, 3644, 3645, 3646, 3647, 3648, 3649, 3650, 3651, 3652, 3653, 3654, 3655, 3656, 3657, 3658, 3659, 3660, 3661, 3662, 3663, 3664, 3665, 3666, 3667, 3668, 3669, 3753, 3754, 3755, 3756, 3757, 3758, 3759, 3760, 3761, 3762, 3763, 3764, 3765, 3766, 3767, 3768, 3769, 3770,
            3771, 3772, 3773, 3774, 3775, 3776, 3777, 3778, 3779, 3780, 3781, 3782, 3783, 3784, 3785, 3786, 3787, 3788, 3789, 3790, 3791, 3792, 3793, 3794, 3795, 3796, 3797, 3881, 3882, 3883, 3884, 3885, 3886, 3887, 3888, 3889, 3890, 3891, 3892, 3893, 3894, 3895, 3896, 3897, 3898, 3899, 3900, 3901, 3902, 3903, 3904, 3905, 3906, 3907, 3908, 3909, 3910, 3911, 3912, 3913, 3914, 3915, 3916, 3917,
            3918, 3919, 3920, 3921, 3922, 3923, 3924, 3925, 4009, 4010, 4011, 4012, 4013, 4014, 4015, 4016, 4017, 4018, 4019, 4020, 4021, 4022, 4023, 4024, 4025, 4026, 4027, 4028, 4029, 4030, 4031, 4032, 4033, 4034, 4035, 4036, 4037, 4038, 4039, 4040, 4041, 4042, 4043, 4044, 4045, 4046, 4047, 4048, 4049, 4050, 4051, 4052, 4053, 4137, 4138, 4139, 4140, 4141, 4142, 4143, 4144, 4145, 4146, 4147,
            4148, 4149, 4150, 4151, 4152, 4153, 4154, 4155, 4156, 4157, 4158, 4159, 4160, 4161, 4162, 4163, 4164, 4165, 4166, 4167, 4168, 4169, 4170, 4171, 4172, 4173, 4174, 4175, 4176, 4177, 4178, 4179, 4180, 4181, 4265, 4266, 4267, 4268, 4269, 4270, 4271, 4272, 4273, 4274, 4275, 4276, 4277, 4278, 4279, 4280, 4281, 4282, 4283, 4284, 4285, 4286, 4287, 4288, 4289, 4290, 4291, 4292, 4293, 4294,
            4295, 4296, 4297, 4298, 4299, 4300, 4301, 4302, 4303, 4304, 4305, 4306, 4307, 4308, 4309, 4393, 4394, 4395, 4396, 4397, 4398, 4399, 4400, 4401, 4402, 4403, 4404, 4405, 4406, 4407, 4408, 4409, 4410, 4411, 4412, 4413, 4414, 4415, 4416, 4417, 4418, 4419, 4420, 4421, 4422, 4423, 4424, 4425, 4426, 4427, 4428, 4429, 4430, 4431, 4432, 4433, 4434, 4435, 4436, 4437, 4521, 4522, 4523, 4524,
            4525, 4526, 4527, 4528, 4529, 4530, 4531, 4532, 4533, 4534, 4535, 4536, 4537, 4538, 4539, 4540, 4541, 4542, 4543, 4544, 4545, 4546, 4547, 4548, 4549, 4550, 4551, 4552, 4553, 4554, 4555, 4556, 4557, 4558, 4559, 4560, 4561, 4562, 4563, 4564, 4565, 4649, 4650, 4651, 4652, 4653, 4654, 4655, 4656, 4657, 4658, 4659, 4660, 4661, 4662, 4663, 4664, 4665, 4666, 4667, 4668, 4669, 4670, 4671,
            4672, 4673, 4674, 4675, 4676, 4677, 4678, 4679, 4680, 4681, 4682, 4683, 4684, 4685, 4686, 4687, 4688, 4689, 4690, 4691, 4692, 4693, 4777, 4778, 4779, 4780, 4781, 4782, 4783, 4784, 4785, 4786, 4787, 4788, 4789, 4790, 4791, 4792, 4793, 4794, 4795, 4796, 4797, 4798, 4799, 4800, 4801, 4802, 4803, 4804, 4805, 4806, 4807, 4808, 4809, 4810, 4811, 4812, 4813, 4814, 4815, 4816, 4817, 4818,
            4819, 4820, 4821, 4905, 4906, 4907, 4908, 4909, 4910, 4911, 4912, 4913, 4914, 4915, 4916, 4917, 4918, 4919, 4920, 4921, 4922, 4923, 4924, 4925, 4926, 4927, 4928, 4929, 4930, 4931, 4932, 4933, 4934, 4935, 4936, 4937, 4938, 4939, 4940, 4941, 4942, 4943, 4944, 4945, 4946, 4947, 4948, 4949, 5033, 5034, 5035, 5036, 5037, 5038, 5039, 5040, 5041, 5042, 5043, 5044, 5045, 5046, 5047, 5048,
            5049, 5050, 5051, 5052, 5053, 5054, 5055, 5056, 5057, 5058, 5059, 5060, 5061, 5062, 5063, 5064, 5065, 5066, 5067, 5068, 5069, 5070, 5071, 5072, 5073, 5074, 5075, 5076, 5077, 5161, 5162, 5163, 5164, 5165, 5166, 5167, 5168, 5169, 5170, 5171, 5172, 5173, 5174, 5175, 5176, 5177, 5178, 5179, 5180, 5181, 5182, 5183, 5184, 5185, 5186, 5187, 5188, 5189, 5190, 5191, 5192, 5193, 5194, 5195,
            5196, 5197, 5198, 5199, 5200, 5201, 5202, 5203, 5204, 5205, 5289, 5290, 5291, 5292, 5293, 5294, 5295, 5296, 5297, 5298, 5299, 5300, 5301, 5302, 5303, 5304, 5305, 5306, 5307, 5308, 5309, 5310, 5311, 5312, 5313, 5314, 5315, 5316, 5317, 5318, 5319, 5320, 5321, 5322, 5323, 5324, 5325, 5326, 5327, 5328, 5329, 5330, 5331, 5332, 5333, 5417, 5418, 5419, 5420, 5421, 5422, 5423, 5424, 5425,
            5426, 5427, 5428, 5429, 5430, 5431, 5432, 5433, 5434, 5435, 5436, 5437, 5438, 5439, 5440, 5441, 5442, 5443, 5444, 5445, 5446, 5447, 5448, 5449, 5450, 5451, 5452, 5453, 5454, 5455, 5456, 5457, 5458, 5459, 5460, 5461, 5545, 5546, 5547, 5548, 5549, 5550, 5551, 5552, 5553, 5554, 5555, 5556, 5557, 5558, 5559, 5560, 5561, 5562, 5563, 5564, 5565, 5566, 5567, 5568, 5569, 5570, 5571, 5572,
            5573, 5574, 5575, 5576, 5577, 5578, 5579, 5580, 5581, 5582, 5583, 5584, 5585, 5586, 5587, 5588, 5589, 5673, 5674, 5675, 5676, 5677, 5678, 5679, 5680, 5681, 5682, 5683, 5684, 5685, 5686, 5687, 5688, 5689, 5690, 5691, 5692, 5693, 5694, 5695, 5696, 5697, 5698, 5699, 5700, 5701, 5702, 5703, 5704, 5705, 5706, 5707, 5708, 5709, 5710, 5711, 5712, 5713, 5714, 5715, 5716, 5717, 5801, 5802,
            5803, 5804, 5805, 5806, 5807, 5808, 5809, 5810, 5811, 5812, 5813, 5814, 5815, 5816, 5817, 5818, 5819, 5820, 5821, 5822, 5823, 5824, 5825, 5826, 5827, 5828, 5829, 5830, 5831, 5832, 5833, 5834, 5835, 5836, 5837, 5838, 5839, 5840, 5841, 5842, 5843, 5844, 5845, 5929, 5930, 5931, 5932, 5933, 5934, 5935, 5936, 5937, 5938, 5939, 5940, 5941, 5942, 5943, 5944, 5945, 5946, 5947, 5948, 5949,
            5950, 5951, 5952, 5953, 5954, 5955, 5956, 5957, 5958, 5959, 5960, 5961, 5962, 5963, 5964, 5965, 5966, 5967, 5968, 5969, 5970, 5971, 5972, 5973, 6057, 6058, 6059, 6060, 6061, 6062, 6063, 6064, 6065, 6066, 6067, 6068, 6069, 6070, 6071, 6072, 6073, 6074, 6075, 6076, 6077, 6078, 6079, 6080, 6081, 6082, 6083, 6084, 6085, 6086, 6087, 6088, 6089, 6090, 6091, 6092, 6093, 6094, 6095, 6096,
            6097, 6098, 6099, 6100, 6101, 6185, 6186, 6187, 6188, 6189, 6190, 6191, 6192, 6193, 6194, 6195, 6196, 6197, 6198, 6199, 6200, 6201, 6202, 6203, 6204, 6205, 6206, 6207, 6208, 6209, 6210, 6211, 6212, 6213, 6214, 6215, 6216, 6217, 6218, 6219, 6220, 6221, 6222, 6223, 6224, 6225, 6226, 6227, 6228, 6229, 6313, 6314, 6315, 6316, 6317, 6318, 6319, 6320, 6321, 6322, 6323, 6324, 6325, 6326,
            6327, 6328, 6329, 6330, 6331, 6332, 6333, 6334, 6335, 6336, 6337, 6338, 6339, 6340, 6341, 6342, 6343, 6344, 6345, 6346, 6347, 6348, 6349, 6350, 6351, 6352, 6353, 6354, 6355, 6356, 6357, 6441, 6442, 6443, 6444, 6445, 6446, 6447, 6448, 6449, 6450, 6451, 6452, 6453, 6454, 6455, 6456, 6457, 6458, 6459, 6460, 6461, 6462, 6463, 6464, 6465, 6466, 6467, 6468, 6469, 6470, 6471, 6472, 6473,
            6474, 6475, 6476, 6477, 6478, 6479, 6480, 6481, 6482, 6483, 6484, 6485, 6569, 6570, 6571, 6572, 6573, 6574, 6575, 6576, 6577, 6578, 6579, 6580, 6581, 6582, 6583, 6584, 6585, 6586, 6587, 6588, 6589, 6590, 6591, 6592, 6593, 6594, 6595, 6596, 6597, 6598, 6599, 6600, 6601, 6602, 6603, 6604, 6605, 6606, 6607, 6608, 6609, 6610, 6611, 6612, 6613, 6697, 6698, 6699, 6700, 6701, 6702, 6703,
            6704, 6705, 6706, 6707, 6708, 6709, 6710, 6711, 6712, 6713, 6714, 6715, 6716, 6717, 6718, 6719, 6720, 6721, 6722, 6723, 6724, 6725, 6726, 6727, 6728, 6729, 6730, 6731, 6732, 6733, 6734, 6735, 6736, 6737, 6738, 6739, 6740, 6741, 6825, 6826, 6827, 6828, 6829, 6830, 6831, 6832, 6833, 6834, 6835, 6836, 6837, 6838, 6839, 6840, 6841, 6842, 6843, 6844, 6845, 6846, 6847, 6848, 6849, 6850,
            6851, 6852, 6853, 6854, 6855, 6856, 6857, 6858, 6859, 6860, 6861, 6862, 6863, 6864, 6865, 6866, 6867, 6868, 6869, 6953, 6954, 6955, 6956, 6957, 6958, 6959, 6960, 6961, 6962, 6963, 6964, 6965, 6966, 6967, 6968, 6969, 6970, 6971, 6972, 6973, 6974, 6975, 6976, 6977, 6978, 6979, 6980, 6981, 6982, 6983, 6984, 6985, 6986, 6987, 6988, 6989, 6990, 6991, 6992, 6993, 6994, 6995, 6996, 6997,
          ];
          break;
        case '2': // flecha abajo
          ledArray = [
            1193, 1194, 1195, 1196, 1197, 1198, 1199, 1200, 1201, 1202, 1203, 1204, 1205, 1206, 1207, 1208, 1209, 1210, 1211, 1212, 1213, 1214, 1215, 1216, 1217, 1218, 1219, 1220, 1221, 1222, 1223, 1224, 1225, 1226, 1227, 1228, 1229, 1230, 1231, 1232, 1233, 1234, 1235, 1236, 1237, 1321, 1322, 1323, 1324, 1325, 1326, 1327, 1328, 1329, 1330, 1331, 1332, 1333, 1334, 1335, 1336, 1337, 1338, 1339,
            1340, 1341, 1342, 1343, 1344, 1345, 1346, 1347, 1348, 1349, 1350, 1351, 1352, 1353, 1354, 1355, 1356, 1357, 1358, 1359, 1360, 1361, 1362, 1363, 1364, 1365, 1449, 1450, 1451, 1452, 1453, 1454, 1455, 1456, 1457, 1458, 1459, 1460, 1461, 1462, 1463, 1464, 1465, 1466, 1467, 1468, 1469, 1470, 1471, 1472, 1473, 1474, 1475, 1476, 1477, 1478, 1479, 1480, 1481, 1482, 1483, 1484, 1485, 1486,
            1487, 1488, 1489, 1490, 1491, 1492, 1493, 1577, 1578, 1579, 1580, 1581, 1582, 1583, 1584, 1585, 1586, 1587, 1588, 1589, 1590, 1591, 1592, 1593, 1594, 1595, 1596, 1597, 1598, 1599, 1600, 1601, 1602, 1603, 1604, 1605, 1606, 1607, 1608, 1609, 1610, 1611, 1612, 1613, 1614, 1615, 1616, 1617, 1618, 1619, 1620, 1621, 1705, 1706, 1707, 1708, 1709, 1710, 1711, 1712, 1713, 1714, 1715, 1716,
            1717, 1718, 1719, 1720, 1721, 1722, 1723, 1724, 1725, 1726, 1727, 1728, 1729, 1730, 1731, 1732, 1733, 1734, 1735, 1736, 1737, 1738, 1739, 1740, 1741, 1742, 1743, 1744, 1745, 1746, 1747, 1748, 1749, 1833, 1834, 1835, 1836, 1837, 1838, 1839, 1840, 1841, 1842, 1843, 1844, 1845, 1846, 1847, 1848, 1849, 1850, 1851, 1852, 1853, 1854, 1855, 1856, 1857, 1858, 1859, 1860, 1861, 1862, 1863,
            1864, 1865, 1866, 1867, 1868, 1869, 1870, 1871, 1872, 1873, 1874, 1875, 1876, 1877, 1961, 1962, 1963, 1964, 1965, 1966, 1967, 1968, 1969, 1970, 1971, 1972, 1973, 1974, 1975, 1976, 1977, 1978, 1979, 1980, 1981, 1982, 1983, 1984, 1985, 1986, 1987, 1988, 1989, 1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2089, 2090, 2091, 2092, 2093,
            2094, 2095, 2096, 2097, 2098, 2099, 2100, 2101, 2102, 2103, 2104, 2105, 2106, 2107, 2108, 2109, 2110, 2111, 2112, 2113, 2114, 2115, 2116, 2117, 2118, 2119, 2120, 2121, 2122, 2123, 2124, 2125, 2126, 2127, 2128, 2129, 2130, 2131, 2132, 2133, 2217, 2218, 2219, 2220, 2221, 2222, 2223, 2224, 2225, 2226, 2227, 2228, 2229, 2230, 2231, 2232, 2233, 2234, 2235, 2236, 2237, 2238, 2239, 2240,
            2241, 2242, 2243, 2244, 2245, 2246, 2247, 2248, 2249, 2250, 2251, 2252, 2253, 2254, 2255, 2256, 2257, 2258, 2259, 2260, 2261, 2345, 2346, 2347, 2348, 2349, 2350, 2351, 2352, 2353, 2354, 2355, 2356, 2357, 2358, 2359, 2360, 2361, 2362, 2363, 2364, 2365, 2366, 2367, 2368, 2369, 2370, 2371, 2372, 2373, 2374, 2375, 2376, 2377, 2378, 2379, 2380, 2381, 2382, 2383, 2384, 2385, 2386, 2387,
            2388, 2389, 2473, 2474, 2475, 2476, 2477, 2478, 2479, 2480, 2481, 2482, 2483, 2484, 2485, 2486, 2487, 2488, 2489, 2490, 2491, 2492, 2493, 2494, 2495, 2496, 2497, 2498, 2499, 2500, 2501, 2502, 2503, 2504, 2505, 2506, 2507, 2508, 2509, 2510, 2511, 2512, 2513, 2514, 2515, 2516, 2517, 2601, 2602, 2603, 2604, 2605, 2606, 2607, 2608, 2609, 2610, 2611, 2612, 2613, 2614, 2615, 2616, 2617,
            2618, 2619, 2620, 2621, 2622, 2623, 2624, 2625, 2626, 2627, 2628, 2629, 2630, 2631, 2632, 2633, 2634, 2635, 2636, 2637, 2638, 2639, 2640, 2641, 2642, 2643, 2644, 2645, 2729, 2730, 2731, 2732, 2733, 2734, 2735, 2736, 2737, 2738, 2739, 2740, 2741, 2742, 2743, 2744, 2745, 2746, 2747, 2748, 2749, 2750, 2751, 2752, 2753, 2754, 2755, 2756, 2757, 2758, 2759, 2760, 2761, 2762, 2763, 2764,
            2765, 2766, 2767, 2768, 2769, 2770, 2771, 2772, 2773, 2857, 2858, 2859, 2860, 2861, 2862, 2863, 2864, 2865, 2866, 2867, 2868, 2869, 2870, 2871, 2872, 2873, 2874, 2875, 2876, 2877, 2878, 2879, 2880, 2881, 2882, 2883, 2884, 2885, 2886, 2887, 2888, 2889, 2890, 2891, 2892, 2893, 2894, 2895, 2896, 2897, 2898, 2899, 2900, 2901, 2985, 2986, 2987, 2988, 2989, 2990, 2991, 2992, 2993, 2994,
            2995, 2996, 2997, 2998, 2999, 3000, 3001, 3002, 3003, 3004, 3005, 3006, 3007, 3008, 3009, 3010, 3011, 3012, 3013, 3014, 3015, 3016, 3017, 3018, 3019, 3020, 3021, 3022, 3023, 3024, 3025, 3026, 3027, 3028, 3029, 3113, 3114, 3115, 3116, 3117, 3118, 3119, 3120, 3121, 3122, 3123, 3124, 3125, 3126, 3127, 3128, 3129, 3130, 3131, 3132, 3133, 3134, 3135, 3136, 3137, 3138, 3139, 3140, 3141,
            3142, 3143, 3144, 3145, 3146, 3147, 3148, 3149, 3150, 3151, 3152, 3153, 3154, 3155, 3156, 3157, 3241, 3242, 3243, 3244, 3245, 3246, 3247, 3248, 3249, 3250, 3251, 3252, 3253, 3254, 3255, 3256, 3257, 3258, 3259, 3260, 3261, 3262, 3263, 3264, 3265, 3266, 3267, 3268, 3269, 3270, 3271, 3272, 3273, 3274, 3275, 3276, 3277, 3278, 3279, 3280, 3281, 3282, 3283, 3284, 3285, 3369, 3370, 3371,
            3372, 3373, 3374, 3375, 3376, 3377, 3378, 3379, 3380, 3381, 3382, 3383, 3384, 3385, 3386, 3387, 3388, 3389, 3390, 3391, 3392, 3393, 3394, 3395, 3396, 3397, 3398, 3399, 3400, 3401, 3402, 3403, 3404, 3405, 3406, 3407, 3408, 3409, 3410, 3411, 3412, 3413, 3497, 3498, 3499, 3500, 3501, 3502, 3503, 3504, 3505, 3506, 3507, 3508, 3509, 3510, 3511, 3512, 3513, 3514, 3515, 3516, 3517, 3518,
            3519, 3520, 3521, 3522, 3523, 3524, 3525, 3526, 3527, 3528, 3529, 3530, 3531, 3532, 3533, 3534, 3535, 3536, 3537, 3538, 3539, 3540, 3541, 3625, 3626, 3627, 3628, 3629, 3630, 3631, 3632, 3633, 3634, 3635, 3636, 3637, 3638, 3639, 3640, 3641, 3642, 3643, 3644, 3645, 3646, 3647, 3648, 3649, 3650, 3651, 3652, 3653, 3654, 3655, 3656, 3657, 3658, 3659, 3660, 3661, 3662, 3663, 3664, 3665,
            3666, 3667, 3668, 3669, 3753, 3754, 3755, 3756, 3757, 3758, 3759, 3760, 3761, 3762, 3763, 3764, 3765, 3766, 3767, 3768, 3769, 3770, 3771, 3772, 3773, 3774, 3775, 3776, 3777, 3778, 3779, 3780, 3781, 3782, 3783, 3784, 3785, 3786, 3787, 3788, 3789, 3790, 3791, 3792, 3793, 3794, 3795, 3796, 3797, 3881, 3882, 3883, 3884, 3885, 3886, 3887, 3888, 3889, 3890, 3891, 3892, 3893, 3894, 3895,
            3896, 3897, 3898, 3899, 3900, 3901, 3902, 3903, 3904, 3905, 3906, 3907, 3908, 3909, 3910, 3911, 3912, 3913, 3914, 3915, 3916, 3917, 3918, 3919, 3920, 3921, 3922, 3923, 3924, 3925, 4009, 4010, 4011, 4012, 4013, 4014, 4015, 4016, 4017, 4018, 4019, 4020, 4021, 4022, 4023, 4024, 4025, 4026, 4027, 4028, 4029, 4030, 4031, 4032, 4033, 4034, 4035, 4036, 4037, 4038, 4039, 4040, 4041, 4042,
            4043, 4044, 4045, 4046, 4047, 4048, 4049, 4050, 4051, 4052, 4053, 4137, 4138, 4139, 4140, 4141, 4142, 4143, 4144, 4145, 4146, 4147, 4148, 4149, 4150, 4151, 4152, 4153, 4154, 4155, 4156, 4157, 4158, 4159, 4160, 4161, 4162, 4163, 4164, 4165, 4166, 4167, 4168, 4169, 4170, 4171, 4172, 4173, 4174, 4175, 4176, 4177, 4178, 4179, 4180, 4181, 4265, 4266, 4267, 4268, 4269, 4270, 4271, 4272,
            4273, 4274, 4275, 4276, 4277, 4278, 4279, 4280, 4281, 4282, 4283, 4284, 4285, 4286, 4287, 4288, 4289, 4290, 4291, 4292, 4293, 4294, 4295, 4296, 4297, 4298, 4299, 4300, 4301, 4302, 4303, 4304, 4305, 4306, 4307, 4308, 4309, 4393, 4394, 4395, 4396, 4397, 4398, 4399, 4400, 4401, 4402, 4403, 4404, 4405, 4406, 4407, 4408, 4409, 4410, 4411, 4412, 4413, 4414, 4415, 4416, 4417, 4418, 4419,
            4420, 4421, 4422, 4423, 4424, 4425, 4426, 4427, 4428, 4429, 4430, 4431, 4432, 4433, 4434, 4435, 4436, 4437, 4521, 4522, 4523, 4524, 4525, 4526, 4527, 4528, 4529, 4530, 4531, 4532, 4533, 4534, 4535, 4536, 4537, 4538, 4539, 4540, 4541, 4542, 4543, 4544, 4545, 4546, 4547, 4548, 4549, 4550, 4551, 4552, 4553, 4554, 4555, 4556, 4557, 4558, 4559, 4560, 4561, 4562, 4563, 4564, 4565, 4649,
            4650, 4651, 4652, 4653, 4654, 4655, 4656, 4657, 4658, 4659, 4660, 4661, 4662, 4663, 4664, 4665, 4666, 4667, 4668, 4669, 4670, 4671, 4672, 4673, 4674, 4675, 4676, 4677, 4678, 4679, 4680, 4681, 4682, 4683, 4684, 4685, 4686, 4687, 4688, 4689, 4690, 4691, 4692, 4693, 4761, 4762, 4763, 4764, 4765, 4766, 4767, 4768, 4769, 4770, 4771, 4772, 4773, 4774, 4775, 4776, 4777, 4778, 4779, 4780,
            4781, 4782, 4783, 4784, 4785, 4786, 4787, 4788, 4789, 4790, 4791, 4792, 4793, 4794, 4795, 4796, 4797, 4798, 4799, 4800, 4801, 4802, 4803, 4804, 4805, 4806, 4807, 4808, 4809, 4810, 4811, 4812, 4813, 4814, 4815, 4816, 4817, 4818, 4819, 4820, 4821, 4822, 4823, 4824, 4825, 4826, 4827, 4828, 4829, 4830, 4831, 4832, 4833, 4834, 4835, 4836, 4837, 4838, 4891, 4892, 4893, 4894, 4895, 4896,
            4897, 4898, 4899, 4900, 4901, 4902, 4903, 4904, 4905, 4906, 4907, 4908, 4909, 4910, 4911, 4912, 4913, 4914, 4915, 4916, 4917, 4918, 4919, 4920, 4921, 4922, 4923, 4924, 4925, 4926, 4927, 4928, 4929, 4930, 4931, 4932, 4933, 4934, 4935, 4936, 4937, 4938, 4939, 4940, 4941, 4942, 4943, 4944, 4945, 4946, 4947, 4948, 4949, 4950, 4951, 4952, 4953, 4954, 4955, 4956, 4957, 4958, 4959, 4960,
            4961, 4962, 4963, 4964, 5021, 5022, 5023, 5024, 5025, 5026, 5027, 5028, 5029, 5030, 5031, 5032, 5033, 5034, 5035, 5036, 5037, 5038, 5039, 5040, 5041, 5042, 5043, 5044, 5045, 5046, 5047, 5048, 5049, 5050, 5051, 5052, 5053, 5054, 5055, 5056, 5057, 5058, 5059, 5060, 5061, 5062, 5063, 5064, 5065, 5066, 5067, 5068, 5069, 5070, 5071, 5072, 5073, 5074, 5075, 5076, 5077, 5078, 5079, 5080,
            5081, 5082, 5083, 5084, 5085, 5086, 5087, 5088, 5089, 5090, 5151, 5152, 5153, 5154, 5155, 5156, 5157, 5158, 5159, 5160, 5161, 5162, 5163, 5164, 5165, 5166, 5167, 5168, 5169, 5170, 5171, 5172, 5173, 5174, 5175, 5176, 5177, 5178, 5179, 5180, 5181, 5182, 5183, 5184, 5185, 5186, 5187, 5188, 5189, 5190, 5191, 5192, 5193, 5194, 5195, 5196, 5197, 5198, 5199, 5200, 5201, 5202, 5203, 5204,
            5205, 5206, 5207, 5208, 5209, 5210, 5211, 5212, 5213, 5214, 5215, 5282, 5283, 5284, 5285, 5286, 5287, 5288, 5289, 5290, 5291, 5292, 5293, 5294, 5295, 5296, 5297, 5298, 5299, 5300, 5301, 5302, 5303, 5304, 5305, 5306, 5307, 5308, 5309, 5310, 5311, 5312, 5313, 5314, 5315, 5316, 5317, 5318, 5319, 5320, 5321, 5322, 5323, 5324, 5325, 5326, 5327, 5328, 5329, 5330, 5331, 5332, 5333, 5334,
            5335, 5336, 5337, 5338, 5339, 5340, 5341, 5412, 5413, 5414, 5415, 5416, 5417, 5418, 5419, 5420, 5421, 5422, 5423, 5424, 5425, 5426, 5427, 5428, 5429, 5430, 5431, 5432, 5433, 5434, 5435, 5436, 5437, 5438, 5439, 5440, 5441, 5442, 5443, 5444, 5445, 5446, 5447, 5448, 5449, 5450, 5451, 5452, 5453, 5454, 5455, 5456, 5457, 5458, 5459, 5460, 5461, 5462, 5463, 5464, 5465, 5466, 5467, 5542,
            5543, 5544, 5545, 5546, 5547, 5548, 5549, 5550, 5551, 5552, 5553, 5554, 5555, 5556, 5557, 5558, 5559, 5560, 5561, 5562, 5563, 5564, 5565, 5566, 5567, 5568, 5569, 5570, 5571, 5572, 5573, 5574, 5575, 5576, 5577, 5578, 5579, 5580, 5581, 5582, 5583, 5584, 5585, 5586, 5587, 5588, 5589, 5590, 5591, 5592, 5593, 5672, 5673, 5674, 5675, 5676, 5677, 5678, 5679, 5680, 5681, 5682, 5683, 5684,
            5685, 5686, 5687, 5688, 5689, 5690, 5691, 5692, 5693, 5694, 5695, 5696, 5697, 5698, 5699, 5700, 5701, 5702, 5703, 5704, 5705, 5706, 5707, 5708, 5709, 5710, 5711, 5712, 5713, 5714, 5715, 5716, 5717, 5718, 5803, 5804, 5805, 5806, 5807, 5808, 5809, 5810, 5811, 5812, 5813, 5814, 5815, 5816, 5817, 5818, 5819, 5820, 5821, 5822, 5823, 5824, 5825, 5826, 5827, 5828, 5829, 5830, 5831, 5832,
            5833, 5834, 5835, 5836, 5837, 5838, 5839, 5840, 5841, 5842, 5843, 5844, 5933, 5934, 5935, 5936, 5937, 5938, 5939, 5940, 5941, 5942, 5943, 5944, 5945, 5946, 5947, 5948, 5949, 5950, 5951, 5952, 5953, 5954, 5955, 5956, 5957, 5958, 5959, 5960, 5961, 5962, 5963, 5964, 5965, 5966, 5967, 5968, 5969, 5970, 6063, 6064, 6065, 6066, 6067, 6068, 6069, 6070, 6071, 6072, 6073, 6074, 6075, 6076,
            6077, 6078, 6079, 6080, 6081, 6082, 6083, 6084, 6085, 6086, 6087, 6088, 6089, 6090, 6091, 6092, 6093, 6094, 6095, 6096, 6193, 6194, 6195, 6196, 6197, 6198, 6199, 6200, 6201, 6202, 6203, 6204, 6205, 6206, 6207, 6208, 6209, 6210, 6211, 6212, 6213, 6214, 6215, 6216, 6217, 6218, 6219, 6220, 6221, 6324, 6325, 6326, 6327, 6328, 6329, 6330, 6331, 6332, 6333, 6334, 6335, 6336, 6337, 6338,
            6339, 6340, 6341, 6342, 6343, 6344, 6345, 6346, 6347, 6454, 6455, 6456, 6457, 6458, 6459, 6460, 6461, 6462, 6463, 6464, 6465, 6466, 6467, 6468, 6469, 6470, 6471, 6472, 6473, 6584, 6585, 6586, 6587, 6588, 6589, 6590, 6591, 6592, 6593, 6594, 6595, 6596, 6597, 6598, 6599, 6714, 6715, 6716, 6717, 6718, 6719, 6720, 6721, 6722, 6723, 6724, 6845, 6846, 6847, 6848, 6849, 6850, 6975, 6976,
          ];
          break;
        case '3': // flecha izquierda
          ledArray = [
            1331, 1457, 1458, 1459, 1584, 1585, 1586, 1587, 1710, 1711, 1712, 1713, 1714, 1715, 1836, 1837, 1838, 1839, 1840, 1841, 1842, 1843, 1962, 1963, 1964, 1965, 1966, 1967, 1968, 1969, 1970, 1971, 2089, 2090, 2091, 2092, 2093, 2094, 2095, 2096, 2097, 2098, 2099, 2215, 2216, 2217, 2218, 2219, 2220, 2221, 2222, 2223, 2224, 2225, 2226, 2227, 2341, 2342, 2343, 2344, 2345, 2346, 2347, 2348,
            2349, 2350, 2351, 2352, 2353, 2354, 2355, 2468, 2469, 2470, 2471, 2472, 2473, 2474, 2475, 2476, 2477, 2478, 2479, 2480, 2481, 2482, 2483, 2594, 2595, 2596, 2597, 2598, 2599, 2600, 2601, 2602, 2603, 2604, 2605, 2606, 2607, 2608, 2609, 2610, 2611, 2612, 2613, 2614, 2615, 2616, 2617, 2618, 2619, 2620, 2621, 2622, 2623, 2624, 2625, 2626, 2627, 2628, 2629, 2630, 2631, 2632, 2633, 2634,
            2635, 2636, 2637, 2638, 2639, 2640, 2641, 2642, 2643, 2644, 2645, 2646, 2647, 2648, 2649, 2650, 2651, 2652, 2653, 2654, 2655, 2656, 2657, 2658, 2659, 2660, 2661, 2662, 2663, 2664, 2665, 2666, 2667, 2668, 2669, 2670, 2671, 2672, 2673, 2674, 2720, 2721, 2722, 2723, 2724, 2725, 2726, 2727, 2728, 2729, 2730, 2731, 2732, 2733, 2734, 2735, 2736, 2737, 2738, 2739, 2740, 2741, 2742, 2743,
            2744, 2745, 2746, 2747, 2748, 2749, 2750, 2751, 2752, 2753, 2754, 2755, 2756, 2757, 2758, 2759, 2760, 2761, 2762, 2763, 2764, 2765, 2766, 2767, 2768, 2769, 2770, 2771, 2772, 2773, 2774, 2775, 2776, 2777, 2778, 2779, 2780, 2781, 2782, 2783, 2784, 2785, 2786, 2787, 2788, 2789, 2790, 2791, 2792, 2793, 2794, 2795, 2796, 2797, 2798, 2799, 2800, 2801, 2802, 2846, 2847, 2848, 2849, 2850,
            2851, 2852, 2853, 2854, 2855, 2856, 2857, 2858, 2859, 2860, 2861, 2862, 2863, 2864, 2865, 2866, 2867, 2868, 2869, 2870, 2871, 2872, 2873, 2874, 2875, 2876, 2877, 2878, 2879, 2880, 2881, 2882, 2883, 2884, 2885, 2886, 2887, 2888, 2889, 2890, 2891, 2892, 2893, 2894, 2895, 2896, 2897, 2898, 2899, 2900, 2901, 2902, 2903, 2904, 2905, 2906, 2907, 2908, 2909, 2910, 2911, 2912, 2913, 2914,
            2915, 2916, 2917, 2918, 2919, 2920, 2921, 2922, 2923, 2924, 2925, 2926, 2927, 2928, 2929, 2930, 2973, 2974, 2975, 2976, 2977, 2978, 2979, 2980, 2981, 2982, 2983, 2984, 2985, 2986, 2987, 2988, 2989, 2990, 2991, 2992, 2993, 2994, 2995, 2996, 2997, 2998, 2999, 3000, 3001, 3002, 3003, 3004, 3005, 3006, 3007, 3008, 3009, 3010, 3011, 3012, 3013, 3014, 3015, 3016, 3017, 3018, 3019, 3020,
            3021, 3022, 3023, 3024, 3025, 3026, 3027, 3028, 3029, 3030, 3031, 3032, 3033, 3034, 3035, 3036, 3037, 3038, 3039, 3040, 3041, 3042, 3043, 3044, 3045, 3046, 3047, 3048, 3049, 3050, 3051, 3052, 3053, 3054, 3055, 3056, 3057, 3058, 3099, 3100, 3101, 3102, 3103, 3104, 3105, 3106, 3107, 3108, 3109, 3110, 3111, 3112, 3113, 3114, 3115, 3116, 3117, 3118, 3119, 3120, 3121, 3122, 3123, 3124,
            3125, 3126, 3127, 3128, 3129, 3130, 3131, 3132, 3133, 3134, 3135, 3136, 3137, 3138, 3139, 3140, 3141, 3142, 3143, 3144, 3145, 3146, 3147, 3148, 3149, 3150, 3151, 3152, 3153, 3154, 3155, 3156, 3157, 3158, 3159, 3160, 3161, 3162, 3163, 3164, 3165, 3166, 3167, 3168, 3169, 3170, 3171, 3172, 3173, 3174, 3175, 3176, 3177, 3178, 3179, 3180, 3181, 3182, 3183, 3184, 3185, 3186, 3225, 3226,
            3227, 3228, 3229, 3230, 3231, 3232, 3233, 3234, 3235, 3236, 3237, 3238, 3239, 3240, 3241, 3242, 3243, 3244, 3245, 3246, 3247, 3248, 3249, 3250, 3251, 3252, 3253, 3254, 3255, 3256, 3257, 3258, 3259, 3260, 3261, 3262, 3263, 3264, 3265, 3266, 3267, 3268, 3269, 3270, 3271, 3272, 3273, 3274, 3275, 3276, 3277, 3278, 3279, 3280, 3281, 3282, 3283, 3284, 3285, 3286, 3287, 3288, 3289, 3290,
            3291, 3292, 3293, 3294, 3295, 3296, 3297, 3298, 3299, 3300, 3301, 3302, 3303, 3304, 3305, 3306, 3307, 3308, 3309, 3310, 3311, 3312, 3313, 3314, 3351, 3352, 3353, 3354, 3355, 3356, 3357, 3358, 3359, 3360, 3361, 3362, 3363, 3364, 3365, 3366, 3367, 3368, 3369, 3370, 3371, 3372, 3373, 3374, 3375, 3376, 3377, 3378, 3379, 3380, 3381, 3382, 3383, 3384, 3385, 3386, 3387, 3388, 3389, 3390,
            3391, 3392, 3393, 3394, 3395, 3396, 3397, 3398, 3399, 3400, 3401, 3402, 3403, 3404, 3405, 3406, 3407, 3408, 3409, 3410, 3411, 3412, 3413, 3414, 3415, 3416, 3417, 3418, 3419, 3420, 3421, 3422, 3423, 3424, 3425, 3426, 3427, 3428, 3429, 3430, 3431, 3432, 3433, 3434, 3435, 3436, 3437, 3438, 3439, 3440, 3441, 3442, 3478, 3479, 3480, 3481, 3482, 3483, 3484, 3485, 3486, 3487, 3488, 3489,
            3490, 3491, 3492, 3493, 3494, 3495, 3496, 3497, 3498, 3499, 3500, 3501, 3502, 3503, 3504, 3505, 3506, 3507, 3508, 3509, 3510, 3511, 3512, 3513, 3514, 3515, 3516, 3517, 3518, 3519, 3520, 3521, 3522, 3523, 3524, 3525, 3526, 3527, 3528, 3529, 3530, 3531, 3532, 3533, 3534, 3535, 3536, 3537, 3538, 3539, 3540, 3541, 3542, 3543, 3544, 3545, 3546, 3547, 3548, 3549, 3550, 3551, 3552, 3553,
            3554, 3555, 3556, 3557, 3558, 3559, 3560, 3561, 3562, 3563, 3564, 3565, 3566, 3567, 3568, 3569, 3570, 3604, 3605, 3606, 3607, 3608, 3609, 3610, 3611, 3612, 3613, 3614, 3615, 3616, 3617, 3618, 3619, 3620, 3621, 3622, 3623, 3624, 3625, 3626, 3627, 3628, 3629, 3630, 3631, 3632, 3633, 3634, 3635, 3636, 3637, 3638, 3639, 3640, 3641, 3642, 3643, 3644, 3645, 3646, 3647, 3648, 3649, 3650,
            3651, 3652, 3653, 3654, 3655, 3656, 3657, 3658, 3659, 3660, 3661, 3662, 3663, 3664, 3665, 3666, 3667, 3668, 3669, 3670, 3671, 3672, 3673, 3674, 3675, 3676, 3677, 3678, 3679, 3680, 3681, 3682, 3683, 3684, 3685, 3686, 3687, 3688, 3689, 3690, 3691, 3692, 3693, 3694, 3695, 3696, 3697, 3698, 3730, 3731, 3732, 3733, 3734, 3735, 3736, 3737, 3738, 3739, 3740, 3741, 3742, 3743, 3744, 3745,
            3746, 3747, 3748, 3749, 3750, 3751, 3752, 3753, 3754, 3755, 3756, 3757, 3758, 3759, 3760, 3761, 3762, 3763, 3764, 3765, 3766, 3767, 3768, 3769, 3770, 3771, 3772, 3773, 3774, 3775, 3776, 3777, 3778, 3779, 3780, 3781, 3782, 3783, 3784, 3785, 3786, 3787, 3788, 3789, 3790, 3791, 3792, 3793, 3794, 3795, 3796, 3797, 3798, 3799, 3800, 3801, 3802, 3803, 3804, 3805, 3806, 3807, 3808, 3809,
            3810, 3811, 3812, 3813, 3814, 3815, 3816, 3817, 3818, 3819, 3820, 3821, 3822, 3823, 3824, 3825, 3826, 3856, 3857, 3858, 3859, 3860, 3861, 3862, 3863, 3864, 3865, 3866, 3867, 3868, 3869, 3870, 3871, 3872, 3873, 3874, 3875, 3876, 3877, 3878, 3879, 3880, 3881, 3882, 3883, 3884, 3885, 3886, 3887, 3888, 3889, 3890, 3891, 3892, 3893, 3894, 3895, 3896, 3897, 3898, 3899, 3900, 3901, 3902,
            3903, 3904, 3905, 3906, 3907, 3908, 3909, 3910, 3911, 3912, 3913, 3914, 3915, 3916, 3917, 3918, 3919, 3920, 3921, 3922, 3923, 3924, 3925, 3926, 3927, 3928, 3929, 3930, 3931, 3932, 3933, 3934, 3935, 3936, 3937, 3938, 3939, 3940, 3941, 3942, 3943, 3944, 3945, 3946, 3947, 3948, 3949, 3950, 3951, 3952, 3953, 3954, 3983, 3984, 3985, 3986, 3987, 3988, 3989, 3990, 3991, 3992, 3993, 3994,
            3995, 3996, 3997, 3998, 3999, 4000, 4001, 4002, 4003, 4004, 4005, 4006, 4007, 4008, 4009, 4010, 4011, 4012, 4013, 4014, 4015, 4016, 4017, 4018, 4019, 4020, 4021, 4022, 4023, 4024, 4025, 4026, 4027, 4028, 4029, 4030, 4031, 4032, 4033, 4034, 4035, 4036, 4037, 4038, 4039, 4040, 4041, 4042, 4043, 4044, 4045, 4046, 4047, 4048, 4049, 4050, 4051, 4052, 4053, 4054, 4055, 4056, 4057, 4058,
            4059, 4060, 4061, 4062, 4063, 4064, 4065, 4066, 4067, 4068, 4069, 4070, 4071, 4072, 4073, 4074, 4075, 4076, 4077, 4078, 4079, 4080, 4081, 4082, 4109, 4110, 4111, 4112, 4113, 4114, 4115, 4116, 4117, 4118, 4119, 4120, 4121, 4122, 4123, 4124, 4125, 4126, 4127, 4128, 4129, 4130, 4131, 4132, 4133, 4134, 4135, 4136, 4137, 4138, 4139, 4140, 4141, 4142, 4143, 4144, 4145, 4146, 4147, 4148,
            4149, 4150, 4151, 4152, 4153, 4154, 4155, 4156, 4157, 4158, 4159, 4160, 4161, 4162, 4163, 4164, 4165, 4166, 4167, 4168, 4169, 4170, 4171, 4172, 4173, 4174, 4175, 4176, 4177, 4178, 4179, 4180, 4181, 4182, 4183, 4184, 4185, 4186, 4187, 4188, 4189, 4190, 4191, 4192, 4193, 4194, 4195, 4196, 4197, 4198, 4199, 4200, 4201, 4202, 4203, 4204, 4205, 4206, 4207, 4208, 4209, 4210, 4238, 4239,
            4240, 4241, 4242, 4243, 4244, 4245, 4246, 4247, 4248, 4249, 4250, 4251, 4252, 4253, 4254, 4255, 4256, 4257, 4258, 4259, 4260, 4261, 4262, 4263, 4264, 4265, 4266, 4267, 4268, 4269, 4270, 4271, 4272, 4273, 4274, 4275, 4276, 4277, 4278, 4279, 4280, 4281, 4282, 4283, 4284, 4285, 4286, 4287, 4288, 4289, 4290, 4291, 4292, 4293, 4294, 4295, 4296, 4297, 4298, 4299, 4300, 4301, 4302, 4303,
            4304, 4305, 4306, 4307, 4308, 4309, 4310, 4311, 4312, 4313, 4314, 4315, 4316, 4317, 4318, 4319, 4320, 4321, 4322, 4323, 4324, 4325, 4326, 4327, 4328, 4329, 4330, 4331, 4332, 4333, 4334, 4335, 4336, 4337, 4338, 4367, 4368, 4369, 4370, 4371, 4372, 4373, 4374, 4375, 4376, 4377, 4378, 4379, 4380, 4381, 4382, 4383, 4384, 4385, 4386, 4387, 4388, 4389, 4390, 4391, 4392, 4393, 4394, 4395,
            4396, 4397, 4398, 4399, 4400, 4401, 4402, 4403, 4404, 4405, 4406, 4407, 4408, 4409, 4410, 4411, 4412, 4413, 4414, 4415, 4416, 4417, 4418, 4419, 4420, 4421, 4422, 4423, 4424, 4425, 4426, 4427, 4428, 4429, 4430, 4431, 4432, 4433, 4434, 4435, 4436, 4437, 4438, 4439, 4440, 4441, 4442, 4443, 4444, 4445, 4446, 4447, 4448, 4449, 4450, 4451, 4452, 4453, 4454, 4455, 4456, 4457, 4458, 4459,
            4460, 4461, 4462, 4463, 4464, 4465, 4466, 4497, 4498, 4499, 4500, 4501, 4502, 4503, 4504, 4505, 4506, 4507, 4508, 4509, 4510, 4511, 4512, 4513, 4514, 4515, 4516, 4517, 4518, 4519, 4520, 4521, 4522, 4523, 4524, 4525, 4526, 4527, 4528, 4529, 4530, 4531, 4532, 4533, 4534, 4535, 4536, 4537, 4538, 4539, 4540, 4541, 4542, 4543, 4544, 4545, 4546, 4547, 4548, 4549, 4550, 4551, 4552, 4553,
            4554, 4555, 4556, 4557, 4558, 4559, 4560, 4561, 4562, 4563, 4564, 4565, 4566, 4567, 4568, 4569, 4570, 4571, 4572, 4573, 4574, 4575, 4576, 4577, 4578, 4579, 4580, 4581, 4582, 4583, 4584, 4585, 4586, 4587, 4588, 4589, 4590, 4591, 4592, 4593, 4594, 4627, 4628, 4629, 4630, 4631, 4632, 4633, 4634, 4635, 4636, 4637, 4638, 4639, 4640, 4641, 4642, 4643, 4644, 4645, 4646, 4647, 4648, 4649,
            4650, 4651, 4652, 4653, 4654, 4655, 4656, 4657, 4658, 4659, 4660, 4661, 4662, 4663, 4664, 4665, 4666, 4667, 4668, 4669, 4670, 4671, 4672, 4673, 4674, 4675, 4676, 4677, 4678, 4679, 4680, 4681, 4682, 4683, 4684, 4685, 4686, 4687, 4688, 4689, 4690, 4691, 4692, 4693, 4694, 4695, 4696, 4697, 4698, 4699, 4700, 4701, 4702, 4703, 4704, 4705, 4706, 4707, 4708, 4709, 4710, 4711, 4712, 4713,
            4714, 4715, 4716, 4717, 4718, 4719, 4720, 4721, 4722, 4757, 4758, 4759, 4760, 4761, 4762, 4763, 4764, 4765, 4766, 4767, 4768, 4769, 4770, 4771, 4772, 4773, 4774, 4775, 4776, 4777, 4778, 4779, 4780, 4781, 4782, 4783, 4784, 4785, 4786, 4787, 4788, 4789, 4790, 4791, 4792, 4793, 4794, 4795, 4796, 4797, 4798, 4799, 4800, 4801, 4802, 4803, 4804, 4805, 4806, 4807, 4808, 4809, 4810, 4811,
            4812, 4813, 4814, 4815, 4816, 4817, 4818, 4819, 4820, 4821, 4822, 4823, 4824, 4825, 4826, 4827, 4828, 4829, 4830, 4831, 4832, 4833, 4834, 4835, 4836, 4837, 4838, 4839, 4840, 4841, 4842, 4843, 4844, 4845, 4846, 4847, 4848, 4849, 4850, 4886, 4887, 4888, 4889, 4890, 4891, 4892, 4893, 4894, 4895, 4896, 4897, 4898, 4899, 4900, 4901, 4902, 4903, 4904, 4905, 4906, 4907, 4908, 4909, 4910,
            4911, 4912, 4913, 4914, 4915, 4916, 4917, 4918, 4919, 4920, 4921, 4922, 4923, 4924, 4925, 4926, 4927, 4928, 4929, 4930, 4931, 4932, 4933, 4934, 4935, 4936, 4937, 4938, 4939, 4940, 4941, 4942, 4943, 4944, 4945, 4946, 4947, 4948, 4949, 4950, 4951, 4952, 4953, 4954, 4955, 4956, 4957, 4958, 4959, 4960, 4961, 4962, 4963, 4964, 4965, 4966, 4967, 4968, 4969, 4970, 4971, 4972, 4973, 4974,
            4975, 4976, 4977, 4978, 5016, 5017, 5018, 5019, 5020, 5021, 5022, 5023, 5024, 5025, 5026, 5027, 5028, 5029, 5030, 5031, 5032, 5033, 5034, 5035, 5036, 5037, 5038, 5039, 5040, 5041, 5042, 5043, 5044, 5045, 5046, 5047, 5048, 5049, 5050, 5051, 5052, 5053, 5054, 5055, 5056, 5057, 5058, 5059, 5060, 5061, 5062, 5063, 5064, 5065, 5066, 5067, 5068, 5069, 5070, 5071, 5072, 5073, 5074, 5075,
            5076, 5077, 5078, 5079, 5080, 5081, 5082, 5083, 5084, 5085, 5086, 5087, 5088, 5089, 5090, 5091, 5092, 5093, 5094, 5095, 5096, 5097, 5098, 5099, 5100, 5101, 5102, 5103, 5104, 5105, 5106, 5146, 5147, 5148, 5149, 5150, 5151, 5152, 5153, 5154, 5155, 5156, 5157, 5158, 5159, 5160, 5161, 5162, 5163, 5164, 5165, 5166, 5167, 5168, 5169, 5170, 5171, 5172, 5173, 5174, 5175, 5176, 5177, 5178,
            5179, 5180, 5181, 5182, 5183, 5184, 5185, 5186, 5187, 5188, 5189, 5190, 5191, 5192, 5193, 5194, 5195, 5196, 5197, 5198, 5199, 5200, 5201, 5202, 5203, 5204, 5205, 5206, 5207, 5208, 5209, 5210, 5211, 5212, 5213, 5214, 5215, 5216, 5217, 5218, 5219, 5220, 5221, 5222, 5223, 5224, 5225, 5226, 5227, 5228, 5229, 5230, 5231, 5232, 5233, 5234, 5275, 5276, 5277, 5278, 5279, 5280, 5281, 5282,
            5283, 5284, 5285, 5286, 5287, 5288, 5289, 5290, 5291, 5292, 5293, 5294, 5295, 5296, 5297, 5298, 5299, 5300, 5301, 5302, 5303, 5304, 5305, 5306, 5307, 5308, 5309, 5310, 5311, 5312, 5313, 5314, 5315, 5316, 5317, 5318, 5319, 5320, 5321, 5322, 5323, 5324, 5325, 5326, 5327, 5328, 5329, 5330, 5331, 5332, 5333, 5334, 5335, 5336, 5337, 5338, 5339, 5340, 5341, 5342, 5343, 5344, 5345, 5346,
            5347, 5348, 5349, 5350, 5351, 5352, 5353, 5354, 5355, 5356, 5357, 5358, 5359, 5360, 5361, 5362, 5405, 5406, 5407, 5408, 5409, 5410, 5411, 5412, 5413, 5414, 5415, 5416, 5417, 5418, 5419, 5420, 5421, 5422, 5423, 5424, 5425, 5426, 5427, 5428, 5429, 5430, 5431, 5432, 5433, 5434, 5435, 5436, 5437, 5438, 5439, 5440, 5441, 5442, 5443, 5444, 5445, 5446, 5447, 5448, 5449, 5450, 5451, 5452,
            5453, 5454, 5455, 5456, 5457, 5458, 5459, 5460, 5461, 5462, 5463, 5464, 5465, 5466, 5467, 5468, 5469, 5470, 5471, 5472, 5473, 5474, 5475, 5476, 5477, 5478, 5479, 5480, 5481, 5482, 5483, 5484, 5485, 5486, 5487, 5488, 5489, 5490, 5535, 5536, 5537, 5538, 5539, 5540, 5541, 5542, 5543, 5544, 5545, 5546, 5547, 5548, 5549, 5550, 5551, 5552, 5553, 5554, 5555, 5556, 5557, 5558, 5559, 5560,
            5561, 5562, 5563, 5564, 5565, 5566, 5567, 5568, 5569, 5570, 5571, 5572, 5573, 5574, 5575, 5576, 5577, 5578, 5579, 5580, 5581, 5582, 5583, 5584, 5585, 5586, 5587, 5588, 5589, 5590, 5591, 5592, 5593, 5594, 5595, 5596, 5597, 5598, 5599, 5600, 5601, 5602, 5603, 5604, 5605, 5606, 5607, 5608, 5609, 5610, 5611, 5612, 5613, 5614, 5615, 5616, 5617, 5618, 5665, 5666, 5667, 5668, 5669, 5670,
            5671, 5672, 5673, 5674, 5675, 5676, 5677, 5678, 5679, 5680, 5681, 5682, 5683, 5684, 5685, 5686, 5687, 5688, 5689, 5690, 5691, 5692, 5693, 5694, 5695, 5696, 5697, 5698, 5699, 5700, 5701, 5702, 5703, 5704, 5705, 5706, 5707, 5708, 5709, 5710, 5711, 5712, 5713, 5714, 5715, 5716, 5717, 5718, 5719, 5720, 5721, 5722, 5723, 5724, 5725, 5726, 5727, 5728, 5729, 5730, 5731, 5732, 5733, 5734,
            5735, 5736, 5737, 5738, 5739, 5740, 5741, 5742, 5743, 5744, 5745, 5746, 5794, 5795, 5796, 5797, 5798, 5799, 5800, 5801, 5802, 5803, 5804, 5805, 5806, 5807, 5808, 5809, 5810, 5811, 5812, 5813, 5814, 5815, 5816, 5817, 5818, 5819, 5820, 5821, 5822, 5823, 5824, 5825, 5826, 5827, 5828, 5829, 5830, 5831, 5832, 5833, 5834, 5835, 5836, 5837, 5838, 5839, 5840, 5841, 5842, 5843, 5844, 5845,
            5846, 5847, 5848, 5849, 5850, 5851, 5852, 5853, 5854, 5855, 5856, 5857, 5858, 5859, 5860, 5861, 5862, 5863, 5864, 5865, 5866, 5867, 5868, 5869, 5870, 5871, 5872, 5873, 5874, 5924, 5925, 5926, 5927, 5928, 5929, 5930, 5931, 5932, 5933, 5934, 5935, 5936, 5937, 5938, 5939, 6054, 6055, 6056, 6057, 6058, 6059, 6060, 6061, 6062, 6063, 6064, 6065, 6066, 6067, 6184, 6185, 6186, 6187, 6188,
            6189, 6190, 6191, 6192, 6193, 6194, 6195, 6313, 6314, 6315, 6316, 6317, 6318, 6319, 6320, 6321, 6322, 6323, 6443, 6444, 6445, 6446, 6447, 6448, 6449, 6450, 6451, 6573, 6574, 6575, 6576, 6577, 6578, 6579, 6703, 6704, 6705, 6706, 6707, 6832, 6833, 6834, 6835, 6962, 6963,
          ];
          break;
        case '4': // flecha derecha
          ledArray = [
            1356, 1357, 1484, 1485, 1486, 1487, 1612, 1613, 1614, 1615, 1616, 1740, 1741, 1742, 1743, 1744, 1745, 1746, 1868, 1869, 1870, 1871, 1872, 1873, 1874, 1875, 1876, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2124, 2125, 2126, 2127, 2128, 2129, 2130, 2131, 2132, 2133, 2134, 2135, 2252, 2253, 2254, 2255, 2256, 2257, 2258, 2259, 2260, 2261, 2262, 2263, 2264, 2265,
            2380, 2381, 2382, 2383, 2384, 2385, 2386, 2387, 2388, 2389, 2390, 2391, 2392, 2393, 2394, 2395, 2445, 2446, 2447, 2448, 2449, 2450, 2451, 2452, 2453, 2454, 2455, 2456, 2457, 2458, 2459, 2460, 2461, 2462, 2463, 2464, 2465, 2466, 2467, 2468, 2469, 2470, 2471, 2472, 2473, 2474, 2475, 2476, 2477, 2478, 2479, 2480, 2481, 2482, 2483, 2484, 2485, 2486, 2487, 2488, 2489, 2490, 2491, 2492,
            2493, 2494, 2495, 2496, 2497, 2498, 2499, 2500, 2501, 2502, 2503, 2504, 2505, 2506, 2507, 2508, 2509, 2510, 2511, 2512, 2513, 2514, 2515, 2516, 2517, 2518, 2519, 2520, 2521, 2522, 2523, 2524, 2525, 2573, 2574, 2575, 2576, 2577, 2578, 2579, 2580, 2581, 2582, 2583, 2584, 2585, 2586, 2587, 2588, 2589, 2590, 2591, 2592, 2593, 2594, 2595, 2596, 2597, 2598, 2599, 2600, 2601, 2602, 2603,
            2604, 2605, 2606, 2607, 2608, 2609, 2610, 2611, 2612, 2613, 2614, 2615, 2616, 2617, 2618, 2619, 2620, 2621, 2622, 2623, 2624, 2625, 2626, 2627, 2628, 2629, 2630, 2631, 2632, 2633, 2634, 2635, 2636, 2637, 2638, 2639, 2640, 2641, 2642, 2643, 2644, 2645, 2646, 2647, 2648, 2649, 2650, 2651, 2652, 2653, 2654, 2701, 2702, 2703, 2704, 2705, 2706, 2707, 2708, 2709, 2710, 2711, 2712, 2713,
            2714, 2715, 2716, 2717, 2718, 2719, 2720, 2721, 2722, 2723, 2724, 2725, 2726, 2727, 2728, 2729, 2730, 2731, 2732, 2733, 2734, 2735, 2736, 2737, 2738, 2739, 2740, 2741, 2742, 2743, 2744, 2745, 2746, 2747, 2748, 2749, 2750, 2751, 2752, 2753, 2754, 2755, 2756, 2757, 2758, 2759, 2760, 2761, 2762, 2763, 2764, 2765, 2766, 2767, 2768, 2769, 2770, 2771, 2772, 2773, 2774, 2775, 2776, 2777,
            2778, 2779, 2780, 2781, 2782, 2783, 2784, 2829, 2830, 2831, 2832, 2833, 2834, 2835, 2836, 2837, 2838, 2839, 2840, 2841, 2842, 2843, 2844, 2845, 2846, 2847, 2848, 2849, 2850, 2851, 2852, 2853, 2854, 2855, 2856, 2857, 2858, 2859, 2860, 2861, 2862, 2863, 2864, 2865, 2866, 2867, 2868, 2869, 2870, 2871, 2872, 2873, 2874, 2875, 2876, 2877, 2878, 2879, 2880, 2881, 2882, 2883, 2884, 2885,
            2886, 2887, 2888, 2889, 2890, 2891, 2892, 2893, 2894, 2895, 2896, 2897, 2898, 2899, 2900, 2901, 2902, 2903, 2904, 2905, 2906, 2907, 2908, 2909, 2910, 2911, 2912, 2913, 2914, 2957, 2958, 2959, 2960, 2961, 2962, 2963, 2964, 2965, 2966, 2967, 2968, 2969, 2970, 2971, 2972, 2973, 2974, 2975, 2976, 2977, 2978, 2979, 2980, 2981, 2982, 2983, 2984, 2985, 2986, 2987, 2988, 2989, 2990, 2991,
            2992, 2993, 2994, 2995, 2996, 2997, 2998, 2999, 3000, 3001, 3002, 3003, 3004, 3005, 3006, 3007, 3008, 3009, 3010, 3011, 3012, 3013, 3014, 3015, 3016, 3017, 3018, 3019, 3020, 3021, 3022, 3023, 3024, 3025, 3026, 3027, 3028, 3029, 3030, 3031, 3032, 3033, 3034, 3035, 3036, 3037, 3038, 3039, 3040, 3041, 3042, 3043, 3044, 3085, 3086, 3087, 3088, 3089, 3090, 3091, 3092, 3093, 3094, 3095,
            3096, 3097, 3098, 3099, 3100, 3101, 3102, 3103, 3104, 3105, 3106, 3107, 3108, 3109, 3110, 3111, 3112, 3113, 3114, 3115, 3116, 3117, 3118, 3119, 3120, 3121, 3122, 3123, 3124, 3125, 3126, 3127, 3128, 3129, 3130, 3131, 3132, 3133, 3134, 3135, 3136, 3137, 3138, 3139, 3140, 3141, 3142, 3143, 3144, 3145, 3146, 3147, 3148, 3149, 3150, 3151, 3152, 3153, 3154, 3155, 3156, 3157, 3158, 3159,
            3160, 3161, 3162, 3163, 3164, 3165, 3166, 3167, 3168, 3169, 3170, 3171, 3172, 3173, 3213, 3214, 3215, 3216, 3217, 3218, 3219, 3220, 3221, 3222, 3223, 3224, 3225, 3226, 3227, 3228, 3229, 3230, 3231, 3232, 3233, 3234, 3235, 3236, 3237, 3238, 3239, 3240, 3241, 3242, 3243, 3244, 3245, 3246, 3247, 3248, 3249, 3250, 3251, 3252, 3253, 3254, 3255, 3256, 3257, 3258, 3259, 3260, 3261, 3262,
            3263, 3264, 3265, 3266, 3267, 3268, 3269, 3270, 3271, 3272, 3273, 3274, 3275, 3276, 3277, 3278, 3279, 3280, 3281, 3282, 3283, 3284, 3285, 3286, 3287, 3288, 3289, 3290, 3291, 3292, 3293, 3294, 3295, 3296, 3297, 3298, 3299, 3300, 3301, 3302, 3303, 3341, 3342, 3343, 3344, 3345, 3346, 3347, 3348, 3349, 3350, 3351, 3352, 3353, 3354, 3355, 3356, 3357, 3358, 3359, 3360, 3361, 3362, 3363,
            3364, 3365, 3366, 3367, 3368, 3369, 3370, 3371, 3372, 3373, 3374, 3375, 3376, 3377, 3378, 3379, 3380, 3381, 3382, 3383, 3384, 3385, 3386, 3387, 3388, 3389, 3390, 3391, 3392, 3393, 3394, 3395, 3396, 3397, 3398, 3399, 3400, 3401, 3402, 3403, 3404, 3405, 3406, 3407, 3408, 3409, 3410, 3411, 3412, 3413, 3414, 3415, 3416, 3417, 3418, 3419, 3420, 3421, 3422, 3423, 3424, 3425, 3426, 3427,
            3428, 3429, 3430, 3431, 3432, 3433, 3469, 3470, 3471, 3472, 3473, 3474, 3475, 3476, 3477, 3478, 3479, 3480, 3481, 3482, 3483, 3484, 3485, 3486, 3487, 3488, 3489, 3490, 3491, 3492, 3493, 3494, 3495, 3496, 3497, 3498, 3499, 3500, 3501, 3502, 3503, 3504, 3505, 3506, 3507, 3508, 3509, 3510, 3511, 3512, 3513, 3514, 3515, 3516, 3517, 3518, 3519, 3520, 3521, 3522, 3523, 3524, 3525, 3526,
            3527, 3528, 3529, 3530, 3531, 3532, 3533, 3534, 3535, 3536, 3537, 3538, 3539, 3540, 3541, 3542, 3543, 3544, 3545, 3546, 3547, 3548, 3549, 3550, 3551, 3552, 3553, 3554, 3555, 3556, 3557, 3558, 3559, 3560, 3561, 3562, 3597, 3598, 3599, 3600, 3601, 3602, 3603, 3604, 3605, 3606, 3607, 3608, 3609, 3610, 3611, 3612, 3613, 3614, 3615, 3616, 3617, 3618, 3619, 3620, 3621, 3622, 3623, 3624,
            3625, 3626, 3627, 3628, 3629, 3630, 3631, 3632, 3633, 3634, 3635, 3636, 3637, 3638, 3639, 3640, 3641, 3642, 3643, 3644, 3645, 3646, 3647, 3648, 3649, 3650, 3651, 3652, 3653, 3654, 3655, 3656, 3657, 3658, 3659, 3660, 3661, 3662, 3663, 3664, 3665, 3666, 3667, 3668, 3669, 3670, 3671, 3672, 3673, 3674, 3675, 3676, 3677, 3678, 3679, 3680, 3681, 3682, 3683, 3684, 3685, 3686, 3687, 3688,
            3689, 3690, 3691, 3692, 3725, 3726, 3727, 3728, 3729, 3730, 3731, 3732, 3733, 3734, 3735, 3736, 3737, 3738, 3739, 3740, 3741, 3742, 3743, 3744, 3745, 3746, 3747, 3748, 3749, 3750, 3751, 3752, 3753, 3754, 3755, 3756, 3757, 3758, 3759, 3760, 3761, 3762, 3763, 3764, 3765, 3766, 3767, 3768, 3769, 3770, 3771, 3772, 3773, 3774, 3775, 3776, 3777, 3778, 3779, 3780, 3781, 3782, 3783, 3784,
            3785, 3786, 3787, 3788, 3789, 3790, 3791, 3792, 3793, 3794, 3795, 3796, 3797, 3798, 3799, 3800, 3801, 3802, 3803, 3804, 3805, 3806, 3807, 3808, 3809, 3810, 3811, 3812, 3813, 3814, 3815, 3816, 3817, 3818, 3819, 3820, 3821, 3822, 3853, 3854, 3855, 3856, 3857, 3858, 3859, 3860, 3861, 3862, 3863, 3864, 3865, 3866, 3867, 3868, 3869, 3870, 3871, 3872, 3873, 3874, 3875, 3876, 3877, 3878,
            3879, 3880, 3881, 3882, 3883, 3884, 3885, 3886, 3887, 3888, 3889, 3890, 3891, 3892, 3893, 3894, 3895, 3896, 3897, 3898, 3899, 3900, 3901, 3902, 3903, 3904, 3905, 3906, 3907, 3908, 3909, 3910, 3911, 3912, 3913, 3914, 3915, 3916, 3917, 3918, 3919, 3920, 3921, 3922, 3923, 3924, 3925, 3926, 3927, 3928, 3929, 3930, 3931, 3932, 3933, 3934, 3935, 3936, 3937, 3938, 3939, 3940, 3941, 3942,
            3943, 3944, 3945, 3946, 3947, 3948, 3949, 3950, 3951, 3952, 3981, 3982, 3983, 3984, 3985, 3986, 3987, 3988, 3989, 3990, 3991, 3992, 3993, 3994, 3995, 3996, 3997, 3998, 3999, 4000, 4001, 4002, 4003, 4004, 4005, 4006, 4007, 4008, 4009, 4010, 4011, 4012, 4013, 4014, 4015, 4016, 4017, 4018, 4019, 4020, 4021, 4022, 4023, 4024, 4025, 4026, 4027, 4028, 4029, 4030, 4031, 4032, 4033, 4034,
            4035, 4036, 4037, 4038, 4039, 4040, 4041, 4042, 4043, 4044, 4045, 4046, 4047, 4048, 4049, 4050, 4051, 4052, 4053, 4054, 4055, 4056, 4057, 4058, 4059, 4060, 4061, 4062, 4063, 4064, 4065, 4066, 4067, 4068, 4069, 4070, 4071, 4072, 4073, 4074, 4075, 4076, 4077, 4078, 4079, 4080, 4081, 4109, 4110, 4111, 4112, 4113, 4114, 4115, 4116, 4117, 4118, 4119, 4120, 4121, 4122, 4123, 4124, 4125,
            4126, 4127, 4128, 4129, 4130, 4131, 4132, 4133, 4134, 4135, 4136, 4137, 4138, 4139, 4140, 4141, 4142, 4143, 4144, 4145, 4146, 4147, 4148, 4149, 4150, 4151, 4152, 4153, 4154, 4155, 4156, 4157, 4158, 4159, 4160, 4161, 4162, 4163, 4164, 4165, 4166, 4167, 4168, 4169, 4170, 4171, 4172, 4173, 4174, 4175, 4176, 4177, 4178, 4179, 4180, 4181, 4182, 4183, 4184, 4185, 4186, 4187, 4188, 4189,
            4190, 4191, 4192, 4193, 4194, 4195, 4196, 4197, 4198, 4199, 4200, 4201, 4202, 4203, 4204, 4205, 4206, 4207, 4208, 4209, 4210, 4237, 4238, 4239, 4240, 4241, 4242, 4243, 4244, 4245, 4246, 4247, 4248, 4249, 4250, 4251, 4252, 4253, 4254, 4255, 4256, 4257, 4258, 4259, 4260, 4261, 4262, 4263, 4264, 4265, 4266, 4267, 4268, 4269, 4270, 4271, 4272, 4273, 4274, 4275, 4276, 4277, 4278, 4279,
            4280, 4281, 4282, 4283, 4284, 4285, 4286, 4287, 4288, 4289, 4290, 4291, 4292, 4293, 4294, 4295, 4296, 4297, 4298, 4299, 4300, 4301, 4302, 4303, 4304, 4305, 4306, 4307, 4308, 4309, 4310, 4311, 4312, 4313, 4314, 4315, 4316, 4317, 4318, 4319, 4320, 4321, 4322, 4323, 4324, 4325, 4326, 4327, 4328, 4329, 4330, 4331, 4332, 4333, 4334, 4335, 4336, 4365, 4366, 4367, 4368, 4369, 4370, 4371,
            4372, 4373, 4374, 4375, 4376, 4377, 4378, 4379, 4380, 4381, 4382, 4383, 4384, 4385, 4386, 4387, 4388, 4389, 4390, 4391, 4392, 4393, 4394, 4395, 4396, 4397, 4398, 4399, 4400, 4401, 4402, 4403, 4404, 4405, 4406, 4407, 4408, 4409, 4410, 4411, 4412, 4413, 4414, 4415, 4416, 4417, 4418, 4419, 4420, 4421, 4422, 4423, 4424, 4425, 4426, 4427, 4428, 4429, 4430, 4431, 4432, 4433, 4434, 4435,
            4436, 4437, 4438, 4439, 4440, 4441, 4442, 4443, 4444, 4445, 4446, 4447, 4448, 4449, 4450, 4451, 4452, 4453, 4454, 4455, 4456, 4457, 4458, 4459, 4460, 4461, 4462, 4463, 4493, 4494, 4495, 4496, 4497, 4498, 4499, 4500, 4501, 4502, 4503, 4504, 4505, 4506, 4507, 4508, 4509, 4510, 4511, 4512, 4513, 4514, 4515, 4516, 4517, 4518, 4519, 4520, 4521, 4522, 4523, 4524, 4525, 4526, 4527, 4528,
            4529, 4530, 4531, 4532, 4533, 4534, 4535, 4536, 4537, 4538, 4539, 4540, 4541, 4542, 4543, 4544, 4545, 4546, 4547, 4548, 4549, 4550, 4551, 4552, 4553, 4554, 4555, 4556, 4557, 4558, 4559, 4560, 4561, 4562, 4563, 4564, 4565, 4566, 4567, 4568, 4569, 4570, 4571, 4572, 4573, 4574, 4575, 4576, 4577, 4578, 4579, 4580, 4581, 4582, 4583, 4584, 4585, 4586, 4587, 4588, 4589, 4621, 4622, 4623,
            4624, 4625, 4626, 4627, 4628, 4629, 4630, 4631, 4632, 4633, 4634, 4635, 4636, 4637, 4638, 4639, 4640, 4641, 4642, 4643, 4644, 4645, 4646, 4647, 4648, 4649, 4650, 4651, 4652, 4653, 4654, 4655, 4656, 4657, 4658, 4659, 4660, 4661, 4662, 4663, 4664, 4665, 4666, 4667, 4668, 4669, 4670, 4671, 4672, 4673, 4674, 4675, 4676, 4677, 4678, 4679, 4680, 4681, 4682, 4683, 4684, 4685, 4686, 4687,
            4688, 4689, 4690, 4691, 4692, 4693, 4694, 4695, 4696, 4697, 4698, 4699, 4700, 4701, 4702, 4703, 4704, 4705, 4706, 4707, 4708, 4709, 4710, 4711, 4712, 4713, 4714, 4715, 4749, 4750, 4751, 4752, 4753, 4754, 4755, 4756, 4757, 4758, 4759, 4760, 4761, 4762, 4763, 4764, 4765, 4766, 4767, 4768, 4769, 4770, 4771, 4772, 4773, 4774, 4775, 4776, 4777, 4778, 4779, 4780, 4781, 4782, 4783, 4784,
            4785, 4786, 4787, 4788, 4789, 4790, 4791, 4792, 4793, 4794, 4795, 4796, 4797, 4798, 4799, 4800, 4801, 4802, 4803, 4804, 4805, 4806, 4807, 4808, 4809, 4810, 4811, 4812, 4813, 4814, 4815, 4816, 4817, 4818, 4819, 4820, 4821, 4822, 4823, 4824, 4825, 4826, 4827, 4828, 4829, 4830, 4831, 4832, 4833, 4834, 4835, 4836, 4837, 4838, 4839, 4840, 4841, 4877, 4878, 4879, 4880, 4881, 4882, 4883,
            4884, 4885, 4886, 4887, 4888, 4889, 4890, 4891, 4892, 4893, 4894, 4895, 4896, 4897, 4898, 4899, 4900, 4901, 4902, 4903, 4904, 4905, 4906, 4907, 4908, 4909, 4910, 4911, 4912, 4913, 4914, 4915, 4916, 4917, 4918, 4919, 4920, 4921, 4922, 4923, 4924, 4925, 4926, 4927, 4928, 4929, 4930, 4931, 4932, 4933, 4934, 4935, 4936, 4937, 4938, 4939, 4940, 4941, 4942, 4943, 4944, 4945, 4946, 4947,
            4948, 4949, 4950, 4951, 4952, 4953, 4954, 4955, 4956, 4957, 4958, 4959, 4960, 4961, 4962, 4963, 4964, 4965, 4966, 4967, 4968, 5005, 5006, 5007, 5008, 5009, 5010, 5011, 5012, 5013, 5014, 5015, 5016, 5017, 5018, 5019, 5020, 5021, 5022, 5023, 5024, 5025, 5026, 5027, 5028, 5029, 5030, 5031, 5032, 5033, 5034, 5035, 5036, 5037, 5038, 5039, 5040, 5041, 5042, 5043, 5044, 5045, 5046, 5047,
            5048, 5049, 5050, 5051, 5052, 5053, 5054, 5055, 5056, 5057, 5058, 5059, 5060, 5061, 5062, 5063, 5064, 5065, 5066, 5067, 5068, 5069, 5070, 5071, 5072, 5073, 5074, 5075, 5076, 5077, 5078, 5079, 5080, 5081, 5082, 5083, 5084, 5085, 5086, 5087, 5088, 5089, 5090, 5091, 5092, 5093, 5094, 5133, 5134, 5135, 5136, 5137, 5138, 5139, 5140, 5141, 5142, 5143, 5144, 5145, 5146, 5147, 5148, 5149,
            5150, 5151, 5152, 5153, 5154, 5155, 5156, 5157, 5158, 5159, 5160, 5161, 5162, 5163, 5164, 5165, 5166, 5167, 5168, 5169, 5170, 5171, 5172, 5173, 5174, 5175, 5176, 5177, 5178, 5179, 5180, 5181, 5182, 5183, 5184, 5185, 5186, 5187, 5188, 5189, 5190, 5191, 5192, 5193, 5194, 5195, 5196, 5197, 5198, 5199, 5200, 5201, 5202, 5203, 5204, 5205, 5206, 5207, 5208, 5209, 5210, 5211, 5212, 5213,
            5214, 5215, 5216, 5217, 5218, 5219, 5220, 5261, 5262, 5263, 5264, 5265, 5266, 5267, 5268, 5269, 5270, 5271, 5272, 5273, 5274, 5275, 5276, 5277, 5278, 5279, 5280, 5281, 5282, 5283, 5284, 5285, 5286, 5287, 5288, 5289, 5290, 5291, 5292, 5293, 5294, 5295, 5296, 5297, 5298, 5299, 5300, 5301, 5302, 5303, 5304, 5305, 5306, 5307, 5308, 5309, 5310, 5311, 5312, 5313, 5314, 5315, 5316, 5317,
            5318, 5319, 5320, 5321, 5322, 5323, 5324, 5325, 5326, 5327, 5328, 5329, 5330, 5331, 5332, 5333, 5334, 5335, 5336, 5337, 5338, 5339, 5340, 5341, 5342, 5343, 5344, 5345, 5346, 5389, 5390, 5391, 5392, 5393, 5394, 5395, 5396, 5397, 5398, 5399, 5400, 5401, 5402, 5403, 5404, 5405, 5406, 5407, 5408, 5409, 5410, 5411, 5412, 5413, 5414, 5415, 5416, 5417, 5418, 5419, 5420, 5421, 5422, 5423,
            5424, 5425, 5426, 5427, 5428, 5429, 5430, 5431, 5432, 5433, 5434, 5435, 5436, 5437, 5438, 5439, 5440, 5441, 5442, 5443, 5444, 5445, 5446, 5447, 5448, 5449, 5450, 5451, 5452, 5453, 5454, 5455, 5456, 5457, 5458, 5459, 5460, 5461, 5462, 5463, 5464, 5465, 5466, 5467, 5468, 5469, 5470, 5471, 5472, 5473, 5517, 5518, 5519, 5520, 5521, 5522, 5523, 5524, 5525, 5526, 5527, 5528, 5529, 5530,
            5531, 5532, 5533, 5534, 5535, 5536, 5537, 5538, 5539, 5540, 5541, 5542, 5543, 5544, 5545, 5546, 5547, 5548, 5549, 5550, 5551, 5552, 5553, 5554, 5555, 5556, 5557, 5558, 5559, 5560, 5561, 5562, 5563, 5564, 5565, 5566, 5567, 5568, 5569, 5570, 5571, 5572, 5573, 5574, 5575, 5576, 5577, 5578, 5579, 5580, 5581, 5582, 5583, 5584, 5585, 5586, 5587, 5588, 5589, 5590, 5591, 5592, 5593, 5594,
            5595, 5596, 5597, 5598, 5599, 5645, 5646, 5647, 5648, 5649, 5650, 5651, 5652, 5653, 5654, 5655, 5656, 5657, 5658, 5659, 5660, 5661, 5662, 5663, 5664, 5665, 5666, 5667, 5668, 5669, 5670, 5671, 5672, 5673, 5674, 5675, 5676, 5677, 5678, 5679, 5680, 5681, 5682, 5683, 5684, 5685, 5686, 5687, 5688, 5689, 5690, 5691, 5692, 5693, 5694, 5695, 5696, 5697, 5698, 5699, 5700, 5701, 5702, 5703,
            5704, 5705, 5706, 5707, 5708, 5709, 5710, 5711, 5712, 5713, 5714, 5715, 5716, 5717, 5718, 5719, 5720, 5721, 5722, 5723, 5724, 5725, 5836, 5837, 5838, 5839, 5840, 5841, 5842, 5843, 5844, 5845, 5846, 5847, 5848, 5849, 5850, 5851, 5964, 5965, 5966, 5967, 5968, 5969, 5970, 5971, 5972, 5973, 5974, 5975, 5976, 5977, 5978, 6092, 6093, 6094, 6095, 6096, 6097, 6098, 6099, 6100, 6101, 6102,
            6103, 6104, 6220, 6221, 6222, 6223, 6224, 6225, 6226, 6227, 6228, 6229, 6230, 6348, 6349, 6350, 6351, 6352, 6353, 6354, 6355, 6356, 6357, 6476, 6477, 6478, 6479, 6480, 6481, 6482, 6483, 6604, 6605, 6606, 6607, 6608, 6609, 6732, 6733, 6734, 6735, 6860, 6861, 6862, 6988,
          ];
          break;
        case '5': // ok
          ledArray = [
            2217, 2344, 2345, 2346, 2471, 2472, 2473, 2474, 2475, 2598, 2599, 2600, 2601, 2602, 2603, 2604, 2725, 2726, 2727, 2728, 2729, 2730, 2731, 2732, 2733, 2854, 2855, 2856, 2857, 2858, 2859, 2860, 2861, 2862, 2983, 2984, 2985, 2986, 2987, 2988, 2989, 2990, 2991, 3112, 3113, 3114, 3115, 3116, 3117, 3118, 3119, 3120, 3241, 3242, 3243, 3244, 3245, 3246, 3247, 3248, 3249, 3370, 3371, 3372,
            3373, 3374, 3375, 3376, 3377, 3378, 3499, 3500, 3501, 3502, 3503, 3504, 3505, 3506, 3507, 3628, 3629, 3630, 3631, 3632, 3633, 3634, 3635, 3636, 3757, 3758, 3759, 3760, 3761, 3762, 3763, 3764, 3765, 3796, 3886, 3887, 3888, 3889, 3890, 3891, 3892, 3893, 3894, 3923, 3924, 3925, 4015, 4016, 4017, 4018, 4019, 4020, 4021, 4022, 4023, 4050, 4051, 4052, 4053, 4054, 4144, 4145, 4146, 4147,
            4148, 4149, 4150, 4151, 4152, 4177, 4178, 4179, 4180, 4181, 4182, 4183, 4273, 4274, 4275, 4276, 4277, 4278, 4279, 4280, 4281, 4304, 4305, 4306, 4307, 4308, 4309, 4310, 4311, 4312, 4402, 4403, 4404, 4405, 4406, 4407, 4408, 4409, 4410, 4431, 4432, 4433, 4434, 4435, 4436, 4437, 4438, 4439, 4531, 4532, 4533, 4534, 4535, 4536, 4537, 4538, 4539, 4558, 4559, 4560, 4561, 4562, 4563, 4564,
            4565, 4566, 4660, 4661, 4662, 4663, 4664, 4665, 4666, 4667, 4668, 4685, 4686, 4687, 4688, 4689, 4690, 4691, 4692, 4693, 4789, 4790, 4791, 4792, 4793, 4794, 4795, 4796, 4797, 4812, 4813, 4814, 4815, 4816, 4817, 4818, 4819, 4820, 4918, 4919, 4920, 4921, 4922, 4923, 4924, 4925, 4926, 4939, 4940, 4941, 4942, 4943, 4944, 4945, 4946, 4947, 5047, 5048, 5049, 5050, 5051, 5052, 5053, 5054,
            5055, 5066, 5067, 5068, 5069, 5070, 5071, 5072, 5073, 5074, 5176, 5177, 5178, 5179, 5180, 5181, 5182, 5183, 5184, 5193, 5194, 5195, 5196, 5197, 5198, 5199, 5200, 5201, 5305, 5306, 5307, 5308, 5309, 5310, 5311, 5312, 5313, 5320, 5321, 5322, 5323, 5324, 5325, 5326, 5327, 5328, 5434, 5435, 5436, 5437, 5438, 5439, 5440, 5441, 5442, 5447, 5448, 5449, 5450, 5451, 5452, 5453, 5454, 5455,
            5563, 5564, 5565, 5566, 5567, 5568, 5569, 5570, 5571, 5574, 5575, 5576, 5577, 5578, 5579, 5580, 5581, 5582, 5692, 5693, 5694, 5695, 5696, 5697, 5698, 5699, 5700, 5701, 5702, 5703, 5704, 5705, 5706, 5707, 5708, 5709, 5821, 5822, 5823, 5824, 5825, 5826, 5827, 5828, 5829, 5830, 5831, 5832, 5833, 5834, 5835, 5836, 5950, 5951, 5952, 5953, 5954, 5955, 5956, 5957, 5958, 5959, 5960, 5961,
            5962, 5963, 6079, 6080, 6081, 6082, 6083, 6084, 6085, 6086, 6087, 6088, 6089, 6090, 6208, 6209, 6210, 6211, 6212, 6213, 6214, 6215, 6216, 6217, 6337, 6338, 6339, 6340, 6341, 6342, 6343, 6344, 6466, 6467, 6468, 6469, 6470, 6471, 6595, 6596, 6597, 6598, 6724, 6725,
          ];
          break;
        case '6': // alerta
          ledArray = [
            958, 959, 960, 961, 1085, 1086, 1087, 1088, 1089, 1090, 1212, 1213, 1214, 1217, 1218, 1219, 1340, 1341, 1346, 1347, 1348, 1467, 1468, 1475, 1476, 1594, 1595, 1596, 1603, 1604, 1605, 1721, 1722, 1723, 1732, 1733, 1734, 1849, 1850, 1851, 1861, 1862, 1976, 1977, 1978, 1989, 1990, 1991, 2103, 2104, 2105, 2118, 2119, 2120, 2231, 2232, 2239, 2240, 2241, 2247, 2248, 2358, 2359, 2360,
            2366, 2367, 2368, 2369, 2370, 2375, 2376, 2377, 2486, 2487, 2493, 2494, 2495, 2496, 2497, 2498, 2499, 2504, 2505, 2506, 2613, 2614, 2621, 2622, 2623, 2624, 2625, 2626, 2627, 2633, 2634, 2740, 2741, 2742, 2749, 2750, 2751, 2752, 2753, 2754, 2755, 2761, 2762, 2763, 2867, 2868, 2869, 2877, 2878, 2879, 2880, 2881, 2882, 2883, 2890, 2891, 2892, 2995, 2996, 2997, 3005, 3006, 3007, 3008,
            3009, 3010, 3011, 3018, 3019, 3020, 3122, 3123, 3124, 3133, 3134, 3135, 3136, 3137, 3138, 3139, 3147, 3148, 3149, 3250, 3251, 3261, 3262, 3263, 3264, 3265, 3266, 3267, 3276, 3277, 3278, 3377, 3378, 3379, 3389, 3390, 3391, 3392, 3393, 3394, 3395, 3405, 3406, 3504, 3505, 3506, 3517, 3518, 3519, 3520, 3521, 3522, 3523, 3533, 3534, 3535, 3631, 3632, 3633, 3645, 3646, 3647, 3648, 3649,
            3650, 3651, 3662, 3663, 3664, 3759, 3760, 3761, 3773, 3774, 3775, 3776, 3777, 3778, 3779, 3791, 3792, 3886, 3887, 3888, 3901, 3902, 3903, 3904, 3905, 3906, 3907, 3919, 3920, 3921, 4013, 4014, 4015, 4029, 4030, 4031, 4032, 4033, 4034, 4048, 4049, 4050, 4141, 4142, 4143, 4158, 4159, 4160, 4161, 4176, 4177, 4178, 4268, 4269, 4270, 4305, 4306, 4307, 4395, 4396, 4397, 4434, 4435, 4436,
            4523, 4524, 4525, 4562, 4563, 4564, 4650, 4651, 4652, 4691, 4692, 4693, 4777, 4778, 4779, 4798, 4799, 4800, 4801, 4802, 4820, 4821, 4822, 4905, 4906, 4907, 4925, 4926, 4927, 4928, 4929, 4930, 4948, 4949, 4950, 5032, 5033, 5034, 5053, 5054, 5055, 5056, 5057, 5058, 5059, 5077, 5078, 5079, 5159, 5160, 5161, 5162, 5181, 5182, 5183, 5184, 5185, 5186, 5187, 5206, 5207, 5208, 5287, 5288,
            5289, 5309, 5310, 5311, 5312, 5313, 5314, 5315, 5334, 5335, 5336, 5414, 5415, 5416, 5437, 5438, 5439, 5440, 5441, 5442, 5443, 5463, 5464, 5465, 5542, 5543, 5544, 5566, 5567, 5568, 5569, 5570, 5591, 5592, 5593, 5670, 5671, 5672, 5719, 5720, 5721, 5798, 5799, 5800, 5847, 5848, 5849, 5926, 5927, 5928, 5929, 5930, 5931, 5932, 5933, 5934, 5935, 5936, 5937, 5938, 5939, 5940, 5941, 5942,
            5943, 5944, 5945, 5946, 5947, 5948, 5949, 5950, 5951, 5952, 5953, 5954, 5955, 5956, 5957, 5958, 5959, 5960, 5961, 5962, 5963, 5964, 5965, 5966, 5967, 5968, 5969, 5970, 5971, 5972, 5973, 5974, 5975, 5976, 5977, 6055, 6056, 6057, 6058, 6059, 6060, 6061, 6062, 6063, 6064, 6065, 6066, 6067, 6068, 6069, 6070, 6071, 6072, 6073, 6074, 6075, 6076, 6077, 6078, 6079, 6080, 6081, 6082, 6083,
            6084, 6085, 6086, 6087, 6088, 6089, 6090, 6091, 6092, 6093, 6094, 6095, 6096, 6097, 6098, 6099, 6100, 6101, 6102, 6103, 6104, 6184, 6185, 6186, 6187, 6188, 6189, 6190, 6191, 6192, 6193, 6194, 6195, 6196, 6197, 6198, 6199, 6200, 6201, 6202, 6203, 6204, 6205, 6206, 6207, 6208, 6209, 6210, 6211, 6212, 6213, 6214, 6215, 6216, 6217, 6218, 6219, 6220, 6221, 6222, 6223, 6224, 6225, 6226,
          ];
          break;
        default:
          ledArray = [];
          break;
      }

      turnValuesIntoLeds(ledArray, self.currentFrame);
    });
  },
};
