/* eslint prefer-promise-reject-errors: 0 */
/* eslint no-nested-ternary: 0 */
/* eslint no-restricted-syntax: 0 */
/* eslint no-unreachable: 0 */
/* eslint no-else-return: 0 */
import axios from 'axios';
import * as DAPjs from 'dapjs';
import * as routes from '@educabot/educablocks-cosmos';
import { getAgentPorts, getNewMessage, getNewProgress, getNewState, getPorts } from '../actions/bloquesAction';

class WebUSBAgent {
  constructor(store, board, socketElectronConnection, httpElectronConnection, serialMonitorMessages, intl) {
    this.store = store;
    this.board = board;
    this.socketElectronConnection = socketElectronConnection;
    this.httpElectronConnection = httpElectronConnection;
    this.selectedPort = null;
    this.monitor = '';
    this.portList = [];
    this.serialPortList = [];
    this.openTarget = null;
    this.intl = intl;

    this.MICROBIT_VENDOR_ID = 0x0d28;
    this.MICROBIT_PRODUCT_ID = 0x0204;
    const compilerBoardName = (this.board.compilerBoard !== 'microbit') ? this.board.compilerBoard.split(':') : [];
    const compilerBoard = (this.board.compilerBoard !== 'microbit') ? compilerBoardName[2] || null : 'microbit';

    this.altIface = null;
    this.iface = null;
    this.epIn = null;
    this.epOut = null;

    this.usb = null;
    if (process.env.IS_ELECTRON || 'usb' in navigator) {
      this.usb = navigator.usb;
      this.usb.addEventListener('connect', event => {
        // console.log('======================CONNECT', event.device);
        this.addPort(event.device);
      });
      this.usb.addEventListener('disconnect', event => {
        // console.log('======================DISCONNECT', event.device);
        this.removePort(event.device);
      });
      this.store.dispatch(getNewState('#### PORT CONNECTED'));
      this.store.dispatch(getNewState('#### BOARD NOT CONNECTED'));
    }

    this.serialMonitorMessages = serialMonitorMessages;
    this.findInitialPorts();
  }

  disconnectSocket = () => { }

  stopCheckingAgent = () => { }

  removePort = (port) => {
    for (let i = 0; i < this.serialPortList.length; i += 1) {
      if (this.serialPortList[i].SerialPort === port) {
        this.serialPortList.splice(i, 1);
        this.portList.splice(i, 1);
        this.store.dispatch(getPorts(this.portList));
        this.store.dispatch(getAgentPorts(this.serialPortList));
        if (this.portList.length > 0) {
          this.store.dispatch(getNewState('#### BOARD CONNECTED'));
        } else this.store.dispatch(getNewState('#### BOARD NOT CONNECTED'));
      }
    }
  }

  addPort = (port) => {
    let exists = false;
    // console.log('===================port', port, this.serialPortList);
    this.serialPortList.map((p) => {
      if (p.SerialPort.serialNumber === port.serialNumber) {
        exists = true;
      }
      return true;
    });
    if (!exists) {
      const vendorID = `0x${port.vendorId.toString(16).padStart(4, '0')}`;
      const productID = `0x${port.productId.toString(16).padStart(4, '0')}`;
      const name = port.productName || `Puerto (${vendorID}-${productID})`;

      const transport = new DAPjs.WebUSB(port);
      const target = new DAPjs.DAPLink(transport);

      target.connect()
        .then(() => target.send(0x80))
        .then((r) => {
          const usesCODAL = r.getUint8(2) === 57 && r.getUint8(3) === 57 && r.getUint8(5) >= 51;

          this.portList.push(name);
          this.serialPortList.push({
            Name: name,
            SerialPort: port,
            VendorID: vendorID,
            ProductID: productID,
            usesCODAL,
          });
          this.store.dispatch(getPorts(this.portList));
          this.store.dispatch(getAgentPorts(this.serialPortList));
          if (this.serialPortList.length > 0) {
            this.store.dispatch(getNewState('#### BOARD CONNECTED'));
          } else {
            this.store.dispatch(getNewState('#### BOARD NOT CONNECTED'));
          }
          return true;
        })
        .then(() => target.disconnect())
        .catch((e) => {
          console.log('Board connection error:', e);
        });
    }
  }

  getSerialPortByName = (name = '') => {
    let serialPort = null;
    for (let i = 0; i < this.serialPortList.length; i += 1) {
      if (this.serialPortList[i].Name === name) {
        serialPort = this.serialPortList[i].SerialPort;
        break;
      }
    }

    return serialPort;
  }

  getPortByName = (name = '') => {
    let serialPort = null;
    for (let i = 0; i < this.serialPortList.length; i += 1) {
      if (this.serialPortList[i].Name === name) {
        serialPort = this.serialPortList[i];
        break;
      }
    }

    return serialPort;
  }

  connectNewBoard = () => {
    if (this.usb) {
      this.usb.requestDevice({ filters: [{ vendorId: this.MICROBIT_VENDOR_ID }] })
        .then((port) => {
          // console.log('===================new port', port);
          this.addPort(port);
        })
        .catch((error) => {
          this.store.dispatch(getNewMessage(this.intl.formatMessage({ id: 'agent.boardNotSelected' })));
          this.store.dispatch(getNewState('#### BOARD NOT CONNECTED'));
        });
    }
  }

  findPorts = () => { }

  findInitialPorts = () => {
    if (this.usb) {
      this.usb.getDevices()
        .then((ports) => {
          ports.forEach(device => {
            this.addPort(device);
          });
        }).catch((error) => {
          this.store.dispatch(getNewMessage(error.toString()));
        });
    }
  }

  openMonitor = (port = '', baudios = 115200) => {
    if (this.usb) {
      const serialPort = this.getSerialPortByName(port);
      if (serialPort) {
        let target;
        if (this.openTarget) {
          target = this.openTarget;
        } else {
          const transport = new DAPjs.WebUSB(serialPort);
          target = new DAPjs.DAPLink(transport);
          this.openTarget = target;
        }

        target.on(DAPjs.DAPLink.EVENT_SERIAL_DATA, (message) => {
          const messageParsed = message.replace(/\r/g, '');
          this.monitor += messageParsed;
          if (messageParsed.match(/\n/g) || this.monitor.length > 100) {
            const date = new Date();
            const time = `${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}:${String(date.getSeconds()).padStart(2, '0')}.${String(date.getMilliseconds()).padStart(3, '0')}`;
            this.serialMonitorMessages.next({ time, message: this.monitor });
            this.monitor = '';
          }
        });

        target.connect()
          .then(() => target.setSerialBaudrate(baudios))
          .then(() => target.disconnect())
          .then(() => target.startSerialRead())
          .catch(error => {
            console.error('====================open monitor', error);
          });
      }
    }
  }

  closeMonitor = (port = '') => {
    if (this.usb) {
      let target;
      if (this.openTarget) {
        target = this.openTarget;
        this.openTarget = null;
      } else {
        const serialPort = this.getSerialPortByName(port);
        if (serialPort) {
          const transport = new DAPjs.WebUSB(serialPort);
          target = new DAPjs.DAPLink(transport);
        }
      }
      if (target) {
        target.stopSerialRead();
        target.disconnect();
      }
    }
  }

  sendMonitor = (port = '', command = '') => {
    if (this.usb) {
      let target = null;
      if (this.openTarget) {
        target = this.openTarget;
      } else {
        const serialPort = this.getSerialPortByName(port);
        if (serialPort) {
          const transport = new DAPjs.WebUSB(serialPort);
          target = new DAPjs.DAPLink(transport);
        }
      }
      if (target) {
        target.connect()
          .then(() => target.serialWrite(command))
          .catch((error) => {
            this.store.dispatch(getNewMessage(error.toString()));
            console.error('================send command', error.toString());
          });
      }
    }
  };

  downloadHexFile = (hex) => {
    if (hex) {
      const blob = new Blob([atob(hex)], { type: 'octet/stream' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.target = '_blank';
      a.download = 'microbit.hex';
      a.setAttribute('id', 'clickme');
      document.body.appendChild(a);
      a.click();

      this.store.dispatch(getNewState('#### FINISH AGENT'));
    }
  }

  uploadToPort = (code = '', board = '', port = '') => {
    if (this.usb) {
      const serialPort = this.getPortByName(port);

      this.store.dispatch(getNewMessage(this.intl.formatMessage({ id: 'agent.codeIsUploading' })));
      this.store.dispatch(getNewState('#### UPLOAD TO PORT'));

      const selectedBoard = board.compilerBoard || 'arduino';

      let buildURI = (process.env.IS_ELECTRON) ? `${this.httpElectronConnection}/eb_build` : ((process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging') ? routes.ebBuildUri : 'https://builder.staging.educabot.com/eb_build');
      let buildParams = `board=${board.compilerBoard}&text=${encodeURIComponent(code)}`;
      if (selectedBoard === 'microbit') {
        buildURI = (process.env.IS_ELECTRON) ? `${this.httpElectronConnection}/microbit_build` : ((process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging') ? routes.microbitBuildUri : 'https://builder.staging.educabot.com/microbit_build');
        // buildURI = (process.env.IS_ELECTRON) ? `${this.httpElectronConnection}/microbit_build` : ((process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging') ? routes.microbitBuildUri : 'http://localhost:5000/microbit_build');
        buildParams = `text=${encodeURIComponent(code)}`;
        const usesCODAL = (typeof serialPort?.usesCODAL !== 'undefined') ? serialPort.usesCODAL : true;
        if (!usesCODAL) {
          buildParams = `${buildParams}&mbdal=1`;
        }
        if (code.match(/bluetooth.startUartService/g)) {
          buildParams = `${buildParams}&bluetooth=1`;
        }
        this.store.dispatch(getNewState('#### UPLOAD TO EB_BUILD'));
      }
      this.store.dispatch(getNewState('#### UPLOAD TO EB_BUILD'));
      axios.post(
        buildURI,
        buildParams,
        {
          withCredentials: false,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': '*/*', //eslint-disable-line
          },
        },
      ).then((response) => {
        const hex = response.data.payload?.hex || null;
        if (response.data.status === 'SUCCESS' && hex) {
          this.store.dispatch(getNewState('#### FINISH EB_BUILD'));
          this.store.dispatch(getNewState('#### UPLOAD TO AGENT'));

          if (serialPort) {
            const buffer = Buffer.from(atob(hex));
            const transport = new DAPjs.WebUSB(serialPort.SerialPort);
            const target = new DAPjs.DAPLink(transport);

            let newProgress = 0;
            target.on(DAPjs.DAPLink.EVENT_PROGRESS, progress => {
              if (parseInt((progress * 100) / 4, 10) !== newProgress) {
                newProgress += 1;
                this.store.dispatch(getNewProgress('#### UPLOAD PROGRESS', newProgress + 75));
              }
            });
            target.connect()
              .then(() => target.flash(buffer))
              .then(() => target.disconnect())
              .then(() => {
                this.store.dispatch(getNewState('#### FINISH AGENT'));
              })
              .catch((error) => {
                this.store.dispatch(getNewMessage(error.toString()));
                this.downloadHexFile(hex);
              });
          } else {
            this.downloadHexFile(hex);
          }
        } else {
          this.store.dispatch(getNewState('#### ERROR EB_BUILD'));
          this.store.dispatch(getNewMessage((JSON.stringify(response.data.message)) ? JSON.stringify(response.data.message) : response.data.message));
        }
      }).catch((responseError) => {
        this.store.dispatch(getNewState('#### ERROR EB_BUILD'));
        this.store.dispatch(getNewMessage(responseError.toString()));
      });
    }
  }

  uploadRemoteSession = (hex = '', board = '', port = '') => new Promise((resolve, reject) => {
    if (this.usb) {
      const serialPort = this.getSerialPortByName(port);
      this.store.dispatch(getNewState('#### UPLOAD TO PORT'));
      /**
       * Response object
       * status: Possible values are SUCCESS, ERROR.
       * message: Error message, if there is any.
       */
      const selectedBoard = board.compilerBoard || 'arduino';
      if (selectedBoard !== 'microbit' && (!port || port === '')) {
        this.store.dispatch(getNewState('#### BOARD NOT CONNECTED'));
        this.store.dispatch(getNewMessage(this.intl.formatMessage({ id: 'agent.boardNotConnected' })));
        reject('No encontramos conectada la placa');
      }
      this.store.dispatch(getNewState('#### BOARD CONNECTED'));
      if (!board) {
        this.store.dispatch(getNewMessage(this.intl.formatMessage({ id: 'agent.boardMustBeSelected' })));
        reject('Debes seleccionar una placa');
      }

      this.store.dispatch(getNewMessage(this.intl.formatMessage({ id: 'agent.remoteCodeIsUploading' })));

      this.store.dispatch(getNewState('#### UPLOAD TO EB_BUILD'));

      /* eslint-disable */
      if (selectedBoard === 'microbit') {
        const buffer = Buffer.from(atob(hex));
        const transport = new DAPjs.WebUSB(serialPort);
        const target = new DAPjs.DAPLink(transport);

        let newProgress = 0;
        target.on(DAPjs.DAPLink.EVENT_PROGRESS, progress => {
          if (parseInt((progress * 100) / 4, 10) !== newProgress) {
            newProgress += 1;
            this.store.dispatch(getNewProgress('#### UPLOAD PROGRESS', newProgress + 75));
          }
        });

        target.connect()
          .then(() => target.flash(buffer))
          .then(() => target.disconnect())
          .then(() => {
            this.store.dispatch(getNewState('#### FINISH AGENT'));
          })
          .catch((error) => {
            // console.error('=================Transfer failed', error);
            this.store.dispatch(getNewState('#### ERROR EB_BUILD'));
            this.store.dispatch(getNewMessage(error.toString()));
          });
      }
    } else {
      reject('Placa no soportada');
    }
  });
}

export default WebUSBAgent;
