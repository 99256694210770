/* eslint no-underscore-dangle: 0 */
/* eslint camelcase: 0 */
/* eslint dot-notation: 0 */
/* eslint no-param-reassign: 0 */
export const ENTRADAS_button = function (block, generator) {
  const dropdown_npin = block.getFieldValue('NPIN');

  generator.setups_[`setup_entradas_button_${dropdown_npin}`] = `pinMode(${dropdown_npin}, INPUT);`

  const code = `!digitalRead(${dropdown_npin})`;

  return [code, generator.ORDER_ATOMIC];
};

export const ENTRADAS_button_touch = function (block, generator) {
  const dropdown_npin = block.getFieldValue('NPIN');

  generator.setups_[`ENTRADAS_button_touch${dropdown_npin}`] = `pinMode(${dropdown_npin}, INPUT);
`;

  const code = `!digitalRead(${dropdown_npin})`;

  return [code, generator.ORDER_ATOMIC];
}

export const entradas_ultrasonido = function (block, generator) {
  const doublePin = block.getFieldValue('NPIN').split(',');

  const trigger_pin = doublePin[0];
  const echo_pin = doublePin[1];

  generator.definitions_['entradas_ultrasonido'] = `
int u_distancia(int TriggerPin, int EchoPin) {
  long duration, distanceCm;
  digitalWrite(TriggerPin, LOW);
  delayMicroseconds(4);
  digitalWrite(TriggerPin, HIGH);
  delayMicroseconds(10);
  duration = pulseIn(EchoPin, HIGH);
  distanceCm = duration * 10 / 292/ 2;
  return distanceCm;
}
`;

  generator.setups_[`entradas_ultrasonido_${echo_pin}_${trigger_pin}`] = `
  pinMode(${echo_pin}, INPUT);
  pinMode(${trigger_pin}, OUTPUT);
`;

  const code = `u_distancia(${trigger_pin}, ${echo_pin})`;

  return [code, generator.ORDER_ATOMIC];
}

export const SALIDAS_read_dht11 = function (block, generator) {
  const dropdown_npin = block.getFieldValue('NPIN');
  const dropdown_type = block.getFieldValue('TYPE');

  generator.definitions_['SALIDAS_read_dht11'] = `
#include <dht11.h>
`;

  generator.definitions_[`SALIDAS_read_dht11_${dropdown_npin}`] = `dht11 DHT11${dropdown_npin};
`;

  generator.loop_[`SALIDAS_read_dht11_${dropdown_npin}`] = `  DHT11${dropdown_npin}.read(${dropdown_npin});
`;

  const code = `DHT11${dropdown_npin}.${dropdown_type === 'T' ? 'temperature' : 'humidity'}`;

  return [code, generator.ORDER_ATOMIC];
}

export const ENTRADAS_obstaculo = function (block, generator) {
  const dropdown_npin = block.getFieldValue('NPIN');

  generator.setups_[`ENTRADAS_obstaculo_${dropdown_npin}`] = `pinMode(${dropdown_npin}, INPUT);
`;

  const code = `digitalRead(${dropdown_npin})`;

  return [code, generator.ORDER_ATOMIC];
}

export const entradas_hall = function (block, generator) {
  const dropdown_npin = block.getFieldValue('NPIN');

  generator.setups_[`entradas_hall_${dropdown_npin}`] = `pinMode(${dropdown_npin}, INPUT);
`;

  const code = `digitalRead(${dropdown_npin})`;

  return [code, generator.ORDER_ATOMIC];
}

export const ENTRADAS_infrared = function (block, generator) {
  const dropdown_npin = block.getFieldValue('NPIN');

  generator.setups_[`ENTRADAS_infrared${dropdown_npin}`] = `pinMode(${dropdown_npin}, INPUT);
`;

  const code = `digitalRead(${dropdown_npin})`;

  return [code, generator.ORDER_ATOMIC];
}

export const SALIDAS_read_ds18b20 = function (block, generator) {
  const dropdown_npin = block.getFieldValue('NPIN');

  generator.definitions_['SALIDAS_ds18b20'] = `
#include <OneWire.h>
#include <DallasTemperature.h>
`;

  generator.definitions_[`SALIDAS_ds18b20_${dropdown_npin}`] = `
OneWire ourWire${dropdown_npin}(${dropdown_npin});
DallasTemperature sensors${dropdown_npin}(&ourWire${dropdown_npin});
`;

  generator.setups_[`SALIDAS_ds18b20_${dropdown_npin}`] = `
  sensors${dropdown_npin}.begin();
`;

  generator.loop_[`SALIDAS_ds18b20_${dropdown_npin}`] = `
  sensors${dropdown_npin}.requestTemperatures();
`;

  const code = `sensors${dropdown_npin}.getTempCByIndex(0)`;

  return [code, generator.ORDER_ATOMIC];
}

export const SALIDAS_IR = function (block, generator) {
  const dropdown_npin = block.getFieldValue('NPIN');
  const dropdown_num = block.getFieldValue('NUM');

  generator.definitions_['SALIDAS_IR'] = `
#include <IRremote.h>
`;

  generator.definitions_[`SALIDAS_IR_${dropdown_npin}`] = `IRrecv ir_rx_${dropdown_npin} = ${dropdown_npin};
decode_results ir_rx_results_${dropdown_npin};

unsigned long fnc_ir_rx_decode_${dropdown_npin}()
{ 
  if(ir_rx_${dropdown_npin}.decode(&ir_rx_results_${dropdown_npin}))
  {
    ir_rx_${dropdown_npin}.resume();
    return ir_rx_results_${dropdown_npin}.value;
  }else{
    return 0;
  }
}
`;

  generator.setups_[`SALIDAS_IR_${dropdown_npin}`] = `ir_rx_${dropdown_npin}.enableIRIn();
`;

  const code = `(unsigned long)fnc_ir_rx_decode_${dropdown_npin}() == ${dropdown_num}`;

  return [code, generator.ORDER_ATOMIC];
}

export const ENTRADAS_VIBRATION = function (block, generator) {
  const dropdown_npin = block.getFieldValue('NPIN');

  generator.setups_[`ENTRADAS_VIBRATION_${dropdown_npin}`] = `pinMode(${dropdown_npin}, INPUT);
`;

  const code = `digitalRead(${dropdown_npin})`;

  return [code, generator.ORDER_ATOMIC];
}

export const ENTRADAS_TILT = function (block, generator) {
  const dropdown_npin = block.getFieldValue('NPIN');

  generator.setups_[`ENTRADAS_TILT_${dropdown_npin}`] = `pinMode(${dropdown_npin}, INPUT);
`;

  const code = `digitalRead(${dropdown_npin})`;

  return [code, generator.ORDER_ATOMIC];
}

export const ENTRADAS_CHOQUE = function (block, generator) {
  const dropdown_npin = block.getFieldValue('NPIN');

  generator.setups_[`ENTRADAS_CHOQUE_${dropdown_npin}`] = `pinMode(${dropdown_npin}, INPUT);
`;

  const code = `digitalRead(${dropdown_npin})`;

  return [code, generator.ORDER_ATOMIC];
}

export const ENTRADAS_follower = function (block, generator) {
  const dropdown_npin = block.getFieldValue('NPIN');
  const dropdown_npin2 = block.getFieldValue('NPIN2');

  generator.setups_[`ENTRADAS_follower_${dropdown_npin}`] = `pinMode(${dropdown_npin}, INPUT);
pinMode(${dropdown_npin2}, INPUT);
`;

  const code_left = generator.statementToCode(block, 'SENS1');
  const code_right = generator.statementToCode(block, 'SENS2');
  const code_none = generator.statementToCode(block, 'SENS3');

  const code = `
  if(!digitalRead(${dropdown_npin}))
  {
    ${code_left}
  }
  else if(!digitalRead(${dropdown_npin2}))
  {
    ${code_right}
  }else{
    ${code_none}
  }
`;

  return code;
}

export const inout_digital_read = function (block, generator) {
  const dropdown_npin = block.getFieldValue('NPIN');

  generator.setups_[`inout_digital_read${dropdown_npin}`] = `pinMode(${dropdown_npin}, INPUT);
`;

  const code = `digitalRead(${dropdown_npin})`;

  return [code, generator.ORDER_ATOMIC];
}