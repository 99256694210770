// import { arduinoGenerator } from './generator';
import { ArduinoGenerator } from './arduinoGenerator';
import * as procedures from './procedureBlocks';
import * as variables from './variableBlocks';
import * as control from './controlBlocks';
import * as logic from './logicBlocks';
import * as text from './textBlocks';
import * as math from './mathBlocks';
import * as outputs from './outputsBlocks';
import * as controlJunior from './controlJuniorBlocks';
import * as motors from './motorsBlocks';
import * as inputDigital from './inputDigital';

const arduinoGenerator = new ArduinoGenerator();

const generators = {
  // SENIOR BLOCKS
  ...procedures,
  ...variables,
  ...control,
  ...logic,
  ...text,
  ...math,
  ...outputs,
  ...motors,
  ...inputDigital,
  // JUNIOR BLOCKS
  ...controlJunior,
};

Object.keys(generators).forEach(name => {
  arduinoGenerator.forBlock[name] = generators[name];
});

export default arduinoGenerator;