import React from 'react';
import flat from 'flat';
import spanishMessages from '../i18n/es.json';
import englishMessages from '../i18n/en.json';
import { getCosmosItem } from '../cosmos';

/**
 * Get custom i18n messages.
 * @param {string} locale
 */
// export const getLocale = (locale = 'es') => ({
//   es: ({ locale: 'es', intlMessages: flat(spanishMessages) }),
//   en: ({ locale: 'en', intlMessages: flat(englishMessages) }),
// })[locale];

export const locales = {
  es: { locale: 'es', intlMessages: flat(spanishMessages), name: 'Español' },
  en: { locale: 'en', intlMessages: flat(englishMessages), name: 'English' },
};

export const getLocale = () => {
  const defaultLocale = getCosmosItem('lang') || 'es';
  const savedLocale = sessionStorage.getItem('educabotLocale') || defaultLocale;

  return locales[savedLocale] || locales[defaultLocale];
};

/**
 * Save a new locale to session storage.
 * @param {string} locale
 */
export const setLocale = (locale) => {
  if (locales[locale]) {
    sessionStorage.setItem('educabotLocale', locale);
  } else {
    console.warn(`Locale "${locale}" is not supported.`);
  }
};
