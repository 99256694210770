/* eslint import/no-extraneous-dependencies: 0 */
/* eslint object-shorthand: 0 */
/* eslint no-underscore-dangle: 0 */
/* eslint-disable */
import * as Blockly from 'blockly';
import colors from '../utils/colors';
import options from '../utils/blocksOptions';
import { config } from 'process';


Blockly.Blocks.initial_block = {
  init: function () {
    this.setColour(colors.COLOUR_CONTROL);
    this.appendDummyInput();
    this.appendStatementInput('Start').appendField(Blockly.Msg.LANG_START_2);
    this.appendStatementInput('Repeat').appendField(Blockly.Msg.LANG_REPEAT);
    this.setPreviousStatement(false);
    this.setNextStatement(false);
    this.setInputsInline(true);
    this.setTooltip(Blockly.Msg.LANG_START_2);
  },
};

Blockly.Blocks.base_delay = {
  init: function () {
    this.setColour(colors.COLOUR_CONTROL);
    this.appendValueInput('NUM', Number)
      .appendField(Blockly.Msg.LANG_CONTROLS_BASE_DELAY_WAIT)
      .setCheck(Number);
    this.setInputsInline(false);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setTooltip(Blockly.Msg.LANG_CONTROLS_BASE_DELAY_TOOLTIP);
  },
};

Blockly.Blocks.controls_if = {
  init: function () {
    this.setOutputShape(Blockly.OUTPUT_SHAPE_ROUND);
    this.elseifCount_ = 0;
    this.elseCount_ = 0;
    this.setHelpUrl(Blockly.Msg.CONTROLS_IF_HELPURL);
    this.appendValueInput('IF0')
      .appendField(Blockly.Msg.CONTROLS_IF_MSG_IF)
      .setCheck("Boolean");
    this.appendDummyInput('THEN0')
      .appendField(Blockly.Msg.CONTROLS_IF_MSG_THEN);
    this.appendStatementInput('DO0');
    this.setInputsInline(true);
    this.setColour(colors.COLOUR_CONTROL);
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.updateShape_();
  },

  /**
   * Create XML to represent number of text inputs.
   * Backwards compatible serialization implementation.
   *
   * @returns XML storage element.
   */
  mutationToDom: function (workspace) {
    if (!this.elseifCount_ && !this.elseCount_) {
      return null;
    }
    const container = Blockly.utils.xml.createElement('mutation');
    if (this.elseifCount_) {
      container.setAttribute('elseif', String(this.elseifCount_));
    }
    if (this.elseCount_) {
      container.setAttribute('else', '1');
    }
    return container;
  },

  /**
   * Parse XML to restore the text inputs.
   * Backwards compatible serialization implementation.
   *
   * @param xmlElement XML storage element.
   */
  domToMutation: function (block, xmlElement) {
    if (!xmlElement) return;
    this.elseifCount_ = parseInt(xmlElement.getAttribute('elseif') || 0, 10);
    this.elseCount_ = parseInt(xmlElement.getAttribute('else') || 0, 10);
    this.reconnectChildBlocks_();
  },

  /**
   * Returns the state of this block as a JSON serializable object.
   *
   * @returns The state of this block, ie the item count.
   */
  saveExtraState: function () {
    if (!this.elseifCount_ && !this.elseCount_) {
      return null;
    }
    const state = Object.create(null);
    if (this.elseifCount_) {
      state['elseIfCount'] = this.elseifCount_;
    }
    if (this.elseCount_) {
      state['elseCount'] = this.elseCount_;
    }
    return state;
  },

  /**
   * Applies the given state to this block.
   *
   * @param state The state to apply to this block, ie the item count.
   */
  loadExtraState: function (state) {
    this.elseifCount_ = state['elseIfCount'] || 0;
    this.elseCount_ = state['elseCount'] || 0;
    this.updateShape_();
  },

  /**
   * Modify this block to have the correct number of inputs.
   *
   */
  updateShape_: function () {
    // Delete everything.
    if (this.getInput('ELSE')) {
      this.removeInput('ELSE');
      this.removeInput('ELSETITLE');
      this.removeInput('ELSEBUTTONS');
    }
    for (let ib = 1; this.getInput('IF' + ib); ib++) {
      this.removeInput('IF' + ib);
      this.removeInput('IFTITLE' + ib);
      this.removeInput('IFBUTTONS' + ib);
      this.removeInput('DO' + ib);
    }
    // Rebuild block.
    const block = this;
    for (let i = 1; i <= this.elseifCount_; i++) {
      var removeElseIf = function (arg) {
        return function () {
          block.removeElseIf_(arg);
        };
      }(i);
      this.appendValueInput('IF' + i)
        .appendField(Blockly.Msg.CONTROLS_IF_MSG_ELSEIF)
        .setCheck("Boolean");
      this.appendDummyInput('IFTITLE' + i)
        .appendField(Blockly.Msg.CONTROLS_IF_MSG_THEN);
      this.appendDummyInput('IFBUTTONS' + i)
        .appendField(
          new Blockly.FieldImage(options.inlineSVG.REMOVE_IMAGE_DATAURI, 24, 24, "Remove Else", removeElseIf))
        .setAlign(Blockly.inputs.Align.RIGHT);
      this.appendStatementInput('DO' + i);
    }
    if (this.elseCount_) {
      this.appendDummyInput('ELSETITLE')
        .appendField(Blockly.Msg.CONTROLS_IF_MSG_ELSE);
      this.appendDummyInput('ELSEBUTTONS')
        .setAlign(Blockly.inputs.Align.RIGHT)
        .appendField(
          new Blockly.FieldImage(options.inlineSVG.REMOVE_IMAGE_DATAURI, 24, 24, "Remove Else", block.removeElse_.bind(block)));
      this.appendStatementInput('ELSE');
    }
    if (this.getInput('ADDBUTTON')) this.removeInput('ADDBUTTON');
    const addElseIf = function () {
      return function () {
        if (block.elseCount_ === 0) {
          block.addElse_();
        } else {
          if (!block.elseifCount_) block.elseifCount_ = 0;
          block.addElseIf_();
        }
      };
    }();
    this.appendDummyInput('ADDBUTTON')
      .appendField(
        new Blockly.FieldImage(options.inlineSVG.ADD_IMAGE_DATAURI, 24, 24, "Add Else", addElseIf));
  },

  storeConnections_: function (arg) {
    if (!arg) arg = 0;
    this.valueConnections_ = [null];
    this.statementConnections_ = [null];
    this.elseStatementConnection_ = null;
    for (var i = 1; i <= this.elseifCount_; i++) {
      if (arg !== i) {
        this.valueConnections_.push(this.getInput('IF' + i).connection.targetConnection);
        this.statementConnections_.push(this.getInput('DO' + i).connection.targetConnection);
      }
    }
    if (this.getInput('ELSE')) this.elseStatementConnection_ = this.getInput('ELSE').connection.targetConnection;
  },

  reconnectChildBlocks_: function () {
    if (this.valueConnections_?.length > 1) {
      for (let i = 1; i <= this.elseifCount_; i++) {
        this.valueConnections_[i]?.reconnect(this, 'IF' + i);
        this.statementConnections_[i]?.reconnect(this, 'DO' + i);
      }
    }
    this.elseStatementConnection_?.reconnect(this, 'ELSE');
  },

  addElse_: function () {
    this.storeConnections_();
    this.elseCount_++;
    this.updateShape_();
    this.reconnectChildBlocks_();
    this.workspace.fireChangeListener(this);
  },

  removeElse_: function () {
    this.storeConnections_();
    this.elseCount_--;
    this.updateShape_();
    this.reconnectChildBlocks_();
    this.workspace.fireChangeListener(this);
  },

  addElseIf_: function () {
    this.storeConnections_();
    this.elseifCount_++;
    this.updateShape_();
    this.reconnectChildBlocks_();
    this.workspace.fireChangeListener(this);
  },

  removeElseIf_: function (arg) {
    this.storeConnections_(arg);
    this.elseifCount_--;
    this.updateShape_();
    this.reconnectChildBlocks_();
    this.workspace.fireChangeListener(this);
  },
};

Blockly.Blocks.controls_repeat_educabot = {
  init: function () {
    this.setColour(colors.COLOUR_CONTROL);
    this.appendDummyInput()
      .appendField(Blockly.Msg.LANG_CONTROLS_REPEAT_TITLE_REPEAT);
    this.appendValueInput('TIMES')
      .setCheck(Number);
    this.appendDummyInput()
      .setAlign(Blockly.inputs.Align.RIGHT)
      .appendField(Blockly.Msg.LANG_CONTROLS_REPEAT_TITLE_TIMES)
    this.appendStatementInput('DO')
      .appendField(Blockly.Msg.LANG_CONTROLS_FOR_INPUT_DO)
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.setInputsInline(true);
    this.setTooltip(Blockly.Msg.LANG_CONTROLS_REPEAT_TOOLTIP)
  },
};

Blockly.Blocks.controls_whileUntil = {
  init: function () {
    this.setColour(colors.COLOUR_CONTROL);
    this.appendValueInput('BOOL')
      .setCheck('Boolean')
      .appendField(Blockly.Msg.LANG_CONTROLS_WHILEUNTIL_TITLE_REPEAT)
      .appendField(new Blockly.FieldDropdown([
        [Blockly.Msg.LANG_CONTROLS_WHILEUNTIL_OPERATOR_WHILE, 'WHILE'],
        [Blockly.Msg.LANG_CONTROLS_WHILEUNTIL_OPERATOR_UNTIL, 'UNTIL']
      ]), 'MODE');
    this.appendStatementInput('DO')
      .appendField(Blockly.Msg.LANG_CONTROLS_WHILEUNTIL_INPUT_DO);
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.setInputsInline(true);
    this.setTooltip(() => {
      const op = this.getFieldValue('MODE')
      return Blockly.Blocks.controls_whileUntil.TOOLTIPS[op];
    })
  },
};
Blockly.Blocks.controls_whileUntil.TOOLTIPS = {
  WHILE: Blockly.Msg.LANG_CONTROLS_WHILEUNTIL_OPERATOR_WHILE,
  UNTIL: Blockly.Msg.LANG_CONTROLS_WHILEUNTIL_OPERATOR_UNTIL
};

Blockly.Blocks.controls_for = {
  init: function () {
    this.setColour(colors.COLOUR_CONTROL);
    this.appendValueInput('VAR')
      .appendField(Blockly.Msg.LANG_CONTROLS_FOR_INPUT_WITH);
    this.appendValueInput('FROM')
      .setCheck(Number)
      .setAlign(Blockly.inputs.Align.RIGHT)
      .appendField(Blockly.Msg.LANG_CONTROLS_FOR_INPUT_FROM);
    this.appendValueInput('TO')
      .setCheck(Number)
      .setAlign(Blockly.inputs.Align.RIGHT)
      .appendField(Blockly.Msg.LANG_CONTROLS_FOR_INPUT_TO);
    this.appendStatementInput('DO')
      .appendField(Blockly.Msg.LANG_CONTROLS_FOT_INPUT_DO);
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.setInputsInline(true);
    // Assign 'this' to a variable for use in the tooltip closure below.
    var thisBlock = this;
    this.setTooltip(function () {
        return Educablocks.LANG_CONTROLS_FOR_TOOLTIP.replace('%1', thisBlock.getFieldValue('VAR'));
    });
  },
  getVars: function () {
    return [this.getFieldValue('VAR')];
  },
  isVariable: function (varValue) {
    for (var i in Blockly.Variables.allVariables()) {
        if (Blockly.Variables.allVariables()[i] === varValue) {
            return true;
        }
    }
    return false;
  },
  onchange: function () {
      try {
          if (this.isVariable(Blockly.Arduino.valueToCode(this, 'FROM', Blockly.Arduino.ORDER_ATOMIC))) {
              this.setWarningText(null);
          } else if (this.isVariable(Blockly.Arduino.valueToCode(this, 'TO', Blockly.Arduino.ORDER_ATOMIC))) {
              this.setWarningText(null);
          } else {
              this.setWarningText(null);
          }
      } catch (e) { }
  },
};

Blockly.Blocks.controls_flow_statements = {
  init: function() {
    this.setColour(colors.COLOUR_CONTROL);
    this.appendDummyInput()
      .appendField(new Blockly.FieldDropdown([
        [Blockly.Msg.LANG_CONTROLS_FLOW_STATEMENTS_OPERATOR_BREAK, 'BREAK'],
        [Blockly.Msg.LANG_CONTROLS_FLOW_STATEMENTS_OPERATOR_CONTINUE, 'CONTINUE']
      ]), 'FLOW')
      .appendField(Blockly.Msg.LANG_CONTROLS_FLOW_STATEMENTS_INPUT_OFLOOP);
    this.setPreviousStatement(true);
    const thisBlock = this;
    this.setTooltip(function () {
      const op = thisBlock.getFieldValue('FLOW')
      return Blockly.Blocks.controls_flow_statements.TOOLTIPS[op]
    })
  },
};
Blockly.Blocks.controls_flow_statements.TOOLTIPS = {
  BREAK: Blockly.Msg.LANG_CONTROLS_FLOW_STATEMENTS_TOOLTIP_BREAK,
  CONTINUE: Blockly.Msg.LANG_CONTROLS_FLOW_STATEMENTS_TOOLTIP_CONTINUE
};

Blockly.Blocks.base_millis = {
  init: function () {
    this.setOutputShape(options.SHAPES.ROUND);
    this.setColour(colors.COLOUR_CONTROL);
    this.appendDummyInput()
      .appendField(Blockly.Msg.LANG_CONTROLS_BASE_MILLIS);
    this.setOutput(true, 'Number');
    this.setTooltip(Blockly.Msg.LANG_CONTROLS_BASE_MILLIS_TOOLTIP);
  },
};

Blockly.Blocks.controls_doWhile = {
  init: function () {
    this.setColour(colors.COLOUR_CONTROL);
    this.appendDummyInput()
      .appendField();
    this.appendStatementInput('DO')
      .appendField(Blockly.Msg.LANG_CONTROLS_DOWHILE_OPERATOR_DO)
    this.appendValueInput('BOOL')
      .setCheck('Boolean')
      .appendField(Blockly.Msg.LANG_CONTROLS_WHILEUNTIL_TITLE_REPEAT)
      .appendField(new Blockly.FieldDropdown([
        [Blockly.Msg.LANG_CONTROLS_WHILEUNTIL_OPERATOR_WHILE, 'WHILE'],
        [Blockly.Msg.LANG_CONTROLS_WHILEUNTIL_OPERATOR_UNTIL, 'UNTIL']
      ]), 'MODE');
      this.setPreviousStatement(true);
      this.setNextStatement(true);
      this.setTooltip(Blockly.Msg.LANG_CONTROLS_DOWHILE_TOOLTIP);
  },
};

Blockly.Blocks.controls_execute = {
  init: function () {
    this.setColour(colors.COLOUR_CONTROL);
    this.appendValueInput('CONTENT', String)
      .appendField(Blockly.Msg.LANG_CONTROLS_EXECUTE);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setTooltip(Blockly.Msg.LANG_CONTROLS_EXECUTE_TOOLTIP);
  },
};