/* eslint import/no-extraneous-dependencies: 0 */
/* eslint no-underscore-dangle: 0 */
/* eslint object-shorthand: 0 */
/* eslint dot-notation: 0 */
/* eslint spaced-comment: 0 */
import * as Blockly from 'blockly';
import { FieldColour } from '@blockly/field-colour';
import { getAudioFromTag, loadAudioExt } from 'src/helpers/LoadAndKillerScripts';
import colors from '../utils/colors';
import options from '../utils/blocksOptions';
import { PianoModal } from './customBlocks/pianoModal';

///////// FIELD COLOUR //////////
/////////////////////////////////
Blockly.Css.register(`
  .blocklyColourTable>tr>td {
    border-radius: 4px;
    height: 25px;
    width: 25px;
    margin: 2px;
  }
  
  .blocklyColourSelected, .blocklyColourSelected:hover {
    border-color: #eee !important;
    outline: 2px solid #333;
    position: relative;
  }
`);

Blockly.Blocks.colour_educabot = {
  init: function () {
    this.setOutputShape(options.SHAPES.ROUND);
    this.appendDummyInput()
      .appendField(new FieldColour('#ff0000'), 'COLOUR');
    this.setOutput(true, String);
    this.setTooltip(Blockly.Msg.LANG_Color);
    this.setColour(colors.COLOUR_SALIDAS);
  },
};
/////////////////////////////////
/////////////////////////////////

Blockly.Blocks.salidas_led2 = {
  init: function () {
    this.setColour(colors.COLOUR_SALIDAS);
    this.appendDummyInput('image')
      .appendField(new Blockly.FieldImage(`${options.imgBase}/blocks/educablocks/18.svg`, options.blockWidth, options.blockHeight))
      .appendField(Blockly.Msg.LANG_SALIDAS_RAYITA);
    this.appendDummyInput()
      .appendField(new Blockly.FieldDropdown([
        [Blockly.Msg.LANG_SALIDAS_LED_ON || 'ON', 'HIGH'],
        [Blockly.Msg.LANG_SALIDAS_LED_OFF || 'OFF', 'LOW'],
      ]), 'STAT');
    this.appendDummyInput()
      .appendField(Blockly.Msg.LANG_SALIDAS_LED_NEW)
      .appendField(new Blockly.FieldDropdown(
        () => {
          return Blockly.getMainWorkspace().getHardwarePinsCombo('digital', `${this.component}_NPIN`)
        },
      ), 'NPIN');
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setTooltip(Blockly.Msg.LANG_SALIDAS_LED_TOOLTIP);
    this.toggled = 0;
    const self = this;
  },
  getPins: function () {
    return ['NPIN'];
  },
  component: 'led',
};

Blockly.Blocks.led_neopixel_pin = {
  init: function () {
    this.setColour(colors.COLOUR_SALIDAS);
    this.appendDummyInput()
      .appendField(new Blockly.FieldImage(`${options.imgBase}/blocks/educablocks/181.svg`, options.blockWidth, options.blockHeight))
      .appendField(Blockly.Msg.LANG_SALIDAS_RAYITA);
    this.appendValueInput('COLOUR')
      .appendField(Blockly.Msg.LANG_BHOOT_LED_RGB);


    this.appendDummyInput()
      .appendField(Blockly.Msg.LANG_BHOOT_LED_RGB_PIN_SINGLE)
      .appendField(new Blockly.FieldDropdown(
        () => {
          return Blockly.getMainWorkspace().getHardwarePinsCombo('digital', `${this.component}_NPIN`)
        },
      ), 'NPIN');

    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setTooltip(Blockly.Msg.LANG_BHOOT_LED_RGB_TOOLTIP);
  },
  component: 'ledRGB',
  getPins: () => ['NPIN'],
};

Blockly.Blocks.salidas_led_brillo = {
  init: function () {
    this.setColour(colors.COLOUR_SALIDAS);
    this.appendDummyInput()
      .appendField(new Blockly.FieldImage(`${options.imgBase}/blocks/educablocks/18.svg`, options.blockWidth, options.blockHeight))
      .appendField(Blockly.Msg.LANG_SALIDAS_RAYITA);
    this.appendValueInput('DATOIN')
      .setAlign(Blockly.ALIGN_RIGHT)
      .appendField(Blockly.Msg.LANG_SALIDAS_LED_INTENSIDAD)
    this.appendDummyInput()
      .setAlign(Blockly.ALIGN_RIGHT)
      .appendField(Blockly.Msg.LANG_SALIDAS_LED_PWM)
      .appendField(new Blockly.FieldDropdown(() => {
        return Blockly.getMainWorkspace().getHardwarePinsCombo('pwm', `${this.component}_NPIN`);
      }), 'NPIN');
    this.setInputsInline();
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setTooltip(Blockly.Msg.LANG_SALIDAS_LED_TOOLTIP);
  },
  getPins: function () {
    return ['NPIN'];
  },
  component: 'led',
}

Blockly.Blocks.ENTRADAS_piezo_buzzer_piano = {
  init: function () {
    this.setColour(colors.COLOUR_SALIDAS);
    this.appendDummyInput()
      .appendField(new Blockly.FieldImage(`${options.imgBase}/blocks/educablocks/152.svg`, options.blockWidth, options.blockHeight))
      .appendField(Blockly.Msg.LANG_SALIDAS_PIEZO_BUZZER_PIANO);
    this.appendDummyInput()
      .appendField(new PianoModal({ basePitch: 165, octave: 3, duration: 1000, pinName: 'NPIN' }, undefined, { }), 'PIANO');
    this.appendDummyInput()
      .setAlign(Blockly.ALIGN_RIGHT)
      .appendField(Blockly.Msg.LANG_SALIDAS_PIEZO_BUZZER_PIANO_PIN)
      .appendField(new Blockly.FieldDropdown(() => {
        return Blockly.getMainWorkspace().getHardwarePinsCombo('digital', `${this.component}_NPIN`);
      }), 'NPIN');
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setTooltip(Blockly.Msg.LANG_SALIDAS_PIEZO_BUZZER_TOOLTIP);
  },
  getPins: function () {
    return ['NPIN'];
  },
  component: 'buzzer',
}

Blockly.Blocks.ENTRADAS_piezo_buzzerno = {
  init: function () {
    this.setColour(colors.COLOUR_SALIDAS);
    this.appendDummyInput()
      .appendField(new Blockly.FieldImage(`${options.imgBase}/blocks/educablocks/152.svg`, options.blockWidth, options.blockHeight))
      .appendField(Blockly.Msg.LANG_SALIDAS_RAYITA);
    this.appendDummyInput()
      .setAlign(Blockly.ALIGN_RIGHT)
      .appendField(Blockly.Msg.LANG_SALIDAS_PIEZO_BUZZERNO)
      .appendField(Blockly.Msg.LANG_SALIDAS_PIEZO_BUZZER_PIN)
      .appendField(new Blockly.FieldDropdown(() => {
        return Blockly.getMainWorkspace().getHardwarePinsCombo('digital', `${this.component}_NPIN`);
      }), 'NPIN');
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
  },
}