/* eslint no-underscore-dangle: 0 */
/* eslint camelcase: 0 */
/* eslint dot-notation: 0 */
/* eslint  no-param-reassign: 0 */
export const initial_block = (block, generator) => {
  let branch = generator.statementToCode(block, 'Start');
  const loop = generator.statementToCode(block, 'Repeat');

  if (generator.INFINITE_LOOP_TRAP) {
    branch = `${generator.INFINITE_LOOP_TRAP.replace(/%1/g, `'${block.id}'`)}${branch}`;
  }

  generator.setups_['initial_block'] = `${branch}`;

  return loop;
};

export const math_number = (block, generator) => {
  const code = parseFloat(block.getField('NUM').getText());
  const order = code < 0 ? generator.ORDER_UNARY_PREFIX : generator.ORDER_ATOMIC;
  return [code, order];
};

export const base_delay = (block, generator) => {
  const num = generator.valueToCode(block, 'NUM', generator.ORDER_NONE) || '0';
  return `delay(${num});
  `;
};

export const controls_if = (block, generator) => {
  let code = '';
  let n = 0;
  let argument = generator.valueToCode(block, `IF${n}`, generator.ORDER_NONE) || 'false';
  let branch = generator.statementToCode(block, `DO${n}`);
  code += `
if (${argument}) {
  ${branch}
}`;

  for (n = 1; n <= block.elseifCount_; n += 1) {
    argument = generator.valueToCode(block, `IF${n}`, generator.ORDER_NONE) || 'false';
    branch = generator.statementToCode(block, `DO${n}`);
    code += ` else if (${argument}) {
  ${branch}
}`;
  }

  if (block.elseCount_) {
    branch = generator.statementToCode(block, 'ELSE');
    code += ` else {
  ${branch}
}`;
  }

  return `${code};
`;
};

export const controls_repeat_educabot = (block, generator) => {
  const loopCount = generator.valueToCode(block, 'TIMES', generator.ORDER_ATOMIC);
  const action = generator.statementToCode(block, 'DO');

  const code = `
  for (int int2 = 0; int2 < ${loopCount}; int2++)
  {
    ${action}
  }`;

  return code;
};

export const controls_whileUntil = (block, generator) => {
  let condition = generator.valueToCode(block, 'BOOL', generator.ORDER_ATOMIC);
  const action = generator.statementToCode(block, 'DO');
  const mode = block.getFieldValue('MODE');

  if (mode === 'UNTIL') {
    condition = `!${condition}`
  }

  const code = `
  while(${condition})
  {
    ${action}
  }`;

  return code;
}

export const controls_for = (block, generator) => {
  const variable = generator.valueToCode(block, 'VAR', generator.ORDER_NONE);
  const loopFrom = generator.valueToCode(block, 'FROM', generator.ORDER_ATOMIC);
  const loopTo = generator.valueToCode(block, 'TO', generator.ORDER_ATOMIC);
  const action = generator.statementToCode(block, 'DO');

  const code = `
  for (${variable} = ${loopFrom}; ${variable} <= ${loopTo}; ${variable}++)
  {
    ${action}
  }`;

  return code;
}

export const controls_flow_statements = (block, generator) => {
  const operator = block.getFieldValue('FLOW');
  const code = operator === 'BREAK' ? 'break;' : 'continue;';

  return code;
}

export const base_millis = (block, generator) => {
  return ['millis()', generator.ORDER_ATOMIC];
}

export const controls_doWhile = (block, generator) => {
  let condition = generator.valueToCode(block, 'BOOL', generator.ORDER_ATOMIC);
  const action = generator.statementToCode(block, 'DO');
  const mode = block.getFieldValue('MODE');

  if (mode === 'UNTIL') {
    condition = `!${condition}`
  }

  const code = `
  do {
    ${action}
  } while(${condition})`;

  return code;
}

export const controls_execute = (block, generator) => {
  const action = generator.valueToCode(block, 'CONTENT', generator.ORDER_ATOMIC);
  return action
}