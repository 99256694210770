/* eslint import/no-extraneous-dependencies: 0 */
/* eslint no-underscore-dangle: 0 */
/* eslint object-shorthand: 0 */
/* eslint dot-notation: 0 */
/* eslint spaced-comment: 0 */
/* eslint no-param-reassign: 0 */
import * as Blockly from 'blockly';
import colors from '../utils/colors';
import options from '../utils/blocksOptions';

Blockly.Blocks.text_unir = {
  init: function () {
    this.setColour(colors.COLOUR_TEXT);
    this.appendDummyInput()
      .appendField(Blockly.Msg.TEXT_JOIN_TITLE_CREATEWITH);
    this.setPreviousStatement(false);
    this.setNextStatement(false);
    this.setInputsInline(true);
    this.setOutput(true, 'String');
    this.setTooltip(Blockly.Msg.TEXT_JOIN_TOOLTIP);
    this.updateShape_();
  },

  /**
   * Create XML to represent number of text inputs.
   * Backwards compatible serialization implementation.
   *
   * @returns XML storage element.
   */
  mutationToDom: function (workspace) {
    const container = Blockly.utils.xml.createElement('mutation');
    container.setAttribute('items', `${this.itemCount_}`);
    return container;
  },

  /**
   * Parse XML to restore the text inputs.
   * Backwards compatible serialization implementation.
   *
   * @param xmlElement XML storage element.
   */
  domToMutation: function (block, xmlElement) {
    this.itemCount_ = parseInt(xmlElement.getAttribute('items') || 2, 10);
    this.updateShape_();
  },

  /**
   * Returns the state of this block as a JSON serializable object.
   *
   * @returns The state of this block, ie the item count.
   */
  saveExtraState: function () {
    return {
      itemCount: this.itemCount_,
    };
  },

  /**
   * Applies the given state to this block.
   *
   * @param state The state to apply to this block, ie the item count.
   */
  loadExtraState: function (state) {
    this.itemCount_ = state['itemCount'];
    this.updateShape_();
  },

  /**
   * Modify this block to have the correct number of inputs.
   *
   */
  updateShape_: function () {
    if (this.itemCount_ && this.getInput('EMPTY')) {
      this.removeInput('EMPTY');
    } else if (!this.itemCount_ && !this.getInput('EMPTY')) {
      this.itemCount_ = 2;
      this.appendDummyInput('EMPTY');
    }
    // Add new inputs.
    for (let i = 0; i < this.itemCount_; i += 1) {
      if (!this.getInput(`ADD${i}`)) {
        const input = this.appendValueInput(`ADD${i}`).setAlign(Blockly.inputs.Align.RIGHT);
      }
    }
    const block = this;
    if (this.itemCount_ > 1) {
      if (this.getInput('REMOVE')) {
        this.removeInput('REMOVE');
      }
      this.appendDummyInput('REMOVE')
        .appendField(new Blockly.FieldImage(options.inlineSVG.REMOVE_IMAGE_DATAURI, 24, 24, 'Remove Item', block.removeItem_.bind(block)))
        .setAlign(Blockly.inputs.Align.RIGHT);
    }
    if (this.getInput('ADD')) {
      this.removeInput('ADD');
    }
    this.appendDummyInput('ADD')
      .appendField(new Blockly.FieldImage(options.inlineSVG.ADD_IMAGE_DATAURI, 24, 24, 'Add Item', block.addItem_.bind(block)))
      .setAlign(Blockly.inputs.Align.RIGHT);

    // Remove deleted inputs.
    for (let i = this.itemCount_; this.getInput(`ADD${i}`); i += 1) {
      this.removeInput(`ADD${i}`);
    }
  },

  removeItem_: function () {
    if (this.itemCount_ > 1) {
      this.itemCount_ -= 1;
      if (this.itemCount_ === 1 && this.getInput('REMOVE')) {
        this.removeInput('REMOVE');
      }
      this.updateShape_();
      this.workspace.fireChangeListener(this);
    }
  },

  addItem_: function () {
    this.itemCount_ += 1;
    this.updateShape_();
    this.workspace.fireChangeListener(this);
  },

};

Blockly.Blocks.text_append = {
  init: function () {
    this.setColour(colors.COLOUR_TEXT);
    this.appendValueInput('VAR')
      .appendField(Blockly.Msg.LANG_TEXT_APPEND_TO);
    this.appendValueInput('TEXT')
      .appendField(Blockly.Msg.LANG_TEXT_APPEND_APPENDTEXT);
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.setInputsInline(true);
    this.setTooltip(Blockly.Msg.LANG_TEXT_APPEND_TOOLTIP);
  },
};

Blockly.Blocks.text_length = {
  init: function () {
    this.setOutputShape(options.SHAPES.ROUND);
    this.setColour(colors.COLOUR_TEXT);
    this.appendValueInput('VALUE')
      .setCheck([String, Array])
      .appendField(Blockly.Msg.LANG_TEXT_LENGTH_INPUT_LENGTH);
    this.setOutput(true, 'Number');
    this.setTooltip(Blockly.Msg.LANG_TEXT_LENGTH_TOOLTIP);
  },
};

Blockly.Blocks.text_equalsIgnoreCase = {
  init: function () {
    this.setOutputShape(options.SHAPES.HEXAGONAL);
    this.setColour(colors.COLOUR_TEXT);
    this.appendValueInput('STRING1')
      .setCheck('String')
      .appendField(Blockly.Msg.LANG_TEXT_EQUALSIGNORECASE_IS);
    this.appendValueInput('STRING2')
      .appendField(Blockly.Msg.LANG_TEXT_EQUALSIGNORECASE_EQUAL)
      .setAlign(Blockly.ALIGN_RIGHT);
    this.appendDummyInput()
      .appendField(Blockly.Msg.LANG_TEXT_EQUALSIGNORECASE_QUESTION);
    this.setInputsInline(true);
    this.setOutput(true);
    this.setTooltip(Blockly.Msg.LANG_TEXT_EQUALSIGNORECASE_TOOLTIP);
  },
};

Blockly.Blocks.text_substring = {
  init: function () {
    this.setOutputShape(options.SHAPES.ROUND);
    this.setColour(colors.COLOUR_TEXT);
    this.appendValueInput('STRING1')
      .appendField(Blockly.Msg.LANG_TEXT_SUBSTRING);

    this.appendValueInput('FROM')
      .appendField(Blockly.Msg.LANG_TEXT_SUBSTRING_FROM)
      .setCheck(Number);

    this.appendValueInput('TO')
      .appendField(Blockly.Msg.LANG_TEXT_SUBSTRING_TO)
      .setCheck(Number)
      .setAlign(Blockly.ALIGN_RIGHT);

    this.setInputsInline(true);
    this.setOutput(true);
    this.setTooltip(Blockly.Msg.LANG_TEXT_SUBSTRING_TOOLTIP);
  },
};

Blockly.Blocks.text_printcode = {
  init: function () {
    this.setColour(colors.COLOUR_TEXT);
    this.appendValueInput('TEXT')
      .appendField(Blockly.Msg.TEXT_PRINTCODE_TITLE);
    this.appendDummyInput()
      .appendField(Blockly.Msg.LANG_TEXT_PRINTCODE_QUESTION);

    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.setInputsInline(true);
    this.setTooltip(Blockly.Msg.LANG_TEXT_PRINTCODE_TOOLTIP);
  },
};