import * as arduinoUno from './arduinoUno';
import * as arduinoUnoFullJR from './arduinoUnoFullJR';
import * as bhoot from './bhoot';
import * as bhootJR from './bhootJR';
import * as ciudadFlor from './ciudadFlor';
import * as ciudadLuces from './ciudadLuces';
import * as ciudadPlanetario from './ciudadPlanetario';
import * as ciudadSemaforos from './ciudadSemaforos';
import * as ciudadTren from './ciudadTren';
import * as codit from './codit';
import * as copaRoboticaCodigo from './copaRoboticaCodigo';
import * as copaRoboticaInmunologico from './copaRoboticaInmunologico';
import * as createcFloralis from './createcFloralis';
import * as createcMadero from './createcMadero';
import * as createcObelisco from './createcObelisco';
import * as createcPlanetario from './createcPlanetario';
import * as createcRetiro from './createcRetiro';
import * as drone from './drone';
import * as drone13 from './drone13';
import * as microbitFull from './microbitFull';
import * as microbitFull2 from './microbitFull2';
import * as microbitFullJR from './microbitFullJR';
import * as smartTeamLibro1Capitulo1 from './smartTeamLibro1Capitulo1';
import * as smartTeamLibro1Capitulo2 from './smartTeamLibro1Capitulo2';
import * as smartTeamLibro1Capitulo3 from './smartTeamLibro1Capitulo3';
import * as smartTeamLibro1Capitulo4 from './smartTeamLibro1Capitulo4';
import * as smartTeamLibro1Capitulo5 from './smartTeamLibro1Capitulo5';
import * as smartTeamLibro2Capitulo1 from './smartTeamLibro2Capitulo1';
import * as smartTeamLibro2Capitulo2 from './smartTeamLibro2Capitulo2';
import * as smartTeamLibro2Capitulo3 from './smartTeamLibro2Capitulo3';
import * as smartTeamLibro2Capitulo4 from './smartTeamLibro2Capitulo4';
import * as smartTeamLibro2Capitulo5 from './smartTeamLibro2Capitulo5';
import * as smartTeamLibro3Capitulo1 from './smartTeamLibro3Capitulo1';
import * as smartTeamLibro4Capitulo1 from './smartTeamLibro4Capitulo1';
import * as smartTeamLibro5Capitulo1 from './smartTeamLibro5Capitulo1';
import * as smartTeamLibro6Capitulo1 from './smartTeamLibro6Capitulo1';
import * as zonda from './zonda';
import * as creabotsInitiation from './creabotsInitiation';
import * as creabotsAutomation from './creabotsAutomation';
import * as creabotsMovement from './creabotsMovement';
import * as bhootAi from './bhootAi';
import * as lgo20 from './lgo20';


export default {
  arduinoUno,
  zonda,
  arduinoUnoFullJR,
  microbitFullJR,
  microbitFull,
  microbitFull2,
  bhoot,
  bhootJR,
  drone,
  drone13,
  codit,
  ciudadSemaforos,
  ciudadLuces,
  ciudadFlor,
  ciudadTren,
  ciudadPlanetario,
  createcFloralis,
  createcMadero,
  createcObelisco,
  createcPlanetario,
  createcRetiro,
  smartTeamLibro1Capitulo1,
  smartTeamLibro1Capitulo2,
  smartTeamLibro1Capitulo3,
  smartTeamLibro1Capitulo4,
  smartTeamLibro1Capitulo5,
  smartTeamLibro2Capitulo1,
  smartTeamLibro2Capitulo2,
  smartTeamLibro2Capitulo3,
  smartTeamLibro2Capitulo4,
  smartTeamLibro2Capitulo5,
  smartTeamLibro3Capitulo1,
  smartTeamLibro4Capitulo1,
  smartTeamLibro5Capitulo1,
  smartTeamLibro6Capitulo1,
  copaRoboticaInmunologico,
  copaRoboticaCodigo,
  creabotsInitiation,
  creabotsAutomation,
  creabotsMovement,
  bhootAi,
  lgo20,
};
