/* eslint import/no-extraneous-dependencies: 0 */
/* eslint no-underscore-dangle: 0 */
/* eslint object-shorthand: 0 */
/* eslint dot-notation: 0 */
/* eslint spaced-comment: 0 */
import * as Blockly from 'blockly';
//import { FieldColour } from '@blockly/field-colour';
import colors from '../utils/colors';
import options from '../utils/blocksOptions';


Blockly.Blocks.ENTRADAS_button = {
  init: function () {
    this.setOutputShape(options.SHAPES.ROUND);
    this.setColour(colors.COLOUR_ENTRADAS);
    this.appendDummyInput('image')
      .appendField(new Blockly.FieldImage(`${options.imgBase}/blocks/educablocks/16.svg`, options.blockWidth, options.blockHeight))
      .appendField(Blockly.Msg.LANG_SALIDAS_RAYITA)
    this.appendDummyInput()
      .appendField(Blockly.Msg.LANG_ENTRADAS_BUTTON_PIN)
      .appendField(new Blockly.FieldDropdown(
        () => {
          return Blockly.getMainWorkspace().getHardwarePinsCombo('digital', `${this.component}_NPIN`)
        },
      ), 'NPIN');
    this.setInputsInline(true);
    this.setOutput(true);
    this.setTooltip(Blockly.Msg.LANG_ENTRADAS_BUTTON_TOOLTIP);
  },
  getPins: function () { return ['NPIN']; },
  component: 'button',
}

Blockly.Blocks.ENTRADAS_button_touch = {
  init: function () {
    this.setOutputShape(options.SHAPES.ROUND);
    this.setColour(colors.COLOUR_ENTRADAS);
    this.appendDummyInput('image')
      .appendField(new Blockly.FieldImage(`${options.imgBase}/blocks/educablocks/04.svg`, options.blockWidth, options.blockHeight))
      .appendField(Blockly.Msg.LANG_SALIDAS_RAYITA)
    this.appendDummyInput()
      .appendField(Blockly.Msg.LANG_ENTRADAS_TOUCH)
      .appendField(Blockly.Msg.LANG_ENTRADAS_TOUCH_PIN)
      .appendField(new Blockly.FieldDropdown(
        () => {
          return Blockly.getMainWorkspace().getHardwarePinsCombo('digital', `${this.component}_NPIN`)
        },
      ), 'NPIN');
    this.setInputsInline(true);
    this.setOutput(true);
    this.setTooltip(Blockly.Msg.LANG_ENTRADAS_TOUCH_TOOLTIP);
  },
}

Blockly.Blocks.entradas_ultrasonido = {
  init: function () {
    this.setOutputShape(options.SHAPES.ROUND);
    this.setColour(colors.COLOUR_ENTRADAS);
    this.appendDummyInput('image')
      .appendField(new Blockly.FieldImage(`${options.imgBase}/blocks/educablocks/09.svg`, options.blockWidth, options.blockHeight))
      .appendField(Blockly.Msg.LANG_SALIDAS_RAYITA)
    this.appendDummyInput()
      .appendField(Blockly.Msg.LANG_ENTRADAS_ULTRASONIDO)
      .appendField(new Blockly.FieldDropdown(
        () => {
          return Blockly.getMainWorkspace().getHardwarePinsCombo('ultrasonic', `${this.component}_NPIN`)
        },
      ), 'NPIN');
    this.setInputsInline(true);
    this.setOutput(true, Number);
    this.setTooltip(Blockly.Msg.LANG_ENTRADAS_ULTRASONIDO_TOOLTIP);
  },
  getPins: function () { return ['NPIN']; },
  component: 'ultrasonic',
}

Blockly.Blocks.SALIDAS_read_dht11 = {
  init: function () {
    this.setOutputShape(options.SHAPES.ROUND);
    this.setColour(colors.COLOUR_ENTRADAS);
    this.appendDummyInput('image')
      .appendField(new Blockly.FieldImage(`${options.imgBase}/blocks/educablocks/06.svg`, options.blockWidth, options.blockHeight))
      .appendField(Blockly.Msg.LANG_SALIDAS_RAYITA)
    this.appendDummyInput()
      .appendField(new Blockly.FieldDropdown(
        [
          [Blockly.Msg.LANG_ENTRADAS_HUMEDAD, 'H'],
          [Blockly.Msg.LANG_ENTRADAS_TEMPERATURA, 'T'],
        ],
      ), 'TYPE');
    this.appendDummyInput()
      .appendField(Blockly.Msg.LANG_ENTRADAS_DHT11_PIN)
      .appendField(new Blockly.FieldDropdown(
        () => {
          return Blockly.getMainWorkspace().getHardwarePinsCombo('digital', `${this.component}_NPIN`)
        },
      ), 'NPIN');
    this.setInputsInline(true);
    this.setOutput(true);
    this.setTooltip(Blockly.Msg.LANG_ENTRADAS_READ_DHT_11_TOOLTIP);
  },
  getPins: function () { return ['NPIN']; },
  component: 'dht11',
}

Blockly.Blocks.ENTRADAS_obstaculo = {
  init: function () {
    this.setOutputShape(options.SHAPES.ROUND);
    this.setColour(colors.COLOUR_ENTRADAS);
    this.appendDummyInput('image')
      .appendField(new Blockly.FieldImage(`${options.imgBase}/blocks/educablocks/51.svg`, options.blockWidth, options.blockHeight))
      .appendField(Blockly.Msg.LANG_SALIDAS_RAYITA)
    this.appendDummyInput()
      .appendField(Blockly.Msg.LANG_ENTRADAS_OBSTACULO)
      .appendField(new Blockly.FieldDropdown(
        () => {
          return Blockly.getMainWorkspace().getHardwarePinsCombo('digital', `${this.component}_NPIN`)
        },
      ), 'NPIN');
    this.setInputsInline(true);
    this.setOutput(true);
    this.setTooltip(Blockly.Msg.LANG_ENTRADAS_OBSTACULO_TOOLTIP);
  },
  getPins: function () { return ['NPIN']; },
  component: 'obstacle',
}

Blockly.Blocks.entradas_hall = {
  init: function () {
    this.setOutputShape(options.SHAPES.ROUND);
    this.setColour(colors.COLOUR_ENTRADAS);
    this.appendDummyInput('image')
      .appendField(new Blockly.FieldImage(`${options.imgBase}/blocks/educablocks/53.png`, options.blockWidth, options.blockHeight))
      .appendField(Blockly.Msg.LANG_SALIDAS_RAYITA)
    this.appendDummyInput()
      .appendField(Blockly.Msg.LANG_ENTRADAS_HALL)
      .appendField(new Blockly.FieldDropdown(
        () => {
          return Blockly.getMainWorkspace().getHardwarePinsCombo('digital', `${this.component}_NPIN`)
        },
      ), 'NPIN');
    this.setInputsInline(true);
    this.setOutput(true);
    this.setTooltip(Blockly.Msg.LANG_ENTRADAS_HALL_TOOLTIP);
  },
  getPins: function () { return ['NPIN']; },
  component: 'hall',
}

Blockly.Blocks.ENTRADAS_infrared = {
  init: function () {
    this.setOutputShape(options.SHAPES.ROUND);
    this.setColour(colors.COLOUR_ENTRADAS);
    this.appendDummyInput('image')
      .appendField(new Blockly.FieldImage(`${options.imgBase}/blocks/educablocks/52.png`, options.blockWidth, options.blockHeight))
      .appendField(Blockly.Msg.LANG_SALIDAS_RAYITA)
    this.appendDummyInput()
      .appendField(Blockly.Msg.LANG_ENTRADAS_INFRARED)
      .appendField(new Blockly.FieldDropdown(
        () => {
          return Blockly.getMainWorkspace().getHardwarePinsCombo('digital', `${this.component}_NPIN`)
        },
      ), 'NPIN');
    this.setInputsInline(true);
    this.setOutput(true);
    this.setTooltip(Blockly.Msg.LANG_ENTRADAS_INFRARED_TOOLTIP);
  },
  getPins: function () { return ['NPIN']; },
  component: 'infrared',
}

Blockly.Blocks.SALIDAS_read_ds18b20 = {
  init: function () {
    this.setOutputShape(options.SHAPES.ROUND);
    this.setColour(colors.COLOUR_ENTRADAS);
    this.appendDummyInput('image')
      .appendField(new Blockly.FieldImage(`${options.imgBase}/blocks/educablocks/54.png`, options.blockWidth, options.blockHeight))
      .appendField(Blockly.Msg.LANG_SALIDAS_RAYITA)
    this.appendDummyInput()
      .appendField(Blockly.Msg.LANG_ENTRADAS_DS18B20_PIN)
      .appendField(new Blockly.FieldDropdown(
        () => {
          return Blockly.getMainWorkspace().getHardwarePinsCombo('digital', `${this.component}_NPIN`)
        },
      ), 'NPIN');
    this.setInputsInline(true);
    this.setOutput(true);
    this.setTooltip(Blockly.Msg.LANG_ENTRADAS_TEMPERATURA_DS18B20_TOOLTIP);
  },
  getPins: function () { return ['NPIN']; },
  component: 'ds18b20',
}

Blockly.Blocks.SALIDAS_IR = {
  init: function () {
    this.setOutputShape(options.SHAPES.HEXAGONAL);
    this.setColour(colors.COLOUR_ENTRADAS);
    this.appendDummyInput('image')
      .appendField(new Blockly.FieldImage(`${options.imgBase}/blocks/educablocks/55a.png`, options.blockWidth, options.blockHeight))
      .appendField(Blockly.Msg.LANG_SALIDAS_RAYITA)
    this.appendDummyInput()
      .appendField(Blockly.Msg.LANG_ENTRADAS_IR)
      .appendField(new Blockly.FieldDropdown(
        () => {
          return Blockly.getMainWorkspace().getHardwarePinsCombo('digital', `${this.component}_NPIN`)
        },
      ), 'NPIN');
    this.appendDummyInput('image')
      .appendField(Blockly.Msg.LANG_ENTRADAS_IRCONTROL2)
      .appendField(new Blockly.FieldImage(`${options.imgBase}/blocks/educablocks/55b.png`, options.blockWidth, options.blockHeight))
    this.appendDummyInput()
      .appendField(Blockly.Msg.LANG_ENTRADAS_IRCONTROL2_PIN)
      .appendField(new Blockly.FieldDropdown([
        [Blockly.Msg.LANG_SALIDAS_LED_PIN13 || '1', '16738455'],
        [Blockly.Msg.LANG_SALIDAS_LED_PIN12 || '2', '16750695'],
        [Blockly.Msg.LANG_SALIDAS_LED_PIN11 || '3', '16756815'],
        [Blockly.Msg.LANG_SALIDAS_LED_PIN10 || '4', '16724175'],
        [Blockly.Msg.LANG_SALIDAS_LED_PIN9 || '5', '16718055'],
        [Blockly.Msg.LANG_SALIDAS_LED_PIN8 || '6', '16743045'],
        [Blockly.Msg.LANG_SALIDAS_LED_PIN7 || '7', '16716015'],
        [Blockly.Msg.LANG_SALIDAS_LED_PIN6 || '8', '16726215'],
        [Blockly.Msg.LANG_SALIDAS_LED_PIN5 || '9', '16734885'],
        [Blockly.Msg.LANG_SALIDAS_LED_PIN4 || '0', '16730805'],
        [Blockly.Msg.LANG_SALIDAS_LED_PIN3 || 'OK', '16712445'],
        [Blockly.Msg.LANG_SALIDAS_LED_PIN2 || '*', '16728765'],
        [Blockly.Msg.LANG_SALIDAS_LED_PIN1 || '#', '16732845'],
        [Blockly.Msg.LANG_SALIDAS_LED_PIN0 || '←', '16720605'],
        [Blockly.Msg.LANG_SALIDAS_LED_PIN14 || '↑', '16736925'],
        [Blockly.Msg.LANG_SALIDAS_LED_PIN15 || '→', '16761405'],
        [Blockly.Msg.LANG_SALIDAS_LED_PIN16 || '↓', '16754775'],
      ]), 'NUM');
    this.setInputsInline(true);
    this.setOutput(true);
    this.setTooltip(Blockly.Msg.LANG_ENTRADAS_IR_TOOLTIP);
  },
  getPins: function () { return ['NPIN']; },
  component: 'infrared',
}

Blockly.Blocks.ENTRADAS_VIBRATION = {
  init: function () {
    this.setOutputShape(options.SHAPES.ROUND);
    this.setColour(colors.COLOUR_ENTRADAS);
    this.appendDummyInput('image')
      .appendField(new Blockly.FieldImage(`${options.imgBase}/blocks/educablocks/40.png`, options.blockWidth, options.blockHeight))
      .appendField(Blockly.Msg.LANG_SALIDAS_RAYITA)
    this.appendDummyInput()
      .appendField(Blockly.Msg.LANG_ENTRADAS_VIBRATION)
      .appendField(new Blockly.FieldDropdown(
        () => {
          return Blockly.getMainWorkspace().getHardwarePinsCombo('digital', `${this.component}_NPIN`)
        },
      ), 'NPIN');
    this.setInputsInline(true);
    this.setOutput(true);
    this.setTooltip(Blockly.Msg.LANG_ENTRADAS_VIBRATION_TOOLTIP);
  },
  getPins: function () { return ['NPIN']; },
  component: 'vibration',
}

Blockly.Blocks.ENTRADAS_TILT = {
  init: function () {
    this.setOutputShape(options.SHAPES.ROUND);
    this.setColour(colors.COLOUR_ENTRADAS);
    this.appendDummyInput('image')
      .appendField(new Blockly.FieldImage(`${options.imgBase}/blocks/educablocks/41.png`, options.blockWidth, options.blockHeight))
      .appendField(Blockly.Msg.LANG_SALIDAS_RAYITA)
    this.appendDummyInput()
      .appendField(Blockly.Msg.LANG_ENTRADAS_TILT)
      .appendField(new Blockly.FieldDropdown(
        () => {
          return Blockly.getMainWorkspace().getHardwarePinsCombo('digital', `${this.component}_NPIN`)
        },
      ), 'NPIN');
    this.setInputsInline(true);
    this.setOutput(true);
    this.setTooltip(Blockly.Msg.LANG_ENTRADAS_TILT_TOOLTIP);
  },
  getPins: function () { return ['NPIN']; },
  component: 'tilt',
}

Blockly.Blocks.ENTRADAS_CHOQUE = {
  init: function () {
    this.setOutputShape(options.SHAPES.ROUND);
    this.setColour(colors.COLOUR_ENTRADAS);
    this.appendDummyInput('image')
      .appendField(new Blockly.FieldImage(`${options.imgBase}/blocks/educablocks/42.png`, options.blockWidth, options.blockHeight))
      .appendField(Blockly.Msg.LANG_SALIDAS_RAYITA)
    this.appendDummyInput()
      .appendField(Blockly.Msg.LANG_ENTRADAS_CHOQUE)
      .appendField(new Blockly.FieldDropdown(
        () => {
          return Blockly.getMainWorkspace().getHardwarePinsCombo('digital', `${this.component}_NPIN`)
        },
      ), 'NPIN');
    this.setInputsInline(true);
    this.setOutput(true);
    this.setTooltip(Blockly.Msg.LANG_ENTRADAS_CHOQUE_TOOLTIP);
  },
  getPins: function () { return ['NPIN']; },
  component: 'choque',
}

Blockly.Blocks.ENTRADAS_follower = {
  init: function () {
    this.setColour(colors.COLOUR_ENTRADAS)
    this.appendDummyInput('image')
      .appendField(Blockly.Msg.LANG_ENTRADAS_FOLLOWER)
      .appendField(new Blockly.FieldImage(`${options.imgBase}/blocks/educablocks/52.svg`, options.blockWidth, options.blockHeight))
    this.appendDummyInput()
      .appendField(Blockly.Msg.LANG_ENTRADAS_FOLLOWER_PIN_LEFT)
      .appendField(new Blockly.FieldDropdown(
        () => {
          return Blockly.getMainWorkspace().getHardwarePinsCombo('digital', `${this.component}_NPIN`)
        },
      ), 'NPIN');
    this.appendDummyInput()
      .appendField(Blockly.Msg.LANG_ENTRADAS_FOLLOWER_PIN_RIGHT)
      .appendField(new Blockly.FieldDropdown(
        () => {
          return Blockly.getMainWorkspace().getHardwarePinsCombo('digital', `${this.component}_NPIN2`)
        },
      ), 'NPIN2');
    this.appendStatementInput('SENS1')
      .appendField(Blockly.Msg.LANG_ENTRADAS_FOLLOWER_LEFT)
    this.appendStatementInput('SENS2')
      .appendField(Blockly.Msg.LANG_ENTRADAS_FOLLOWER_RIGHT)
    this.appendStatementInput('SENS3')
      .appendField(Blockly.Msg.LANG_ENTRADAS_FOLLOWER_NINGUNO)
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setTooltip(Blockly.Msg.LANG_ENTRADAS_FOLLOWER_TOOLTIP);
  },
  getPins: function () { return ['NPIN', 'NPIN2']; },
  component: 'follower',
}

Blockly.Blocks.inout_digital_read = {
  init: function () {
    this.setOutputShape(options.SHAPES.ROUND)
    this.setColour(colors.COLOUR_ENTRADAS)
    this.appendDummyInput()
      .appendField(Blockly.Msg.LANG_ADVANCED_INOUT_DIGITAL_READ)
      .appendField(new Blockly.FieldDropdown(
        () => {
          return Blockly.getMainWorkspace().getHardwarePinsCombo('digital', 'PIN')
        },
      ), 'NPIN');
    this.setInputsInline(true);
    this.setOutput(true, Number);
    this.setTooltip(Blockly.Msg.LANG_ADVANCED_INOUT_DIGITAL_READ);
  },
}