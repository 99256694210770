/* eslint no-underscore-dangle: 0 */
/* eslint camelcase: 0 */
/* eslint dot-notation: 0 */
/* eslint no-param-reassign: 0 */
/* eslint no-else-return: 0 */
export const text = (block, generator) => {
  // const code = generator.quote_(block.getFieldValue('TEXT'));
  const code = `"${block.getFieldValue('TEXT').replace(/"|'/g, '')}"`;

  return [code, generator.ORDER_ATOMIC];
}

export const text_unir = (block, generator) => {
  if (block.itemCount_ <= 0) {
    return ['\'\'', generator.ORDER_ATOMIC];
  } else {
    let code = '';
    let item;
    for (let i = 0; i < block.itemCount_; i += 1) {
      item = generator.valueToCode(block, `ADD${i}`, generator.ORDER_NONE) || '';
      code += `String(${item})`;
      if (i !== block.itemCount_ - 1) {
        code += ' + ';
      }
    }

    return [code, generator.ORDER_UNARY_POSTFIX];
  }
};

export const text_append = (block, generator) => {
  const variable = generator.valueToCode(block, 'VAR', generator.ORDER_NONE) || '';
  const newText = generator.valueToCode(block, 'TEXT', generator.ORDER_NONE) || '';
  return `${variable} += String(${newText})`;
};

export const text_length = (block, generator) => {
  const value = generator.valueToCode(block, 'VALUE', generator.ORDER_NONE) || '';
  return [`${value}.length()`, generator.ORDER_ATOMIC];
};

export const text_equalsIgnoreCase = (block, generator) => {
  const string1 = generator.valueToCode(block, 'STRING1', generator.ORDER_NONE) || '';
  const string2 = generator.valueToCode(block, 'STRING2', generator.ORDER_NONE) || '';
  return [`${string1}.equalsIgnoreCase(${string2})`, generator.ORDER_ATOMIC];
};

export const text_substring = (block, generator) => {
  const string1 = generator.valueToCode(block, 'STRING1', generator.ORDER_NONE) || '';
  const from = generator.valueToCode(block, 'FROM', generator.ORDER_NONE) || '';
  const to = generator.valueToCode(block, 'TO', generator.ORDER_NONE) || '';
  return [`${string1}.substring(${from}, ${to})`, generator.ORDER_ATOMIC];
};

export const text_printcode = (block, generator) => {
  const code = generator.valueToCode(block, 'TEXT', generator.ORDER_NONE) || '';
  return code.replace(/^"|"$/g, ''); // Remove leading and trailing quotes
};